import { useMutation } from "@apollo/client";
import type {
  JobberPaymentsLimitsChangeRequest,
  JobberPaymentsLimitsChangeRequestCancelMutation,
  JobberPaymentsLimitsChangeRequestCancelMutationVariables,
} from "~/utilities/API/graphql";
import { JOBBER_PAYMENTS_LIMIT_CHANGE_REQUEST_CANCEL } from "./JobberPaymentsLimitsChangeRequestCancel.graphql";

export interface CancelPaymentLimitsRequestInput {
  requestId: string;
}

export const useJobberPaymentsLimitsChangeRequestCancel = (
  requestId?: string,
) => {
  const [createPaymentsLimitsRequestMutation] = useMutation<
    JobberPaymentsLimitsChangeRequestCancelMutation,
    JobberPaymentsLimitsChangeRequestCancelMutationVariables
  >(JOBBER_PAYMENTS_LIMIT_CHANGE_REQUEST_CANCEL, {
    fetchPolicy: "no-cache",
  });

  const cancelPaymentsLimitsRequest = async (): Promise<{
    jobberPaymentsLimitsChangeRequest:
      | JobberPaymentsLimitsChangeRequest
      | undefined;
    errorMessage?: string;
  }> => {
    if (!requestId) {
      return {
        jobberPaymentsLimitsChangeRequest: undefined,
        errorMessage: "Request ID is required",
      };
    }

    const variables: JobberPaymentsLimitsChangeRequestCancelMutationVariables =
      {
        input: { requestId },
      };

    try {
      const result = await createPaymentsLimitsRequestMutation({
        variables,
      });

      return {
        jobberPaymentsLimitsChangeRequest:
          result.data?.jobberPaymentsLimitsChangeRequestCancel
            .jobberPaymentsLimitsChangeRequest,
      };
    } catch (error) {
      return {
        jobberPaymentsLimitsChangeRequest: undefined,
        errorMessage: error.message,
      };
    }
  };

  return { cancelPaymentsLimitsRequest };
};
