import { defineMessages } from "react-intl";

export const messages = defineMessages({
  primaryButton: {
    id: "setupGuide.connectStep.primaryButton",
    defaultMessage: "Connect",
    description: "Button that navigates to the connect step",
  },
  secondaryButton: {
    id: "setupGuide.connectStep.secondaryButton",
    defaultMessage: "Set a profile up for free",
    description: "Button to set up a profile for free",
  },
});
