import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import type { Template } from "~/utilities/API/graphql";
import { Carousel } from "components/Carousel";
import { AutomatedCampaignsPromptCard } from "jobber/campaigns/views/CampaignsLandingPage/components/EducationCards/AutomatedCampaignsPromptCard/AutomatedCampaignsPromptCard";
import { EducationVideoCard } from "./EducationVideoCard";
import { ExploreTemplatesCard } from "./ExploreTemplatesCard";
import { EDUCATION_CARDS_QUERY } from "./EducationCards.graphql";
import styles from "./EducationCards.module.css";

export function EducationCards() {
  const [slideIndex, setSlideIndex] = useState(0);
  const [activeDisclosure, setActiveDisclosure] = useState<Template | null>(
    null,
  );

  const { data } = useQuery(EDUCATION_CARDS_QUERY);

  const visibleCardTypes = {
    automatedCampaigns: data?.automatedCampaignsCta?.shouldShow,
    educationVideo: data?.educationVideoCta?.shouldShow,
    exploreTemplates: data?.exploreTemplatesCta?.shouldShow,
  };

  const handleDismiss = (ctaType: string) => {
    // Get array of visible cards in their fixed order
    const visibleCardsInOrder = [
      visibleCardTypes.automatedCampaigns && "automatedCampaigns",
      visibleCardTypes.educationVideo && "educationVideo",
      visibleCardTypes.exploreTemplates && "exploreTemplates",
    ].filter(Boolean) as string[];

    // Find the index of the dismissed card
    const dismissedIndex = visibleCardsInOrder.indexOf(ctaType);

    // If dismissing the first card, show the next card (index stays 0)
    if (dismissedIndex === 0) {
      setSlideIndex(0);
      return;
    }

    // For any other card, show the previous card
    setSlideIndex(dismissedIndex - 1);
  };

  const visibleCount = Object.values(visibleCardTypes).filter(Boolean).length;
  const isSingleCard = visibleCount === 1;
  const showIndicators = visibleCount > 1;

  if (visibleCount === 0) {
    return null;
  }

  const renderCards = (mobile: boolean) =>
    [
      visibleCardTypes.automatedCampaigns && (
        <AutomatedCampaignsPromptCard
          key="automatedCampaignsPromptCard"
          onDismiss={() => handleDismiss("automatedCampaigns")}
          activeDisclosure={activeDisclosure}
          onToggleDisclosure={setActiveDisclosure}
        />
      ),
      visibleCardTypes.educationVideo && (
        <EducationVideoCard
          key="educationVideoCard"
          onDismiss={() => handleDismiss("educationVideo")}
          useExpandedLayout={!mobile && isSingleCard}
        />
      ),
      visibleCardTypes.exploreTemplates && (
        <ExploreTemplatesCard
          key="exploreTemplatesCard"
          onDismiss={() => handleDismiss("exploreTemplates")}
          useExpandedLayout={!mobile && isSingleCard}
        />
      ),
    ].filter(Boolean);

  return (
    <div data-testid="education-cards-container">
      {/* Desktop view */}
      <div
        data-testid="education-cards-desktop-container"
        className={styles.desktopCards}
      >
        {renderCards(false)}
      </div>

      {/* Mobile view */}
      <div
        data-testid="education-cards-mobile-container"
        className={styles.mobileCards}
      >
        <Carousel
          showIndicators={showIndicators}
          slideIndex={slideIndex}
          onIndexChange={setSlideIndex}
        >
          {renderCards(true)}
        </Carousel>
      </div>
    </div>
  );
}
