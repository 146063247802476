import { useQuery } from "@apollo/client";
import { REVIEW_BENCHMARK_INSIGHTS_QUERY } from "../components/ReviewsBenchmark/ReviewBenchmarkInsights.graphql";

export interface ReviewBenchmarkInsights {
  aiInsights?: string;
}

export function useReviewBenchmarkInsightsQuery() {
  const { loading, data, error, refetch } = useQuery<{
    reviewBenchmarkInsights: ReviewBenchmarkInsights;
  }>(REVIEW_BENCHMARK_INSIGHTS_QUERY);
  return {
    insights: data?.reviewBenchmarkInsights,
    loading,
    error,
    refetch,
  };
}
