import { defineMessages } from "react-intl";

export const messages = defineMessages({
  error: {
    id: "reviews.ReviewsBenchmark.error",
    defaultMessage: "Error: {message}",
    description: "Error message when loading competitor data fails",
  },
  reviews: {
    id: "reviews.ReviewsBenchmark.reviews",
    defaultMessage: "reviews",
    description: "Text displayed after the number of reviews",
  },
  sp: {
    id: "reviews.ReviewsBenchmark.sp",
    defaultMessage: "Your business",
    description: "Text displayed after the number of reviews",
  },
  benchmarkTitle: {
    id: "reviews.benchmark.benchmarkTitle",
    defaultMessage: "Competitor analysis",
    description: "Title for the reviews benchmark section",
  },
  cardHeaderTooltipMessage: {
    id: "reviews.benchmark.cardHeaderTooltipMessage",
    defaultMessage:
      "Your competitor's average rating and review count compared to yours. Higher ratings boost credibility, while more reviews improve visibility.",
    description:
      "Tooltip message explaining the competitor's average rating and review count.",
  },
  avgCompetitorRatingHeading: {
    id: "reviews.benchmark.avgCompetitorRatingHeading",
    defaultMessage: "Average competitor rating",
    description: "Heading for the average competitor rating section.",
  },
  avgCompetitorRatingNumber: {
    id: "reviews.benchmark.avgCompetitorRatingNumber",
    defaultMessage: "{avgCompetitorRating}/5",
    description: "Text displaying the average competitor rating number.",
  },
  avgCompetitorRating: {
    id: "reviews.benchmark.avgCompetitorRating",
    defaultMessage: "ratings",
    description: "Text displaying the average competitor rating.",
  },
  avgCompetitorReviews: {
    id: "reviews.benchmark.avgCompetitorReviews",
    defaultMessage: "reviews",
    description: "Text displaying the average competitor reviews.",
  },
  emptyCompetitorAltText: {
    id: "reviews.benchmark.emptyCompetitorAltText",
    defaultMessage: "Empty competitor card.",
    description: "Alt text for the empty competitor list image",
  },
  emptyCompetitorHeading: {
    id: "reviews.benchmark.emptyCompetitorHeading",
    defaultMessage: "Stay ahead of the competition",
    description: "Prompt for the empty competitor card",
  },
  emptyCompetitorDescription: {
    id: "reviews.benchmark.emptyCompetitorDescription",
    defaultMessage:
      "See how you stack up against competitors by adding up to 10 businesses to your watchlist.",
    description: "Description for the empty competitor card",
  },
  addCompetitorsButton: {
    id: "reviews.benchmark.addCompetitorsButton",
    defaultMessage: "Add competitors",
    description: "Button text for adding competitors",
  },
  aiText: {
    id: "reviews.benchmark.aiText",
    defaultMessage: "Some AI text here.",
    description: "Placeholder text for AI-generated content.",
  },
});
