import React from "react";
import { Box } from "@jobber/components/Box";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import { Divider } from "@jobber/components/Divider";
// eslint-disable-next-line no-restricted-imports
import { Typography } from "@jobber/components/Typography";
import { useIntl } from "react-intl";
import forceFleetTrackingConnectImg from "./force-flee-tracking-connect.png";
import { messages } from "./messages";
import styles from "./GpsTrackingSignupOrConnectModal.module.css";

interface SignupConnectContentProps {
  onGetStarted: () => void;
  onConnect: () => void;
}

function StyledDivider({ text }: { text: string }) {
  return (
    <div className={styles.styledDividerContainer}>
      <div className={styles.dividerContainer}>
        <Divider direction="horizontal" />
      </div>
      <Typography
        element="span"
        fontWeight="bold"
        size="small"
        textCase="uppercase"
        textColor="textSecondary"
      >
        {text}
      </Typography>
      <div className={styles.dividerContainer}>
        <Divider direction="horizontal" />
      </div>
    </div>
  );
}

export function SignupConnectContent({
  onGetStarted,
  onConnect,
}: SignupConnectContentProps) {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.gpsTrackingModalLayout}>
      <div className={styles.gpsTrackingModalContentContainer}>
        <Box gap="large">
          <Box gap="base">
            <Heading level={2} element="span">
              {formatMessage(messages.signUpOrConnectContentSignUpHeading)}
            </Heading>
            <Text>
              {formatMessage(messages.signUpOrConnectContentSignUpDescription)}
            </Text>
          </Box>
          <Button label="Get Started" onClick={onGetStarted} />
        </Box>
        <StyledDivider text="OR" />
        <Box gap="large">
          <Box gap="base">
            <Heading level={4} element="span">
              {formatMessage(messages.signUpOrConnectContentConnectHeading)}
            </Heading>
            <Text>
              {formatMessage(messages.signUpOrConnectContentConnectDescription)}
            </Text>
          </Box>
          <Button
            label="Connect"
            type="secondary"
            variation="learning"
            onClick={onConnect}
          />
        </Box>
      </div>
      <div className={styles.imageContainer}>
        <img
          src={forceFleetTrackingConnectImg}
          alt={formatMessage(messages.signUpOrConnectContentImageAlt)}
        />
      </div>
    </div>
  );
}
