import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  type IntercomConversationCreateMutation,
  type IntercomConversationCreateMutationVariables,
  IntercomConversationRoute,
} from "~/utilities/API/graphql";
import { Intercom } from "utilities/chat";
import { PRIORITY_SUPPORT_INTERCOM_CONVERSATION } from "./PrioritySupportIntercomConversation.graphql";

export const useCreatePrioritySupportIntercomConversation = () => {
  const [createConversation, { loading }] = useMutation<
    IntercomConversationCreateMutation,
    IntercomConversationCreateMutationVariables
  >(PRIORITY_SUPPORT_INTERCOM_CONVERSATION);

  const createPrioritySupportIntercomConversation = async () => {
    try {
      const result = await createConversation({
        variables: {
          input: {
            conversationRouting: IntercomConversationRoute.PREMIUM_SUPPORT,
            userInitiatedMessage: "Requesting downgrade support",
            uniqueIdentifier: "plus_plan_priority_support",
          },
        },
      });
      return result.data?.intercomConversationCreate?.conversationId;
    } catch (e) {
      return undefined;
    }
  };

  return {
    createPrioritySupportIntercomConversation,
    loading,
  };
};

export const useRequestPlusPlanPrioritySupport = () => {
  const { createPrioritySupportIntercomConversation, loading } =
    useCreatePrioritySupportIntercomConversation();

  const handlePrioritySupportCall = useCallback(async () => {
    const intercomConversationId =
      await createPrioritySupportIntercomConversation();

    if (intercomConversationId) {
      callSupportChatTwice("showConversation", intercomConversationId);
    } else {
      callSupportChatTwice("showNewMessage", "");
    }
  }, [createPrioritySupportIntercomConversation]);

  return {
    handlePrioritySupportCall,
    loading,
  };
};

function callSupportChatTwice(methodName: string, argument: string) {
  /* We intentionally call the method twice because there is a bug in the Intercom,
       JS API that sometimes causes the Intercom modal to open to the wrong screen.
       A bug for this has been filed with Intercom, but for now a workaround is to
       call the API twice.
    */
  Intercom.EXECUTE(methodName, argument);
  Intercom.EXECUTE(methodName, argument);
}
