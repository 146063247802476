import { Button, Heading, Option, Select } from "@jobber/components";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { IntlProvider } from "@translations/IntlProvider";
import styles from "./UkKycComplianceRegistrationStart.module.css";
import { complianceStartMessages } from "./complianceStartMessages";

export interface UkKycComplianceRegistrationStartProps {
  onClick: (endUserType: string) => void;
  loading?: boolean;
  endUserType?: string;
}

const endUserTypeOptions = {
  business: "business",
  individual: "individual",
};

const defaultEndUserType = endUserTypeOptions.business;

function UkKycComplianceRegistrationStartContent(
  props: UkKycComplianceRegistrationStartProps,
) {
  const [endUserType, setEndUserType] = useState<string | undefined>(
    props.endUserType?.toLowerCase() || defaultEndUserType,
  );
  const { formatMessage } = useIntl();

  return (
    <div className={styles.content}>
      <Heading level={2}>
        {formatMessage(complianceStartMessages.gettingStartedHeader)}
      </Heading>
      <Select
        placeholder={"I am registering as a"}
        value={endUserType}
        onChange={e => setEndUserType(e.toString())}
      >
        <Option value={endUserTypeOptions.business}>
          {formatMessage(complianceStartMessages.business)}
        </Option>
        <Option value={endUserTypeOptions.individual}>
          {formatMessage(complianceStartMessages.individual)}
        </Option>
      </Select>
      {endUserType === endUserTypeOptions.business && (
        <div className={styles.notice}>
          {formatMessage(complianceStartMessages.businessRegistrationNotice)}
        </div>
      )}
      <Button
        label={formatMessage(complianceStartMessages.continueButton)}
        fullWidth={true}
        size={"base"}
        variation={"work"}
        loading={props.loading}
        onClick={() => {
          if (endUserType) {
            props.onClick(endUserType);
          }
        }}
      />
    </div>
  );
}

export function UkKycComplianceRegistrationStart(
  props: UkKycComplianceRegistrationStartProps,
) {
  return (
    <IntlProvider>
      <UkKycComplianceRegistrationStartContent {...props} />
    </IntlProvider>
  );
}
