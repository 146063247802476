import React from "react";
import { Redirect } from "react-router-dom";
import { StepComponent } from "./components/StepComponent/StepComponent";
import { messages } from "./messages";
import { Routes, type SetupGuideStepProps } from "../../types";
import { ConnectImage } from "../../images";

const PRIMARY_BUTTON_ACTION =
  "/automated_reviews/google_business/connect?post_oauth_redirect=%2Freviews%2Fprofile";
const SECONDARY_BUTTON_ACTION = "https://business.google.com/create";

export function ConnectStep(stepProps: SetupGuideStepProps): JSX.Element {
  const stepDetails = {
    image: {
      src: ConnectImage.src,
      alt: ConnectImage.alt,
    },
    primaryButton: {
      label: messages.primaryButton,
      action: () => {
        window.location.href = PRIMARY_BUTTON_ACTION;
      },
    },
    secondaryButton: {
      label: messages.secondaryButton,
      action: () => {
        window.open(SECONDARY_BUTTON_ACTION, "_blank");
      },
    },
  };

  if (stepProps.isConnected) {
    return <Redirect to={Routes.connected} />;
  }

  return (
    <StepComponent
      navigation={stepProps.navigation}
      stepDetails={stepDetails}
    />
  );
}
