import React from "react";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import { Markdown } from "@jobber/components/Markdown";
import { Emphasis } from "@jobber/components/Emphasis";
import { useIntl } from "react-intl";
import { Content } from "@jobber/components/Content";
import { useBreakpoints } from "@jobber/hooks/useBreakpoints";
import type { BuildStepProps } from "./types";
import styles from "./styles.module.css";

export function BuildStep({
  navigation,
  stepDetails,
}: BuildStepProps): JSX.Element {
  const { formatMessage } = useIntl();
  const { largeAndUp } = useBreakpoints();
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [secondaryButtonLoading, setSecondaryButtonLoading] =
    React.useState(false);

  return (
    <div className={styles.stepContainer}>
      <div className={styles.imgContainer}>
        <img
          className={styles.img}
          src={stepDetails.image.src}
          alt={formatMessage(stepDetails.image.alt)}
        />
      </div>
      <div className={styles.column}>
        <h1>{formatMessage(stepDetails.title)}</h1>
        {stepDetails.subtitle && (
          <div className={styles.subtitle}>
            <Text variation="subdued">
              <Emphasis variation="bold">
                {formatMessage(stepDetails.subtitle)}
              </Emphasis>
            </Text>
          </div>
        )}
        <Content>
          <Markdown content={formatMessage(stepDetails.description)} />
        </Content>
        {stepDetails.subtext && (
          <div className={styles.subtext} data-testid="subtext">
            <Markdown
              externalLink={true}
              content={formatMessage(stepDetails.subtext)}
            />
          </div>
        )}
        <div className={styles.row}>
          <Button
            fullWidth={largeAndUp ? false : true}
            type="primary"
            onClick={() => {
              setButtonLoading(true);
              if (stepDetails.primaryButton.action !== undefined) {
                stepDetails.primaryButton.action();
              } else {
                navigation.onNext();
              }
            }}
            loading={buttonLoading}
            label={formatMessage(stepDetails.primaryButton.label)}
            ariaLabel={formatMessage(stepDetails.primaryButton.label)}
          />
          {stepDetails.secondaryButton && (
            <Button
              fullWidth={largeAndUp ? false : true}
              variation="subtle"
              type="secondary"
              onClick={() => {
                setSecondaryButtonLoading(true);
                stepDetails.secondaryButton!.action();
              }}
              loading={secondaryButtonLoading}
              label={formatMessage(stepDetails.secondaryButton.label)}
              ariaLabel={formatMessage(stepDetails.secondaryButton.label)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
