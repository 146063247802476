import React, { type MutableRefObject, useRef, useState } from "react";
import { Modal } from "@jobber/components/Modal";
import { Text } from "@jobber/components/Text";
import { Content } from "@jobber/components/Content";
import { Form, type FormRef } from "@jobber/components/Form";
import { useMutation } from "@apollo/client";
import { useIntl } from "react-intl";
import { InputAddress } from "components/InputAddress/InputAddress";
import type {
  AccountEditCompanyDetailsMutation,
  AccountEditCompanyDetailsMutationVariables,
} from "~/utilities/API/graphql";
import type { AddressValues } from "components/InputAddress/types";
import type { CampaignCompanyInformationQueryAddressInformation } from "jobber/campaigns/views/CampaignsContentPage/hooks/useCampaignCompanyInformation";
import { ACCOUNT_EDIT_COMPANY_DETAILS } from "./AccountEditCompanyDetails.graphql";
import { messages } from "./messages";

interface CompanyAddressModalProps {
  showModal: boolean;
  existingAddress: CampaignCompanyInformationQueryAddressInformation;
  closeModal: () => void;
}

export function CompanyAddressModal(props: CompanyAddressModalProps) {
  const { formatMessage } = useIntl();

  const [propertyAddress, setPropertyAddress] = useState<AddressValues>(
    toFormAddress(props.existingAddress),
  );
  const formRef = useRef() as MutableRefObject<FormRef>;

  const [accountEditCompanyDetails] = useMutation<
    AccountEditCompanyDetailsMutation,
    AccountEditCompanyDetailsMutationVariables
  >(ACCOUNT_EDIT_COMPANY_DETAILS);

  return (
    <Modal
      title={messages.companyAddresModalTitle.defaultMessage}
      open={props.showModal}
      dismissible={true}
      onRequestClose={() => props.closeModal()}
      primaryAction={{
        label: "Save",
        onClick: () => {
          formRef.current.submit();
        },
      }}
      secondaryAction={{
        label: "Cancel",
        onClick: () => props.closeModal(),
      }}
    >
      <Content>
        <Text>{formatMessage(messages.companyAddressModalDescription)}</Text>
        <Form onSubmit={handleSave} ref={formRef}>
          <InputAddress
            name={"comapanyAddress-"}
            address={propertyAddress}
            onChange={setPropertyAddress}
            requireProvince={true}
            specificValidationMessages={true}
          />
        </Form>
      </Content>
    </Modal>
  );

  async function handleSave() {
    const {
      city,
      country,
      zipOrPostalCode,
      stateOrProvince,
      street1,
      street2,
    } = propertyAddress;

    const variables: AccountEditCompanyDetailsMutationVariables = {
      input: {
        street1,
        street2,
        postalCode: zipOrPostalCode,
        province: stateOrProvince,
        country,
        city,
      },
    };

    const result = await accountEditCompanyDetails({ variables });
    if (result.data?.accountEditCompanyDetails) {
      window.location.reload();
    }
    props.closeModal();
    return;
  }
}

function toFormAddress(
  address?: Partial<CampaignCompanyInformationQueryAddressInformation>,
): AddressValues {
  return {
    street1: address?.street1,
    street2: address?.street2,
    city: address?.city,
    stateOrProvince: address?.province,
    zipOrPostalCode: address?.postalCode,
    country: address?.country,
  };
}
