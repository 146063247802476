import { gql } from "@apollo/client";

export const LATEST_REVIEW_BENCHMARKS_QUERY = gql`
  query latestReviewBenchmarks {
    latestReviewBenchmarks {
      nodes {
        id
        googlePlaceId
        averageRating
        totalReviews
      }
    }
  }
`;
