import { camelCase } from "lodash";
import type { UserPermission } from "~/utilities/API/graphql";
import type {
  Feature,
  FeatureName,
} from "~/utilities/contexts/internal/AccountContext";
import type { AuthorizationRules } from "./authorizationTypes";

export type UserPermissions = Partial<
  Record<UserPermission["name"], UserPermission>
>;

/**
 * This is where we add authorization rules
 * Adding an AuthorizationRule will allow you to call `can("action", "Subject")`
 */
export function authorizationRules(
  isAdmin: boolean,
  userPermissions: UserPermission[],
  accountPermission: Record<FeatureName, Feature>,
): Partial<AuthorizationRules> {
  const mappedUserPermissions = userPermissions?.reduce<UserPermissions>(
    (acc, permission) => {
      acc[camelCase(permission.name)] = permission;
      return acc;
    },
    {} as UserPermissions,
  );

  const rules: Partial<AuthorizationRules> = {
    viewJobs: {
      description: "Users can view jobs",
      action: "view",
      subject: "Jobs",
      condition: () => mappedUserPermissions.jobs?.read || false,
    },
    editJobs: {
      description: "Users can edit jobs",
      action: "edit",
      subject: "Jobs",
      condition: () => mappedUserPermissions.jobs?.write || false,
    },
    reassignAppointments: {
      description: "Users can reassign scheduled appointments",
      action: "reassign",
      subject: "Appointments",
      condition: () => mappedUserPermissions.scheduledItems?.manage || false,
    },
    completeAppointmentsAssignedToMe: {
      description: "Users can mark appointments assigned to them as completed",
      action: "complete",
      subject: "AppointmentsAssignedToMe",
      condition: () => mappedUserPermissions.scheduledItems?.create || false,
    },
    completeAppointmentsAssignedToOthers: {
      description:
        "Users can mark appointments assigned to others as completed",
      action: "complete",
      subject: "AppointmentsAssignedToOthers",
      condition: () => mappedUserPermissions.scheduledItems?.manage || false,
    },
    rescheduleAppointmentsAssignedToMe: {
      description: "Users can reschedule appointments assigned to them",
      action: "reschedule",
      subject: "AppointmentsAssignedToMe",
      condition: () => mappedUserPermissions.scheduledItems?.write || false,
    },
    rescheduleAppointmentsAssignedToOthers: {
      description: "Users reschedule appointments assigned to others",
      action: "reschedule",
      subject: "AppointmentsAssignedToOthers",
      condition: () => mappedUserPermissions.scheduledItems?.manage || false,
    },
    viewAppointmentsOfOthers: {
      description: "Users can manage the schedules of others",
      action: "view",
      subject: "AppointmentsOfOthers",
      condition: () => mappedUserPermissions.scheduledItems?.manage || false,
    },
    viewExpenses: {
      description: "Users can view expenses",
      action: "view",
      subject: "Expenses",
      condition: () => mappedUserPermissions.expenses?.read || false,
    },
    editExpenses: {
      description: "Users can edit expense entries",
      action: "edit",
      subject: "Expenses",
      condition: () => mappedUserPermissions.expenses?.write || false,
    },
    manageExpenses: {
      description: "Users can edit everyone's expense entries",
      action: "manage",
      subject: "Expenses",
      condition: () => mappedUserPermissions.expenses?.manage || false,
    },
    viewTimesheets: {
      description: "Users can view timesheet entries",
      action: "view",
      subject: "Timesheets",
      condition: () => mappedUserPermissions.timeSheetEntries?.read || false,
    },
    editTimesheets: {
      description: "Users can edit timesheet entries",
      action: "edit",
      subject: "Timesheets",
      condition: () => mappedUserPermissions.timeSheetEntries?.write || false,
    },
    manageTimesheets: {
      description: "Users can edit everyone's timesheet entries",
      action: "manage",
      subject: "Timesheets",
      condition: () => mappedUserPermissions.timeSheetEntries?.manage || false,
    },
    viewJobCosts: {
      description: "Users can view job costs",
      action: "view",
      subject: "JobCosts",
      condition: () => {
        return (
          (accountPermission.jobCosting?.enabled &&
            mappedUserPermissions.jobCosting?.read) ||
          false
        );
      },
    },
    editLabourRates: {
      description: "Users can edit default labour rates",
      action: "edit",
      subject: "LabourRates",
      condition: () => {
        return isAdmin && accountPermission.jobCosting?.enabled;
      },
    },
    viewPricing: {
      description: "Users can view pricing",
      action: "view",
      subject: "Pricing",
      condition: () => {
        return mappedUserPermissions.pricing?.read || false;
      },
    },
    viewProductsAndServices: {
      description: "Users can view products and services",
      action: "view",
      subject: "ProductsAndServices",
      condition: () => isAdmin,
    },
    viewInvoices: {
      description: "Users can view invoices",
      action: "view",
      subject: "Invoices",
      condition: () => mappedUserPermissions.invoices?.read || false,
    },
    viewQuotes: {
      description: "Users can view quotes",
      action: "view",
      subject: "Quotes",
      condition: () => mappedUserPermissions.quotes?.read || false,
    },
    createQuotes: {
      description: "Users can create quotes",
      action: "create",
      subject: "Quotes",
      condition: () => mappedUserPermissions.quotes?.create || false,
    },
    editQuotes: {
      description: "Users can edit quotes",
      action: "edit",
      subject: "Quotes",
      condition: () => mappedUserPermissions.quotes?.write || false,
    },
    deleteQuotes: {
      description: "Users can delete quotes",
      action: "delete",
      subject: "Quotes",
      condition: () => mappedUserPermissions.quotes?.delete || false,
    },
    viewReports: {
      description: "Users can view reports",
      action: "view",
      subject: "Reports",
      condition: () => mappedUserPermissions.reports?.read || false,
    },
    createCustomFields: {
      description: "Users can create custom fields",
      action: "create",
      subject: "CustomFields",
      condition: () => isAdmin,
    },
    createInvoices: {
      description: "Users can create invoices",
      action: "create",
      subject: "Invoices",
      condition: () => mappedUserPermissions.invoices?.create || false,
    },
    createQuoteNotes: {
      description: "Users can create notes on quotes",
      action: "create",
      subject: "QuoteNotes",
      condition: () => mappedUserPermissions.quoteNotes?.create || false,
    },
    createQuoteTemplates: {
      description: "Users can create quote templates",
      action: "create",
      subject: "QuoteTemplates",
      condition: () => isAdmin,
    },
  };

  return rules;
}
