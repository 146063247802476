import { Button } from "@jobber/components/Button";
import {
  DataList,
  type DataListItemType,
  type DataListSorting,
} from "@jobber/components/DataList";
import { useBreakpoints } from "@jobber/hooks/useBreakpoints";
import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Grid } from "@jobber/components/Grid";
import { Content } from "@jobber/components/Content";
import { Icon } from "@jobber/components/Icon";
import { Tooltip } from "@jobber/components/Tooltip";
import { Text } from "@jobber/components/Text";
import { generatePath, useHistory } from "react-router-dom";
import { CampaignStatus, type Campaigns } from "~/utilities/API/graphql";
import { messages } from "jobber/campaigns/views/CampaignsLandingPage/messages";
import { SORT_KEYS } from "jobber/campaigns/views/CampaignsLandingPage/hooks/useCampaignsQuery";
import {
  CAMPAIGNS_CONTENT_EDIT_PATH,
  CAMPAIGNS_REVIEW_PATH,
  CAMPAIGNS_SENT_PATH,
  CAMPAIGNS_TEMPLATE_PATH,
} from "jobber/campaigns/constants";
import { useCampaignWizardContext } from "jobber/campaigns/contexts/CampaignWizardProvider/CampaignWizardProvider";
import type { SingleCampaign } from "jobber/campaigns/views/CampaignsLandingPage/hooks/useCampaignDataListItems";
import { SplitNames, useFeatureFlag } from "utilities/split";
import styles from "./CampaignList.module.css";

export enum listAction {
  SEND_NOW = "sendNow",
  PAUSE_AND_EDIT = "pauseAndEdit",
}

interface CampaignListProps {
  readonly campaigns: SingleCampaign[];
  readonly loadingInitial?: boolean;
  readonly loadingMore?: boolean;
  readonly totalCount?: number;
  readonly loadMore?: () => void;
  setVariablesForSorting: (
    sortKey: string,
    sortDirection: "asc" | "desc" | undefined,
  ) => void;
  setSelectedCampaignId: (id: string) => void;
}

const STATUSES_ALLOWING_DELETE = [
  CampaignStatus.SCHEDULED,
  CampaignStatus.DRAFT,
];

export function CampaignList({
  campaigns,
  loadingInitial,
  loadingMore,
  totalCount = 0,
  loadMore,
  setVariablesForSorting,
  setSelectedCampaignId,
}: CampaignListProps) {
  const isCampaignsStreamlined = useFeatureFlag(
    SplitNames.CampaignsStreamlined,
  );
  const { formatMessage } = useIntl();
  const [sortingState, setSortingState] = useState<DataListSorting | undefined>(
    { order: "desc", key: "lastUpdated" },
  );

  const { largeAndUp } = useBreakpoints();
  const { push } = useHistory();

  const {
    pauseAndEdit: {
      setOpen: setPauseAndEditModalOpen,
      setCampaignId: setPauseAndEditModalCampaignId,
    },
    sendNow: {
      setCampaignId: setSentNowModalCampaignId,
      setOpen: setSentNowModalOpen,
    },
  } = useCampaignWizardContext();

  const campaignStatus = (item: SingleCampaign): CampaignStatus => {
    return (JSON.parse(item.rawData) as Campaigns).status;
  };

  // eslint-disable-next-line max-statements
  const rowAction = (item: SingleCampaign, action?: listAction) => {
    const status = campaignStatus(item);
    if (status === CampaignStatus.DRAFT) {
      if (isCampaignsStreamlined) {
        push(generatePath(CAMPAIGNS_REVIEW_PATH, { campaignId: item.id }));
      } else {
        push(
          generatePath(CAMPAIGNS_CONTENT_EDIT_PATH, { campaignId: item.id }),
        );
      }
    } else if (status === CampaignStatus.SCHEDULED) {
      if (action === listAction.PAUSE_AND_EDIT) {
        setPauseAndEditModalCampaignId(item.id);
        setPauseAndEditModalOpen(true);
      } else if (action === listAction.SEND_NOW) {
        setSentNowModalCampaignId(item.id);
        setSentNowModalOpen(true);
      } else {
        setPauseAndEditModalCampaignId(item.id);
        setPauseAndEditModalOpen(true);
      }
    } else if (status === CampaignStatus.SENT) {
      push(generatePath(CAMPAIGNS_SENT_PATH, { campaignId: item.id }));
    }
  };

  const key = useRef(0);
  return (
    <DataList
      data={campaigns}
      headers={{
        label: formatMessage(messages.campaign),
        openRate: formatMessage(messages.openRate),
        clickRate: formatMessage(messages.clickRate),
        revenue: formatMessage(messages.revenue),
        status: formatMessage(messages.status),
        updatedAt: formatMessage(messages.lastUpdated),
      }}
      onLoadMore={loadMore}
      loadingState={getLoadingState({ loadingInitial, loadingMore })}
      headerVisibility={{ xs: false, md: true }}
      title={formatMessage(messages.oneoffCampaignListTitle)}
      totalCount={totalCount}
      sorting={{
        state: sortingState,
        onSort: sorting => {
          setVariablesForSorting(SORT_KEYS.UPDATED_AT, sorting?.order);
          setSortingState(sorting);
        },
        sortable: [
          {
            key: "updatedAt",
            sortType: "toggle",
            options: [
              {
                id: "updatedAt",
                label: "Last Updated (Newest)",
                order: "desc",
              },
              {
                id: "updatedAt",
                label: "Last Updated (Oldest)",
                order: "asc",
              },
            ],
          },
        ],
      }}
    >
      <DataList.ItemActions<SingleCampaign> onClick={rowAction}>
        <DataList.ItemAction
          icon="edit"
          label={formatMessage(messages.edit)}
          visible={(item: SingleCampaign) =>
            campaignStatus(item) === CampaignStatus.DRAFT
          }
          onClick={rowAction}
        />
        <DataList.ItemAction
          icon="edit"
          label={formatMessage(messages.pauseAndEdit)}
          visible={(item: SingleCampaign) =>
            campaignStatus(item) === CampaignStatus.SCHEDULED
          }
          onClick={(item: SingleCampaign) =>
            rowAction(item, listAction.PAUSE_AND_EDIT)
          }
        />
        <DataList.ItemAction
          icon="sendMessage"
          label={formatMessage(messages.sendNow)}
          visible={(item: SingleCampaign) =>
            campaignStatus(item) === CampaignStatus.SCHEDULED
          }
          onClick={(item: SingleCampaign) =>
            rowAction(item, listAction.SEND_NOW)
          }
        />
        <DataList.ItemAction
          destructive
          label={formatMessage(messages.delete)}
          visible={(item: SingleCampaign) =>
            STATUSES_ALLOWING_DELETE.includes(campaignStatus(item))
          }
          onClick={(item: SingleCampaign) => setSelectedCampaignId(item.id)}
        />
      </DataList.ItemActions>
      <DataList.Layout size="md">
        {(item: DataListItemType<typeof campaigns>) => {
          key.current += 1;
          const isHeader = !item.id && !loadingInitial;
          return (
            <Grid alignItems={"center"} key={key.current}>
              <Grid.Cell size={{ xs: 3 }}>
                <div className={styles.preventWrap}>{item.label}</div>
              </Grid.Cell>
              <Grid.Cell size={{ xs: 2 }}>
                <div className={styles.justifyContentEnd}>
                  {item.openRate}
                  {isHeader &&
                    largeAndUp &&
                    buildTooltip(formatMessage(messages.openRateTooltip))}
                </div>
              </Grid.Cell>
              <Grid.Cell size={{ xs: 2 }}>
                <div className={styles.justifyContentEnd}>
                  {item.clickRate}
                  {isHeader &&
                    largeAndUp &&
                    buildTooltip(formatMessage(messages.clickRateTooltip))}
                </div>
              </Grid.Cell>
              <Grid.Cell size={{ xs: 2 }}>
                <div className={styles.justifyContentEnd}>
                  {item.revenue}
                  {isHeader &&
                    largeAndUp &&
                    buildTooltip(formatMessage(messages.revenueTooltip))}
                </div>
              </Grid.Cell>
              <Grid.Cell size={{ xs: 1 }}>{item.status}</Grid.Cell>
              <Grid.Cell size={{ xs: 2 }}>
                <div className={styles.updatedAtWrapper}>
                  {item.updatedAt} {item.actions}
                </div>
              </Grid.Cell>
            </Grid>
          );
        }}
      </DataList.Layout>
      <DataList.Layout size="xs">
        {(item: DataListItemType<typeof campaigns>) => (
          <div className={styles.smallListItemWrapper}>
            <div className={styles.smallListItemLabel}>
              <div className={styles.preventWrap}>{item.label}</div>
              {item.status}
            </div>
            <Content spacing={"small"}>
              <Text>
                {item.openRate} {item.clickRate} {item.revenue}
              </Text>
              <div className={styles.updatedAtWrapper}>
                <Text>{item.updatedAt}</Text>
                {item.actions}
              </div>
            </Content>
          </div>
        )}
      </DataList.Layout>
      <DataList.EmptyState
        type="empty"
        message={formatMessage(messages.noCampaignsMessage)}
        action={
          <Button
            label={formatMessage(messages.newCampaignLabel)}
            onClick={() => push(CAMPAIGNS_TEMPLATE_PATH)}
          />
        }
      />
      <DataList.EmptyState
        type="filtered"
        message={formatMessage(messages.noFilteredCampaignsMessage)}
        action={
          <Button
            label={formatMessage(messages.clearFiltersMessage)}
            onClick={() => alert("Clear Filters Button Clicked")}
          />
        }
      />
    </DataList>
  );
}

function getLoadingState({
  loadingMore,
  loadingInitial,
}: {
  loadingMore?: boolean;
  loadingInitial?: boolean;
}) {
  if (loadingInitial) {
    return "initial";
  }
  if (loadingMore) {
    return "loadingMore";
  }
  return undefined;
}

function buildTooltip(message: string) {
  return (
    <div className={styles.marginY}>
      <Tooltip message={message}>
        <Icon name={"help"} size={"small"} />
      </Tooltip>
    </div>
  );
}
