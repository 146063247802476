import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import type {
  CreateDefaultCampaignMutation,
  CreateDefaultCampaignMutationVariables,
  Template,
} from "~/utilities/API/graphql";
import { CREATE_DEFAULT_CAMPAIGN } from "./useCreateDefaultCampaign.graphql";

export function useCreateDefaultCampaign() {
  const [createDefaultCampaignMutation, { loading, error, data }] = useMutation<
    CreateDefaultCampaignMutation,
    CreateDefaultCampaignMutationVariables
  >(CREATE_DEFAULT_CAMPAIGN);

  const createDefaultCampaign = useCallback(
    async (templateType: Template) => {
      const mutationResult = await createDefaultCampaignMutation({
        variables: { input: { templateType } },
      });
      const userErrors =
        mutationResult.data?.createDefaultCampaign.userErrors ?? [];
      return {
        campaign: mutationResult.data?.createDefaultCampaign.campaign,
        userErrors,
      };
    },
    [createDefaultCampaignMutation],
  );

  return {
    createDefaultCampaign,
    loading,
    error,
    data,
  };
}
