import { defineMessages } from "react-intl";

export const messages = defineMessages({
  dedicatedPhoneNumberTitle: {
    id: "settings.commsSettings.dedicatedPhoneNumberStatus.title",
    defaultMessage: "Status",
    description: "The title for the DPN status section",
  },
  dedicatedPhoneNumberSendingTextsStatus: {
    id: "settings.commsSettings.dedicatedPhoneNumberStatus.sendingTextsStatus",
    defaultMessage: "Sending texts from dedicated number",
    description: "The label for the DPN sending texts status",
  },
  dedicatedPhoneNumberReceivingTextsStatus: {
    id: "settings.commsSettings.dedicatedPhoneNumberStatus.receivingTextsStatus",
    defaultMessage: "Receiving text replies on dedicated number",
    description: "The label for the DPN receiving texts status",
  },
  dedicatedPhoneNumberPhoneCallsStatus: {
    id: "settings.commsSettings.dedicatedPhoneNumberStatus.phoneCallsStatus",
    defaultMessage: "Phone calls",
    description: "The label for the DPN phone calls status",
  },
  dedicatedPhoneNumberAiReceptionistStatus: {
    id: "settings.commsSettings.dedicatedPhoneNumberStatus.aiReceptionistStatus",
    defaultMessage: "Answered by ",
    description: "The label for the DPN AI Receptionist status",
  },
  dedicatedPhoneNumberAiReceptionistStatusLink: {
    id: "settings.commsSettings.dedicatedPhoneNumberStatus.aiReceptionistStatusLink",
    defaultMessage: "AI Receptionist",
    description: "The label for the DPN AI Receptionist status link",
  },
  dedicatedPhoneNumberForwardedStatus: {
    id: "settings.commsSettings.dedicatedPhoneNumberStatus.forwardedStatus",
    defaultMessage: "Forwarded to {forwardTo}",
    description: "The label for the DPN forwarded status",
  },
  dedicatedPhoneNumberNoForwardingStatus: {
    id: "settings.commsSettings.dedicatedPhoneNumberStatus.noForwardingStatus",
    defaultMessage: "No forwarding number set",
    description: "The label for the DPN no forwarding status",
  },
  enabled: {
    id: "settings.commsSettings.dedicatedPhoneNumberStatus.enabled",
    defaultMessage: "Enabled",
    description: "The label for enabled status",
  },
  disabled: {
    id: "settings.commsSettings.dedicatedPhoneNumberStatus.disabled",
    defaultMessage: "Disabled",
    description: "The label for disabled status",
  },
  sendingFromSharedNumber: {
    id: "settings.commsSettings.dedicatedPhoneNumberStatus.sendingFromSharedNumber",
    defaultMessage: "Sending from shared number",
    description: "The label for sending from shared number status",
  },
});
