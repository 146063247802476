import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "setupGuide.buildingDashboard.title",
    defaultMessage: "Building your dashboard",
    description: "Title for the building dashboard step",
  },
  description: {
    id: "setupGuide.buildingDashboard.description",
    defaultMessage:
      "Your review automation and dashboard tools are being set up!",
    description: "Body for the building dashboard step",
  },
});
