import { defineMessages } from "react-intl";

export const messages = defineMessages({
  recipientSummary: {
    id: "campaigns.campaignReviewPage.recipientSummary",
    defaultMessage:
      "This email will send to the <b>{segmentName}</b> segment, with <b>{consentingClientsCount}</b> of {totalCount} subscribed to email marketing.",
    description: "Recipient summary",
  },
  campaignRecipientsTitle: {
    id: "campaigns.campaignReviewPage.campaignRecipientsTitle",
    defaultMessage: "Recipients",
    description: "Title of campaign recipients section",
  },
  campaignRecipientTimeoutError: {
    id: "campaigns.campaignReviewPage.campaignRecipientTimeoutError",
    defaultMessage:
      "Recipient count couldn't be retrieved due to a system timeout. You can proceed with sending your campaign without it.",
    description:
      "The error message to show when the client segment query returns a timeout error",
  },
  campaignRecipientEditButton: {
    id: "campaigns.campaignReviewPage.automatedCampaignRecipientEditButton",
    defaultMessage: "Edit",
    description: "Edit recipients button",
  },
  campaignsConfirmImpliedConsentPermission: {
    id: "campaigns.campaignReviewPage.campaignsConfirmImpliedConsentPermission",
    defaultMessage: "I confirm that I have permission to email these clients",
    description:
      "Description to confirm that I have permission to email these clients",
  },
  clients: {
    id: "campaigns.campaignReviewPage.clients",
    defaultMessage:
      "{totalCount} {totalCount, plural, one {client} other {clients}} ",
    description: "clients label",
  },
  automatedCampaignRecipientSummary: {
    id: "campaigns.campaignReviewPage.automatedCampaignRecipientSummary",
    defaultMessage:
      "This campaign will automatically send to clients {timeFrame} after {trigger} -> {condition}",
    description: "Recipient summary",
  },
});
