import type { AxisTickProps } from "@nivo/axes";

export const MarketingTick = ({
  tick,
  x = 0,
  y = 0,
  anchor = "end",
  formatValue = (val: string) => val,
  showTitle = false,
  wrap = false,
}: {
  tick: AxisTickProps<string>;
  x?: number;
  y?: number;
  anchor?: string;
  formatValue?: (val: string) => string;
  showTitle?: boolean;
  wrap?: boolean;
}) => {
  return (
    <text
      key={tick.tickIndex}
      x={tick.x + x}
      y={tick.y + y}
      textAnchor={anchor}
      fill="var(--color-text)"
      style={{
        fontSize: "var(--typography--fontSize-small)",
        color: "var(--color-text)",
      }}
    >
      {wrap
        ? tick.value?.split(" ").map((word: string, index: number) => (
            // 1.143 to match --typography--lineHeight-tighter
            <tspan key={index} x={tick.x + x} dy={index > 0 ? "1.143em" : 0}>
              {word}
            </tspan>
          ))
        : formatValue(tick.value)}
      {showTitle && <title>{tick.value}</title>}
    </text>
  );
};
