import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import type { MutableRefObject } from "react";
import type { CallToActionRef } from "~/jobber/settings/users/components/CallToAction/CallToAction";
import {
  CallToAction,
  convertCTA,
  dismissCTA,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";
import { DrawerView } from "jobber/reviews/views/ReviewsPage/context/ReviewsSettingsDrawerContext";
import { messages } from "../../messages";

interface ReviewsMessageCustomizationModalProps {
  drawerActions?: {
    goTo: (view: DrawerView) => void;
    closeSideDrawer: () => void;
  };
  showGlimmer: boolean;
}

export function ReviewsMessageCustomizationModal({
  drawerActions,
  showGlimmer,
}: ReviewsMessageCustomizationModalProps): JSX.Element {
  const { formatMessage } = useIntl();
  const ctaRef = React.useRef() as MutableRefObject<CallToActionRef>;
  const [modalOpen, setModalOpen] = useState(true);

  return (
    <CallToAction ref={ctaRef} ctaName={"reviews_message_customization_modal"}>
      <Modal
        title={formatMessage(messages.CTAModalHeader)}
        open={modalOpen}
        onRequestClose={() => {
          dismissCTA(ctaRef)();
          setModalOpen(false);
          drawerActions?.closeSideDrawer();
        }}
        primaryAction={{
          variation: "learning",
          label: formatMessage(messages.CTAModalButton),
          onClick: () => {
            drawerActions?.goTo(DrawerView.MessageSettings);
            convertCTA(ctaRef)();
            setModalOpen(false);
          },
          loading: showGlimmer,
        }}
        dismissible
      >
        <Content>
          <Text>{formatMessage(messages.CTAModalBody)}</Text>
        </Content>
      </Modal>
    </CallToAction>
  );
}
