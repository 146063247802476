import { useContext } from "react";
import { datadogLogs } from "@datadog/browser-logs";
import { JOBBER_ONLINE_LOGGER } from "./constants";
import type { DatadogLoggerContextProviderProps } from "./types";
import { DatadogLoggerContext } from "./DatadogLoggerContext";
import { initDatadogLogging } from "./initDatadogLogging";
import { ErrorBoundary } from "../ErrorBoundary/ErrorBoundary";

function DatadogErrorBoundaryProvider({
  children,
  loggerName = JOBBER_ONLINE_LOGGER,
}: DatadogLoggerContextProviderProps) {
  // Ensure Datadog is initialized first
  initDatadogLogging();

  const logError = (
    message: string,
    errorContext?: object,
    error?: Error,
  ): void => {
    datadogLogs.logger.error(
      message,
      {
        ...errorContext,
        logger: {
          name: loggerName,
        },
      },
      error,
    );
  };

  const logInfo = (message: string, infoContext?: object): void => {
    datadogLogs.logger.info(message, {
      ...infoContext,
      logger: {
        name: loggerName,
      },
    });
  };

  return (
    <DatadogLoggerContext.Provider value={{ logError, logInfo }}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </DatadogLoggerContext.Provider>
  );
}

const useDatadogLogger = () => {
  const context = useContext(DatadogLoggerContext);

  if (!context) {
    throw new Error(
      "useDatadogLogger must be used within a DatadogLoggerContextProvider",
    );
  }

  return context;
};

export { DatadogErrorBoundaryProvider, useDatadogLogger };
