import type { AddonDiscountGroup } from "~/utilities/API/graphql";
import { BillingCycleName as MainBillingCycleName } from "~/utilities/API/graphql";
import type { AddonPreview } from "~/utilities/API/graphqlAnchor";
import { BillingCycleName as AnchorBillingCycleName } from "~/utilities/API/graphqlAnchor";

export interface SubscriptionAddon {
  name: string;
  recommended?: boolean;
  isActive: boolean;
  identifier: string;
  baseIdentifier: string;
  includedAddonSetIdentifiers?: string[];
  includedWithAddonSetIdentifiers?: string[];
  currency?: string;
  isCancelled?: boolean;
  isResubscribeDisabled?: boolean;
  shouldShowDisclaimer?: boolean;
  currentBillingCycle?: SubscriptionAddonCurrentBillingCycle;
  monthlyBillingCycle?: SubscriptionAddonDefaultBillingCycle;
  discountGroup?: AddonDiscountGroup;
}

export interface SubscriptionAddonCurrentBillingCycle {
  billingCycleName: string;
  addonCode: string;
  monthlyCost: number;
  renewalDate: string;
  futureStartBillingOnDate?: string;
}

interface SubscriptionAddonDefaultBillingCycle {
  addonCode?: string;
  monthlyCost: number;
}

export type SubscriptionAddonPreview = SubscriptionAddon & {
  monthlyBillingCycle: {
    addonCode: string;
    monthlyCost: number;
  };
  previewGroup: SubscriptionAddonPreviewGroup;
};

interface SubscriptionAddonPreviewGroup {
  monthlyPreview: Pick<
    AddonPreview,
    | "proratedDiscount"
    | "proratedTotal"
    | "proratedTax"
    | "nextBillingDate"
    | "totalCredit"
  >;
}

export interface SubscriptionDiscountGroup {
  monthlyDiscount?: SubscriptionDiscount;
  annualDiscount?: SubscriptionDiscount;
}

export interface SubscriptionDiscount {
  discountAmount?: number;
  discountDurationUnit?: string;
  discountType?: string;
  remainingDiscountedFullMonths?: number;
  discountEndDate?: string;
  planCostMonthlyDiscounted?: number;
}

export interface SubscriptionPreview {
  proratedDiscount: number;
  proratedSubtotal: number;
  proratedTax?: number;
  taxRate?: number;
  proratedTotal: number;
  totalCredit: number;
  startBillingDate: string;
  renewalDate?: string;
  isCurrentBillingCycle: boolean;
  subscribeDisabled: boolean;
}

export interface PlanInfo {
  planTier: string;
  planIdentifier: string;
  monthlyBillingCycle?: BillingCycle;
  annualBillingCycle?: BillingCycle;
}

export interface BillingCycle {
  planCode: string;
  displayName: string;
  name: BillingCycleNameType;
  monthlyPrice: number;
  annualPrice: number;
}

export interface SubscriptionPreviewGroup {
  monthlyPreview?: SubscriptionPreview;
  annualPreview?: SubscriptionPreview;
}

// This is a workaround to normalize enums spanning multiple apis
export const BillingCycleName = {
  ...MainBillingCycleName,
  ...AnchorBillingCycleName,
};
export type BillingCycleNameType =
  | MainBillingCycleName
  | AnchorBillingCycleName;
