import { useQuery } from "@apollo/client";
import type { LatestReviewBenchmarksQuery } from "~/utilities/API/graphql";
import { LATEST_REVIEW_BENCHMARKS_QUERY } from "../components/ReviewsBenchmark/ReviewsBenchmark.graphql";

export function useReviewBenchmarksQuery() {
  const { loading, data, error } = useQuery<LatestReviewBenchmarksQuery>(
    LATEST_REVIEW_BENCHMARKS_QUERY,
  );

  return {
    benchmarkData: data?.latestReviewBenchmarks?.nodes,
    loading,
    error,
  };
}
