import React, { useCallback, useEffect } from "react";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import { useIntl } from "react-intl";
import { Heading } from "@jobber/components/Heading";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Grid } from "@jobber/components/Grid";
import { Intercom } from "utilities/chat";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { formatCurrency } from "utilities/formatCurrency";
// eslint-disable-next-line import/no-internal-modules
import { LandingPageCtaButton } from "~/jobber/billing/components/LandingPageCtaButton/LandingPageCtaButton";
import styles from "./styles.module.css";
import {
  MoreClientsImg,
  PlanTapeImg,
  ProtectReputationImg,
  ReviewsHeroImg,
  TrustedBrandImg,
} from "./images";
import { messages } from "./messages";
import type { FeatureHighlightProps } from "../types";

export interface PurchasePageProps {
  recurlyPublicKey: string;
  addonSetIdentifier: string;
  monthlyCost?: number;
  monthlyDiscountedCost?: number;
  isMobileBilled: boolean;
  discountCallToAction?: string;
  salesforceTrackingId: string | null;
  requiresPaidPlan: boolean;
  loading: boolean;
}

export function PurchasePage(props: PurchasePageProps): JSX.Element {
  const {
    recurlyPublicKey,
    addonSetIdentifier,
    monthlyCost,
    monthlyDiscountedCost,
    isMobileBilled,
    discountCallToAction,
    salesforceTrackingId,
    requiresPaidPlan,
    loading,
  } = props;

  useEffect(() => {
    Amplitude.TRACK_EVENT("Viewed Page", {
      name: "review_add_on_landing_page",
    });
  }, []);

  return (
    <div className={styles.landingPageContainer}>
      <ValuePropHeader
        recurlyPublicKey={recurlyPublicKey}
        addonSetIdentifier={addonSetIdentifier}
        isMobileBilled={isMobileBilled}
        discountCallToAction={discountCallToAction}
        requiresPaidPlan={requiresPaidPlan}
        salesforceTrackingId={salesforceTrackingId}
        loading={loading}
      />
      <FeatureHighlights />
      <ComingSoon />
      {monthlyCost && (
        <PlanUpgradeFooter
          recurlyPublicKey={recurlyPublicKey}
          addonSetIdentifier={addonSetIdentifier}
          monthlyCost={monthlyCost}
          monthlyDiscountedCost={monthlyDiscountedCost}
          isMobileBilled={isMobileBilled}
          requiresPaidPlan={requiresPaidPlan}
          discountCallToAction={discountCallToAction}
          salesforceTrackingId={salesforceTrackingId}
          loading={loading}
        />
      )}
    </div>
  );
}

function ValuePropHeader({
  recurlyPublicKey,
  addonSetIdentifier,
  isMobileBilled,
  discountCallToAction,
  requiresPaidPlan,
  salesforceTrackingId,
  loading,
}: PurchasePageProps) {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.headerContainer}>
      <Content>
        <img
          className={styles.tapeHeaderImg}
          src={PlanTapeImg.src}
          alt={formatMessage(PlanTapeImg.alt)}
        />
        <Heading level={1}>{formatMessage(messages.headingTitle)}</Heading>
        <Text size={"large"} align={"center"}>
          {formatMessage(messages.headingBody)}
        </Text>
        {discountCallToAction && !isMobileBilled && (
          <Text size={"large"} align={"center"}>
            <Emphasis variation={"bold"}>
              <Emphasis variation={"highlight"}>
                {discountCallToAction}
              </Emphasis>
            </Emphasis>
          </Text>
        )}

        <div className={styles.headerButtonContainer}>
          <LandingPageCtaButton
            recurlyPublicKey={recurlyPublicKey}
            addonSetIdentifier={addonSetIdentifier}
            addonSetName={formatMessage(messages.addonSetName)}
            redirectToPricingPage={requiresPaidPlan}
            isMobileBilled={isMobileBilled}
            salesforceTrackingId={salesforceTrackingId}
            loading={loading}
            onPurchaseSuccess={() => window.location.reload()}
            isMarketingSource
          />
        </div>
      </Content>
      <img src={ReviewsHeroImg.src} alt={formatMessage(ReviewsHeroImg.alt)} />
    </div>
  );
}

function FeatureHighlights(): JSX.Element {
  const { formatMessage } = useIntl();

  const rowData: FeatureHighlightProps[] = [
    {
      img: TrustedBrandImg,
      title: messages.firstRowTitle,
      body: messages.firstRowBody,
    },
    {
      img: ProtectReputationImg,
      title: messages.secondRowTitle,
      body: messages.secondRowBody,
    },
    {
      img: MoreClientsImg,
      title: messages.thirdRowTitle,
      body: messages.thirdRowBody,
    },
  ];

  return (
    <div className={styles.highlightsContainer}>
      {rowData.map((data, idx) => (
        <Grid key={idx} alignItems={"center"} gap={true}>
          <Grid.Cell size={{ xs: 12, sm: 12, md: 7, lg: 7 }}>
            <img src={data.img.src} alt={formatMessage(data.img.alt)} />
          </Grid.Cell>
          <Grid.Cell size={{ xs: 12, sm: 12, md: 5, lg: 5 }}>
            <Content>
              <Heading level={3}>{formatMessage(data.title)}</Heading>
              <Text size={"large"}>{formatMessage(data.body)}</Text>
            </Content>
          </Grid.Cell>
        </Grid>
      ))}
    </div>
  );
}

function ComingSoon(): JSX.Element {
  const { formatMessage } = useIntl();

  const updateSignupCallback = useCallback(() => {
    Intercom.EXECUTE("startSurvey", "38822598");
  }, []);

  return (
    <div className={styles.comingSoonContainer}>
      <div className={styles.content}>
        <Heading level={5}>{formatMessage(messages.comingSoonTitle)}</Heading>
        <Heading level={2}>{formatMessage(messages.comingSoonBody)}</Heading>
        <Button
          label={formatMessage(messages.signUpButton)}
          ariaLabel={formatMessage(messages.signUpButton)}
          variation={"subtle"}
          type={"primary"}
          onClick={updateSignupCallback}
        />
      </div>
    </div>
  );
}

function PlanUpgradeFooter({
  recurlyPublicKey,
  addonSetIdentifier,
  monthlyCost,
  monthlyDiscountedCost,
  isMobileBilled,
  discountCallToAction,
  requiresPaidPlan,
  salesforceTrackingId,
  loading,
}: PurchasePageProps): JSX.Element {
  const { formatMessage } = useIntl();

  if (requiresPaidPlan || !monthlyCost) {
    return <div></div>;
  }

  return (
    <div className={styles.footerContainer}>
      <div className={styles.content}>
        <Content>
          <p className={styles.footerBody}>
            {discountCallToAction || formatMessage(messages.footerToolboxTitle)}
          </p>
          <p className={styles.footerTitle}>
            {formatMessage(messages.footerPricing)}
            <FooterPrice
              monthlyCost={monthlyCost}
              monthlyDiscountedCost={monthlyDiscountedCost}
            />
          </p>
          <LandingPageCtaButton
            loading={loading}
            recurlyPublicKey={recurlyPublicKey}
            addonSetIdentifier={addonSetIdentifier}
            addonSetName={formatMessage(messages.addonSetName)}
            redirectToPricingPage={requiresPaidPlan}
            isMobileBilled={isMobileBilled}
            salesforceTrackingId={salesforceTrackingId}
            onPurchaseSuccess={() => window.location.reload()}
            isMarketingSource
          />
        </Content>
      </div>
    </div>
  );
}

// TODO: Replace with PriceHighlightText component
function FooterPrice({
  monthlyCost,
  monthlyDiscountedCost,
}: {
  monthlyCost: number;
  monthlyDiscountedCost?: number;
}) {
  if (monthlyDiscountedCost) {
    return (
      <span className={styles.footerPrice}>
        <span className={styles.footerBasePrice}>
          {formatCurrency(monthlyCost, "$", 0)}
        </span>
        {getFooterPrice(monthlyDiscountedCost)}
      </span>
    );
  }

  return (
    <span className={styles.footerPrice}>{getFooterPrice(monthlyCost)}</span>
  );
}

function getFooterPrice(monthlyCost: number) {
  return `${formatCurrency(monthlyCost, "$", 0)}/mo`;
}
