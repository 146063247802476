import { IntervalUnits, Template } from "~/utilities/API/graphql";
import {
  dayOptions,
  monthOptions,
} from "jobber/campaigns/views/CampaignRecipientsPage/components/SetAutomationRules/ruleBuilder/utils/dropdownOptions";

export const CAMPAIGNS_MAIN_PATH = "/campaigns";
export const CAMPAIGNS_NEW_REFERRAL_PATH = "/campaigns/new/recipients/REFERRAL";
export const CAMPAIGN_FULL_SENT_PATH = (id: string) => `/campaigns/sent/${id}`;
export const CAMPAIGNS_LANDING_PAGE_PATH = "";
export const CAMPAIGNS_SEGMENT_PATH = "/new/recipients/:templateType";
export const CAMPAIGNS_SEGMENT_EDIT_PATH = "/edit/:campaignId/recipients";
export const CAMPAIGNS_CONTENT_EDIT_PATH = "/edit/:campaignId/content";
export const CAMPAIGNS_REVIEW_PATH = "/edit/:campaignId/review";
export const CAMPAIGNS_SENT_PATH = "/sent/:campaignId/";
export const CAMPAIGNS_TEMPLATE_EDIT_PATH = "/edit/:campaignId/template";
export const CAMPAIGNS_TEMPLATE_PATH = "/new/template";
export const CAMPAIGNS_HELP_DOCS_PATH =
  "https://help.getjobber.com/hc/en-us/articles/19885016029207-Campaigns-Marketing-Tools";
export const CAMPAIGNS_HOW_CAMPAIGNS_WORK_VIDEO_ID = "GmLMQf8ywn8";

export const AUTOMATIONS_PAGE_PATH = "/automations";

export const COMMUNICATION_SETTINGS_PATH = "/communication_settings";

export const URL_PARAM_CAMPAIGNS_TYPE = "type";

export const CAMPAIGNS_PAGE_TITLE_STEPS = {
  segment: 2,
  content: 3,
  review: 4,
};

export const PAST_CLIENT_DEFAULT_UNIT = IntervalUnits.MONTHS;
export const PAST_CLIENT_DEFAULT_INTERVAL = 12;

export const DEFAULT_PAST_CLIENTS_SEGMENT_CRITERIA = {
  interval: PAST_CLIENT_DEFAULT_INTERVAL,
  unit: PAST_CLIENT_DEFAULT_UNIT,
};

export const UPCOMING_CLIENTS_SEGMENT_CRITERIA_DEFAULT_UNIT =
  IntervalUnits.DAYS;
export const UPCOMING_CLIENTS_SEGMENT_CRITERIA_DEFAULT_INTERVAL = 30;

export const DEFAULT_UPCOMING_CLIENTS_SEGMENT_CRITERIA = {
  interval: UPCOMING_CLIENTS_SEGMENT_CRITERIA_DEFAULT_INTERVAL,
  unit: UPCOMING_CLIENTS_SEGMENT_CRITERIA_DEFAULT_UNIT,
};

export const SEGMENT_CLIENT_DEBOUNCE_TIME = 1000;

export const USE_24_HOUR_TIME = window.is_24_hour_format;

export const TRIGGER_DROPDOWN_OPTIONS = [...dayOptions, ...monthOptions];

export const CRITERIA_MODEL_OPTIONS = [
  {
    id: "CampaignPastClients",
    label: "a job ends",
  },
  {
    id: "Quote",
    label: "a quote is sent",
  },
];

export const JOB_TYPE = [
  { label: "is recurring", id: "contract" },
  { label: "is one-off", id: "one-off" },
];

export const AUTOMATION_RULE_TRIGGER_TYPES = [
  { id: "time_since_last_job", label: "Job ended" },
  { id: "after_date", label: "Quote sent" },
];

export const AUTOMATION_RULE_NAME = [
  { id: Template.AUTOMATED_RE_ENGAGE, name: "CampaignPastClientsAutomation" },
  {
    id: Template.AUTOMATED_WIN_BACK_LOST_LEADS,
    name: "CampaignLostLeadsAutomation",
  },
  {
    id: Template.AUTOMATED_CLOSE_ON_PENDING_QUOTES,
    name: "CampaignPendingQuotesAutomation",
  },
];

export const QUOTE_STATUS = [
  { id: "archived", label: "archived" },
  { id: "awaiting_response", label: "awaiting response" },
];
