import { gql } from "@apollo/client";

const JOBBER_PAYMENTS_LIMIT_CHANGE_REQUEST_CANCEL = gql`
  mutation jobberPaymentsLimitsChangeRequestCancel(
    $input: JobberPaymentsLimitsChangeRequestCancelInput!
  ) {
    jobberPaymentsLimitsChangeRequestCancel(input: $input) {
      jobberPaymentsLimitsChangeRequest {
        limitsType
        status
        requestedAt
        id
      }
      userErrors {
        path
        message
      }
    }
  }
`;

export { JOBBER_PAYMENTS_LIMIT_CHANGE_REQUEST_CANCEL };
