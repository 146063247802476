import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Box, Button, Flex, Text } from "@jobber/components";
import { CAMPAIGNS_TEMPLATE_PATH } from "jobber/campaigns/constants";
import CampaignImage from "@images/marketingCampaignsExpansion/landingPage/CreateCampaigns.png";
import { messages } from "./messages";
import { EducationCard } from "./EducationCard";
import styles from "./EducationCards.module.css";

interface ExploreTemplatesCardProps {
  onDismiss?: () => void;
  useExpandedLayout?: boolean;
}

function ImageContent({ useExpandedLayout }: { useExpandedLayout: boolean }) {
  const { formatMessage } = useIntl();
  return (
    <Box alignItems="center" justifyContent="center">
      <div
        className={
          useExpandedLayout
            ? styles.mediaContainerExpanded
            : styles.mediaContainerCompact
        }
        data-testid="media-container"
      >
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
          src={CampaignImage}
          alt={formatMessage(messages.campaignImageAlt)}
        />
      </div>
    </Box>
  );
}

function TextContent() {
  const { formatMessage } = useIntl();
  const history = useHistory();
  return (
    <>
      <Text>{formatMessage(messages.exploreTemplatesCardText)}</Text>
      <div>
        <Button
          fullWidth={false}
          size="small"
          type="secondary"
          onClick={() => history.push(CAMPAIGNS_TEMPLATE_PATH)}
          label={formatMessage(messages.exploreTemplatesButton)}
        />
      </div>
    </>
  );
}

export function ExploreTemplatesCard({
  onDismiss,
  useExpandedLayout = false,
}: ExploreTemplatesCardProps) {
  const { formatMessage } = useIntl();

  return (
    <EducationCard
      ctaName="campaigns_education_card_explore_templates"
      title={formatMessage(messages.exploreTemplatesHeading)}
      onDismiss={onDismiss}
    >
      {useExpandedLayout ? (
        <Flex direction="row" template={["shrink", "shrink"]}>
          <ImageContent useExpandedLayout={useExpandedLayout} />
          <Box gap="base" direction="column">
            <TextContent />
          </Box>
        </Flex>
      ) : (
        <>
          <ImageContent useExpandedLayout={useExpandedLayout} />
          <TextContent />
        </>
      )}
    </EducationCard>
  );
}
