import React, { useState } from "react";
import { Heading, Icon, InlineLabel, List, Text } from "@jobber/components";
import type { ListItemProps } from "@jobber/components";
import type { IconNames } from "@jobber/design";
import { Emphasis } from "@jobber/components/Emphasis";
import { useIntl } from "react-intl";
import { StarGroup } from "jobber/reviews/views/ReviewsPage/components/StarGroup";
import { messages } from "./messages";
import styles from "./styles.module.css";

const getOrdinalString = (n: number): string => {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

type OrderChange = "up" | "down" | "none";

interface CompetitorListItemProps extends ListItemProps {
  readonly id: string;
  readonly name: string;
  readonly onClick?: () => void;
  readonly icon: IconNames;
  readonly averageRating: number;
  readonly numReviews: number;
  readonly order: number;
  readonly isSP?: boolean;
  readonly orderChange?: OrderChange;
  readonly averageRatingChange?: number;
  readonly totalReviewsChange?: number;
}

interface CompetitorData {
  readonly id: string;
  readonly googlePlaceId: string;
  readonly averageRating: number;
  readonly totalReviews: number;
  readonly order: number;
}

interface CompetitorListProps {
  readonly competitors: CompetitorData[];
}

export const CompetitorList = ({ competitors }: CompetitorListProps) => {
  const { formatMessage } = useIntl();
  // TODO- check the count to see if its the initial pull or not
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showRanking, setShowRanking] = useState(true);
  const renderCompetitorItem = (item: CompetitorListItemProps) => {
    return (
      <div className={styles.item}>
        <div className={styles.itemWrapper}>
          {showRanking && (
            <Ranking orderChange={item.orderChange} order={item.order} />
          )}
          <div className={styles.itemColumn}>
            <Heading level={3}>{item.name}</Heading>
            <div className={styles.ratingAndReviews}>
              <div className={styles.ratingGroup} data-testid="ratingGroup">
                <Text>
                  <Emphasis variation="bold">
                    {item.averageRating.toFixed(1)}
                  </Emphasis>
                </Text>
                <StarGroup rating={item.averageRating} allowHalfStars={true} />
                {showRanking && (
                  <ReviewAverageChange
                    averageChange={item.averageRatingChange}
                  />
                )}
              </div>
              <div className={styles.reviewCountGroup}>
                <Text>
                  <Emphasis variation="bold">{item.numReviews}</Emphasis>{" "}
                  {formatMessage(messages.reviews)}
                </Text>
                {showRanking && (
                  <ReviewCountChange
                    totalReviewsChange={item.totalReviewsChange}
                  />
                )}
              </div>
            </div>
            {item.isSP && (
              <div className={styles.inlineLabel}>
                <InlineLabel color="lightBlue">
                  {formatMessage(messages.sp)}
                </InlineLabel>
              </div>
            )}
          </div>
        </div>
        <div className={styles.itemIcon}>
          <Icon name={item.icon} size="small" />
        </div>
      </div>
    );
  };

  const items: CompetitorListItemProps[] = competitors
    .map((competitor, index) => ({
      id: competitor.id,
      // TODO - once we add the company name to the database update this to reflect the real company name
      name: "Sample Name",
      onClick: () =>
        window.location.assign(
          `/reviews/competitors/${competitor.googlePlaceId}`,
        ),
      icon: "arrowRight" as IconNames,
      averageRating: competitor.averageRating,
      numReviews: competitor.totalReviews,
      // TODO - once we add the list order to the database update this to reflect the real order
      order: index + 1,
      // TODO - once we add the isSP to the database update this to reflect the real isSP
      isSP: index === 0,
      orderChange: (index % 3 === 0
        ? "up"
        : index % 3 === 1
          ? "down"
          : "none") as OrderChange,
      averageRatingChange: (index % 3 === 0
        ? 0.1
        : index % 3 === 1
          ? -0.1
          : 0) as number,
      totalReviewsChange: (index % 3 === 0
        ? 5
        : index % 3 === 1
          ? -3
          : 0) as number,
    }))
    .sort((a, b) => a.order - b.order);

  return <List items={items} customRenderItem={renderCompetitorItem} />;
};

const getRankingIcon = (orderChange: OrderChange): React.JSX.Element => {
  const className = {
    up: styles.positive,
    down: styles.negative,
    none: styles.neutral,
  }[orderChange];

  const symbol = {
    up: "↑",
    down: "↓",
    none: "-",
  }[orderChange];

  return (
    <span className={className}>
      <Text>
        <Emphasis variation="bold">{symbol}</Emphasis>
      </Text>
    </span>
  );
};

function Ranking({
  orderChange,
  order,
}: {
  orderChange: OrderChange | undefined;
  order: number;
}) {
  if (!orderChange) {
    return null;
  }
  return (
    <div className={styles.ranking} data-testid="ranking">
      {getRankingIcon(orderChange)}
      <Text>
        <Emphasis variation="bold">{getOrdinalString(order)}</Emphasis>
      </Text>
    </div>
  );
}

function ReviewAverageChange({
  averageChange,
}: {
  averageChange: number | undefined;
}) {
  if (!averageChange || averageChange === 0) {
    return null;
  }
  const isPositive = averageChange > 0;
  return (
    <div
      className={`${styles.reviewAverageChange} ${isPositive ? styles.positive : styles.negative}`}
      data-testid="reviewAverageChange"
    >
      {getRankingIcon(isPositive ? "up" : "down")}
      <Text>{averageChange.toFixed(1)}</Text>
    </div>
  );
}

function ReviewCountChange({
  totalReviewsChange,
}: {
  totalReviewsChange: number | undefined;
}) {
  if (!totalReviewsChange || totalReviewsChange === 0) {
    return null;
  }
  const isPositive = totalReviewsChange > 0;
  return (
    <div
      className={`${styles.reviewAverageChange} ${isPositive ? styles.positive : styles.negative}`}
      data-testid="reviewCountChange"
    >
      {getRankingIcon(isPositive ? "up" : "down")}
      <Text>{Math.abs(totalReviewsChange)}</Text>
    </div>
  );
}
