import {
  type CommsCampaignClientSegmentReviewQuery,
  type CommsCampaignQuery,
  type CommsCampaignReviewQuery,
  Segment,
} from "~/utilities/API/graphql";

export interface TransformedCampaignData {
  fromEmail: string;
  segmentName: string;
  totalCount: number;
  consentingClientsCount: number;
  replyToEmail: string;
  segmentType: Segment;
}

interface TransformCommsCampaignProps {
  campaignData?: CommsCampaignReviewQuery;
  clientSegmentData?: CommsCampaignClientSegmentReviewQuery;
}

export function transformCommsCampaign({
  campaignData,
  clientSegmentData,
}: TransformCommsCampaignProps): TransformedCampaignData {
  const clientSegment = clientSegmentData?.commsCampaign?.clientSegment;

  return {
    totalCount: clientSegment?.clients?.totalCount || 0,
    consentingClientsCount: clientSegment?.consentingClients?.totalCount || 0,
    segmentName: clientSegment?.friendlyName || "",
    replyToEmail: campaignData?.commsCampaign?.replyToEmail || "",
    fromEmail: buildFromEmail(campaignData),
    segmentType: clientSegment?.type || Segment.ALL_CLIENTS,
  };
}

export function buildFromEmail(
  campaign?: CommsCampaignReviewQuery | CommsCampaignQuery,
) {
  const companyName = campaign?.commsCampaign?.updatedBy?.account?.name || "";
  const fromEmail = campaign?.commsCampaign?.fromEmailAddress?.value || "";

  if (!companyName || !fromEmail) {
    return "";
  }

  return `${companyName} <${fromEmail}>`;
}
