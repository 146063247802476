import React from "react";
import { Banner } from "@jobber/components";
import { useIntl } from "react-intl";
import styles from "./PurchaseDisclaimerBanner.module.css";
import { messages } from "./messages";

interface PurchaseDisclaimerBannerProps {
  showCTA?: boolean;
  isBeforeSubscription?: boolean;
}

export function PurchaseDisclaimerBanner({
  showCTA = true,
}: PurchaseDisclaimerBannerProps) {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.bannerContainer}>
      <Banner
        type={"notice"}
        primaryAction={
          showCTA
            ? {
                variation: "work",
                type: "secondary",
                label: formatMessage(messages.setUp),
                ariaLabel: formatMessage(messages.setUpAriaLabel),
                url: "/text_messaging_settings",
              }
            : undefined
        }
        dismissible={false}
      >
        {showCTA
          ? formatMessage(messages.dpnMissingDescription)
          : formatMessage(messages.dpnMissingBeforeSubscriptionDescription)}
      </Banner>
    </div>
  );
}
