import React from "react";
import { useIntl } from "react-intl";
import {
  ReviewSummaryCard,
  formatSummaryDisplayValue,
} from "jobber/reviews/views/ReviewsPage/components/ReviewSummaryCard/ReviewSummaryCard";
import { messages } from "./messages";
import { StarGroup } from "../StarGroup";

export interface ReviewRatingSummaryCardProps {
  header: string;
  rating: number;
  isLoading: boolean;
}

export function ReviewRatingSummaryCard({
  header,
  rating,
  isLoading,
}: ReviewRatingSummaryCardProps): JSX.Element {
  const formatMessage = useIntl().formatMessage;

  const showPlaceholder = rating === 0;

  const formatRatingValue = (ratingValue: number) => {
    if (showPlaceholder) {
      return formatMessage(messages.emptyPlaceholder);
    }
    return formatSummaryDisplayValue(ratingValue);
  };

  return (
    <ReviewSummaryCard
      header={header}
      summaryValue={rating}
      isLoading={isLoading}
      formatValue={formatRatingValue}
    >
      {!showPlaceholder && <StarGroup rating={rating} allowHalfStars={true} />}
    </ReviewSummaryCard>
  );
}
