import { defineMessages } from "react-intl";

export const messages = defineMessages({
  tipSettingsTitle: {
    id: "managedAccountsSettings.tipSettings.title",
    defaultMessage: "Accept tips from clients",
    description: "Title for tip settings",
  },

  invoicePreferenceSettingsTitle: {
    id: "managedAccountsSettings.invoicePreferencesSettings.title",
    defaultMessage: "Accept online payments on client hub",
    description: "Title for invoice settings",
  },

  invoicePreferenceSettingsCardTitle: {
    id: "managedAccountsSettings.invoicePreferencesSettings.CardTitle",
    defaultMessage: "Invoices preferences",
    description: "Title for invoice settings Card",
  },
  quotePreferenceSettingsCardTitle: {
    id: "managedAccountsSettings.quotePreferencesSettings.CardTitle",
    defaultMessage: "Quotes preferences",
    description: "Title for quote settings Card",
  },
  quotePreferenceSettingsTitle: {
    id: "managedAccountsSettings.invoicePreferencesSettings.Title",
    defaultMessage: "Accept online deposits on client hub",
    description: "Title for quote settings",
  },
  quoteMandatoryPaymentMethodOnFileTitle: {
    id: "managedAccountsSettings.quoteMandatoryPaymentMethodOnFile.Title",
    defaultMessage: "Require payment method to be saved on file",
    description: "Title for mandatory payment method on file switch",
  },
  defaultPreferenceRadioGroupTitle: {
    id: "managedAccountsSettings.defaultPreferenceRadioGroup.Title",
    defaultMessage: "Set your default payment options",
    description:
      "Title of the radio group for the default preferences for invoices and quotes",
  },
  defaultPreferenceRadioOptionAchAndCreditLabel: {
    id: "managedAccountsSettings.defaultPreferenceRadioButtonAchAndCredit.Label",
    defaultMessage: "Accept both card and bank payments (ACH)",
    description: "Label for the ACH and Credit Radio Option",
  },
  defaultPreferenceRadioOptionAchLabel: {
    id: "managedAccountsSettings.defaultPreferenceRadioOptionAch.Label",
    defaultMessage: "Accept bank payments (ACH) only",
    description: "Label for the ACH only Radio Option",
  },
  defaultPreferenceRadioOptionCreditLabel: {
    id: "managedAccountsSettings.defaultPreferenceRadioOptionCredit.Label",
    defaultMessage: "Accept card payments only",
    description: "Label for the Credit only Radio Option",
  },
  defaultMutationErrorMessage: {
    id: "managedAccountsSettings.internalClientHubSettings.mutationErrorMessage",
    defaultMessage: "Cannot retrieve Jobber Payments settings",
    description: "error message for generic mutation error",
  },
  paymentOptionChangeToastMessage: {
    id: "managedAccountsSettings.defaultPaymentPreference.paymentOptionChangeToastMessage",
    defaultMessage: "Default payment settings have been updated",
    description: "Toast message for default payment options update",
  },
  recommendedLabel: {
    id: "managedAccountsSettings.defaultPaymentRadioGroup.recommendedLabel",
    defaultMessage: "RECOMMENDED",
    description: "Label for the recommended status",
  },
});
