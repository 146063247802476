import React, { type ReactNode } from "react";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { type MessageDescriptor, useIntl } from "react-intl";
import { Box } from "@jobber/components/Box";
import { Tooltip } from "@jobber/components/Tooltip";
import { Icon } from "@jobber/components/Icon";
import { InlineLabel } from "@jobber/components/InlineLabel";
import { SplitNames, useSplit, withSplitClient } from "utilities/split";
import { formatCurrency } from "utilities/formatCurrency";
import { AddonPurchaseModal } from "jobber/billing/features/AddonPurchaseModal";
import type { SubscriptionAddon } from "~/shared/billing/pricePreview/types";
import { addonBeta } from "legacy/jobber/billing/features/Checkout/utils";
import type { QuickPurchaseProps } from "legacy/jobber/billing/features/SubscriptionAddons/SubscriptionAddons.loader";
import { AddonDetails } from "./components/AddonDetails";
import styles from "./SubscriptionAddonCard.module.css";
import { RemoveAddonModal } from "./components/RemoveAddonModal";
import { messages } from "./messages";
import { AddonSubscriptionModal } from "./components/AddonSubscriptionModal";
import { PurchaseDisclaimerBanner } from "./components/PurchaseDisclaimerBanner/PurchaseDisclaimerBanner";

interface SubscriptionAddonCardProps {
  subscriptionAddon: SubscriptionAddon;
  recurlyPublicKey?: string;
  inactiveCardAction?: ReactNode;
  //Please remove https://github.com/GetJobber/Jobber/pull/55943 when removing QuickPurchaseProps
  quickPurchase?: QuickPurchaseProps;
  shouldShowDisclaimerCTA?: boolean;
}
const addonTooltipMap: { [key: string]: MessageDescriptor } = {
  ai_receptionist: messages.aiReceptionistTooltip,
};

function SubscriptionAddonCard(props: SubscriptionAddonCardProps) {
  const {
    recurlyPublicKey,
    subscriptionAddon,
    inactiveCardAction,
    quickPurchase,
    shouldShowDisclaimerCTA,
  } = props;

  const {
    isActive,
    isCancelled,
    name,
    currentBillingCycle,
    baseIdentifier,
    includedAddonSetIdentifiers,
    isResubscribeDisabled,
    shouldShowDisclaimer,
  } = subscriptionAddon;

  const { formatMessage } = useIntl();
  const { getTreatmentValue } = useSplit({
    names: [SplitNames.AddonCancellation],
  });
  const addonCancellationEnabled = getTreatmentValue(
    SplitNames.AddonCancellation,
  );
  const isBeta = addonBeta(baseIdentifier);

  return (
    <Box
      background={isActive ? "surface--background--subtle" : "surface"}
      border="base"
      radius="base"
      padding="base"
      height="100%"
    >
      {shouldShowDisclaimer && (
        <PurchaseDisclaimerBanner showCTA={shouldShowDisclaimerCTA} />
      )}
      <CardHeader title={name} pill={renderPill()} tooltip={renderTooltip()}>
        {isActive
          ? renderActiveCardAction()
          : renderInactiveCardAction(formatMessage(messages.add))}
      </CardHeader>
      <Content>
        <div className={styles.cardBody}>
          <AddonDetails subscriptionAddon={subscriptionAddon} />
        </div>
      </Content>
    </Box>
  );

  function renderPill() {
    if (isBeta) {
      return (
        <InlineLabel color="lightBlue">
          {formatMessage(messages.betaPillText)}
        </InlineLabel>
      );
    }
  }

  function renderTooltip() {
    if (isBeta && baseIdentifier in addonTooltipMap) {
      return (
        <div className={styles.tooltipMargin}>
          <Tooltip message={formatMessage(addonTooltipMap[baseIdentifier])}>
            <Icon size="small" name="help" />
          </Tooltip>
        </div>
      );
    }
  }

  function renderActiveCardAction() {
    if (!currentBillingCycle) {
      return null;
    }

    return addonCancellationEnabled ? (
      <AddonSubscriptionModal
        addonIdentifier={baseIdentifier}
        addonName={name}
        addonCode={currentBillingCycle.addonCode}
        includedAddonSetIdentifiers={includedAddonSetIdentifiers}
        isCancelled={isCancelled}
        isResubscribeDisabled={isResubscribeDisabled}
        monthlyCost={getAddonCost(subscriptionAddon)}
        renewalDate={currentBillingCycle.renewalDate}
      />
    ) : (
      <RemoveAddonModal
        addonIdentifier={baseIdentifier}
        addonName={name}
        addonCode={currentBillingCycle.addonCode}
        includedAddonSetIdentifiers={includedAddonSetIdentifiers}
        isCancelled={isCancelled}
        isResubscribeDisabled={isResubscribeDisabled}
      />
    );
  }

  function renderInactiveCardAction(buttonText: string) {
    if (inactiveCardAction) {
      return inactiveCardAction;
    }

    if (!recurlyPublicKey) {
      return null;
    }

    return (
      <AddonPurchaseModal
        recurlyPublicKey={recurlyPublicKey}
        addonSetIdentifier={baseIdentifier}
        buttonProps={{
          buttonText: buttonText,
          ariaLabel: formatMessage(messages.addAriaLabel, { addonName: name }),
          buttonType: "tertiary",
        }}
        defaultToOpen={
          subscriptionAddon.identifier === quickPurchase?.addonIdentifier
        }
        successAction={() => {
          if (quickPurchase) {
            window.location.href = quickPurchase.onSuccessRedirectTo;
          }
        }}
      />
    );
  }
}

function getAddonCost(addon: SubscriptionAddon) {
  const discountInfo = addon.discountGroup?.monthlyAddonDiscount;
  const hasSingleDiscount =
    discountInfo &&
    !discountInfo.hasMultipleActiveDiscounts &&
    discountInfo.addonCostMonthlyDiscounted;

  const monthlyCost = hasSingleDiscount
    ? discountInfo.addonCostMonthlyDiscounted
    : addon.currentBillingCycle?.monthlyCost;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return formatCurrency(monthlyCost!, "$", 0);
}

function CardHeader({
  title,
  children,
  pill,
  tooltip,
}: {
  title: string;
  children: ReactNode;
  pill: ReactNode;
  tooltip: ReactNode;
}) {
  return (
    <div className={styles.cardHeader}>
      <div className={styles.headerAndDiscountContainer}>
        <Heading level={4}>{title}</Heading>
        {tooltip}
        {pill}
      </div>
      {children}
    </div>
  );
}

const SplitWrappedComponent = withSplitClient(SubscriptionAddonCard);
export { SplitWrappedComponent as SubscriptionAddonCard };
