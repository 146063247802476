import React from "react";
import { useIntl } from "react-intl";
import { Glimmer } from "@jobber/components/Glimmer";
import { Text } from "@jobber/components/Text";
import { Heading } from "@jobber/components/Heading";
import { StatusLabel } from "@jobber/components/StatusLabel";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import styles from "./DetailsSection.module.css";
import { messages } from "./messages";

interface DetailsSectionProps {
  loading: boolean;
  fromEmail?: string;
  replyToEmail?: string;
  isFromEmailValidated: boolean;
}

export function DetailsSection({
  loading,
  fromEmail,
  replyToEmail,
  isFromEmailValidated,
}: DetailsSectionProps): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <Card
      header={{
        title: formatMessage(messages.detailsSectionTitle),
      }}
    >
      <Content>
        <Heading level={4}>
          {formatMessage(messages.campaignsFromEmail)}
        </Heading>
        {loading && <Glimmer width={500} />}
        {fromEmail && !loading && (
          <div className={styles.fromEmailContainer}>
            <Text>{fromEmail}</Text>
            <StatusLabel
              status={isFromEmailValidated ? "success" : "warning"}
              label={
                isFromEmailValidated
                  ? formatMessage(messages.fromEmailVerifiedLabel)
                  : formatMessage(messages.fromEmailNotVerifiedLabel)
              }
            />
          </div>
        )}
        {fromEmail && !loading && !isFromEmailValidated && (
          <Text variation="warn">
            {formatMessage(messages.fromEmailNotVerifiedText)}
          </Text>
        )}

        <Heading level={4}>
          {formatMessage(messages.campaignsReplyToEmailTitle)}
        </Heading>
        {loading && <Glimmer width={500} />}
        {replyToEmail && !loading && <Text>{replyToEmail}</Text>}
      </Content>
    </Card>
  );
}
