import React from "react";
import { Button, Card, Content, Text } from "@jobber/components";
import { useIntl } from "react-intl";
import type { JobberCapitalDetailsSectionQuery } from "~/utilities/API/graphql";
import { SplitNames, useFeatureFlag } from "utilities/split";
import styles from "./style.module.css";
import { messages } from "./messages";

export const PARAFIN_ASSOCIATION_TYPE =
  "CapitalLending::Associations::ParafinAssociation";

export interface JobberCapitalDetailsSectionProps {
  data: JobberCapitalDetailsSectionQuery;
}

export function JobberCapitalDetailsSection({
  data,
}: JobberCapitalDetailsSectionProps) {
  const { formatMessage } = useIntl();
  const inParafinExclusiveSplit = useFeatureFlag(SplitNames.ParafinExclusive);
  const inJobberCapitalParafinSplit = useFeatureFlag(
    SplitNames.JobberCapitalParafin,
  );

  const hasParafinAssociation =
    data?.capitalLendingAssociation?.type === PARAFIN_ASSOCIATION_TYPE;

  const hasStripeFinancing =
    data?.capitalFinancingPromotion?.activeFinancing ||
    data?.capitalFinancingPromotion?.financingHistory ||
    data?.capitalFinancingPromotion?.offerAvailable;

  const shouldShowJobberCapitalDetails = () => {
    // Show for Parafin users in the Parafin split
    if (hasParafinAssociation && inJobberCapitalParafinSplit) {
      return true;
    }

    // Show for users with stripe financing who aren't in Parafin exclusive
    if (hasStripeFinancing && !inParafinExclusiveSplit) {
      return true;
    }

    return false;
  };

  const isAccountOwner = data?.user?.isAccountOwner ?? false;
  const jobberCapitalDetailsUrl = "/jobber_capital";

  if (!isAccountOwner || !shouldShowJobberCapitalDetails()) {
    return <></>;
  }

  const headerAction = (
    <Button
      label="View"
      type="secondary"
      url={jobberCapitalDetailsUrl}
      fullWidth={false}
    />
  );

  return (
    <Content>
      <Card
        header={{
          title: formatMessage(messages.jobberCapitalDetailsSectionTitle),
          action: headerAction,
        }}
      >
        <div className={styles.jobberCapitalDetailsSectionText}>
          <Text>{formatMessage(messages.jobberCapitalDetailsSectionText)}</Text>
        </div>
      </Card>
    </Content>
  );
}
