import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "setupGuide.completeSetup.title",
    defaultMessage: "Track client feedback",
    description: "Title for the complete setup step",
  },
  description: {
    id: "setupGuide.completeSetup.description",
    defaultMessage:
      "Manage which clients are asked for a review by toggling off messages. Clients that left a review will have it appear on their profile!",
    description: "Description for the complete setup step",
  },
  primaryButton: {
    id: "setupGuide.completeSetup.primaryButton",
    defaultMessage: "Complete Setup",
    description: "Button that navigates to the reviews page",
  },
});
