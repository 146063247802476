import { generatePath, useHistory } from "react-router-dom";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import type { SingleCampaign } from "jobber/campaigns/views/CampaignsLandingPage/hooks/useCampaignDataListItems";
import {
  CAMPAIGNS_CONTENT_EDIT_PATH,
  CAMPAIGNS_REVIEW_PATH,
  CAMPAIGNS_SENT_PATH,
} from "jobber/campaigns/constants";
import type { Campaigns } from "~/utilities/API/graphql";
import { CampaignStatus } from "~/utilities/API/graphql";
import { SplitNames, useFeatureFlag } from "utilities/split";

export const ALLOWED_STATUSES = {
  REACTIVATE: [CampaignStatus.AUTOMATIONINACTIVE],
  DEACTIVATE: [CampaignStatus.AUTOMATIONACTIVE],
  EDIT: [CampaignStatus.AUTOMATIONINACTIVE, CampaignStatus.DRAFT],
  PAUSE_AND_EDIT: [CampaignStatus.AUTOMATIONACTIVE],
  DELETE: [CampaignStatus.DRAFT],
};

export enum LIST_ACTIONS {
  EDIT = "EDIT",
  REACTIVATE = "REACTIVATE",
  DEACTIVATE = "DEACTIVATE",
  PAUSE_AND_EDIT = "PAUSE_AND_EDIT",
  ROW_CLICK = "ROW_CLICK",
}

export function useAutomatedCampaignRowActions() {
  const isCampaignsStreamlined = useFeatureFlag(
    SplitNames.CampaignsStreamlined,
  );
  const { push } = useHistory();

  const {
    reactivateAutomatedCampaign: {
      setOpen: setReactivateModalOpen,
      setCampaignId: setReactivateModalCampaignId,
    },
    deactivateAutomatedCampaign: {
      setOpen: setDeactivateModalOpen,
      setCampaignId: setDeactivateModalCampaignId,
    },
    pauseAndEditAutomatedCampaign: {
      setOpen: setAutomatedPauseAndEditModalOpen,
      setCampaignId: setAutomatedPauseAndEditCampaignId,
    },
  } = useCampaignWizardContext();

  const rowAction = (item: SingleCampaign, action?: LIST_ACTIONS) => {
    switch (action) {
      case LIST_ACTIONS.EDIT:
        if (isCampaignsStreamlined) {
          push(generatePath(CAMPAIGNS_REVIEW_PATH, { campaignId: item.id }));
        } else {
          push(
            generatePath(CAMPAIGNS_CONTENT_EDIT_PATH, { campaignId: item.id }),
          );
        }
        break;
      case LIST_ACTIONS.REACTIVATE:
        setReactivateModalCampaignId(item.id);
        setReactivateModalOpen(true);
        break;
      case LIST_ACTIONS.DEACTIVATE:
        setDeactivateModalCampaignId(item.id);
        setDeactivateModalOpen(true);
        break;
      case LIST_ACTIONS.PAUSE_AND_EDIT:
        setAutomatedPauseAndEditCampaignId(item.id);
        setAutomatedPauseAndEditModalOpen(true);
        break;
      case LIST_ACTIONS.ROW_CLICK:
        handleRowClick(item);
        break;
      default:
        return null;
    }
  };

  const handleRowClick = (item: SingleCampaign) => {
    const status = (JSON.parse(item.rawData) as Campaigns).status;
    switch (status) {
      case CampaignStatus.DRAFT:
        if (isCampaignsStreamlined) {
          return push(
            generatePath(CAMPAIGNS_REVIEW_PATH, { campaignId: item.id }),
          );
        } else {
          return push(
            generatePath(CAMPAIGNS_CONTENT_EDIT_PATH, { campaignId: item.id }),
          );
        }
      case CampaignStatus.AUTOMATIONACTIVE:
      case CampaignStatus.AUTOMATIONINACTIVE:
        return push(generatePath(CAMPAIGNS_SENT_PATH, { campaignId: item.id }));
    }
  };

  const canPerformAction = (
    item: SingleCampaign,
    actions: CampaignStatus[],
  ) => {
    return actions.includes((JSON.parse(item.rawData) as Campaigns).status);
  };

  return {
    rowAction,
    canPerformAction,
  };
}
