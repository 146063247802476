import type { TypedDocumentNode } from "@apollo/client";
import { gql } from "@apollo/client";
import {
  reviewsNotificationScheduleFragment,
  stringWithDefaultFieldsFragment,
  variableFieldsFragment,
  variableSectionFragment,
} from "jobber/reviews/views/ReviewsPage/fragments";
import type { FirstMessageSettingsQuery } from "~/utilities/API/graphql";

export const INITIAL_MESSAGE_SETTINGS_QUERY = gql`
  query InitialMessageSettings {
    googleBusinessAccount {
      id
      isConnected
      deliveryMethod
    }
    reviewsInitialMessageSettings {
      id
      deliveryMethod
      triggerType
    }
    reviewRequest: clientNotification(id: REVIEW_REQUEST) {
      templates {
        nodes {
          ... on MessageTemplateEmail {
            id
            deliveryMethod
            message {
              ...stringWithDefaultFields
            }
            subject {
              current
              default
            }
            variables {
              nodes {
                ...variableSection
              }
            }
          }
        }
      }
    }
    reviewV2Transactional: clientNotification(id: REVIEW_V2_TRANSACTIONAL) {
      templates {
        nodes {
          ... on MessageTemplateSMS {
            id
            deliveryMethod
            message {
              ...stringWithDefaultFields
            }
            variables {
              nodes {
                ...variableSection
              }
            }
            footerText
          }
        }
      }
    }
    workOrderReviewTransactional: clientNotification(
      id: WORK_ORDER_REVIEW_REQUEST_TRANSACTIONAL
    ) {
      templates {
        nodes {
          ... on MessageTemplateSMS {
            id
            deliveryMethod
            message {
              current
              default
            }
            variables {
              nodes {
                ...variableSection
              }
            }
          }
        }
      }
    }
    reviewV2Request: clientNotification(id: REVIEW_V2_REQUEST) {
      templates {
        nodes {
          ... on MessageTemplateSMS {
            id
            deliveryMethod
            message {
              ...stringWithDefaultFields
            }
            variables {
              nodes {
                ...variableSection
              }
            }
            footerText
          }
        }
      }
    }
  }
  ${stringWithDefaultFieldsFragment}
  ${variableFieldsFragment}
  ${variableSectionFragment}
`;

export const GOOGLE_BUSINESS_ACCOUNT_DISCONNECT_MUTATION = gql`
  mutation googleBusinessAccountDisconnect {
    googleBusinessAccountDisconnect {
      googleBusinessAccount {
        id
        isConnected
      }
      userErrors {
        message
        path
      }
    }
  }
`;

export const FIRST_MESSAGE_SETTINGS_QUERY: TypedDocumentNode<FirstMessageSettingsQuery> = gql`
  query FirstMessageSettings {
    reviewsInitialMessageSettings {
      triggerType
    }
  }
`;

export const FOLLOW_UP_MESSAGE_SETTINGS_QUERY = gql`
  query followUpMessageSettings {
    clientNotification(id: REVIEW_REQUEST_REMINDER) {
      id
      schedules {
        edges {
          node {
            ...reviewsNotificationSchedule
          }
        }
      }
      templates {
        nodes {
          ... on MessageTemplateSMS {
            id
            deliveryMethod
            message {
              ...stringWithDefaultFields
            }
            variables {
              nodes {
                ...variableSection
              }
            }
          }
          ... on MessageTemplateEmail {
            id
            deliveryMethod
            message {
              ...stringWithDefaultFields
            }
            subject {
              ...stringWithDefaultFields
            }
            variables {
              nodes {
                ...variableSection
              }
            }
          }
        }
      }
    }
  }
  ${reviewsNotificationScheduleFragment}
  ${stringWithDefaultFieldsFragment}
  ${variableFieldsFragment}
  ${variableSectionFragment}
`;

export const EXPERIMENT_STATUS = gql`
  mutation experimentStatus($name: String!) {
    assignAndGetExperimentStatus(name: $name) {
      experiment {
        inExperiment
      }
    }
  }
`;
