import { defineMessages } from "react-intl";

export const messages = defineMessages({
  singleTransactionLimit: {
    id: "managed_accounts.settings.TransactionUsage.SingleTransactionLimit",
    defaultMessage: "<b>Single transaction:</b> {amount}",
    description: "Label for single transaction",
  },
  twentyFourHourLimit: {
    id: "managed_accounts.settings.TransactionUsage.24HourLimit",
    defaultMessage: "<b>24 hours:</b> {amount}",
    description: "Label for 24 hours",
  },
  weeklyLimit: {
    id: "managed_accounts.settings.TransactionUsage.weeklyLimit",
    defaultMessage: "<b>7-day:</b> {amount}",
    description: "Label for weekly",
  },
  thirtyDayLimit: {
    id: "managed_accounts.settings.TransactionUsage.30DayLimit",
    defaultMessage: "<b>30-day:</b> {amount}",
    description: "Label for 30 days",
  },
  amountUsed: {
    id: "managed_accounts.settings.TransactionUsage.amountUsed",
    defaultMessage: "Used {br} {amount}",
    description: "Used Label",
  },
  amountAvailable: {
    id: "managed_accounts.settings.TransactionUsage.amountAvailable",
    defaultMessage: "Available {br} {amount}",
    description: "Available Label",
  },
});
