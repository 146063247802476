import { defineMessages } from "react-intl";

export const messages = defineMessages({
  firstMessage: {
    id: "setupGuide.previewTemplate.followUpChip.firstMessage",
    defaultMessage: "First message",
    description: "First message for the preview template step",
  },
  firstFollowUp: {
    id: "setupGuide.previewTemplate.followUpChip.firstFollowUp",
    defaultMessage: "First follow-up",
    description: "First follow-up for the preview template step",
  },
  secondFollowUp: {
    id: "setupGuide.previewTemplate.followUpChip.secondFollowUp",
    defaultMessage: "Second follow-up",
    description: "Second follow-up for the preview template step",
  },
});
