import { gql } from "@apollo/client";

const CLIENT_HUB_SETTING_QUERY = gql`
  query ClientHubSettings {
    payQuoteDeposits: jobberPaymentsSetting(id: PAY_QUOTE_DEPOSITS) {
      enabled
    }
    payInvoices: jobberPaymentsSetting(id: PAY_INVOICES) {
      enabled
    }
    americanExpressPayments: jobberPaymentsSetting(
      id: AMERICAN_EXPRESS_PAYMENTS
    ) {
      enabled
    }
    mandatoryPaymentMethodOnFile: jobberPaymentsSetting(
      id: MANDATORY_PAYMENT_METHOD_ON_FILE
    ) {
      enabled
    }
    achPayments: jobberPaymentsSetting(id: ALLOW_ACH_PAYMENTS) {
      enabled
    }
    allowTips: jobberPaymentsSetting(id: ALLOW_TIPS) {
      enabled
    }
    jobberPaymentsSettings {
      achLimits {
        singleTransactionLimit
        twentyFourHourTransactionLimit
        weekTransactionLimit
        monthTransactionLimit
      }
      quotePaymentOptionPreference
      invoicePaymentOptionPreference
    }
  }
`;

export { CLIENT_HUB_SETTING_QUERY };
