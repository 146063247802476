import { useMutation } from "@apollo/client";
import type {
  JobberPaymentsLimitsChangeRequest,
  JobberPaymentsLimitsChangeRequestCreateMutation,
  JobberPaymentsLimitsChangeRequestCreateMutationVariables,
  JobberPaymentsLimitsChangeRequestLimits,
} from "~/utilities/API/graphql";
import { JOBBER_PAYMENTS_LIMIT_CHANGE_REQUEST_CREATE } from "./JobberPaymentsLimitsChangeRequestCreate.graphql";

export interface CreateACHPaymentLimitsRequestInput {
  limitsType: JobberPaymentsLimitsChangeRequestLimits;
}

export const useJobberPaymentsLimitsChangeRequestCreate = (
  limitsType: JobberPaymentsLimitsChangeRequestLimits,
) => {
  const [createPaymentsLimitsRequestMutation] = useMutation<
    JobberPaymentsLimitsChangeRequestCreateMutation,
    JobberPaymentsLimitsChangeRequestCreateMutationVariables
  >(JOBBER_PAYMENTS_LIMIT_CHANGE_REQUEST_CREATE, {
    fetchPolicy: "no-cache",
  });

  const createPaymentsLimitsRequest = async (): Promise<{
    jobberPaymentsLimitsChangeRequest:
      | JobberPaymentsLimitsChangeRequest
      | undefined;
    errorMessage?: string;
  }> => {
    const variables: JobberPaymentsLimitsChangeRequestCreateMutationVariables =
      {
        input: { limitsType },
      };

    try {
      const result = await createPaymentsLimitsRequestMutation({
        variables,
      });

      return {
        jobberPaymentsLimitsChangeRequest:
          result.data?.jobberPaymentsLimitsChangeRequestCreate
            .jobberPaymentsLimitsChangeRequest,
      };
    } catch (error) {
      return {
        jobberPaymentsLimitsChangeRequest: undefined,
        errorMessage: error.message,
      };
    }
  };

  return { createPaymentsLimitsRequest };
};
