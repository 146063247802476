import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { ConfirmationModal } from "@jobber/components/ConfirmationModal";
import { APIProvider } from "~/utilities/API/APIProvider";
import { IntlProvider } from "@translations/IntlProvider";
import { SplitNames, useFeatureFlag } from "utilities/split";
import { useClientSegmentPageNavigation } from "jobber/campaigns/views/CampaignRecipientsPage/hooks/useClientSegmentPageNavigation/useClientSegmentPageNavigation";
import { interactedWithClientSegmentAmplitudeEvent } from "jobber/campaigns/amplitude/events";
import { useCommsCampaignQuery } from "jobber/campaigns/hooks/useCommsCampaignQuery";
import type { ClientSegmentFragment, Template } from "~/utilities/API/graphql";
import { useSaveOnDirty } from "jobber/campaigns/views/CampaignRecipientsPage/hooks/useSaveOnDirty/useSaveOnDirty";
import { useAutomationControls } from "jobber/campaigns/views/CampaignRecipientsPage/hooks/useAutomationControls/useAutomationControls";
import { isAutomatedCampaignType } from "jobber/campaigns/utils";
import { useStateInitialization } from "jobber/campaigns/views/CampaignRecipientsPage/hooks/useStateInitialization";
import { ClientSegmentNavigationModal } from "jobber/campaigns/components/ClientSegmentNavigationModal/ClientSegmentNavigationModal";
import { messages as contentPageMessages } from "jobber/campaigns/views/CampaignsContentPage/messages";
import { SelectClientSegment } from "./components/SelectClientSegment/SelectClientSegment";
import { RecipientsWrapper } from "./components/RecipientsWrapper/RecipientsWrapper";
import { CAMPAIGNS_LANDING_PAGE_PATH } from "../../constants";

export interface CampaignRecipientsPageProps {
  campaignId?: string;
  templateType?: string;
}

export function CampaignRecipientsPage(props: CampaignRecipientsPageProps) {
  return (
    <APIProvider>
      <IntlProvider>
        <CampaignsRecipientsPageInternal {...props} />
      </IntlProvider>
    </APIProvider>
  );
}

// eslint-disable-next-line max-statements
function CampaignsRecipientsPageInternal({
  campaignId,
  templateType,
}: CampaignRecipientsPageProps): JSX.Element {
  const [clientSegment, setClientSegment] = useState<
    ClientSegmentFragment | undefined
  >();
  const [isInErrorState, setIsInErrorState] = useState(false);
  const [renderExitConfirmationModal, setRenderExitConfirmationModal] =
    useState(false);
  const { formatMessage } = useIntl();
  const isCampaignsStreamlined = useFeatureFlag(
    SplitNames.CampaignsStreamlined,
  );

  // This was in the useClientSegmentPageNavigation hook
  const history = useHistory();
  function onCampaignNotFound() {
    history.replace(CAMPAIGNS_LANDING_PAGE_PATH);
  }

  const { data, loading } = useCommsCampaignQuery({
    campaignId: campaignId,
    onCampaignNotFound,
  });

  // Sets up the provider state with our query results or templateType prop
  const { campaignTemplateType } = useStateInitialization({
    clientSegment,
    setClientSegment,
    data,
    templateType,
    onCampaignNotFound,
    loading,
  });

  // This will fire anytime the page loads, not the drawer listing clients
  // This should probably move to the drawer or a subcomponent
  useEffect(() => {
    interactedWithClientSegmentAmplitudeEvent({
      interaction: "List Viewed",
    });
  }, []);

  // wrapper navigation and banner control
  const { onCancel, onCancelLabel, onNext, onLeaveForm } =
    useClientSegmentPageNavigation(
      campaignId,
      setRenderExitConfirmationModal,
      renderExitConfirmationModal,
    );

  const { saveOnNext, updateSegmentCriteriaLoading } = useSaveOnDirty({
    campaignId,
    initialClientSegment: clientSegment,
    onNextNavigation: onNext,
    setIsInErrorState: setIsInErrorState,
    renderExitConfirmationModal,
    setRenderExitConfirmationModal,
  });

  const isAutomatedType = isAutomatedCampaignType(
    campaignTemplateType as Template,
    data?.commsCampaign?.isAutomated,
  );

  const {
    onNext: onAutomationNext,
    loading: automationOnNextIsLoading,
    conditions: automationConditions,
    dispatch: automationDispatch,
    trigger: triggerArguments,
    setTrigger: setTriggerArguments,
    errors: automationRuleErrors,
    clearError,
  } = useAutomationControls({
    onNextNav: onNext,
    savedRule: data?.commsCampaign?.automationRule,
    templateType: campaignTemplateType,
    isAutomated: isAutomatedType,
  });

  const onNextButton = () => {
    return isAutomatedType ? onAutomationNext() : saveOnNext();
  };

  return (
    <RecipientsWrapper
      onNext={onNextButton}
      onCancel={onCancel}
      onCancelLabel={onCancelLabel}
      isInErrorState={isInErrorState}
      loading={updateSegmentCriteriaLoading || automationOnNextIsLoading}
    >
      {isCampaignsStreamlined ? (
        <ConfirmationModal
          title={formatMessage(contentPageMessages.exitConfirmationModalTitle)}
          message={formatMessage(contentPageMessages.exitConfirmationModalText)}
          open={renderExitConfirmationModal}
          confirmLabel={formatMessage(
            contentPageMessages.exitConfirmationModalPrimaryButton,
          )}
          onConfirm={onLeaveForm}
          onRequestClose={() => setRenderExitConfirmationModal(false)}
          size={"small"}
        />
      ) : (
        <ClientSegmentNavigationModal
          showModal={renderExitConfirmationModal}
          toggleModal={setRenderExitConfirmationModal}
          onPrimaryAction={onNextButton}
          onSecondaryAction={onLeaveForm}
        />
      )}
      <SelectClientSegment
        clientSegment={clientSegment}
        campaignTemplateType={campaignTemplateType}
        campaign={data?.commsCampaign}
        loading={loading}
        setClientSegment={setClientSegment}
        automationProps={{
          automationConditions,
          automationDispatch,
          triggerArguments,
          setTriggerArguments,
          automationRuleErrors,
          clearError,
        }}
      />
    </RecipientsWrapper>
  );
}
