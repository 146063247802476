/* eslint-disable */
/* This file is automatically generated and should not be edited. */
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** An encoded id */
  EncodedId: { input: string; output: string; }
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: string; output: string; }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string; }
};

/** The company of a Service Provider who uses Jobber for their business operations. */
export type Account = {
  __typename?: 'Account';
  /** Address */
  billingAddress?: Maybe<BillingAddress>;
  /** The time the account was created */
  createdAt: Scalars['ISO8601DateTime']['output'];
  /** The unique identifier */
  id: Scalars['EncodedId']['output'];
  /** Retrieves all invoices that are part of the given account */
  invoices: InvoiceConnection;
  /** The associated lead routes */
  leadRoutes: LeadRouteConnection;
  /** The name of the company */
  name: Scalars['String']['output'];
};


/** The company of a Service Provider who uses Jobber for their business operations. */
export type AccountInvoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<InvoiceFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** The company of a Service Provider who uses Jobber for their business operations. */
export type AccountLeadRoutesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** The connection type for Account. */
export type AccountConnection = {
  __typename?: 'AccountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AccountEdge>>;
  /** A list of nodes. */
  nodes: Array<Account>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /**
   * The total count of possible records in this list. Supports filters.
   * Please use with caution. Using totalCount raises the likelyhood you will be throttled
   */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AccountEdge = {
  __typename?: 'AccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Account;
};

/** Filters for accounts */
export type AccountFilterInput = {
  /** This ignores deactivated and/or not claimed franchisee accounts */
  ignoreInvalidFranchiseeAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  /** The scope of the accounts returned */
  scope?: InputMaybe<AccountScopeEnum>;
};

export enum AccountScopeEnum {
  /** Return accounts associated to the franchise */
  FRANCHISE = 'FRANCHISE',
  /** Return accounts associated to the current user */
  USER = 'USER'
}

/** Billing address information */
export type BillingAddress = {
  __typename?: 'BillingAddress';
  /** Street1 component of the address. */
  address1?: Maybe<Scalars['String']['output']>;
  /** Street2 component of the address. */
  address2?: Maybe<Scalars['String']['output']>;
  /** City of the address. */
  city?: Maybe<Scalars['String']['output']>;
  /** Country of the address. */
  country?: Maybe<Scalars['String']['output']>;
  /** State or province of the address. */
  state?: Maybe<Scalars['String']['output']>;
  /** Zip or postal code of this address. */
  zip?: Maybe<Scalars['String']['output']>;
};

export enum BulkInvoiceQueryStatus {
  /** bulk invoice query has finished */
  COMPLETED = 'COMPLETED',
  /** bulk invoice query has failed */
  FAILED = 'FAILED',
  /** bulk invoice query has finished, with partial results */
  PARTIAL = 'PARTIAL',
  /** bulk invoice query is being resolved */
  RUNNING = 'RUNNING',
  /** bulk invoice query has been created and is ready to be scheduled */
  WAITING = 'WAITING'
}

/** Representation of a BulkInvoicesQuery */
export type BulkInvoicesQuery = {
  __typename?: 'BulkInvoicesQuery';
  /** The unique identifier */
  id: Scalars['EncodedId']['output'];
  /** The status of the bulk operation */
  status: BulkInvoiceQueryStatus;
  /** The URL of the file, available when status is COMPLETED or PARTIAL */
  url?: Maybe<Scalars['String']['output']>;
};

/** Filters for BulkInvoiceQueryCreate */
export type BulkInvoicesQueryCreateInput = {
  /** Invoices created after this date */
  createdAfter: Scalars['ISO8601DateTime']['input'];
  /** Invoices created before this date */
  createdBefore: Scalars['ISO8601DateTime']['input'];
};

/** Autogenerated return type of BulkInvoicesQueryCreate. */
export type BulkInvoicesQueryCreatePayload = {
  __typename?: 'BulkInvoicesQueryCreatePayload';
  /** The created bulk invoices query */
  bulkInvoicesQuery?: Maybe<BulkInvoicesQuery>;
  /** Errors if there are problems saving the BulkInvoicesQuery */
  userErrors?: Maybe<Array<MutationErrors>>;
};

/** Autogenerated return type of ExportCsv. */
export type ExportCsvPayload = {
  __typename?: 'ExportCsvPayload';
  /** The response from the export */
  response: ReportTypeEnum;
  /** Errors encountered when exporting the report */
  userErrors: Array<MutationErrors>;
};

/** Attributes for sending an invoice detailed csv */
export type ExportInvoiceDetailCsvInput = {
  /** The filter options for selecting specific invoices */
  filter?: InputMaybe<InvoiceDetailFilterInput>;
  /** The sort attributes for invoices */
  sort?: InputMaybe<InvoiceDetailSortInput>;
};

/** Autogenerated return type of ExportInvoiceDetailCsv. */
export type ExportInvoiceDetailCsvPayload = {
  __typename?: 'ExportInvoiceDetailCsvPayload';
  /** The response from the export */
  response: ReportTypeEnum;
  /** Errors encountered when exporting the report */
  userErrors: Array<MutationErrors>;
};

/** Attributes sending a invoices summary csv */
export type ExportInvoicesSummaryCsvInput = {
  /** The filter options for selecting specific invoices */
  filter?: InputMaybe<InvoiceSummaryFilterInput>;
  /** The sort attributes for invoices */
  sort?: InputMaybe<InvoiceSummarySortInput>;
};

/** Autogenerated return type of ExportInvoicesSummaryCsv. */
export type ExportInvoicesSummaryCsvPayload = {
  __typename?: 'ExportInvoicesSummaryCsvPayload';
  /** The response from the export */
  response: ReportTypeEnum;
  /** Errors encountered when exporting the report */
  userErrors: Array<MutationErrors>;
};

/** Attributes for sending an quote detailed csv */
export type ExportQuoteDetailCsvInput = {
  /** The filter options for selecting specific quotes */
  filter?: InputMaybe<QuoteDetailFilterInput>;
  /** The sort attributes for quotes */
  sort?: InputMaybe<QuoteDetailSortInput>;
};

/** Autogenerated return type of ExportQuoteDetailCsv. */
export type ExportQuoteDetailCsvPayload = {
  __typename?: 'ExportQuoteDetailCsvPayload';
  /** The response from the export */
  response: ReportTypeEnum;
  /** Errors encountered when exporting the report */
  userErrors: Array<MutationErrors>;
};

/** Attributes sending a quote summary csv */
export type ExportQuotesSummaryCsvInput = {
  /** The filter options */
  filter?: InputMaybe<QuoteSummaryFilterInput>;
  /** The sort options */
  sort?: InputMaybe<QuoteSummarySortInput>;
};

/** Autogenerated return type of ExportQuotesSummaryCsv. */
export type ExportQuotesSummaryCsvPayload = {
  __typename?: 'ExportQuotesSummaryCsvPayload';
  /** The response from the export */
  response: ReportTypeEnum;
  /** Errors encountered when exporting the report */
  userErrors: Array<MutationErrors>;
};

/** Represents a single entry in the franchise leaderboard */
export type FranchiseLeaderboardEntry = {
  __typename?: 'FranchiseLeaderboardEntry';
  /** Average monetary value of jobs for this franchise */
  averageJobValue: Scalars['Float']['output'];
  /** Total issued invoice amount by this franchise location */
  issuedAmount: Scalars['Float']['output'];
  /** Percentage change in issued amount compared to previous period */
  issuedTrend: Scalars['Float']['output'];
  /** Number of jobs closed by this franchise location */
  jobsClosed: Scalars['Int']['output'];
  /** Name/identifier of the franchise location */
  name: Scalars['String']['output'];
};

/** Inputs for filtering franchise leaderboard data */
export type FranchiseLeaderboardFilterInput = {
  /** The accounts to filter by */
  accountIds?: InputMaybe<Array<Scalars['EncodedId']['input']>>;
  /** End date for the current leaderboard period */
  endDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** End date for the previous comparison period */
  previousPeriodEndDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Start date for the previous comparison period */
  previousPeriodStartDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
  /** Start date for the current leaderboard period */
  startDate?: InputMaybe<Scalars['ISO8601Date']['input']>;
};

/** Fields available for sorting franchise leaderboard data */
export type FranchiseLeaderboardSortInput = {
  /** The direction of the sort */
  direction: SortDirectionEnum;
  /** The key to sort on */
  key: FranchiseLeaderboardSortKey;
};

export enum FranchiseLeaderboardSortKey {
  /** Sort by average job value */
  AVERAGE_JOB_VALUE = 'AVERAGE_JOB_VALUE',
  /** Sort by issued amount */
  ISSUED_AMOUNT = 'ISSUED_AMOUNT',
  /** Sort by issued trend percentage */
  ISSUED_TREND = 'ISSUED_TREND',
  /** Sort by number of closed jobs */
  JOBS_CLOSED = 'JOBS_CLOSED'
}

/** A request for payment which Service Providers send to their clients after the work is done */
export type Invoice = {
  __typename?: 'Invoice';
  /** All amounts related to the invoice */
  amounts?: Maybe<InvoiceAmounts>;
  /** The date the invoice is due on */
  dueDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The unique identifier */
  id: Scalars['EncodedId']['output'];
  /** Number of whole days after the issue_date that payment is due */
  invoiceNet?: Maybe<Scalars['Int']['output']>;
  /** The invoice number */
  invoiceNumber: Scalars['String']['output'];
  /** The date the invoice was issued on */
  issuedDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  /** The date the invoice was received on */
  receivedDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** All amounts related to an invoice */
export type InvoiceAmounts = {
  __typename?: 'InvoiceAmounts';
  /** The deposit amount */
  depositAmount: Scalars['Float']['output'];
  /** The discount amount */
  discountAmount: Scalars['Float']['output'];
  /** The invoice balance after all payments */
  invoiceBalance: Scalars['Float']['output'];
  /** The computed discount amount applied to the invoice subtotal */
  legacyDiscountAmount: Scalars['Float']['output'];
  /** The non-tax amount including the line items which are exempted from the tax */
  nonTaxAmount: Scalars['Float']['output'];
  /** The total payments payed on the invoice */
  paymentsTotal: Scalars['Float']['output'];
  /** The subtotal including line item costs but excluding tax amounts */
  subtotal: Scalars['Float']['output'];
  /** The tax amount */
  taxAmount: Scalars['Float']['output'];
  /** The sum of all tips paid to an invoice */
  tipsTotal: Scalars['Float']['output'];
  /** The total cost of the invoice or quote, including line item costs and tax amounts */
  total: Scalars['Float']['output'];
};

/** The connection type for Invoice. */
export type InvoiceConnection = {
  __typename?: 'InvoiceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<InvoiceEdge>>;
  /** A list of nodes. */
  nodes: Array<Invoice>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /**
   * The total count of possible records in this list. Supports filters.
   * Please use with caution. Using totalCount raises the likelyhood you will be throttled
   */
  totalCount: Scalars['Int']['output'];
};

/** Detail information for a given Invoice. */
export type InvoiceDetail = {
  __typename?: 'InvoiceDetail';
  /** The users assigned to visits on the job linked to the invoice */
  assignedUsers?: Maybe<Scalars['String']['output']>;
  /** The remaining balance on the invoice */
  balance?: Maybe<Scalars['Float']['output']>;
  /** The associated client name */
  clientName: Scalars['String']['output'];
  /** The deposit amount on the invoice */
  depositAmount?: Maybe<Scalars['Float']['output']>;
  /** The discount amount for the invoice */
  discountAmount?: Maybe<Scalars['Float']['output']>;
  /** The date the invoice was drafted, formatted for display */
  draftedOn?: Maybe<Scalars['String']['output']>;
  /** The due date for the invoice, formatted for display */
  dueOn?: Maybe<Scalars['String']['output']>;
  /** The name of the associated franchisee */
  franchiseeName: Scalars['String']['output'];
  /** The invoice number */
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  /** The date the invoice was issued, formatted for display */
  issuedOn?: Maybe<Scalars['String']['output']>;
  /** The date the invoice was paid, formatted for display */
  paidOn?: Maybe<Scalars['String']['output']>;
  /** The invoice status */
  status: InvoiceStatusTypeEnum;
  /** The subject on the invoice */
  subject: Scalars['String']['output'];
  /** The pre-tax total for the invoice */
  subtotal?: Maybe<Scalars['Float']['output']>;
  /** The tax amount on the invoice */
  taxAmount?: Maybe<Scalars['Float']['output']>;
  /** The percentage of tax on the invoice */
  taxRate?: Maybe<Scalars['Float']['output']>;
  /** The total value of tips associated with the invoice */
  tipsTotal?: Maybe<Scalars['Float']['output']>;
  /** The total cost of the invoice */
  total?: Maybe<Scalars['Float']['output']>;
};

/** The connection type for InvoiceDetail. */
export type InvoiceDetailConnection = {
  __typename?: 'InvoiceDetailConnection';
  /** A list of edges. */
  edges?: Maybe<Array<InvoiceDetailEdge>>;
  /** A list of nodes. */
  nodes: Array<InvoiceDetail>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /**
   * The total count of possible records in this list. Supports filters.
   * Please use with caution. Using totalCount raises the likelyhood you will be throttled
   */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InvoiceDetailEdge = {
  __typename?: 'InvoiceDetailEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: InvoiceDetail;
};

/** Input for filter for invoice detail data */
export type InvoiceDetailFilterInput = {
  /** Invoices drafted after this date */
  draftedAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Invoices drafted before this date */
  draftedBefore?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** ID of the franchisee account to filter by */
  franchiseeId?: InputMaybe<Scalars['EncodedId']['input']>;
  /** Invoices issued after this date */
  issuedAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Invoices issued before this date */
  issuedBefore?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Invoices paid after this date */
  paidAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Invoices paid before this date */
  paidBefore?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

/** The attributes to sort on invoice detail data */
export type InvoiceDetailSortInput = {
  /** The direction of the sort */
  direction: SortDirectionEnum;
  /** The key to sort on */
  key: InvoiceDetailSortKey;
};

/** The fields, or associated fields, on a collection of invoice detail which support sorting functionality */
export enum InvoiceDetailSortKey {
  /** The remaining balance on the invoice */
  BALANCE = 'BALANCE',
  /** The associated client name */
  CLIENT_NAME = 'CLIENT_NAME',
  /** The deposit amount on the invoice */
  DEPOSIT_AMOUNT = 'DEPOSIT_AMOUNT',
  /** The discount amount for the invoice */
  DISCOUNT_AMOUNT = 'DISCOUNT_AMOUNT',
  /** The date the invoice was drafted */
  DRAFTED_ON = 'DRAFTED_ON',
  /** The due date for the invoice */
  DUE_ON = 'DUE_ON',
  /** The franchisee name */
  FRANCHISEE_NAME = 'FRANCHISEE_NAME',
  /** The invoice number */
  INVOICE_NUMBER = 'INVOICE_NUMBER',
  /** The date the invoice was issued */
  ISSUED_ON = 'ISSUED_ON',
  /** The date the invoice was paid */
  PAID_ON = 'PAID_ON',
  /** The invoice status */
  STATUS = 'STATUS',
  /** The subject on the invoice */
  SUBJECT = 'SUBJECT',
  /** The tax amount on the invoice */
  TAX_AMOUNT = 'TAX_AMOUNT',
  /** The percentage of tax on the invoice */
  TAX_RATE = 'TAX_RATE',
  /** The total value of tips associated with the invoice */
  TIPS_TOTAL = 'TIPS_TOTAL',
  /** The total cost of the invoice */
  TOTAL = 'TOTAL'
}

/** Invoice totals data for all invoices under a given franchisee account. */
export type InvoiceDetailTotals = {
  __typename?: 'InvoiceDetailTotals';
  /** Total remaining balance for all invoices */
  balanceTotal?: Maybe<Scalars['Float']['output']>;
  /** Total deposit amount for all invoices */
  depositAmountTotal?: Maybe<Scalars['Float']['output']>;
  /** Total discount amount for all invoices */
  discountAmountTotal?: Maybe<Scalars['Float']['output']>;
  /** Subtotal amount for all invoices */
  subtotalAmountTotal?: Maybe<Scalars['Float']['output']>;
  /** Total tax amount for all invoices */
  taxAmountTotal?: Maybe<Scalars['Float']['output']>;
  /** Total tip amount for all invoices */
  tipAmountTotal?: Maybe<Scalars['Float']['output']>;
  /** Total amount for invoices */
  totalAmountTotal: Scalars['Float']['output'];
};

/** An edge in a connection. */
export type InvoiceEdge = {
  __typename?: 'InvoiceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Invoice;
};

/** Filters for invoices */
export type InvoiceFilterInput = {
  /** Invoices created after this date */
  createdOnOrAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Invoices created after this date */
  createdOnOrBefore?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

export enum InvoiceStatusTypeEnum {
  /** Invoice is awaiting payment */
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  /** Invoice is in bad debt state */
  BAD_DEBT = 'BAD_DEBT',
  /** Invoice has been created */
  DRAFT = 'DRAFT',
  /** Invoice has been paid */
  PAID = 'PAID',
  /** Invoice is past due */
  PAST_DUE = 'PAST_DUE'
}

/** Invoice summary data for a franchise account. */
export type InvoiceSummary = {
  __typename?: 'InvoiceSummary';
  /** The average amount per invoice issued */
  averagePerInvoice?: Maybe<Scalars['Float']['output']>;
  /** The total number of invoices issued */
  issuedCount?: Maybe<Scalars['Float']['output']>;
  /** The name of the franchise */
  name: Scalars['String']['output'];
  /** The total amount for invoices issued */
  totalIssued?: Maybe<Scalars['Float']['output']>;
  /** The total amount for invoices paid */
  totalPaid?: Maybe<Scalars['Float']['output']>;
};

/** Input for filtering invoice summary data */
export type InvoiceSummaryFilterInput = {
  /** The accounts to filter by */
  accountIds?: InputMaybe<Array<Scalars['EncodedId']['input']>>;
  /** The end date to filter by */
  endDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** The start date to filter by */
  startDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

/** The list and page information for reporting records */
export type InvoiceSummaryReportList = {
  __typename?: 'InvoiceSummaryReportList';
  /** List of reporting records */
  records: Array<InvoiceSummary>;
  /** Information to aid in pagination */
  reportPageInfo?: Maybe<ReportPageInfo>;
  /** The total count of possible records in this list. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** The attributes to sort on invoice summary data */
export type InvoiceSummarySortInput = {
  /** The direction of the sort */
  direction: SortDirectionEnum;
  /** The key to sort on */
  key: InvoiceSummarySortKey;
};

/** The fields, or associated fields, on a collection of invoice summary which support sorting functionality */
export enum InvoiceSummarySortKey {
  /** The average amount per invoice issued */
  AVERAGE_PER_INVOICE = 'AVERAGE_PER_INVOICE',
  /** The name of the franchise */
  FRANCHISE_NAME = 'FRANCHISE_NAME',
  /** The total number of invoices issued */
  ISSUED_COUNT = 'ISSUED_COUNT',
  /** The total amount for invoices issued */
  TOTAL_ISSUED = 'TOTAL_ISSUED',
  /** The total amount for invoices paid */
  TOTAL_PAID = 'TOTAL_PAID'
}

/** Invoice summary data totals for accounts under a franchise */
export type InvoiceSummaryTotals = {
  __typename?: 'InvoiceSummaryTotals';
  /** Average of all invoices issued ($) for all accounts under the franchise */
  issuedAverageTotal?: Maybe<Scalars['Float']['output']>;
  /** Total number of invoices issued for all accounts under the franchise */
  issuedCountTotal?: Maybe<Scalars['Float']['output']>;
  /** Total amount of invoices issued ($) for all accounts under the franchise */
  issuedTotal?: Maybe<Scalars['Float']['output']>;
  /** Total amount for invoices paid ($) for all accounts under the franchise */
  paidTotal?: Maybe<Scalars['Float']['output']>;
};

/** Lead funnel data totals for accounts under a franchise */
export type LeadFunnel = {
  __typename?: 'LeadFunnel';
  /** Total number of jobs closed for all accounts under the franchise */
  closedJobs?: Maybe<Scalars['Int']['output']>;
  /** Total number of assessments for accounts under the franchise */
  newAssessments?: Maybe<Scalars['Int']['output']>;
  /** Total number of jobs created by new leads for all accounts under the franchise */
  newJobs?: Maybe<Scalars['Int']['output']>;
  /** Total number of new leads for all accounts under the franchise */
  newLeads?: Maybe<Scalars['Int']['output']>;
  /** Total number of requests for accounts under the franchise */
  newRequests?: Maybe<Scalars['Int']['output']>;
  /** Total number of quotes sent for all accounts under the franchise */
  sentQuotes?: Maybe<Scalars['Int']['output']>;
};

/** Input for filtering franchise lead funnel data */
export type LeadFunnelFilterInput = {
  /** The accounts to filter by */
  accountIds?: InputMaybe<Array<Scalars['EncodedId']['input']>>;
  /** The end date to filter by */
  endDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** The start date to filter by */
  startDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

/** Represents a single lead route attached to a franchise account */
export type LeadRoute = {
  __typename?: 'LeadRoute';
  /** The franchise account mapped to this lead route */
  account?: Maybe<Account>;
  /** The unique identifier */
  id: Scalars['EncodedId']['output'];
  /** The routing identifier (e.g. zip code) for this lead route */
  routingCode: Scalars['String']['output'];
  /** URL to navigate to when this lead route is selected */
  url: Scalars['String']['output'];
};

/** The connection type for LeadRoute. */
export type LeadRouteConnection = {
  __typename?: 'LeadRouteConnection';
  /** A list of edges. */
  edges?: Maybe<Array<LeadRouteEdge>>;
  /** A list of nodes. */
  nodes: Array<LeadRoute>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /**
   * The total count of possible records in this list. Supports filters.
   * Please use with caution. Using totalCount raises the likelyhood you will be throttled
   */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LeadRouteEdge = {
  __typename?: 'LeadRouteEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: LeadRoute;
};

/** Autogenerated return type of LeadRoutesEdit. */
export type LeadRoutesEditPayload = {
  __typename?: 'LeadRoutesEditPayload';
  /** The account's lead routes */
  leadRoutes?: Maybe<Array<LeadRoute>>;
  /** Errors if there are problems saving the lead routes */
  userErrors: Array<MutationErrors>;
};

/** Information about the current user's Jobber Central membership */
export type Membership = {
  __typename?: 'Membership';
  /** The member ID of the current user */
  memberId?: Maybe<Scalars['String']['output']>;
  /** The name of the organization */
  organizationName: Scalars['String']['output'];
};

/** The Franchise schema's entry point for all mutation operations. */
export type Mutation = {
  __typename?: 'Mutation';
  /** Mutation to create a BulkInvoicesQuery */
  bulkInvoicesQueryCreate: BulkInvoicesQueryCreatePayload;
  /** Exports a csv for a report */
  exportCsv: ExportCsvPayload;
  /** Exports a csv for an invoice detail report */
  exportInvoiceDetailCsv: ExportInvoiceDetailCsvPayload;
  /** Exports a csv for an invoices summary report */
  exportInvoicesSummaryCsv: ExportInvoicesSummaryCsvPayload;
  /** Exports a csv for an quote detail report */
  exportQuoteDetailCsv: ExportQuoteDetailCsvPayload;
  /** Exports a csv for the quote summary report */
  exportQuotesSummaryCsv: ExportQuotesSummaryCsvPayload;
  /** Mutation to edit a franchise account's lead routes */
  leadRoutesEdit: LeadRoutesEditPayload;
};


/** The Franchise schema's entry point for all mutation operations. */
export type MutationBulkInvoicesQueryCreateArgs = {
  input: BulkInvoicesQueryCreateInput;
};


/** The Franchise schema's entry point for all mutation operations. */
export type MutationExportCsvArgs = {
  filter?: InputMaybe<RoyaltyFilterAttributes>;
  reportType: ReportTypeEnum;
  sort?: InputMaybe<RoyaltySortInput>;
};


/** The Franchise schema's entry point for all mutation operations. */
export type MutationExportInvoiceDetailCsvArgs = {
  input?: InputMaybe<ExportInvoiceDetailCsvInput>;
};


/** The Franchise schema's entry point for all mutation operations. */
export type MutationExportInvoicesSummaryCsvArgs = {
  input?: InputMaybe<ExportInvoicesSummaryCsvInput>;
};


/** The Franchise schema's entry point for all mutation operations. */
export type MutationExportQuoteDetailCsvArgs = {
  input?: InputMaybe<ExportQuoteDetailCsvInput>;
};


/** The Franchise schema's entry point for all mutation operations. */
export type MutationExportQuotesSummaryCsvArgs = {
  input?: InputMaybe<ExportQuotesSummaryCsvInput>;
};


/** The Franchise schema's entry point for all mutation operations. */
export type MutationLeadRoutesEditArgs = {
  accountId: Scalars['EncodedId']['input'];
  leadRoutes: Array<Scalars['String']['input']>;
};

/** User errors that are triggered by a mutation */
export type MutationErrors = UserErrorsInterface & {
  __typename?: 'MutationErrors';
  /** The message provided for this error. */
  message: Scalars['String']['output'];
  /** The field that triggered the error. */
  path: Array<Scalars['String']['output']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The franchise schema's entry point for all query operations. */
export type Query = {
  __typename?: 'Query';
  /** Retrieves accounts associated with the current organization for navigation purposes */
  accessibleAccounts: AccountConnection;
  /** Retrieves a single account that is part of the franchise specified by the ID */
  account: Account;
  /** Retrieves accounts that are part of this franchise */
  accounts: AccountConnection;
  /** Retrieves accounts associated with the current organization for reporting purposes */
  accountsWithAccessibleReports: AccountConnection;
  /** A bulk invoices query on the franchise */
  bulkInvoicesQuery?: Maybe<BulkInvoicesQuery>;
  /** Retrieves Jobber Central membership information for the current user */
  centralMembership: Membership;
  /** Retrieves franchise leaderboard data */
  franchiseLeaderboard: Array<FranchiseLeaderboardEntry>;
  /** Retrieves invoice detail data for all accounts under the franchise */
  invoiceDetail: InvoiceDetailConnection;
  /** Retrieves totals for all invoices that are part of the given account */
  invoiceDetailTotals: InvoiceDetailTotals;
  /** Retrieves invoice summary for all accounts that are a part of this franchise */
  invoiceSummary: InvoiceSummaryReportList;
  /** Retrieves invoice summary totals for all accounts that are a part of this franchise */
  invoiceSummaryTotals: InvoiceSummaryTotals;
  /** Retrieves lead funnel details for all accounts that are a part of this franchise */
  leadFunnel: LeadFunnel;
  /** Retrieves lead routes that are part of this franchise */
  leadRoutes: LeadRouteConnection;
  /** Retrieves quotes for all accounts that are a part of a franchise */
  quoteDetail: QuoteDetailConnection;
  /** Retrieves totals for all quotes that are part of the given franchise */
  quoteDetailTotals: QuoteDetailTotals;
  /** Retrieves quote summary for all accounts that are a part of this franchise */
  quotesSummary: QuoteSummaryReportList;
  /** Retrieves quote summary totals for all accounts that are a part of this franchise */
  quotesSummaryTotals: QuoteSummaryTotals;
  /** Retrieves royalties for all accounts that are a part of this franchise */
  royalties: RoyaltyReportList;
  /** Retrieves total values for payments and royalties on all accounts that are a part of the franchise */
  royaltyTotals: RoyaltyTotals;
  /** Retrieves all source attribution details for franchise accounts */
  sourceAttribution: SourceAttributionConnection;
};


/** The franchise schema's entry point for all query operations. */
export type QueryAccessibleAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** The franchise schema's entry point for all query operations. */
export type QueryAccountArgs = {
  id: Scalars['EncodedId']['input'];
};


/** The franchise schema's entry point for all query operations. */
export type QueryAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AccountFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** The franchise schema's entry point for all query operations. */
export type QueryAccountsWithAccessibleReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** The franchise schema's entry point for all query operations. */
export type QueryBulkInvoicesQueryArgs = {
  id: Scalars['EncodedId']['input'];
};


/** The franchise schema's entry point for all query operations. */
export type QueryFranchiseLeaderboardArgs = {
  filter?: InputMaybe<FranchiseLeaderboardFilterInput>;
  sort?: InputMaybe<FranchiseLeaderboardSortInput>;
};


/** The franchise schema's entry point for all query operations. */
export type QueryInvoiceDetailArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<InvoiceDetailFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<InvoiceDetailSortInput>;
};


/** The franchise schema's entry point for all query operations. */
export type QueryInvoiceDetailTotalsArgs = {
  filter?: InputMaybe<InvoiceDetailFilterInput>;
};


/** The franchise schema's entry point for all query operations. */
export type QueryInvoiceSummaryArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<InvoiceSummaryFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<InvoiceSummarySortInput>;
};


/** The franchise schema's entry point for all query operations. */
export type QueryInvoiceSummaryTotalsArgs = {
  filter?: InputMaybe<InvoiceSummaryFilterInput>;
};


/** The franchise schema's entry point for all query operations. */
export type QueryLeadFunnelArgs = {
  filter?: InputMaybe<LeadFunnelFilterInput>;
};


/** The franchise schema's entry point for all query operations. */
export type QueryLeadRoutesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchTerm: Scalars['String']['input'];
};


/** The franchise schema's entry point for all query operations. */
export type QueryQuoteDetailArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<QuoteDetailFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<QuoteDetailSortInput>;
};


/** The franchise schema's entry point for all query operations. */
export type QueryQuoteDetailTotalsArgs = {
  filter?: InputMaybe<QuoteDetailFilterInput>;
};


/** The franchise schema's entry point for all query operations. */
export type QueryQuotesSummaryArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<QuoteSummaryFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<QuoteSummarySortInput>;
};


/** The franchise schema's entry point for all query operations. */
export type QueryQuotesSummaryTotalsArgs = {
  filter?: InputMaybe<QuoteSummaryFilterInput>;
};


/** The franchise schema's entry point for all query operations. */
export type QueryRoyaltiesArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<RoyaltyFilterAttributes>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<RoyaltySortInput>;
};


/** The franchise schema's entry point for all query operations. */
export type QueryRoyaltyTotalsArgs = {
  filter?: InputMaybe<RoyaltyFilterAttributes>;
};


/** The franchise schema's entry point for all query operations. */
export type QuerySourceAttributionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SourceAttributionFilterInput>;
  filterBy: SourceAttributionFilterBy;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** Information about a quote */
export type QuoteDetail = {
  __typename?: 'QuoteDetail';
  /** The date the quote was approved, formatted for display */
  approvedOn?: Maybe<Scalars['String']['output']>;
  /** The date the quote was archived, formatted for display */
  archivedOn?: Maybe<Scalars['String']['output']>;
  /** Name of the client */
  clientName?: Maybe<Scalars['String']['output']>;
  /** The date the quote was converted to a job, formatted for display */
  convertedOn?: Maybe<Scalars['String']['output']>;
  /** The date the quote was created, formatted for display */
  draftedOn?: Maybe<Scalars['String']['output']>;
  /** The id of the franchisee */
  franchiseeId: Scalars['String']['output'];
  /** The name of the franchisee */
  franchiseeName: Scalars['String']['output'];
  /** The identification numbers of linked jobs */
  jobNumbers?: Maybe<Scalars['String']['output']>;
  /** The last date the client requested changes to the quote, formatted for display */
  lastChangesRequestedOn?: Maybe<Scalars['String']['output']>;
  /** Property address */
  propertyAddress?: Maybe<Scalars['String']['output']>;
  /** The quote identification number */
  quoteNumber?: Maybe<Scalars['String']['output']>;
  /** The date the quote was sent to the client, formatted for display */
  sentOn?: Maybe<Scalars['String']['output']>;
  /** The current status of the quote */
  status?: Maybe<QuoteStatusTypeEnum>;
  /** The total value of the quote */
  total?: Maybe<Scalars['Float']['output']>;
};

/** The connection type for QuoteDetail. */
export type QuoteDetailConnection = {
  __typename?: 'QuoteDetailConnection';
  /** A list of edges. */
  edges?: Maybe<Array<QuoteDetailEdge>>;
  /** A list of nodes. */
  nodes: Array<QuoteDetail>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /**
   * The total count of possible records in this list. Supports filters.
   * Please use with caution. Using totalCount raises the likelyhood you will be throttled
   */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type QuoteDetailEdge = {
  __typename?: 'QuoteDetailEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: QuoteDetail;
};

/** Input for filter for quote detail data */
export type QuoteDetailFilterInput = {
  /** Filter for quotes converted after this date */
  convertedAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Filter for quotes converted before this date */
  convertedBefore?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Filter for quotes created after this date */
  draftedAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Filter for quotes created before this date */
  draftedBefore?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** ID of the franchisee account to filter by */
  franchiseeIds?: InputMaybe<Scalars['EncodedId']['input']>;
  /** Filter for quotes sent after this date */
  sentAfter?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** Filter for quotes sent before this date */
  sentBefore?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

/** The attributes to sort quotes */
export type QuoteDetailSortInput = {
  /** The direction of the sort */
  direction: SortDirectionEnum;
  /** The key to sort on */
  key: QuoteDetailSortKey;
};

/** The fields, or associated fields, on a collection of quotes which support sorting functionality */
export enum QuoteDetailSortKey {
  /** The date the quote was approved */
  APPROVED_ON = 'APPROVED_ON',
  /** The date the quote was archived */
  ARCHIVED_ON = 'ARCHIVED_ON',
  /** Name of the client */
  CLIENT_NAME = 'CLIENT_NAME',
  /** The date the quote was converted to a job */
  CONVERTED_ON = 'CONVERTED_ON',
  /** The date the quote was created */
  DRAFTED_ON = 'DRAFTED_ON',
  /** The name of the franchisee */
  FRANCHISEE_NAME = 'FRANCHISEE_NAME',
  /** The last date the client requested changes to the quote */
  LAST_CHANGES_REQUESTED_ON = 'LAST_CHANGES_REQUESTED_ON',
  /** The location of the quoted work */
  PROPERTY_ADDRESS = 'PROPERTY_ADDRESS',
  /** The quote identification number */
  QUOTE_NUMBER = 'QUOTE_NUMBER',
  /** The date the quote was sent to the client */
  SENT_ON = 'SENT_ON',
  /** The current status of the quote */
  STATUS = 'STATUS',
  /** The total value of the quote */
  TOTAL = 'TOTAL'
}

/** Total dollar information about a quote */
export type QuoteDetailTotals = {
  __typename?: 'QuoteDetailTotals';
  /** The total dollar value worth of many quotes */
  total?: Maybe<Scalars['Float']['output']>;
};

export enum QuoteStatusTypeEnum {
  /** The state when a quote is approved by a client */
  APPROVED = 'approved',
  /** The state when a quote is archived */
  ARCHIVED = 'archived',
  /** The state when the quote is sent to a client */
  AWAITING_RESPONSE = 'awaiting_response',
  /** The state when a client request changes to the quote */
  CHANGES_REQUESTED = 'changes_requested',
  /** The state when a quote is converted to a job */
  CONVERTED = 'converted',
  /** The default state of a quote */
  DRAFT = 'draft'
}

/** Summary of Quote information on a franchisee account */
export type QuoteSummary = {
  __typename?: 'QuoteSummary';
  /** The name of the franchisee */
  name: Scalars['String']['output'];
  /** Rate of sent quotes that end up in a converted state */
  quoteConversionRate?: Maybe<Scalars['Float']['output']>;
  /** The average value of quotes in the converted state */
  quoteConvertedAverage?: Maybe<Scalars['Float']['output']>;
  /** The average value of quotes in the sent state */
  quoteSentAverage?: Maybe<Scalars['Float']['output']>;
  /** The number of quotes in the converted state */
  quotesConvertedCount?: Maybe<Scalars['Int']['output']>;
  /** The sum of quote values in the converted state */
  quotesConvertedTotal?: Maybe<Scalars['Float']['output']>;
  /** The number of quotes in the sent state */
  quotesSentCount?: Maybe<Scalars['Int']['output']>;
  /** The sum of quote values in the sent state */
  quotesSentTotal?: Maybe<Scalars['Float']['output']>;
};

/** Input for filtering quotes summary data */
export type QuoteSummaryFilterInput = {
  /** The accounts to filter by */
  accountIds?: InputMaybe<Array<Scalars['EncodedId']['input']>>;
  /** The end date to filter by */
  endDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** The start date to filter by */
  startDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

/** The list and page information for reporting records */
export type QuoteSummaryReportList = {
  __typename?: 'QuoteSummaryReportList';
  /** List of reporting records */
  records: Array<QuoteSummary>;
  /** Information to aid in pagination */
  reportPageInfo?: Maybe<ReportPageInfo>;
  /** The total count of possible records in this list. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** The attributes to sort on quote summary data */
export type QuoteSummarySortInput = {
  /** The direction of the sort */
  direction: SortDirectionEnum;
  /** The key to sort on */
  key: QuoteSummarySortKey;
};

/** The fields, or associated fields, on a collection of quotes summary which support sorting functionality */
export enum QuoteSummarySortKey {
  /** The conversion rate of quotes converted/sent */
  CONVERSION_RATE = 'CONVERSION_RATE',
  /** The average amount per quote converted */
  CONVERTED_AVERAGE = 'CONVERTED_AVERAGE',
  /** The count of quotes converted */
  CONVERTED_COUNT = 'CONVERTED_COUNT',
  /** The total amount per quotes converted */
  CONVERTED_TOTAL = 'CONVERTED_TOTAL',
  /** The name of the franchise */
  FRANCHISE_NAME = 'FRANCHISE_NAME',
  /** The average amount for quote sent */
  SENT_AVERAGE = 'SENT_AVERAGE',
  /** The total number of quotes sent */
  SENT_COUNT = 'SENT_COUNT',
  /** The total amount for quotes sent */
  SENT_TOTAL = 'SENT_TOTAL'
}

/** Summary of Quote information on all franchisee accounts for a single franchise */
export type QuoteSummaryTotals = {
  __typename?: 'QuoteSummaryTotals';
  /** The average rate of sent quotes that end up in a converted state */
  quoteConversionRateAverage?: Maybe<Scalars['Float']['output']>;
  /** The average value of quotes in the converted state */
  quoteConvertedAverage?: Maybe<Scalars['Float']['output']>;
  /** The average value of quotes in the sent state */
  quoteSentAverage?: Maybe<Scalars['Float']['output']>;
  /** The number of quotes in the converted state */
  quotesConvertedCount?: Maybe<Scalars['Int']['output']>;
  /** The sum of quote values in the converted state */
  quotesConvertedTotal?: Maybe<Scalars['Float']['output']>;
  /** The number of quotes in the sent state */
  quotesSentCount?: Maybe<Scalars['Int']['output']>;
  /** The sum of quote values in the sent state */
  quotesSentTotal?: Maybe<Scalars['Float']['output']>;
};

/** Report pagination information */
export type ReportPageInfo = {
  __typename?: 'ReportPageInfo';
  /** Number of records for the current page */
  currentPageDocCount?: Maybe<Scalars['Int']['output']>;
  /** Cursor for the next page */
  nextCursor?: Maybe<Scalars['String']['output']>;
  /** Offset of the next cursor */
  nextCursorOffset?: Maybe<Scalars['Int']['output']>;
  /** Url that can be used to get the next page */
  nextPageLink?: Maybe<Scalars['String']['output']>;
  /** Start cursor for the current page */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export enum ReportTypeEnum {
  /** An invoice detail report */
  INVOICE_DETAIL = 'INVOICE_DETAIL',
  /** An invoice summary report */
  INVOICE_SUMMARY = 'INVOICE_SUMMARY',
  /** A quote summary report */
  QUOTES_SUMMARY = 'QUOTES_SUMMARY',
  /** An quote detail report */
  QUOTE_DETAIL = 'QUOTE_DETAIL',
  /** A royalty report */
  ROYALTY = 'ROYALTY'
}

/** Royalty data for a franchise account. */
export type Royalty = {
  __typename?: 'Royalty';
  /** The name of the franchise */
  name: Scalars['String']['output'];
  /** Pre-tax total * royalty rate */
  royaltyAmount?: Maybe<Scalars['Float']['output']>;
  /** The percentage take of the distribution channel as royalties */
  royaltyRate?: Maybe<Scalars['Float']['output']>;
  /** The pre-tax payments total */
  total?: Maybe<Scalars['Float']['output']>;
};

/** Attributes for filtering royalty data */
export type RoyaltyFilterAttributes = {
  /** The accounts to filter by */
  accountIds?: InputMaybe<Array<Scalars['EncodedId']['input']>>;
  /** The end date to filter by */
  endDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** The start date to filter by */
  startDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

/** The list and page information for reporting records */
export type RoyaltyReportList = {
  __typename?: 'RoyaltyReportList';
  /** List of reporting records */
  records: Array<Royalty>;
  /** Information to aid in pagination */
  reportPageInfo?: Maybe<ReportPageInfo>;
  /** The total count of possible records in this list. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** The attributes to sort on royalty data */
export type RoyaltySortInput = {
  /** The direction of the sort */
  direction: SortDirectionEnum;
  /** The key to sort on */
  key: RoyaltySortKey;
};

/** The fields, or associated fields, on a collection of royalties which support sorting functionality */
export enum RoyaltySortKey {
  /** The name of the franchise */
  FRANCHISE_NAME = 'FRANCHISE_NAME',
  /** The pre-tax payments total */
  PAYMENTS_TOTAL = 'PAYMENTS_TOTAL',
  /** Pre-tax total * royalty rate */
  ROYALTY_AMOUNT = 'ROYALTY_AMOUNT',
  /** The percentage take of the distribution channel as royalties */
  ROYALTY_RATE = 'ROYALTY_RATE'
}

/** Royalty data totals for accounts under a franchise */
export type RoyaltyTotals = {
  __typename?: 'RoyaltyTotals';
  /** The pre-tax payments total for all accounts under the franchise */
  paymentsTotal?: Maybe<Scalars['Float']['output']>;
  /** Pre-tax total * royalty rate for all accounts under the franchise */
  royaltyAmountsTotal?: Maybe<Scalars['Float']['output']>;
};

/** Sort directions */
export enum SortDirectionEnum {
  /** Sort by ascending order */
  ASCENDING = 'ASCENDING',
  /** Sort by descending order */
  DESCENDING = 'DESCENDING'
}

/** Source attribution data for a franchise */
export type SourceAttribution = {
  __typename?: 'SourceAttribution';
  /** The number of jobs attributed to the source attribution */
  numberOfJobs: Scalars['Int']['output'];
  /** The number of leads attributed to the source attribution */
  numberOfLeads: Scalars['Int']['output'];
  /** The name of the source attribution */
  sourceName: Scalars['String']['output'];
  /** The total revenue attributed to the source attribution */
  totalRevenue: Scalars['Float']['output'];
};

/** The connection type for SourceAttribution. */
export type SourceAttributionConnection = {
  __typename?: 'SourceAttributionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SourceAttributionEdge>>;
  /** A list of nodes. */
  nodes: Array<SourceAttribution>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /**
   * The total count of possible records in this list. Supports filters.
   * Please use with caution. Using totalCount raises the likelyhood you will be throttled
   */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SourceAttributionEdge = {
  __typename?: 'SourceAttributionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: SourceAttribution;
};

export enum SourceAttributionFilterBy {
  /** Filters the results using the client_created field. */
  CLIENT_CREATED = 'CLIENT_CREATED',
  /** Filters the results by the first_job_created field. */
  FIRST_JOB_CREATED = 'FIRST_JOB_CREATED'
}

/** Input for filtering source attribution data */
export type SourceAttributionFilterInput = {
  /** The accounts to filter by */
  accountIds?: InputMaybe<Array<Scalars['EncodedId']['input']>>;
  /** The end date to filter by */
  endDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  /** The start date to filter by */
  startDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

/** User errors that are triggered */
export type UserErrorsInterface = {
  /** The message provided for this error. */
  message: Scalars['String']['output'];
  /** The field that triggered the error. */
  path: Array<Scalars['String']['output']>;
};

export type InvoiceDetailDataQueryVariables = Exact<{
  filter?: InputMaybe<InvoiceDetailFilterInput>;
  sort?: InputMaybe<InvoiceDetailSortInput>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type InvoiceDetailDataQuery = { __typename?: 'Query', invoiceDetail: { __typename?: 'InvoiceDetailConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', startCursor?: string | undefined, endCursor?: string | undefined, hasNextPage: boolean, hasPreviousPage: boolean }, nodes: Array<{ __typename?: 'InvoiceDetail', assignedUsers?: string | undefined, balance?: number | undefined, clientName: string, depositAmount?: number | undefined, discountAmount?: number | undefined, draftedOn?: string | undefined, dueOn?: string | undefined, issuedOn?: string | undefined, paidOn?: string | undefined, franchiseeName: string, invoiceNumber?: string | undefined, status: InvoiceStatusTypeEnum, subject: string, subtotal?: number | undefined, taxAmount?: number | undefined, taxRate?: number | undefined, tipsTotal?: number | undefined, total?: number | undefined }> } };

export type InvoiceDetailTotalsQueryVariables = Exact<{
  filter?: InputMaybe<InvoiceDetailFilterInput>;
}>;


export type InvoiceDetailTotalsQuery = { __typename?: 'Query', invoiceDetailTotals: { __typename?: 'InvoiceDetailTotals', balanceTotal?: number | undefined, depositAmountTotal?: number | undefined, discountAmountTotal?: number | undefined, subtotalAmountTotal?: number | undefined, taxAmountTotal?: number | undefined, tipAmountTotal?: number | undefined, totalAmountTotal: number } };

export type InvoiceDetailReportExportMutationVariables = Exact<{
  input?: InputMaybe<ExportInvoiceDetailCsvInput>;
}>;


export type InvoiceDetailReportExportMutation = { __typename?: 'Mutation', exportInvoiceDetailCsv: { __typename?: 'ExportInvoiceDetailCsvPayload', response: ReportTypeEnum } };

export type InvoiceSummaryQueryVariables = Exact<{
  sort?: InputMaybe<InvoiceSummarySortInput>;
  filter?: InputMaybe<InvoiceSummaryFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type InvoiceSummaryQuery = { __typename?: 'Query', invoiceSummary: { __typename?: 'InvoiceSummaryReportList', totalCount?: number | undefined, reportPageInfo?: { __typename?: 'ReportPageInfo', startCursor?: string | undefined, nextCursor?: string | undefined } | undefined, records: Array<{ __typename?: 'InvoiceSummary', averagePerInvoice?: number | undefined, issuedCount?: number | undefined, name: string, totalIssued?: number | undefined, totalPaid?: number | undefined }> } };

export type InvoiceSummaryTotalsQueryVariables = Exact<{
  filter?: InputMaybe<InvoiceSummaryFilterInput>;
}>;


export type InvoiceSummaryTotalsQuery = { __typename?: 'Query', invoiceSummaryTotals: { __typename?: 'InvoiceSummaryTotals', issuedAverageTotal?: number | undefined, issuedCountTotal?: number | undefined, issuedTotal?: number | undefined, paidTotal?: number | undefined } };

export type InvoiceSummaryReportExportMutationVariables = Exact<{
  input?: InputMaybe<ExportInvoicesSummaryCsvInput>;
}>;


export type InvoiceSummaryReportExportMutation = { __typename?: 'Mutation', exportInvoicesSummaryCsv: { __typename?: 'ExportInvoicesSummaryCsvPayload', response: ReportTypeEnum } };

export type QuoteDetailQueryVariables = Exact<{
  sort?: InputMaybe<QuoteDetailSortInput>;
  filter?: InputMaybe<QuoteDetailFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type QuoteDetailQuery = { __typename?: 'Query', quoteDetailTotals: { __typename?: 'QuoteDetailTotals', total?: number | undefined }, quoteDetail: { __typename?: 'QuoteDetailConnection', totalCount: number, nodes: Array<{ __typename?: 'QuoteDetail', total?: number | undefined, status?: QuoteStatusTypeEnum | undefined, sentOn?: string | undefined, quoteNumber?: string | undefined, propertyAddress?: string | undefined, lastChangesRequestedOn?: string | undefined, franchiseeName: string, franchiseeId: string, clientName?: string | undefined, convertedOn?: string | undefined, draftedOn?: string | undefined, archivedOn?: string | undefined, approvedOn?: string | undefined }>, pageInfo: { __typename?: 'PageInfo', startCursor?: string | undefined, hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | undefined } } };

export type QuoteDetailExportMutationVariables = Exact<{
  input?: InputMaybe<ExportQuoteDetailCsvInput>;
}>;


export type QuoteDetailExportMutation = { __typename?: 'Mutation', exportQuoteDetailCsv: { __typename?: 'ExportQuoteDetailCsvPayload', response: ReportTypeEnum } };

export type QuoteSummaryQueryVariables = Exact<{
  sort?: InputMaybe<QuoteSummarySortInput>;
  filter?: InputMaybe<QuoteSummaryFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type QuoteSummaryQuery = { __typename?: 'Query', quotesSummary: { __typename?: 'QuoteSummaryReportList', totalCount?: number | undefined, reportPageInfo?: { __typename?: 'ReportPageInfo', startCursor?: string | undefined, nextCursor?: string | undefined } | undefined, records: Array<{ __typename?: 'QuoteSummary', name: string, quoteConversionRate?: number | undefined, quoteConvertedAverage?: number | undefined, quotesConvertedCount?: number | undefined, quoteSentAverage?: number | undefined, quotesConvertedTotal?: number | undefined, quotesSentCount?: number | undefined, quotesSentTotal?: number | undefined }> } };

export type QuoteSummaryTotalsQueryVariables = Exact<{
  filter?: InputMaybe<QuoteSummaryFilterInput>;
}>;


export type QuoteSummaryTotalsQuery = { __typename?: 'Query', quotesSummaryTotals: { __typename?: 'QuoteSummaryTotals', quoteConversionRateAverage?: number | undefined, quoteConvertedAverage?: number | undefined, quoteSentAverage?: number | undefined, quotesConvertedCount?: number | undefined, quotesSentCount?: number | undefined, quotesSentTotal?: number | undefined, quotesConvertedTotal?: number | undefined } };

export type QuoteSummaryExportMutationVariables = Exact<{
  input?: InputMaybe<ExportQuotesSummaryCsvInput>;
}>;


export type QuoteSummaryExportMutation = { __typename?: 'Mutation', exportQuotesSummaryCsv: { __typename?: 'ExportQuotesSummaryCsvPayload', response: ReportTypeEnum } };

export type RoyaltyQueryVariables = Exact<{
  sort?: InputMaybe<RoyaltySortInput>;
  filter?: InputMaybe<RoyaltyFilterAttributes>;
  first?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type RoyaltyQuery = { __typename?: 'Query', royalties: { __typename?: 'RoyaltyReportList', totalCount?: number | undefined, reportPageInfo?: { __typename?: 'ReportPageInfo', startCursor?: string | undefined, nextCursor?: string | undefined } | undefined, records: Array<{ __typename?: 'Royalty', total?: number | undefined, royaltyRate?: number | undefined, royaltyAmount?: number | undefined, name: string }> } };

export type RoyaltyTotalsQueryVariables = Exact<{
  filter?: InputMaybe<RoyaltyFilterAttributes>;
}>;


export type RoyaltyTotalsQuery = { __typename?: 'Query', royaltyTotals: { __typename?: 'RoyaltyTotals', paymentsTotal?: number | undefined, royaltyAmountsTotal?: number | undefined } };

export type ReportExportMutationVariables = Exact<{
  reportType: ReportTypeEnum;
  filter?: InputMaybe<RoyaltyFilterAttributes>;
  sort?: InputMaybe<RoyaltySortInput>;
}>;


export type ReportExportMutation = { __typename?: 'Mutation', exportCsv: { __typename?: 'ExportCsvPayload', response: ReportTypeEnum } };

export type FranchiseeNameAutoCompleteQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type FranchiseeNameAutoCompleteQuery = { __typename?: 'Query', accounts: { __typename?: 'AccountConnection', totalCount: number, nodes: Array<{ __typename?: 'Account', id: string, name: string }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | undefined } } };

export type LeadRoutesQueryVariables = Exact<{
  searchTerm: Scalars['String']['input'];
}>;


export type LeadRoutesQuery = { __typename?: 'Query', leadRoutes: { __typename?: 'LeadRouteConnection', nodes: Array<{ __typename?: 'LeadRoute', id: string, url: string, routingCode: string, account?: { __typename?: 'Account', name: string } | undefined }> } };

export type LeadRoutingQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type LeadRoutingQueryQuery = { __typename?: 'Query', accounts: { __typename?: 'AccountConnection', nodes: Array<{ __typename?: 'Account', id: string, name: string, leadRoutes: { __typename?: 'LeadRouteConnection', nodes: Array<{ __typename?: 'LeadRoute', routingCode: string, id: string }> } }> } };

export type LeadRoutingMutationMutationVariables = Exact<{
  leadRoutes: Array<Scalars['String']['input']> | Scalars['String']['input'];
  accountId: Scalars['EncodedId']['input'];
}>;


export type LeadRoutingMutationMutation = { __typename?: 'Mutation', leadRoutesEdit: { __typename?: 'LeadRoutesEditPayload', leadRoutes?: Array<{ __typename?: 'LeadRoute', id: string }> | undefined, userErrors: Array<{ __typename?: 'MutationErrors', message: string }> } };
