import { useQuery } from "@apollo/client";
import type { CampaignTemplateStatsQuery } from "~/utilities/API/graphql";
import { GET_CAMPAIGN_TEMPLATE_STATS } from "./graphql";

interface CampaignTemplateEnabledStatus {
  reengageEnabled: boolean;
  winBackEnabled: boolean;
  closeOnPendingQuoteEnabled: boolean;
  automatedTemplateTypesEnabledCount: number;
}

interface TemplateData {
  activeCampaignsForTemplateTypeCount: number;
}

const isTemplateEnabled = (
  template: TemplateData | null | undefined,
): boolean => {
  return (template?.activeCampaignsForTemplateTypeCount ?? 0) > 0;
};

const countEnabledTemplates = (
  templates: (TemplateData | null | undefined)[],
): number => {
  return templates.filter(template => isTemplateEnabled(template)).length;
};

export function useCampaignTemplateStatsQuery() {
  const { loading, data, error } = useQuery<CampaignTemplateStatsQuery>(
    GET_CAMPAIGN_TEMPLATE_STATS,
    {
      fetchPolicy: "cache-and-network",
    },
  );

  const processedData: CampaignTemplateEnabledStatus = {
    reengageEnabled: isTemplateEnabled(data?.reengageTemplate),
    winBackEnabled: isTemplateEnabled(data?.winBackTemplate),
    closeOnPendingQuoteEnabled: isTemplateEnabled(
      data?.closeOnPendingQuoteTemplate,
    ),
    automatedTemplateTypesEnabledCount: countEnabledTemplates([
      data?.reengageTemplate,
      data?.winBackTemplate,
      data?.closeOnPendingQuoteTemplate,
    ]),
  };

  return { loading, error, ...processedData };
}
