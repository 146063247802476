import { Content } from "@jobber/components/Content";
import { Page } from "@jobber/components/Page";
import React, { createContext } from "react";
import { useIntl } from "react-intl";
import {
  DedicatedPhoneNumberStatus,
  DedicatedPhoneSetupStatus,
  VoiceStatus,
} from "jobber/settings/dedicatedPhoneNumber/components/dedicatedPhoneNumberStatus/DedicatedPhoneNumberStatus";
import { DedicatedPhoneNumberSettingWrapper } from "jobber/settings/dedicatedPhoneNumber/DedicatedPhoneNumberSettingWrapper";
import { UkKycComplianceRegistration } from "jobber/settings/dedicatedPhoneNumber/UkKycComplianceRegistration";
import type { TwilioRegistrationStatus } from "~/utilities/API/graphql";
import { UkKycRegistrationRequirementTypes } from "~/utilities/API/graphql";
import {
  AccountContext,
  type AccountContextType,
} from "~/utilities/contexts/internal/AccountContext";
import { APIProvider } from "~/utilities/API/APIProvider";
import {
  UrlsContext,
  type UrlsContextType,
} from "~/utilities/contexts/internal/UrlsContext";
import { IntlProvider } from "@translations/IntlProvider";
import { messages } from "./messages";

interface TextMessagingSettingsPageProps {
  accountContext: AccountContextType;
  aiReceptionistDiscoverable: boolean;
  aiReceptionistEnabled: boolean;
  aiReceptionistSettingsPath: string;
  companyPhoneNumber: string;
  companySettingsPath: string;
  isSelectedNumberActive: boolean;
  kycRegistrationRequirement: UkKycRegistrationRequirementTypes;
  registrationStatus: TwilioRegistrationStatus;
  registrationPending: boolean;
  requiresRegistration: boolean;
  selectedNumber: string;
  urlsContext: UrlsContextType;
}
export const LinkContext = createContext("");
function TextMessagingSettingsPageInternal({
  accountContext,
  aiReceptionistDiscoverable,
  aiReceptionistEnabled,
  aiReceptionistSettingsPath,
  companyPhoneNumber,
  companySettingsPath,
  isSelectedNumberActive,
  kycRegistrationRequirement,
  registrationStatus,
  registrationPending,
  requiresRegistration,
  selectedNumber,
  urlsContext,
}: TextMessagingSettingsPageProps) {
  const { formatMessage } = useIntl();
  const canRegisterDPN =
    kycRegistrationRequirement ===
    UkKycRegistrationRequirementTypes.NOT_REQUIRED;

  return (
    <UrlsContext.Provider value={urlsContext}>
      <Page title={formatMessage(messages.dedicatedPhoneNumberTitle)}>
        <Content>
          <Content spacing="large">
            <LinkContext.Provider value={companySettingsPath}>
              <DedicatedPhoneNumberSettingWrapper
                selectedNumber={selectedNumber}
                registrationStatus={registrationStatus}
                isSelectedNumberActive={isSelectedNumberActive}
                requiresRegistration={requiresRegistration}
                canRegisterDPN={canRegisterDPN}
                aiReceptionistDiscoverable={aiReceptionistDiscoverable}
              />
            </LinkContext.Provider>
            <AccountContext.Provider value={accountContext}>
              <APIProvider>
                <UkKycComplianceRegistration />
              </APIProvider>
            </AccountContext.Provider>
          </Content>
          {selectedNumber && (
            <DedicatedPhoneNumberStatus
              aiReceptionistSettingsPath={aiReceptionistSettingsPath}
              companySettingsPath={companySettingsPath}
              dedicatedPhoneSetupStatus={dedicatedPhoneSetupStatus()}
              forwardTo={companyPhoneNumber}
              voiceStatus={
                aiReceptionistEnabled
                  ? VoiceStatus.AiReceptionist
                  : VoiceStatus.Forwarded
              }
            />
          )}
        </Content>
      </Page>
    </UrlsContext.Provider>
  );

  function dedicatedPhoneSetupStatus() {
    // enabled, disabled, pending

    if (!selectedNumber) {
      return DedicatedPhoneSetupStatus.Disabled;
    }

    if (requiresRegistration || registrationPending) {
      return DedicatedPhoneSetupStatus.Pending;
    }

    return isSelectedNumberActive
      ? DedicatedPhoneSetupStatus.Enabled
      : DedicatedPhoneSetupStatus.Disabled;
  }
}

export function TextMessagingSettingsPage(
  props: TextMessagingSettingsPageProps,
) {
  return (
    <IntlProvider>
      <TextMessagingSettingsPageInternal {...props} />
    </IntlProvider>
  );
}
