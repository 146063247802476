import { defineMessages } from "react-intl";

export const messages = defineMessages({
  contentCardTitle: {
    id: "campaigns.review.content.card.title",
    defaultMessage: "Content",
    description: "Content card title",
  },
  editSubjectButton: {
    id: "campaigns.review.content.edit.button",
    defaultMessage: "Edit",
    description: "Edit content button",
  },
  campaignsSubjectTitle: {
    id: "campaigns.review.content.subject.title",
    defaultMessage: "Subject",
    description: "Subject title",
  },
});
