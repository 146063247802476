import { useState } from "react";
import { useIntl } from "react-intl";
import { InputDate } from "@jobber/components/InputDate";
import { InputGroup } from "@jobber/components/InputGroup";
import { useViewport } from "legacy/jobber/hooks/useViewport";
import { FilterSelect } from "~/jobber/features/Reporting/components/Report/components/ReportFilters/components/FilterSelect/FilterSelect";
import type { AppliedDateRange } from "~/jobber/features/Reporting/components/Report/components/ReportFilters/types";
import { dateRangeLabel } from "~/jobber/features/Reporting/components/Report/components/ReportFilters/util";
import { isInDesktopView } from "~/jobber/features/Reporting/components/Report/helpers/screenWidth";
import type { DateRangeSelectOptions } from "./constants";
import {
  DateRangeOptions,
  dateRangeOptionIndex,
  defaultDateRangeOption,
} from "./constants";
import { messages } from "./messages";
import type { DateRange, dateRangeOptionIndexType } from "./types";

interface DateSelectorProps {
  heading?: string;
  appliedDateRange: AppliedDateRange;
  selectedDateRange: DateRange;
  handleDateFilterChange(range: DateRange): void;
  setSelectedDateRangeOption(option: DateRangeSelectOptions): void;
  showDateSelector?: boolean;
  allowedOptions?: DateRangeOptions[];
}

export function DateSelector({
  heading,
  selectedDateRange,
  handleDateFilterChange,
  appliedDateRange,
  setSelectedDateRangeOption,
  showDateSelector = true,
  allowedOptions,
}: DateSelectorProps): JSX.Element {
  const { formatMessage } = useIntl();

  const [assistiveText, setAssistiveText] = useState<string | undefined>(
    dateRangeLabel(appliedDateRange.startDate, appliedDateRange.endDate),
  );
  const [isCustomRange, setIsCustomRange] = useState<boolean>(
    appliedDateRange.dateRangeOption ===
      formatMessage(messages[DateRangeOptions.custom]),
  );

  const { innerWidth } = useViewport();

  const [customStartDateChange, setCustomStartDateChange] =
    useState<boolean>(false);
  const [customEndDateChange, setCustomEndDateChange] =
    useState<boolean>(false);

  const setDateRangeSelection = (value: string) => {
    setSelectedDateRangeOption(value as DateRangeSelectOptions);
    if (value === formatMessage(messages[DateRangeOptions.custom])) {
      setIsCustomRange(true);
    } else {
      setIsCustomRange(false);
      const foundOption = dateRangeOptionIndex.find(
        ({ option }: dateRangeOptionIndexType) => {
          return formatMessage(messages[option]) === value;
        },
      );
      const finalOption =
        foundOption === undefined ? defaultDateRangeOption : foundOption;
      handleDateFilterChange({
        startDate: finalOption.start,
        endDate: finalOption.end,
      });
      setAssistiveText(dateRangeLabel(finalOption.start, finalOption.end));
    }
  };

  const onStartDateChange = (value: Date) => {
    setCustomStartDateChange(true);
    handleDateFilterChange({
      startDate: value,
      endDate: selectedDateRange.endDate,
    });
  };
  const onEndDateChange = (value: Date) => {
    setCustomEndDateChange(true);
    handleDateFilterChange({
      startDate: selectedDateRange.startDate,
      endDate: value,
    });
  };

  const getFilterOptions = () => {
    const options = allowedOptions || Object.values(DateRangeOptions);
    return options.map(option => formatMessage(messages[option]));
  };

  return (
    <>
      {(showDateSelector || isInDesktopView(innerWidth)) && (
        <FilterSelect
          heading={heading}
          description={isCustomRange ? undefined : assistiveText}
          placeholder={formatMessage(messages.dateRangeSelectPlaceholder)}
          onFilterSelectChange={setDateRangeSelection}
          defaultValue={appliedDateRange.dateRangeOption}
          filterOptions={getFilterOptions()}
        />
      )}
      {isCustomRange && showDateSelector && (
        <InputGroup flowDirection="horizontal">
          <InputDate
            placeholder={formatMessage(messages.filterStartDatePlaceholder)}
            value={
              customStartDateChange
                ? selectedDateRange.startDate
                : appliedDateRange.startDate
            }
            onChange={onStartDateChange}
            maxDate={selectedDateRange.endDate}
          />
          <InputDate
            placeholder={formatMessage(messages.filterEndDatePlaceholder)}
            value={
              customEndDateChange
                ? selectedDateRange.endDate
                : appliedDateRange.endDate
            }
            onChange={onEndDateChange}
            minDate={selectedDateRange.startDate}
          />
        </InputGroup>
      )}
    </>
  );
}
