/* eslint-disable max-statements */
import { generatePath, useHistory } from "react-router-dom";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import {
  CAMPAIGNS_LANDING_PAGE_PATH,
  CAMPAIGNS_REVIEW_PATH,
  CAMPAIGNS_SEGMENT_EDIT_PATH,
  CAMPAIGNS_SEGMENT_PATH,
} from "jobber/campaigns/constants";
import { landingPath } from "jobber/campaigns/utils";
import { Template } from "~/utilities/API/graphql";
import { useCreateDefaultCampaign } from "jobber/campaigns/hooks/useCreateDefaultCampaign/useCreateDefaultCampaign";
import { SplitNames, useFeatureFlag } from "utilities/split";
import { messages } from "./messages";

interface UseClientSegmentPageNavigationArgs {
  template?: Template;
  campaignId?: string;
}

export function useTemplatePageNavigation({
  template = Template.RE_ENGAGE,
  campaignId,
}: UseClientSegmentPageNavigationArgs) {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const isCampaignsStreamlined = useFeatureFlag(
    SplitNames.CampaignsStreamlined,
  );
  const { createDefaultCampaign, loading } = useCreateDefaultCampaign();
  const [error, setError] = useState<string | null>(null);

  function onBack() {
    history.push(landingPath(template));
  }

  function onCampaignNotFound() {
    history.replace(CAMPAIGNS_LANDING_PAGE_PATH);
  }

  const handleCreateCampaign = useCallback(
    async (templateType: Template) => {
      const result = await createDefaultCampaign(templateType);
      if (result.userErrors.length > 0) {
        setError(result.userErrors[0].message);
        return null;
      }
      if (!result.campaign) {
        setError(formatMessage(messages.createCampaignError));
        return null;
      }
      return result.campaign;
    },
    [createDefaultCampaign, formatMessage],
  );

  const onNext = useCallback(
    async (value: Template = Template.RE_ENGAGE) => {
      try {
        setError(null);
        if (campaignId) {
          if (isCampaignsStreamlined) {
            const reviewPath = generatePath(CAMPAIGNS_REVIEW_PATH, {
              campaignId,
            });
            history.push(reviewPath);
          } else {
            const reviewPath = generatePath(CAMPAIGNS_SEGMENT_EDIT_PATH, {
              campaignId,
            });
            history.push(reviewPath);
          }
        } else {
          if (isCampaignsStreamlined) {
            const campaign = await handleCreateCampaign(value);
            if (campaign) {
              const reviewPath = generatePath(CAMPAIGNS_REVIEW_PATH, {
                campaignId: campaign.id,
              });
              history.push(reviewPath);
            }
          } else {
            const segmentPath = generatePath(CAMPAIGNS_SEGMENT_PATH, {
              templateType: value,
            });
            history.push(segmentPath);
          }
        }
      } catch (e) {
        setError(formatMessage(messages.createCampaignError));
      }
    },
    [
      campaignId,
      history,
      isCampaignsStreamlined,
      handleCreateCampaign,
      formatMessage,
    ],
  );

  return { onBack, onNext, onCampaignNotFound, loading, error };
}
