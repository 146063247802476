import React, { useContext, useMemo } from "react";
import { useIntl } from "react-intl";
import { ConfirmationModal } from "@jobber/components";
import { SideDrawer } from "components/SideDrawer";
import {
  DrawerView,
  ReviewsSettingsDrawerContext,
} from "jobber/reviews/views/ReviewsPage/context/ReviewsSettingsDrawerContext";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { useFollowUpMessageSettingsQuery } from "jobber/reviews/views/ReviewsPage/hooks/useFollowUpMessageSettingsQuery";
import { PrivacyMask } from "components/Observability/PrivacyMask";
import { messages as sharedMessages } from "jobber/reviews/views/ReviewsPage/messages";
import { ManageSettings } from "./ManageSettings";
import { InitialMessageSettings } from "./InitialMessageSettings";
import { messages } from "./messages";
import { FollowUpMessageSettings } from "./FollowUpMessageSettings";

export interface ReviewsSettingsProps {
  showCustomizations: boolean;
}
export function ReviewsSettings({
  showCustomizations,
}: ReviewsSettingsProps): JSX.Element {
  const { formatMessage } = useIntl();
  const { currentView, drawerActions, form, unsavedChangesModalActions } =
    useContext(ReviewsSettingsDrawerContext);

  const { loading, error, schedules, templates } =
    useFollowUpMessageSettingsQuery();
  const drawerRenderEventName = "Viewed Reviews Message Customization";

  const currentDrawerProps = useMemo(() => {
    switch (currentView) {
      case DrawerView.MessageSettings:
        Amplitude.TRACK_EVENT(drawerRenderEventName, {
          interaction: "initial_message",
          source: "drawer",
        });

        return {
          title: formatMessage(messages.commsSettingsTitle),
          component: (
            <InitialMessageSettings showCustomizations={showCustomizations} />
          ),
        };
      case DrawerView.FollowUpMessageSettings1:
        Amplitude.TRACK_EVENT(drawerRenderEventName, {
          interaction: "1st_follow_up",
          source: "drawer",
        });

        return {
          title: formatMessage(messages.followUpTitle),
          component: (
            <FollowUpMessageSettings
              schedule={schedules[0]}
              templates={templates}
              error={error}
              hasDPN={showCustomizations}
            />
          ),
        };
      case DrawerView.FollowUpMessageSettings2:
        Amplitude.TRACK_EVENT(drawerRenderEventName, {
          interaction: "2nd_follow_up",
          source: "drawer",
        });
        return {
          title: formatMessage(messages.followUpTitle),
          component: (
            <FollowUpMessageSettings
              schedule={schedules[1]}
              templates={templates}
              error={error}
              hasDPN={showCustomizations}
            />
          ),
        };
      case DrawerView.ManageSettings:
      default:
        return {
          title: formatMessage(sharedMessages.settingsLabel),
          component: (
            <ManageSettings
              schedules={schedules}
              error={error}
              loading={loading}
            />
          ),
        };
    }
  }, [
    loading,
    currentView,
    formatMessage,
    showCustomizations,
    schedules,
    templates,
    error,
  ]);

  return (
    <>
      <ConfirmationModal
        open={unsavedChangesModalActions?.isOpen}
        title={formatMessage(messages.unsavedChangesModalTitle)}
        message={formatMessage(messages.unsavedChangesModalMessage)}
        confirmLabel={formatMessage(messages.unsavedChangesConfirmLabel)}
        onConfirm={() => {
          unsavedChangesModalActions?.close();
          drawerActions?.goTo(DrawerView.ManageSettings);
          form?.setIsDirty(false);
        }}
        onCancel={() => {
          unsavedChangesModalActions?.close();
        }}
        variation="destructive"
      />
      <PrivacyMask disabled>
        <SideDrawer
          title={currentDrawerProps.title}
          open={true}
          requestDrawerClose={() => {
            if (form?.isDirty) {
              unsavedChangesModalActions?.open();
            } else {
              drawerActions?.closeSideDrawer();

              Amplitude.TRACK_EVENT(
                "Interacted with Reviews Message Customization",
                {
                  interaction: "closed",
                },
              );
            }
          }}
        >
          {currentDrawerProps.component}
        </SideDrawer>
      </PrivacyMask>
    </>
  );
}
