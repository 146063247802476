import { gql } from "@apollo/client";

export const PAYMENT_OPTIONS_CARD_QUERY = gql`
  query PaymentOptionsCard {
    jobberPaymentsSettings {
      achLimits {
        monthTransactionLimit
        twentyFourHourTransactionLimit
        singleTransactionLimit
        tier
      }
      achUsage {
        last24Hours
        last7Days
        last30Days
      }
      creditCardLimits {
        singleTransactionLimit
        twentyFourHourTransactionLimit
        weekTransactionLimit
        monthTransactionLimit
        tier
      }
      creditCardUsage {
        last24Hours
        last7Days
        last30Days
      }
    }
    achEnabledSetting: jobberPaymentsSetting(id: ALLOW_ACH_PAYMENTS) {
      enabled
    }
    amexEnabledSetting: jobberPaymentsSetting(id: AMERICAN_EXPRESS_PAYMENTS) {
      enabled
    }
    jobberPaymentsFees {
      ach {
        percent
      }
      creditCard {
        centsPerCharge
        percent
      }
    }
    account {
      settings {
        localization {
          currencySymbol
        }
      }
    }
  }
`;
