import React from "react";
import { useIntl } from "react-intl";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import { Glimmer } from "@jobber/components/Glimmer";
import { messages } from "./messages";

interface ContentSectionProps {
  loading: boolean;
  subject?: string;
  redirectToEdit: () => void;
}

export function ContentSection({
  loading,
  subject,
  redirectToEdit,
}: ContentSectionProps): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <Card
      header={{
        title: formatMessage(messages.contentCardTitle),
        action: (
          <Button
            label={formatMessage(messages.editSubjectButton)}
            onClick={redirectToEdit}
            type="tertiary"
            size="base"
          />
        ),
      }}
    >
      <Content>
        <Heading level={5}>
          {formatMessage(messages.campaignsSubjectTitle)}
        </Heading>
        {loading && <Glimmer width={500} />}
        {subject && !loading && <Text>{subject}</Text>}
      </Content>
    </Card>
  );
}
