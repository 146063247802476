import { defineMessages } from "react-intl";

export const messages = defineMessages({
  primaryButtonLabel: {
    id: "connectToGrowTrial.preTrialModal.primaryButtonLabel",
    defaultMessage: "Start Free Trial",
    description: "try features label",
  },
  secondaryButtonLabel: {
    id: "connectToGrowTrial.preTrialModal.secondaryButtonLabel",
    defaultMessage: "Dismiss",
    description: "dismiss label",
  },
  modalDefaultError: {
    id: "connectToGrowTrial.preTrialModal.modalDefaultError",
    defaultMessage:
      "Grow trial could not be started. Please try again or contact support.",
    description: "Modal Default Error",
  },
});
