import SecondFollowUpSmsSrc from "@images/Marketing/Reviews/secondFollowUpSMS.png";
import InitialEmailSrc from "@images/Marketing/Reviews/firstMessageEmail.png";
import JobTriggeredReviewSrc from "@images/Marketing/Reviews/jobTriggeredReviewRequest.png";
import InvoiceTriggeredReviewSrc from "@images/Marketing/Reviews/invoiceTriggeredReviewRequest.png";
import FirstFollowUpEmailSrc from "@images/Marketing/Reviews/firstFollowUpEmail.png";
import SecondFollowUpEmailSrc from "@images/Marketing/Reviews/secondFollowUpEmail.png";
import FirstFollowUpSmsSrc from "@images/Marketing/Reviews/firstFollowUpSMS.png";
import ConnectSrc from "@images/connect.png";
import MessagingSrc from "@images/messaging.png";
import RequestsSrc from "@images/requests.png";
import ConnectedSrc from "@images/connected.png";
import InitialSmsSrc from "@images/Marketing/Reviews/firstMessageSMS.png";
import { messages } from "./messages";
import type { ImageType } from "../../types";

const ConnectImage: ImageType = {
  src: ConnectSrc as string,
  alt: messages.connectImage,
};

const MessagingImage: ImageType = {
  src: MessagingSrc as string,
  alt: messages.messagingImage,
};

const JobTriggeredReviewImage: ImageType = {
  src: JobTriggeredReviewSrc as string,
  alt: messages.jobTriggeredReviewImage,
};

const InvoiceTriggeredReviewImage: ImageType = {
  src: InvoiceTriggeredReviewSrc as string,
  alt: messages.invoiceTriggeredReviewImage,
};

const RequestsImage: ImageType = {
  src: RequestsSrc as string,
  alt: messages.requestsImage,
};

const ConnectedImage: ImageType = {
  src: ConnectedSrc as string,
  alt: messages.requestsImage,
};

const PreviewTemplateInitialEmail: ImageType = {
  src: InitialEmailSrc as string,
  alt: messages.previewTemplateInitialEmail,
};

const PreviewTemplateInitialSms: ImageType = {
  src: InitialSmsSrc as string,
  alt: messages.previewTemplateInitialSms,
};

const PreviewTemplateFirstFollowUpEmail: ImageType = {
  src: FirstFollowUpEmailSrc as string,
  alt: messages.previewTemplateFirstFollowUpEmail,
};

const PreviewTemplateFirstFollowUpSms: ImageType = {
  src: FirstFollowUpSmsSrc as string,
  alt: messages.previewTemplateFirstFollowUpSms,
};

const PreviewTemplateSecondFollowUpEmail: ImageType = {
  src: SecondFollowUpEmailSrc as string,
  alt: messages.previewTemplateSecondFollowUpEmail,
};

const PreviewTemplateSecondFollowUpSms: ImageType = {
  src: SecondFollowUpSmsSrc as string,
  alt: messages.previewTemplateSecondFollowUpSms,
};

export {
  ConnectImage,
  ConnectedImage,
  InvoiceTriggeredReviewImage,
  JobTriggeredReviewImage,
  MessagingImage,
  RequestsImage,
  PreviewTemplateInitialEmail,
  PreviewTemplateInitialSms,
  PreviewTemplateFirstFollowUpEmail,
  PreviewTemplateFirstFollowUpSms,
  PreviewTemplateSecondFollowUpEmail,
  PreviewTemplateSecondFollowUpSms,
};
