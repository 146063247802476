import React from "react";
import type { IntlFormatters } from "react-intl";
import type { Serie } from "@nivo/line";
import { ReviewKPICard } from "jobber/reviews/views/ReviewsPage/components/ReviewKPICard/ReviewKPICard";
import { ReviewTrendLineChart } from "jobber/reviews/views/ReviewsPage/charts/ReviewTrendLineChart";
import { messages } from "../messages";
import { generateDateSegments } from "../utilities";

interface ReviewTrendCard {
  activationDate?: Date;
  formatMessage: IntlFormatters["formatMessage"];
  isLoading: boolean;
  requestsData?: Serie[];
  reviewsTotalData?: Serie[];
}

export function ReviewTrendCard({
  activationDate,
  formatMessage,
  isLoading,
  requestsData,
  reviewsTotalData,
}: ReviewTrendCard): JSX.Element | null {
  return (
    <ReviewKPICard
      header={formatMessage(messages.reviewTrendsHeader)}
      isLoading={isLoading}
      subheader={formatMessage(messages.reviewTrendsSubheader)}
      tooltip={formatMessage(messages.reviewTrendsTooltip)}
    >
      <ReviewTrendLineChart
        activationDate={activationDate}
        requestsData={requestsData}
        reviewsTotalData={reviewsTotalData}
      />
    </ReviewKPICard>
  );
}

export function generateMockData() {
  const reviewsTotalValues = [0, 8, 14, 18];
  const requestsValues = [4, 20, 32, 41];
  const dates = generateDateSegments();

  const reviewsTotalData = {
    id: "reviews",
    data: [
      {
        x: dates[0],
        y: reviewsTotalValues[0],
      },
      {
        x: dates[1],
        y: reviewsTotalValues[1],
      },
      {
        x: dates[2],
        y: reviewsTotalValues[2],
      },
      { x: dates[3], y: reviewsTotalValues[3] },
    ],
  };

  const requestsData = {
    id: "requests",
    data: [
      { x: dates[0], y: requestsValues[0] },
      { x: dates[1], y: requestsValues[1] },
      { x: dates[2], y: requestsValues[2] },
      { x: dates[3], y: requestsValues[3] },
    ],
  };

  return {
    reviewsTotalData: [reviewsTotalData],
    requestsData: [requestsData],
  };
}
