import { defineMessages } from "react-intl";

export const messages = defineMessages({
  customizationCTAHeading: {
    id: "reviews.resources.customizationCTAHeading",
    defaultMessage:
      "Customize your request message with a template we think might help you",
    description: "Heading for the 'use it now' sections",
  },
  useItNowButtonLabel: {
    id: "reviews.resources.useItNowButtonLabel",
    defaultMessage: "Use It Now",
    description: "Heading for the 'use it now' sections",
  },
  templateText: {
    id: "reviews.resources.templateText",
    defaultMessage:
      "Could you spare a minute to share your feedback? We value your input, and mentioning a technician's name means they get a $10 tip bonus!",
    description: "Example template text for the first 'use it now' section",
  },
  heading: {
    id: "reviews.resources.heading",
    defaultMessage: "Resources",
    description: "Heading for the resources section",
  },
  articleLinkText: {
    id: "reviews.resources.articleLinkText",
    defaultMessage: "Read article",
    description: "Text for the link to an article",
  },
  videoLinkText: {
    id: "reviews.resources.videoLinkText",
    defaultMessage: "Watch video",
    description: "Text for the link to a video",
  },
});
