// Shared analytics events
export const ANALYTICS_EVENTS = {
  interactedWithExperiments: "Interacted with Experiment",
  viewedPage: "Viewed Page",
  viewedDialog: "Viewed Dialog",
  clickedSearch: "Clicked Search",
  interactedWithQuoteCreate: "Interacted With Quote Create",
  ctaViewed: "CTA Viewed",
  ctaClicked: "CTA Clicked",
  clickedButton: "Clicked Button",
};
