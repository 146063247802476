import React from "react";
import { Chip } from "@jobber/components/Chip";
import { Chips } from "@jobber/components/Chips";
import { Content } from "@jobber/components/Content";
import { useIntl } from "react-intl";
import { messages } from "./messages";

export interface FollowUpChipsProps {
  selected: string;
  setSelected: (selected: string) => void;
}

export function FollowUpChips({
  selected,
  setSelected,
}: FollowUpChipsProps): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <Content>
      <Chips selected={selected} onChange={setSelected} type="singleselect">
        <Chip label={formatMessage(messages.firstMessage)} value={"0"} />
        <Chip label={formatMessage(messages.firstFollowUp)} value={"1"} />
        <Chip label={formatMessage(messages.secondFollowUp)} value={"2"} />
      </Chips>
    </Content>
  );
}
