import React from "react";
import { APIProvider } from "~/utilities/API/APIProvider";
import { DedicatedPhoneNumberSetting } from "jobber/settings/dedicatedPhoneNumber/DedicatedPhoneNumberSetting";
import type { TwilioRegistrationStatus } from "~/utilities/API/graphql";

export interface DedicatedPhoneNumberSettingWrapperProps {
  selectedNumber?: string;
  registrationStatus: TwilioRegistrationStatus;
  isSelectedNumberActive?: boolean;
  requiresRegistration: boolean;
  canRegisterDPN: boolean;
  aiReceptionistDiscoverable: boolean;
}

export function DedicatedPhoneNumberSettingWrapper({
  selectedNumber,
  registrationStatus,
  isSelectedNumberActive,
  requiresRegistration,
  canRegisterDPN,
  aiReceptionistDiscoverable,
}: DedicatedPhoneNumberSettingWrapperProps) {
  return (
    <APIProvider>
      <DedicatedPhoneNumberSetting
        selectedNumber={selectedNumber}
        registrationStatus={registrationStatus}
        isSelectedNumberActive={isSelectedNumberActive}
        requiresRegistration={requiresRegistration}
        canRegisterDPN={canRegisterDPN}
        aiReceptionistDiscoverable={aiReceptionistDiscoverable}
      />
    </APIProvider>
  );
}
