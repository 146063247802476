import { Banner } from "@jobber/components/Banner";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Divider } from "@jobber/components/Divider";
import { Heading } from "@jobber/components/Heading";
import { Icon } from "@jobber/components/Icon";
import { Markdown } from "@jobber/components/Markdown";
import { Page } from "@jobber/components/Page";
import React, { type MutableRefObject, useRef, useState } from "react";
import { showToast } from "@jobber/components/Toast";
import {
  JPayAutoenableTurnOffReasonModal,
  ctaName as jPayAutoenableTurnOffReasonModalCtaName,
} from "jobber/jPayAutoenable/components/JPayAutoenableTurnOffReasonModal";
import { APIProvider } from "~/utilities/API/APIProvider";
import {
  CallToAction,
  type CallToActionRef,
  convertCTA,
  dismissCTA,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";
import { useJobberPayments } from "~/utilities/contexts/internal/useJobberPayments";
import { useTranslation } from "~/utilities/contexts/internal/useTranslations";
import { useJobberPaymentsEnableDisable } from "~/utilities/hooks/useJobberPaymentsEnableDisable";
import type {
  AchProcessingRate,
  ProcessingRates,
} from "~/jobber/managed_accounts/ProcessingRateBadges/types";
import { ProcessingRateBadges } from "~/jobber/managed_accounts/ProcessingRateBadges/ProcessingRateBadges";
import { BankAccount, type BankAccountProps } from "./BankAccount";
import type { SuccessBank } from "./bankAccountReducer";
import styles from "./Setup.module.css";
import { Verify } from "./Verify";
import { HostedOnboardingProgress } from "./verifyReducer";

export interface SetupProps {
  supportsInstantPayouts: boolean;
  splashImgSrc: string;
  processingRates: ProcessingRates;
  bankDetails: SuccessBank;
  chargesEnabled: boolean;
  hostedOnboardingProgress: HostedOnboardingProgress;
  hostedOnboardingUrlCreationUrl: string;
  bankAccountProps: BankAccountProps;
  demoJobberPayments: boolean;
  fromInlineSignup: boolean;
  isInPaymentsOneClickOnboarding: boolean;
  achProcessingRate?: AchProcessingRate;
  trackingSourceParam?: string;
}

// eslint-disable-next-line max-statements
function Setup({
  supportsInstantPayouts,
  splashImgSrc,
  processingRates,
  chargesEnabled,
  hostedOnboardingProgress,
  hostedOnboardingUrlCreationUrl,
  bankAccountProps,
  demoJobberPayments,
  fromInlineSignup,
  isInPaymentsOneClickOnboarding,
  achProcessingRate,
  trackingSourceParam,
}: SetupProps) {
  const enableAddBankAccount = shouldEnableAddBankAccount(
    hostedOnboardingProgress,
  );
  const {
    enabled: globalEnabled,
    setEnabled: setGlobalEnabled,
    permissions,
  } = useJobberPayments();

  const { setJobberPaymentsEnabled } = useJobberPaymentsEnableDisable();

  const [t] = useTranslation();

  const [showJPayTurnOffReasonModal, setShowJPayTurnOffReasonModal] =
    useState(false);
  const jPayAutoenableReminderCtaRef =
    useRef() as MutableRefObject<CallToActionRef>;

  return (
    <>
      {fromInlineSignup && (
        <Banner type="notice">
          <Markdown content="To go back to the invoice page, close this tab" />
        </Banner>
      )}
      <Page
        title={
          demoJobberPayments
            ? t("demoJobberPaymentsTitle")
            : t("jobberPaymentsTitle")
        }
        subtitle={t("jobberPaymentsSubtitle")}
        intro={t("managedAccountTagline")}
      >
        <Content spacing="large">
          <ProcessingRateBadges
            processingRates={processingRates}
            achProcessingRate={achProcessingRate}
          />
          <div className="row small-collapse large-uncollapse">
            <div
              className={`columns u-paddingNone show-for-large ${styles.columnMaxWidth}`}
            >
              <div className="u-positionRelative">
                <div className={styles.splashContainer}>
                  <img
                    className={styles.splashImg}
                    src={splashImgSrc}
                    alt="Fast, simple, convenient payments with no hidden fees"
                  />
                </div>
                <div className={`centerContainer ${styles.centerContainer}`}>
                  <h1 className={`u-textUppercase ${styles.splashText}`}>
                    Fast, simple, convenient payments with
                    <br />
                    <span className="highlighter">no hidden fees</span>
                  </h1>
                </div>
              </div>
            </div>
            <div className={`columns ${styles.columnMaxWidth}`}>
              <Content spacing="large">
                <Heading level={2}>We&rsquo;ve got you covered</Heading>
                <div>
                  <Icon name="checkmark" color="green" />{" "}
                  <strong>Contactless payments.</strong> Send invoices by text
                  message or email and let your clients pay online.
                </div>
                <Divider />
                <div>
                  <Icon name="checkmark" color="green" />{" "}
                  <strong>Save cards on file.</strong> Securely save your
                  client&rsquo;s cards on file for future billing or set up
                  automatic payments (on available plans).
                </div>
                <Divider />
                <div>
                  <Icon name="checkmark" color="green" />{" "}
                  <strong>Collect tips.</strong> Get rewarded for a job well
                  done by collecting tips on invoices paid through client hub.
                </div>
                <Divider />
                <div>
                  <Icon name="checkmark" color="green" />{" "}
                  <strong>Look professional.</strong> Use the Bluetooth Jobber
                  Card Reader (US only) to collect card payments in person.
                </div>
                <Divider />
                {supportsInstantPayouts && (
                  <>
                    <div>
                      <Icon name="checkmark" color="green" />{" "}
                      <strong>Instant payouts.</strong> Have the flexibility to
                      speed up your cash flow and get paid out in seconds for an
                      additional 1% fee.
                    </div>
                    <Divider />
                  </>
                )}
                <Verify
                  hostedOnboardingProgress={hostedOnboardingProgress}
                  hostedOnboardingUrlCreationUrl={
                    hostedOnboardingUrlCreationUrl
                  }
                  trackingSourceParam={trackingSourceParam}
                />
                <Divider />
                <BankAccount
                  {...bankAccountProps}
                  disableAddBankAccount={!enableAddBankAccount}
                />
                {isInPaymentsOneClickOnboarding &&
                  globalEnabled &&
                  permissions.canDisablePayments && (
                    <div>
                      <Divider />
                      <br />
                      <Button
                        label="Disable Jobber Payments"
                        onClick={onDisableJobberPaymentsClick}
                        type="tertiary"
                      />
                      <span className="u-marginTopSmall u-inlineBlock">
                        When disabled, clients will not be able to pay using
                        credit or debit on any outstanding or new invoices.
                      </span>
                    </div>
                  )}
                {isInPaymentsOneClickOnboarding &&
                  !globalEnabled &&
                  permissions.canEnablePayments &&
                  chargesEnabled && (
                    <div>
                      <Divider />
                      <br />
                      <Button
                        label="Enable Jobber Payments"
                        onClick={onEnableJobberPaymentsClick}
                        type="tertiary"
                      />
                      <br />
                      <span className="u-marginTopSmall u-inlineBlock">
                        When enabled, clients will be able to pay using credit
                        or debit cards on any outstanding or new invoices.
                      </span>
                    </div>
                  )}
              </Content>
            </div>
          </div>
          <Divider />
          <div>
            <p className={styles.contactSuccess}>
              Have a question before you want to get started?{" "}
              <span className={`js-intercom-jobber-payments ${styles.chat}`}>
                Chat with a payments specialist
              </span>
              .
            </p>
          </div>
        </Content>
        {showJPayTurnOffReasonModal && (
          <APIProvider>
            <CallToAction
              ref={jPayAutoenableReminderCtaRef}
              ctaName={jPayAutoenableTurnOffReasonModalCtaName}
            >
              <JPayAutoenableTurnOffReasonModal
                dismissCTA={dismissCTA(jPayAutoenableReminderCtaRef)}
                convertCTA={convertCTA(jPayAutoenableReminderCtaRef)}
              />
            </CallToAction>
          </APIProvider>
        )}
      </Page>
    </>
  );

  function onEnableJobberPaymentsClick() {
    setJobberPaymentsEnabled(true)
      .then(() => {
        showToast({
          message: "Jobber Payments has been enabled",
        });
        setGlobalEnabled(true);
      })
      .catch(() => {
        showToast({
          message: "Failed to enable Jobber Payments",
        });
      });
  }

  function onDisableJobberPaymentsClick() {
    setJobberPaymentsEnabled(false)
      .then(() => {
        showToast({
          message: "Jobber Payments has been disabled",
        });
        setGlobalEnabled(false);
        setShowJPayTurnOffReasonModal(true);
      })
      .catch(() => {
        showToast({
          message: "Failed to enable Jobber Payments",
        });
      });
  }
}

function shouldEnableAddBankAccount(
  hostedOnboardingProgress: HostedOnboardingProgress,
): boolean {
  const statesAllowingAddingBankAccount = [
    HostedOnboardingProgress.Verifying,
    HostedOnboardingProgress.CompletedHostedOnboarding,
    HostedOnboardingProgress.Verified,
  ];

  for (const state of statesAllowingAddingBankAccount) {
    if (state === hostedOnboardingProgress) {
      return true;
    }
  }

  return false;
}

export { Setup };
