import React from "react";
import { Emphasis, Heading, Icon, Text } from "@jobber/components";
import type { MessageDescriptor } from "react-intl";
import { useIntl } from "react-intl";
import { LandingPageCtaButton } from "~/jobber/billing/components/LandingPageCtaButton/LandingPageCtaButton";
import styles from "./styles.module.css";
import { messages } from "./messages";
import {
  ExperimentalFeature1Img,
  ExperimentalFeature2Img,
  ExperimentalFeature3Img,
  ExperimentalReviewsHeroImg,
  PlanTapeImg,
} from "./images";
import type { PurchasePageProps } from "./PurchasePage";

export function ExperimentalReviewsLandingPage(
  props: PurchasePageProps,
): JSX.Element {
  const { formatMessage } = useIntl();
  const {
    recurlyPublicKey,
    addonSetIdentifier,
    monthlyCost,
    monthlyDiscountedCost,
    isMobileBilled,
    discountCallToAction,
    salesforceTrackingId,
    requiresPaidPlan,
    loading,
  } = props;

  return (
    <div className={styles.experimentalLandingPageContainer}>
      <ValuePropHeader
        recurlyPublicKey={recurlyPublicKey}
        addonSetIdentifier={addonSetIdentifier}
        isMobileBilled={isMobileBilled}
        discountCallToAction={discountCallToAction}
        requiresPaidPlan={requiresPaidPlan}
        salesforceTrackingId={salesforceTrackingId}
        loading={loading}
        formatMessage={formatMessage}
      />
      <HeroSection formatMessage={formatMessage} />
      <SuiteSection discountCallToAction={discountCallToAction} />
      <VideoSection />
      <TestimonialSection />
      <FeaturesSection formatMessage={formatMessage} />
      <FooterSection
        formatMessage={formatMessage}
        discountCallToAction={discountCallToAction}
        monthlyCost={monthlyCost}
        monthlyDiscountedCost={monthlyDiscountedCost}
        recurlyPublicKey={recurlyPublicKey}
        addonSetIdentifier={addonSetIdentifier}
        requiresPaidPlan={requiresPaidPlan}
        isMobileBilled={isMobileBilled}
        salesforceTrackingId={salesforceTrackingId}
        loading={loading}
      />
    </div>
  );
}

function ValuePropHeader({
  recurlyPublicKey,
  addonSetIdentifier,
  isMobileBilled,
  requiresPaidPlan,
  salesforceTrackingId,
  loading,
  formatMessage,
}: PurchasePageProps & {
  formatMessage: (message: MessageDescriptor) => string;
}) {
  return (
    <div className={styles.headerContainer}>
      <img
        className={styles.tapeHeaderImg}
        src={PlanTapeImg.src}
        alt={formatMessage(PlanTapeImg.alt)}
      />

      <LandingPageCtaButton
        recurlyPublicKey={recurlyPublicKey}
        addonSetIdentifier={addonSetIdentifier}
        addonSetName={formatMessage(messages.addonSetName)}
        redirectToPricingPage={requiresPaidPlan}
        isMobileBilled={isMobileBilled}
        salesforceTrackingId={salesforceTrackingId}
        loading={loading}
        onPurchaseSuccess={() => window.location.reload()}
        isMarketingSource
      />
    </div>
  );
}

function HeroSection({
  formatMessage,
}: {
  formatMessage: (message: MessageDescriptor) => string;
}) {
  return (
    <div className={styles.heroSection}>
      <div className={styles.heroImgContainer}>
        <div className={styles.heroImgContent}>
          <Heading level={1}>Double your Google reviews</Heading>
          <Text size="large">
            Never miss asking for a review, again. Get more 5-star Google
            reviews that help you stand out from your competition.
          </Text>
        </div>
        <img
          className={styles.experimentalHeroImg}
          src={ExperimentalReviewsHeroImg.src}
          alt={formatMessage(ExperimentalReviewsHeroImg.alt)}
        />
      </div>
      <div className={styles.heroSectionContent}>
        <div className={styles.heroSectionContentItem}>
          <Icon name="star" size="large" />
          <Heading level={4}>Become top-rated</Heading>
          <Text variation="subdued">
            Get more 5-star reviews by automating review requests so you can
            focus on doing your best work.
          </Text>
        </div>
        <div className={styles.heroSectionContentItem}>
          <Icon name="sms2" size="large" />
          <Heading level={4}>Build trust</Heading>
          <Text variation="subdued">
            Show customers you care by responding to reviews quickly and
            professionally. Thanks to AI, you can do it in just a click.
          </Text>
        </div>
        <div className={styles.heroSectionContentItem}>
          <Icon name="sendInvoice" size="large" color="heading" />
          <Heading level={4}>Attract more customers</Heading>
          <Text variation="subdued">
            Make finding your business easy for new customers by getting regular
            reviews that boost your Google ranking.
          </Text>
        </div>
      </div>
    </div>
  );
}

function SuiteSection({
  discountCallToAction,
}: {
  monthlyCost?: number;
  monthlyDiscountedCost?: number;
  discountCallToAction?: string;
}) {
  return (
    <div className={styles.suiteSection}>
      <Heading level={3}>
        <Emphasis variation="highlight">{discountCallToAction}</Emphasis>
      </Heading>
      <Heading level={1}>A suite of tools to power your growth</Heading>
      <Text align="center">
        Reviews is one of several tools in the Marketing Suite. <br /> On
        average, customers using the entire suite grow their revenue{" "}
        <Emphasis variation="highlight">80% more</Emphasis> than other
        customers.
      </Text>
    </div>
  );
}

function VideoSection() {
  return (
    <div className={styles.videoSection}>
      <div className={styles.videoSectionContent}>
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/q6SrB85ZE5w?si=KkxFaiKB_J2OdAPf"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
        ></iframe>
        <Heading level={3}>Reviews</Heading>
        <Text variation="subdued">
          Watch your online reputation soar by automating review requests and
          responding to reviews.
        </Text>
      </div>
      <div className={styles.videoSectionContent}>
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/YZTMOLWkEY8?si=zVrKQLhwQv1zeUmg"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
        ></iframe>
        <Heading level={3}>Referrals</Heading>
        <Text variation="subdued">
          Let happy clients be your best marketers by rewarding them for the new
          business they bring in.
        </Text>
      </div>
      <div className={styles.videoSectionContent}>
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/yimQPJTYxGI?si=mvlVZq7UAvCFcEwD"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
        ></iframe>
        <Heading level={3}>Campaigns</Heading>
        <Text variation="subdued">
          Turn your client list into a revenue-generating machine with powerful
          email campaigns.
        </Text>
      </div>
    </div>
  );
}

function TestimonialSection() {
  return (
    <div className={styles.testimonialSection}>
      <div className={styles.testimonialSectionContent}>
        <Heading level={3}>
          “We had about 100 reviews and now have over 250 reviews in just 5
          months. Making it easy for clients to leave us a review has meant the
          world to us.”
        </Heading>
        <div className={styles.testimonialSectionContentItem}>
          <Text>Stephen Jobe</Text>
          <Text variation="subdued">Jobe & Sons Plumbing</Text>
        </div>
      </div>
      <div className={styles.testimonialSectionVideo}>
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/G3YlfLmG3yU?si=2M_a63tSEr4A7eFs"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
        ></iframe>
      </div>
    </div>
  );
}

function FeaturesSection({
  formatMessage,
}: {
  formatMessage: (message: MessageDescriptor) => string;
}) {
  return (
    <div className={styles.featuresSection}>
      <Heading level={1}>Building a trusted reputation is easy</Heading>
      <div className={styles.featuresSectionContent}>
        <div className={styles.featuresSectionContentItem}>
          <Heading level={2}>Never forget to ask for a review</Heading>
          <Text variation="subdued">
            Automatically ask your clients for a review by text or email once
            they pay their invoice. Don’t want to ask a specific client? No
            problem—you control who gets asked for a review.
          </Text>
        </div>
        <img
          className={styles.featuresSectionContentItemImg}
          src={ExperimentalFeature1Img.src}
          alt={formatMessage(ExperimentalFeature1Img.alt)}
        />
      </div>
      <div className={styles.featuresSectionContent}>
        <div className={styles.featuresSectionContentItem}>
          <Heading level={2}>Send thoughtful replies in seconds</Heading>
          <Text variation="subdued">
            Responding to every review doesn’t have to be time consuming. Make
            your customers feel heard and impress potential customers with the
            help of AI-generated responses.
          </Text>
        </div>
        <img
          className={styles.featuresSectionContentItemImg}
          src={ExperimentalFeature2Img.src}
          alt={formatMessage(ExperimentalFeature2Img.alt)}
        />
      </div>
      <div className={styles.featuresSectionContent}>
        <div className={styles.featuresSectionContentItem}>
          <Heading level={2}>Monitor your reputation in real-time</Heading>
          <Text variation="subdued">
            Understand more about your business through your customer reviews by
            keeping tabs on client satisfaction and click-through rates.
          </Text>
        </div>
        <img
          className={styles.featuresSectionContentItemImg}
          src={ExperimentalFeature3Img.src}
          alt={formatMessage(ExperimentalFeature3Img.alt)}
        />
      </div>
    </div>
  );
}

function FooterSection({
  formatMessage,
  discountCallToAction,
  monthlyCost,
  monthlyDiscountedCost,
  recurlyPublicKey,
  addonSetIdentifier,
  requiresPaidPlan,
  isMobileBilled,
  salesforceTrackingId,
  loading,
}: {
  formatMessage: (message: MessageDescriptor) => string;
  discountCallToAction?: string;
  monthlyCost?: number;
  monthlyDiscountedCost?: number;
  recurlyPublicKey: string;
  addonSetIdentifier: string;
  requiresPaidPlan: boolean;
  isMobileBilled: boolean;
  salesforceTrackingId: string | null;
  loading: boolean;
}) {
  return (
    <div className={styles.footerSection}>
      <Heading level={3}>{discountCallToAction}</Heading>
      {monthlyCost && monthlyDiscountedCost && (
        <Heading level={2}>
          Get Reviews with the Marketing Suite for just{" "}
          {(monthlyCost - monthlyDiscountedCost).toFixed(2)}/month
        </Heading>
      )}
      <LandingPageCtaButton
        recurlyPublicKey={recurlyPublicKey}
        addonSetIdentifier={addonSetIdentifier}
        addonSetName={formatMessage(messages.addonSetName)}
        redirectToPricingPage={requiresPaidPlan}
        isMobileBilled={isMobileBilled}
        salesforceTrackingId={salesforceTrackingId}
        loading={loading}
        onPurchaseSuccess={() => window.location.reload()}
        isMarketingSource
      />
    </div>
  );
}
