import { gql } from "@apollo/client";

const CAMPAIGN_TEMPLATE_INFO_FRAGMENT = gql`
  fragment CampaignTemplateInfo on CampaignTemplate {
    type
    templateTypeLastSentAt
    activeCampaignsForTemplateTypeCount
    title
    description
    previewBody
  }
`;

export const CAMPAIGN_TEMPLATES_QUERY = gql`
  query CampaignTemplates {
    commsCampaignTemplates {
      ...CampaignTemplateInfo
    }
  }
  ${CAMPAIGN_TEMPLATE_INFO_FRAGMENT}
`;
