import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "setupGuide.trigger.title",
    defaultMessage: "Automate messages",
    description: "Title for the trigger step",
  },
  description: {
    id: "setupGuide.trigger.description",
    defaultMessage: "Tell Jobber to ask your clients for a review when a...",
    description: "Description for the trigger step",
  },
  primaryButton: {
    id: "setupGuide.trigger.primaryButton",
    defaultMessage: "Set Automation",
    description: "Primary button for the trigger step",
  },
  jobTrigger: {
    id: "setupGuide.trigger.jobTrigger",
    defaultMessage: "Job is closed",
    description: "Job trigger for the trigger step",
  },
  invoiceTrigger: {
    id: "setupGuide.trigger.invoiceTrigger",
    defaultMessage: "Invoice is paid",
    description: "Invoice trigger for the trigger step",
  },
  // Error Handling
  jobFollowUpsError: {
    id: "reviews.profile.jobFollowUpsError",
    defaultMessage:
      "Failed to turn off job follow-ups. Please turn them off [in your settings](/communication_settings#jobs)",
    description:
      "A general warning that something has gone wrong while disabling job follow-ups",
  },
  triggerSettingsError: {
    id: "reviews.profile.triggerSettingsError",
    defaultMessage:
      "We couldn’t save your review request preference. For now, requests will send after an invoice is paid. You can update this in Reviews settings.",
    description:
      "A general warning that something has gone wrong while saving the trigger settings",
  },
});
