import React from "react";
import { useIntl } from "react-intl";
import { messages } from "./messages";
import styles from "./styles.module.css";
import type { SetupGuideStepProps } from "../../types";
import { OnboardingStep } from "../OnboardingStep";
import { RequestsImage } from "../../images";

export function CompleteSetup(stepProps: SetupGuideStepProps): JSX.Element {
  const { formatMessage } = useIntl();

  const rightColumnChild = () => {
    return (
      <div className={styles.imgContainer}>
        <img src={RequestsImage.src} alt={formatMessage(RequestsImage.alt)} />
      </div>
    );
  };

  return (
    <OnboardingStep
      title={formatMessage(messages.title)}
      subtitle={formatMessage(messages.description)}
      primaryButton={{
        label: formatMessage(messages.primaryButton),
        action: () => {
          stepProps.navigation.onNext();
        },
      }}
      rightColumnChild={rightColumnChild()}
    />
  );
}
