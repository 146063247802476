import React from "react";
import { useIntl } from "react-intl";
import { Content } from "@jobber/components/Content";
import { Divider } from "@jobber/components/Divider";
import { Emphasis } from "@jobber/components";
import { Flex } from "@jobber/components/Flex";
import { Heading } from "@jobber/components/Heading";
import { Link } from "@jobber/components/Link";
import { StatusLabel } from "@jobber/components/StatusLabel";
import { Text } from "@jobber/components/Text";
import { IntlProvider } from "@translations/IntlProvider";
import { friendlyPhoneFormat } from "utilities/format/phone";
import { messages } from "./messages";

export enum DedicatedPhoneSetupStatus {
  Enabled = "enabled",
  Disabled = "disabled",
  Pending = "pending",
}

export enum VoiceStatus {
  Forwarded = "forwarded",
  AiReceptionist = "ai-receptionist",
}

interface DedicatedPhoneNumberStatusProps {
  aiReceptionistSettingsPath: string;
  companySettingsPath: string;
  dedicatedPhoneSetupStatus: DedicatedPhoneSetupStatus;
  forwardTo: string;
  voiceStatus: VoiceStatus;
}

function DedicatedPhoneNumberStatusInternal({
  aiReceptionistSettingsPath,
  companySettingsPath,
  dedicatedPhoneSetupStatus,
  forwardTo,
  voiceStatus,
}: DedicatedPhoneNumberStatusProps) {
  const { formatMessage } = useIntl();

  return (
    <IntlProvider>
      <Content>
        <Heading level={3}>
          {formatMessage(messages.dedicatedPhoneNumberTitle)}
        </Heading>
        <Content>
          <Content>
            <div data-testid="sending-texts-section">
              <Flex template={["grow", "shrink"]}>
                <Emphasis variation="bold">
                  {formatMessage(
                    messages.dedicatedPhoneNumberSendingTextsStatus,
                  )}
                </Emphasis>
                <SendingTextsStatusBadge />
              </Flex>
            </div>
          </Content>
          <Divider direction={"horizontal"} />
          <Content>
            <div data-testid="receiving-texts-section">
              <Flex template={["grow", "shrink"]}>
                <Emphasis variation="bold">
                  {formatMessage(
                    messages.dedicatedPhoneNumberReceivingTextsStatus,
                  )}
                </Emphasis>
                <ReceivingTextsStatusBadge />
              </Flex>
            </div>
          </Content>
          <Divider direction={"horizontal"} />
          <Content>
            <div data-testid="phone-calls-section">
              <Flex template={["grow", "shrink"]}>
                <Emphasis variation="bold">
                  {formatMessage(messages.dedicatedPhoneNumberPhoneCallsStatus)}
                </Emphasis>
                <VoiceStatusLabel />
              </Flex>
            </div>
          </Content>
        </Content>
      </Content>
    </IntlProvider>
  );

  function SendingTextsStatusBadge() {
    switch (dedicatedPhoneSetupStatus) {
      case DedicatedPhoneSetupStatus.Enabled:
        return (
          <StatusLabel
            label={formatMessage(messages.enabled)}
            status="success"
          />
        );
      case DedicatedPhoneSetupStatus.Pending:
        return (
          <StatusLabel
            label={formatMessage(messages.sendingFromSharedNumber)}
            status="warning"
          />
        );
      case DedicatedPhoneSetupStatus.Disabled:
        return (
          <StatusLabel
            label={formatMessage(messages.disabled)}
            status="critical"
          />
        );
    }
  }

  function ReceivingTextsStatusBadge() {
    switch (dedicatedPhoneSetupStatus) {
      case DedicatedPhoneSetupStatus.Enabled:
        return (
          <StatusLabel
            label={formatMessage(messages.enabled)}
            status="success"
          />
        );
      case DedicatedPhoneSetupStatus.Pending:
      case DedicatedPhoneSetupStatus.Disabled:
        return (
          <StatusLabel
            label={formatMessage(messages.disabled)}
            status="critical"
          />
        );
    }
  }

  function VoiceStatusLabel() {
    if (voiceStatus === VoiceStatus.AiReceptionist) {
      return (
        <Text>
          {formatMessage(messages.dedicatedPhoneNumberAiReceptionistStatus)}
          <Link url={aiReceptionistSettingsPath}>
            {formatMessage(
              messages.dedicatedPhoneNumberAiReceptionistStatusLink,
            )}
          </Link>
        </Text>
      );
    } else if (forwardTo && forwardTo.length !== 0) {
      return (
        <Text>
          {formatMessage(messages.dedicatedPhoneNumberForwardedStatus, {
            forwardTo: (
              <Link url={companySettingsPath}>
                {friendlyPhoneFormat(forwardTo)}
              </Link>
            ),
          })}
        </Text>
      );
    } else {
      return (
        <Text>
          <Link url={companySettingsPath}>
            {formatMessage(messages.dedicatedPhoneNumberNoForwardingStatus)}
          </Link>
        </Text>
      );
    }
  }
}

export function DedicatedPhoneNumberStatus(
  props: DedicatedPhoneNumberStatusProps,
) {
  return (
    <IntlProvider>
      <DedicatedPhoneNumberStatusInternal {...props} />
    </IntlProvider>
  );
}
