import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "setupGuide.connectedStep.title",
    defaultMessage: "Connect a profile",
    description: "Title for the connect step",
  },
  subtitle: {
    id: "setupGuide.connectedStep.subtitle",
    defaultMessage: "Setup time 3 min",
    description: "Subtitle for the connect step",
  },
  description: {
    id: "setupGuide.connectedStep.description",
    defaultMessage: "Connect your Google Business Profile in order to",
    description: "Description for the connect step",
  },
  descriptionSubText1: {
    id: "setupGuide.connectedStep.descriptionSubText1",
    defaultMessage: "Ask for reviews automatically",
    description: "Description for the connect step",
  },
  descriptionSubText2: {
    id: "setupGuide.connectedStep.descriptionSubText2",
    defaultMessage: "Link clients directly to your profile",
    description: "Description for the connect step",
  },
  descriptionSubText3: {
    id: "setupGuide.connectedStep.descriptionSubText3",
    defaultMessage: "View client reviews from their profile",
    description: "Description for the connect step",
  },
});
