import type { MutableRefObject, ReactNode } from "react";
import React, { useRef } from "react";
import { useIntl } from "react-intl";
import { Box, Button, Card, Content, Flex, Heading } from "@jobber/components";
import {
  CallToAction,
  type CallToActionRef,
} from "~/jobber/settings/users/components/CallToAction/CallToAction";
import { messages } from "./messages";
import styles from "./EducationCards.module.css";

interface EducationCardProps {
  ctaName: string;
  title: string | ReactNode;
  children: ReactNode;
  flexTemplate?: Array<"grow" | "shrink">;
  onDismiss?: () => void;
}

export function EducationCard({
  ctaName,
  title,
  children,
  flexTemplate = ["shrink", "grow", "shrink"],
  onDismiss,
}: EducationCardProps) {
  const { formatMessage } = useIntl();
  const ctaRef = useRef() as MutableRefObject<CallToActionRef>;

  const handleDismiss = () => {
    ctaRef.current?.dismiss();
    onDismiss?.();
  };

  return (
    <CallToAction ctaName={ctaName} ref={ctaRef}>
      <div className={styles.cardWrapper}>
        <Card>
          <Content spacing="small">
            <Flex direction="column" template={flexTemplate}>
              <Box
                width="100%"
                justifyContent="space-between"
                direction="row"
                alignItems="center"
              >
                <Heading level={4}>{title}</Heading>
                <Button
                  onClick={handleDismiss}
                  icon="cross"
                  type="secondary"
                  variation="subtle"
                  ariaLabel={formatMessage(messages.dismiss)}
                />
              </Box>
              {children}
            </Flex>
          </Content>
        </Card>
      </div>
    </CallToAction>
  );
}
