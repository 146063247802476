import type { PropsWithChildren, ReactNode } from "react";
import React from "react";
import {
  Card,
  Content,
  Flex,
  Glimmer,
  Heading,
  Text,
} from "@jobber/components";
import { UnderlinedText } from "~/shared/UnderlinedText/UnderlinedText";
import styles from "./ReviewKPICard.module.css";

export interface KPIValue {
  amount: ReactNode;
  description: string;
}

export interface ReviewKPICardProps {
  header: string;
  isLoading: boolean;
  KPIValues?: KPIValue[]; // Try to limit the number of KPIValues to 2, or the UI may break
  subheader?: string;
  tooltip?: string;
}

export function ReviewKPICard({
  children,
  header,
  isLoading,
  KPIValues,
  subheader,
  tooltip,
}: PropsWithChildren<ReviewKPICardProps>): JSX.Element {
  return (
    <div className={styles.cardContainer}>
      <Card>
        <div className={styles.cardContent}>
          <Content spacing="smallest">
            {tooltip ? (
              <Heading level={4}>
                <UnderlinedText text={header} tooltipText={tooltip} />
              </Heading>
            ) : (
              <Heading level={4}>{header}</Heading>
            )}

            {subheader && (
              <Text size="small" variation="subdued">
                {subheader}
              </Text>
            )}
          </Content>
          {isLoading ? (
            <Glimmer size="large" />
          ) : children !== undefined ? (
            <div
              className={styles.childrenContainer}
              data-testid="childrenContainer"
            >
              {children}
            </div>
          ) : (
            <div
              className={styles.metricsContainer}
              data-testid="metricsContainer"
            >
              {KPIValues?.map((value, index) => (
                <Content key={index}>
                  <Flex
                    template={["grow", "shrink"]}
                    direction="column"
                    gap="smaller"
                    align="end"
                  >
                    {value.amount}
                    <Text size="small" variation="subdued">
                      {value.description}
                    </Text>
                  </Flex>
                </Content>
              ))}
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}
