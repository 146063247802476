import React from "react";
import { Box, ProgressBar, Text } from "@jobber/components";
import { useIntl } from "react-intl";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import { formatCurrency } from "utilities/formatCurrency";
import { messages } from "./messages";

interface LimitsUsageProgressBarProps {
  currentUsage: number;
  totalLimit: number;
}

export function LimitsUsageProgressBar({
  currentUsage,
  totalLimit,
}: LimitsUsageProgressBarProps) {
  const { formatMessage } = useIntl();
  const { currencySymbol } = useAccount();

  return (
    <Box width={"100%"} height={"grow"}>
      <ProgressBar currentStep={currentUsage} totalSteps={totalLimit} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "var(--space-smaller)",
        }}
      >
        <Box direction={"column"} width={"50%"}>
          <Text>
            {formatMessage(messages.amountUsed, {
              br: <br />,
              amount: formatCurrency(currentUsage, currencySymbol, 2),
            })}
          </Text>
        </Box>
        <Box width={"50%"} alignSelf="stretch">
          <Text align="end">
            {formatMessage(messages.amountAvailable, {
              br: <br />,
              amount: formatCurrency(
                totalLimit - currentUsage,
                currencySymbol,
                2,
              ),
            })}
          </Text>
        </Box>
      </div>
    </Box>
  );
}
