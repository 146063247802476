import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Button } from "@jobber/components/Button";
import { useQuery } from "@apollo/client";
import { IntlProvider } from "@translations/IntlProvider";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { APIProvider } from "~/utilities/API/APIProvider";
import { ACCOUNT_TIER_INFO } from "~/jobber/plans/components/DowngradeModal/Downgrade.graphql";
import { messages } from "./messages";
import { SAVE_OFFER_ELIGIBILITY } from "./SaveOfferEligibilityQuery.graphql";
import { DowngradePromoModal } from "../DowngradePromoModal";
import { DowngradeContactModal } from "../DowngradeContactModal";

interface SubscriptionPermissionsType {
  subscriptionPermissions: {
    canAcceptDowngradeSaveOffer: boolean;
  };
}

interface AccountTierInfoType {
  accountPlanInfo: {
    planTier: string;
  };
}

export interface DowngradeButtonProps {
  planName: string;
  accountPlanName: string;
  isAnnualBillingCycle: boolean;
}

function DowngradeButton({
  planName,
  accountPlanName,
  isAnnualBillingCycle,
}: DowngradeButtonProps) {
  const { formatMessage } = useIntl();
  const { data } = useQuery<SubscriptionPermissionsType>(
    SAVE_OFFER_ELIGIBILITY,
  );
  const { data: accountTierData } =
    useQuery<AccountTierInfoType>(ACCOUNT_TIER_INFO);
  const [showOffer, setShowOffer] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);

  return (
    <>
      {data && (
        <>
          <Button
            label={formatMessage(messages.label, { planName: planName })}
            onClick={handleClick}
          />
          <DowngradePromoModal
            shouldShowModal={showOffer}
            hideModal={offerAccepted => handleShowOfferDismiss(offerAccepted)}
            currentPlanTier={accountPlanName}
            isAnnualBillingCycle={isAnnualBillingCycle}
          />
          <DowngradeContactModal
            showModal={showContactModal}
            hideModal={() => setShowContactModal(false)}
            currentPlanTier={accountTierData?.accountPlanInfo?.planTier}
          />
        </>
      )}
    </>
  );

  function handleClick() {
    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "downgrade_to_" + planName.toLowerCase().replace(" ", "_"),
    });
    if (data?.subscriptionPermissions?.canAcceptDowngradeSaveOffer) {
      setShowOffer(true);
    } else {
      setShowContactModal(true);
    }
  }

  function handleShowOfferDismiss(offerAccepted: boolean) {
    setShowOffer(false);
    if (!offerAccepted) {
      setShowContactModal(true);
    }
  }
}

function WrappedDowngradeButton(props: DowngradeButtonProps): JSX.Element {
  return (
    <IntlProvider>
      <APIProvider>
        <DowngradeButton {...props} />
      </APIProvider>
    </IntlProvider>
  );
}

export { WrappedDowngradeButton as DowngradeButton };
