import React from "react";
import { useIntl } from "react-intl";
import {
  Box,
  Button,
  Disclosure,
  Heading,
  Icon,
  Text,
} from "@jobber/components";
import type { Template } from "~/utilities/API/graphql";
import { messages } from "./messages";
import styles from "./styles.module.css";

function CircleCheckmark({ checked = false }: { checked?: boolean }) {
  return (
    <div
      className={`${styles.circle} ${checked ? styles.checked : styles.unchecked}`}
    >
      {checked && <Icon name="checkmark" size="small" color="white" />}
    </div>
  );
}

interface AutomatedCampaignDisclosureProps {
  template: Template;
  isEnabled: boolean;
  activeDisclosure: Template | null;
  onToggle: (template: Template) => void;
  onNext: (template: Template) => void;
  title: string;
  description: string;
}

export function AutomatedCampaignDisclosure({
  template,
  isEnabled,
  activeDisclosure,
  onToggle,
  onNext,
  title,
  description,
}: AutomatedCampaignDisclosureProps) {
  const { formatMessage } = useIntl();

  return (
    <Disclosure
      open={activeDisclosure === template}
      onToggle={() => onToggle(template)}
      title={
        <Box direction="row" alignItems="center" gap="small">
          <CircleCheckmark checked={isEnabled} />
          <Heading level={5}>{title}</Heading>
        </Box>
      }
    >
      <Box
        padding={{ left: "larger" }}
        direction="column"
        alignItems="flex-start"
        gap="base"
      >
        <Text>{description}</Text>
        {!isEnabled && (
          <Button
            fullWidth={false}
            size="small"
            type="secondary"
            onClick={() => onNext(template)}
            label={formatMessage(messages.automatedCampaignsDisclosureButton)}
          />
        )}
      </Box>
    </Disclosure>
  );
}
