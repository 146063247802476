import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Button } from "@jobber/components/Button";
import { useBreakpoints } from "@jobber/hooks/useBreakpoints";
import { ApolloError, useMutation } from "@apollo/client";
import {
  Content,
  Flex,
  Heading,
  Option,
  Select,
  Text,
} from "@jobber/components";
import { Intercom } from "utilities/chat";
import {
  ClientNotificationId,
  RequestTrigger,
  type UpdateJobFollowUpMutation,
} from "~/utilities/API/graphql";
import { Rollbar } from "~/utilities/errors/Rollbar";
import { useEditReviewsInitialMessageSettings } from "jobber/reviews/hooks/useEditReviewsInitialMessageSettings";
import styles from "./TriggerStep.module.css";
import { messages } from "./messages";
import { JOB_FOLLOW_UPS } from "../../SetupGuide.graphql";
import type { SetupGuideStepProps } from "../../types";
import {
  InvoiceTriggeredReviewImage,
  JobTriggeredReviewImage,
} from "../../images";

export function TriggerStep({
  navigation,
  setErrMsg,
}: SetupGuideStepProps): JSX.Element {
  const { formatMessage } = useIntl();
  const { largeAndUp } = useBreakpoints();
  const [newTriggerType, setNewTriggerType] = useState<RequestTrigger>(
    RequestTrigger.INVOICE,
  );
  const [editJFUMutation] =
    useMutation<UpdateJobFollowUpMutation>(JOB_FOLLOW_UPS);
  const { editReviewsInitialMessageSettings } =
    useEditReviewsInitialMessageSettings();

  useEffect(() => {
    Intercom.EXECUTE("trackEvent", "connected_google_business_account");
  }, []);

  function handleMutationError(
    error: ApolloError | string,
    isJFUError = false,
  ) {
    const errorMessage = error instanceof ApolloError ? error.message : error;
    const errorContext = isJFUError
      ? {
          message: `Failed to disable job follow ups: ${errorMessage}`,
          error: new Error("TriggerStep"),
        }
      : {
          message: `Failed to save trigger settings: ${errorMessage}`,
          error: new Error("TriggerStep"),
        };

    Rollbar.EXECUTE(errorContext.message, errorContext.error);
  }

  function editJobFollowUps() {
    editJFUMutation({
      variables: {
        id: ClientNotificationId.JOB_FOLLOWUP,
        attributes: {
          enabled: false,
        },
      },
    })
      .then(result => {
        const userErrors = result.data?.updateNotification.errors ?? [];
        if (userErrors.length > 0) {
          setErrMsg(messages.jobFollowUpsError);
          handleMutationError(userErrors[0], true);
        }
      })
      .catch(error => {
        setErrMsg(messages.jobFollowUpsError);
        handleMutationError(error, true);
      })
      .finally(() => {
        navigation.onNext();
      });
  }

  const handleOnClick = async () => {
    const triggerErrors = await editReviewsInitialMessageSettings({
      triggerType: newTriggerType,
    });
    if (triggerErrors.length > 0) {
      handleMutationError(triggerErrors[0]);
      setErrMsg(messages.triggerSettingsError);
    }

    editJobFollowUps();
  };

  return (
    <div className={styles.stepContainer}>
      <Flex
        align="center"
        direction={largeAndUp ? "row" : "column"}
        gap="large"
        template={["grow", "shrink"]}
      >
        <div
          className={styles.imageContainer}
          style={{ order: largeAndUp ? 2 : 0 }}
        >
          <img
            src={
              newTriggerType == RequestTrigger.JOB
                ? JobTriggeredReviewImage.src
                : InvoiceTriggeredReviewImage.src
            }
            alt={formatMessage(
              newTriggerType == RequestTrigger.JOB
                ? JobTriggeredReviewImage.alt
                : InvoiceTriggeredReviewImage.alt,
            )}
          />
        </div>
        <Flex
          align="center"
          direction="column"
          gap="large"
          template={["grow", "shrink"]}
        >
          <Heading level={1}>{formatMessage(messages.title)}</Heading>
          <Content spacing="small">
            <Text>{formatMessage(messages.description)}</Text>
            <Select
              name="triggerType"
              onChange={(value: RequestTrigger) => setNewTriggerType(value)}
              value={newTriggerType}
            >
              <Option value={RequestTrigger.INVOICE}>
                {formatMessage(messages.invoiceTrigger)}
              </Option>
              <Option value={RequestTrigger.JOB}>
                {formatMessage(messages.jobTrigger)}
              </Option>
            </Select>
          </Content>
          <div>
            <Button
              ariaLabel={formatMessage(messages.primaryButton)}
              fullWidth={largeAndUp ? false : true}
              label={formatMessage(messages.primaryButton)}
              onClick={handleOnClick}
              type="primary"
            />
          </div>
        </Flex>
      </Flex>
    </div>
  );
}
