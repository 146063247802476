import { gql } from "@apollo/client";

export const GET_CURRENT_USER_QUERY = gql`
  query getCurrentUser {
    user {
      id
      isAccountOwner
      isAdmin
    }
  }
`;
