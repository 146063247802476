import { Heading } from "@jobber/components/Heading";
import React, { type PropsWithChildren } from "react";
import { Glimmer } from "@jobber/components/Glimmer";
import styles from "./ReviewSummaryCard.module.css";

export interface ReviewSummaryCardProps {
  header: string;
  summaryValue: number;
  isLoading: boolean;
  formatValue?: (value: number) => string;
}

export function ReviewSummaryCard({
  header,
  summaryValue,
  isLoading,
  formatValue = formatSummaryDisplayValue,
  children,
}: PropsWithChildren<ReviewSummaryCardProps>): JSX.Element {
  return (
    <>
      {isLoading ? (
        <OverviewLoading />
      ) : (
        <div className={styles.summaryContainer}>
          <Heading level={5}>{header}</Heading>
          <div className={styles.row}>
            <Heading level={1}>{formatValue(summaryValue)}</Heading>
            {children}
            {/* TODO JOB-81699: Add back TrendChip */}
          </div>
        </div>
      )}
    </>
  );
}

export function formatSummaryDisplayValue(value: number): string {
  return Number.isInteger(value) ? value.toString() : value.toFixed(1);
}

function OverviewLoading(): JSX.Element {
  return (
    <div className={styles.overviewCardLoading}>
      <div className={styles.glimmerRow}>
        <Glimmer size={"base"} width={150} />
      </div>
      <div className={styles.glimmerRow}>
        <Glimmer size={"larger"} width={100} />
      </div>
    </div>
  );
}
