import type { IntlFormatters } from "react-intl";
import { useIntl } from "react-intl";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { Dispatch, SetStateAction } from "react";
import { Button } from "@jobber/components/Button";
import { Page } from "@jobber/components/Page";
import { Heading } from "@jobber/components/Heading";
import { Content } from "@jobber/components/Content";
import { DataList } from "@jobber/components/DataList";
import { Glimmer } from "@jobber/components/Glimmer";
import { Banner } from "@jobber/components/Banner";
import { useMutation, useQuery } from "@apollo/client";
import { Grid } from "@jobber/components/Grid";
import type { ComboboxOption } from "@jobber/components/Combobox";
import { subDays } from "date-fns";
import { AttributionModal } from "~/jobber/features/Marketing/Reviews/views/AttributionModal/AttributionModal";
import type {
  ExperimentStatusMutation,
  IndustryComparison,
  MutationReviewReplyEditArgs,
  ReviewDataFragment,
  ReviewReplyEditInput,
  ReviewReplyEditMutation,
  ReviewsCommsSummaryQuery,
  ReviewsQuery,
} from "~/utilities/API/graphql";
import {
  MonitoringName,
  useSubscriptionMonitoring,
} from "utilities/useSubscriptionMonitoring";
import {
  DrawerView,
  ReviewsSettingsDrawerContext,
} from "jobber/reviews/views/ReviewsPage/context/ReviewsSettingsDrawerContext";
import { ReviewsEngagement } from "~/jobber/marketingSuiteExpansion/ReviewsEngagement/ReviewsEngagement";
import { useClientAutomatedReviewsDetailsEdit } from "jobber/reviews/hooks/useClientAutomatedReviewsDetailsEdit";
import { SplitNames, useFeatureFlag, withSplitClient } from "utilities/split";
import { ReviewSummaryCard } from "./components/ReviewSummaryCard";
import { ReviewRatingSummaryCard } from "./components/ReviewRatingSummaryCard/ReviewRatingSummaryCard";
import styles from "./ReviewsPage.module.css";
import { messages } from "./messages";
import {
  REVIEWS_COMMS_SUMMARY_QUERY,
  REVIEW_REPLY_EDIT_MUTATION,
} from "./ReviewsPage.graphql";
import { ReviewCard } from "./components/ReviewCard/ReviewCard";
import { ReviewFilter } from "./components/ReviewFilter/ReviewFilter";
import { ReviewsSettings } from "./components/ReviewsSettings/ReviewsSettings";
import type { ReviewsError } from "./types";
import { EXPERIMENT_STATUS } from "./components/ReviewsSettings/ReviewsSettings.graphql";
import { Resources } from "./components/Resources";
import { ReviewsBenchmark } from "./components/ReviewsBenchmark/ReviewsBenchmark";
import { ReviewsMessageCustomizationModal } from "./components/ReviewsMessageCustomizationModal/ReviewsMessageCustomizationModal";
import {
  ReviewTrendCard,
  generateMockData,
} from "./components/ReviewTrendCard";
import { ResponseTimeCard } from "./components/ResponseTimeCard";
import { ReviewMetricsCard } from "./components/ReviewMetricsCard";

interface ReviewsPageArgs {
  data: ReviewsQuery | undefined;
  loading: boolean;
  error: ReviewsError | undefined;
  setStatus: (status: ComboboxOption[]) => void;
  filtered: boolean;
  status: ComboboxOption[];
  defaultStatus?: ComboboxOption;
  isFeatureFlagEnabled?: boolean;
}

export const ReviewsPage = withSplitClient(ReviewsPageInternal);

function ReviewsPageInternal({
  data,
  loading,
  error,
  setStatus,
  filtered,
  status,
  defaultStatus,
  isFeatureFlagEnabled, // COBRA KAI, JOB-116974
}: ReviewsPageArgs): JSX.Element {
  const { formatMessage } = useIntl();
  const [reviewReplyEdit] = useMutation<
    ReviewReplyEditMutation,
    MutationReviewReplyEditArgs
  >(REVIEW_REPLY_EDIT_MUTATION);

  useSubscriptionMonitoring({
    name: MonitoringName.GOOGLE_REVIEWS,
    isLoading: loading,
  });

  const showDashboardV2 = useFeatureFlag(SplitNames.ReviewsDashboardWip);

  const { currentView, drawerActions } = useContext(
    ReviewsSettingsDrawerContext,
  );
  const errorRef = useRef<ReviewsError | undefined>();
  const handleError = useCallback((givenError: ReviewsError) => {
    errorRef.current = givenError;
  }, []);

  const { attributionModalData, setAttributionModalData } =
    useAttributionModal();

  const { reviews, showGlimmer } = useMemo(() => {
    function sendReviewReply({ reviewId, comment }: ReviewReplyEditInput) {
      return reviewReplyEdit({ variables: { input: { reviewId, comment } } });
    }
    const reviewList =
      data?.reviews?.reviews?.edges?.map(({ node }) => ({
        id: node.id,
        render: <ReviewCard review={node} sendReviewReply={sendReviewReply} />,
      })) || [];

    if (error) {
      handleError(error);
    }

    return {
      reviews: reviewList,
      showGlimmer: loading || error !== undefined,
    };
  }, [
    data?.reviews.reviews.edges,
    error,
    loading,
    reviewReplyEdit,
    handleError,
  ]);
  const hasDPN = !!data?.communicationSettings?.phoneNumber;

  return (
    <>
      <ReviewsMessageCustomizationModal
        drawerActions={drawerActions}
        showGlimmer={showGlimmer}
      />

      <Banners currentError={errorRef?.current} />

      <div className={styles.pageWrapper}>
        <Page
          title={formatMessage(messages.reviewsHeader)}
          width={isFeatureFlagEnabled ? "fill" : "standard"}
          primaryAction={{
            label: formatMessage(messages.settingsLabel),
            type: "secondary",
            onClick: () => {
              drawerActions?.goTo(DrawerView.ManageSettings);
            },
          }}
        >
          {currentView && <ReviewsSettings showCustomizations={hasDPN} />}

          <Overview
            averageRating={data?.reviews.averageRating}
            industryComparison={data?.reviews.industryComparison}
            onError={handleError}
            previousAverageRating={0} // TODO: JOB-118116 Grab actual totals at sign up
            previousTotalReviewCount={0} // TODO: JOB-118116 Grab actual totals at sign up
            responseTime={35} // TODO: JOB-118116 Grab actual response time
            reviewsPageLoading={showGlimmer}
            totalReviewCount={data?.reviews.totalReviewCount}
            formatMessage={formatMessage}
          />

          <AttributionModalAdapter
            attributionModalData={attributionModalData}
            setAttributionModalData={setAttributionModalData}
          />

          <Grid gap>
            <Grid.Cell
              size={{
                xs: 12,
                md: isFeatureFlagEnabled ? 12 : 8,
                lg: isFeatureFlagEnabled ? 7 : 9,
              }}
            >
              <DataList
                data={reviews}
                headers={{}}
                headerVisibility={{ xs: false }}
                title={formatMessage(messages.latestReviewsHeader)}
                loadingState={showGlimmer ? "initial" : "none"}
                filtered={filtered}
              >
                <DataList.Filters>
                  <ReviewFilter selected={status} setSelected={setStatus} />
                </DataList.Filters>
                <DataList.ItemActions>
                  <DataList.ItemAction
                    alwaysVisible={true}
                    label={formatMessage(messages.clientMatchesButtonLabel)}
                    visible={(item: (typeof reviews)[number]) => {
                      const numberOfPendingAttributions =
                        item.render.props.review.clientAttributions
                          ?.pendingReviewAttributions?.nodes?.length;
                      const numberOfConfirmedAttributions =
                        item.render.props.review.clientAttributions
                          ?.confirmedReviewAttributions?.nodes?.length;
                      return (
                        numberOfPendingAttributions +
                          numberOfConfirmedAttributions >
                        0
                      );
                    }}
                    onClick={(item: (typeof reviews)[number]) => {
                      setAttributionModalData(item.render.props.review);
                    }}
                  />
                </DataList.ItemActions>
                <DataList.Layout size="xs">
                  {showGlimmer ? ReviewsLoadingLayout : ReviewLayout}
                </DataList.Layout>
                <DataList.EmptyState
                  type="empty"
                  message={formatMessage(messages.noReviews)}
                />
                <DataList.EmptyState
                  type="filtered"
                  message={formatMessage(messages.noUnansweredReviews)}
                  action={
                    <Button
                      label={formatMessage(messages.showAll)}
                      onClick={() =>
                        setStatus(defaultStatus ? [defaultStatus] : [])
                      }
                    />
                  }
                />
              </DataList>
            </Grid.Cell>
            <Grid.Cell
              size={{
                xs: 12,
                md: isFeatureFlagEnabled ? 12 : 4,
                lg: isFeatureFlagEnabled ? 5 : 3,
              }}
            >
              {isFeatureFlagEnabled ? (
                <ReviewsBenchmark />
              ) : showGlimmer ? (
                <ReviewsLoadingLayout />
              ) : (
                <Resources showCTA={hasDPN && !showDashboardV2} />
              )}
            </Grid.Cell>
          </Grid>
        </Page>
      </div>
    </>
  );

  function ReviewLayout({ id, render }: { id: string; render: JSX.Element }) {
    return <div key={id}>{render}</div>;
  }
}

function ReviewsLoadingLayout() {
  return (
    <div>
      <Content type={"section"} spacing={"smaller"}>
        <Glimmer size={"base"} width={100} />
        <Glimmer size={"base"} width={200} />
        <Glimmer size={"base"} />
        <Glimmer size={"base"} />
      </Content>
    </div>
  );
}

function Banners({
  currentError,
}: {
  currentError?: ReviewsError | undefined;
}): JSX.Element {
  const hasError = currentError?.credentialError || currentError?.generalError;
  const formatMessage = useIntl().formatMessage;

  return (
    <>
      {hasError && (
        <div className={styles.bannersContainer}>
          {currentError.credentialError && (
            <Banner
              type="error"
              primaryAction={{
                label: "Login",
                ariaLabel: "Refresh Google business account credentials",
                onClick: () => {
                  window.location.href =
                    "/automated_reviews/google_business/reconnect?post_oauth_redirect=%2Freviews";
                },
              }}
              dismissible={false}
            >
              {formatMessage(messages.credentialError)}
            </Banner>
          )}

          {currentError.generalError && (
            <Banner type="error">{formatMessage(messages.generalError)}</Banner>
          )}
        </div>
      )}
    </>
  );
}

interface OverviewProps {
  averageRating?: number;
  formatMessage: IntlFormatters["formatMessage"];
  industryComparison?: IndustryComparison;
  onError?: (error: ReviewsError) => void;
  previousAverageRating: number;
  previousTotalReviewCount: number;
  responseTime: number;
  reviewsPageLoading: boolean;
  totalReviewCount?: number;
}

function Overview({
  reviewsPageLoading,
  onError,
  averageRating,
  formatMessage,
  totalReviewCount,
  industryComparison,
  responseTime,
  previousAverageRating,
  previousTotalReviewCount,
}: OverviewProps): JSX.Element {
  const { data, loading, error } = useQuery<ReviewsCommsSummaryQuery>(
    REVIEWS_COMMS_SUMMARY_QUERY,
  );
  const isLoading = reviewsPageLoading || loading || !!error;

  if (error && onError) {
    onError({ generalError: true });
  }

  if (useFeatureFlag(SplitNames.ReviewsDashboardWip)) {
    const activationDate = subDays(new Date(), 67); //TODO JOB-118116 grab real created_at
    const { reviewsTotalData, requestsData } = generateMockData();
    const recommendedResponseTime = 24;

    return (
      <div className={styles.overviewContainer} data-testid="overviewV2">
        <ReviewMetricsCard
          averageRating={averageRating}
          formatMessage={formatMessage}
          isLoading={isLoading}
          previousAverageRating={previousAverageRating}
          previousTotalReviewCount={previousTotalReviewCount}
          totalReviewCount={totalReviewCount}
        />
        <ResponseTimeCard
          formatMessage={formatMessage}
          isLoading={isLoading}
          recommendedResponseTime={recommendedResponseTime}
          responseTime={responseTime}
        />
        <ReviewTrendCard
          activationDate={activationDate}
          formatMessage={formatMessage}
          isLoading={isLoading}
          requestsData={requestsData}
          reviewsTotalData={reviewsTotalData}
        />
      </div>
    );
  }

  const { totalSmsSent = 0 } = data?.reviewsCommsSummary ?? {};
  return (
    <>
      <div className={styles.heading}>
        <Heading level={3}>{formatMessage(messages.overviewHeader)}</Heading>
      </div>
      <div className={styles.row}>
        <ReviewRatingSummaryCard
          header={formatMessage(messages.ratingsHeader)}
          rating={averageRating || 0}
          isLoading={isLoading}
        />
        <ReviewSummaryCard
          header={formatMessage(messages.reviewsTotalHeader)}
          summaryValue={totalReviewCount || 0}
          isLoading={isLoading}
        />
        <ReviewSummaryCard
          header={formatMessage(messages.smsHeader)}
          summaryValue={totalSmsSent}
          isLoading={isLoading}
        />
      </div>
      <ReviewsEngagement
        accountNumReviews={totalReviewCount || 0}
        industryComparison={industryComparison}
        isLoading={isLoading}
      />
    </>
  );
}

export function useExperiment(experimentName: string) {
  const [checkExperiment, { data, loading }] =
    useMutation<ExperimentStatusMutation>(EXPERIMENT_STATUS, {
      variables: { name: experimentName },
    });

  useEffect(() => {
    void checkExperiment();
  }, [checkExperiment]);

  return {
    loading: loading,
    inExperiment:
      !!data?.assignAndGetExperimentStatus?.experiment?.inExperiment,
  };
}

interface AttributionModalAdapterProps {
  attributionModalData: ReviewDataFragment | undefined;
  setAttributionModalData: Dispatch<
    SetStateAction<ReviewDataFragment | undefined>
  >;
}

function AttributionModalAdapter({
  attributionModalData,
  setAttributionModalData,
}: AttributionModalAdapterProps): JSX.Element | undefined {
  const [openModal, setOpenModal] = useState(false);
  const { editClientAutomatedReviewDetails } =
    useClientAutomatedReviewsDetailsEdit();

  useEffect(() => {
    setOpenModal(!!attributionModalData);
  }, [attributionModalData]);

  if (!attributionModalData) return;

  return (
    <AttributionModal
      openModal={openModal}
      onRequestClose={() => setAttributionModalData(undefined)}
      review={attributionModalData}
      onReviewMatchSelected={async (matchSelected, clientId) => {
        setAttributionModalData(undefined);
        if (clientId !== undefined) {
          await editClientAutomatedReviewDetails?.({
            variables: {
              input: {
                clientId,
                allowReviewRequests: !matchSelected,
              },
            },
            onError: () => ({}),
          });
        }
      }}
    />
  );
}

function useAttributionModal() {
  const [attributionModalData, setAttributionModalData] =
    useState<ReviewDataFragment>();

  return {
    attributionModalData,
    setAttributionModalData,
  };
}
