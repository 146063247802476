import { useHistory, useLocation } from "react-router-dom";
import { useCallback } from "react";
import { SplitNames, useFeatureFlag } from "utilities/split";
import { defaultSteps } from "../stepsDefinition";

export function useSetupGuideSteps() {
  const history = useHistory();
  const routerLocation = useLocation();
  const showMultiTrigger = useFeatureFlag(SplitNames.ReviewMultiTriggerRelease);
  const steps = defaultSteps(showMultiTrigger);

  const totalSteps = steps.filter(step => step.includeInProgress).length - 2;
  const currentStepIndex = steps
    .map(step => step.route.toString())
    .indexOf(routerLocation.pathname);
  const completedSteps = steps.slice(0, currentStepIndex);
  const progressBarStep = completedSteps.reduce((greatestIndexSoFar, step) => {
    return step.includeInProgress ? greatestIndexSoFar + 1 : greatestIndexSoFar;
  }, 0);

  const onNext = useCallback(() => {
    if (currentStepIndex + 1 >= steps.length) {
      history.push("/reviews");
    } else {
      const nextStep = steps[currentStepIndex + 1].route;
      history.push(nextStep.toString());
    }
  }, [currentStepIndex, history, steps]);

  function goBack() {
    if (currentStepIndex > 0) {
      const previousStep = steps[currentStepIndex - 1].route;
      history.replace(previousStep.toString());
    } else {
      window.location.href = "/reviews";
    }
  }

  return {
    progressBarStep,
    totalSteps,
    showBackButton: steps[currentStepIndex]?.showBackButton || false,
    goBack,
    onNext,
  };
}
