import { Template } from "~/utilities/API/graphql";

export interface AutomatedCampaignState {
  reengageEnabled: boolean;
  winBackEnabled: boolean;
  closeOnPendingQuoteEnabled: boolean;
}

export function getDefaultOpenDisclosure({
  reengageEnabled,
  winBackEnabled,
  closeOnPendingQuoteEnabled,
}: AutomatedCampaignState): Template | null {
  if (!reengageEnabled) return Template.AUTOMATED_RE_ENGAGE;
  if (!winBackEnabled) return Template.AUTOMATED_WIN_BACK_LOST_LEADS;
  if (!closeOnPendingQuoteEnabled) {
    return Template.AUTOMATED_CLOSE_ON_PENDING_QUOTES;
  }

  return null;
}
