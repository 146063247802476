import React, { useState } from "react";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { List } from "@jobber/components/List";
import { Glimmer } from "@jobber/components/Glimmer";
import { Markdown } from "@jobber/components/Markdown";
import { Button } from "@jobber/components/Button";
import { useEditGoogleBusinessAccount } from "jobber/reviews/hooks/useEditGoogleBusinessAccount";
import type { GoogleProfileSubscription } from "~/utilities/API/graphql";
import { messages } from "./messages";
import styles from "./styles.module.css";
import type { ListItem } from "./types";
import type { SetupGuideStepProps } from "../../types";

export function ProfileStep({
  data,
  loading,
  errors,
  navigation,
  setErrMsg,
}: SetupGuideStepProps): JSX.Element {
  const { formatMessage } = useIntl();
  const { editGoogleBusinessAccount: editGbaAccountInternal } =
    useEditGoogleBusinessAccount();

  // TODO: Find a better place for this function / use async/await syntax w/ try/catch
  function editGoogleBusinessAccount(location: string, businessName: string) {
    editGbaAccountInternal({
      location,
      businessName,
    })
      .then(result => {
        if (result.length > 0) {
          if (
            result[0].message ===
            "AutomatedReviews::GoogleReviewsService::NoReviewUrlError"
          ) {
            setErrMsg(messages.unverifiedError);
          } else {
            setErrMsg(messages.generalError);
          }
        } else {
          navigation.onNext();
        }
      })
      .catch(() => {
        setErrMsg(messages.generalError);
      });
  }

  if (errors) {
    return (
      <div className={styles.mainContainer}>
        <Content>
          <Heading level={1}>{formatMessage(messages.title)}</Heading>
          {errors && <ErrorState />}
        </Content>
      </div>
    );
  }

  return (
    <div className={styles.mainContainer}>
      <Content>
        <Heading level={1}>{formatMessage(messages.title)}</Heading>
        {data === undefined || loading ? (
          <LoadingState />
        ) : (
          <List items={listItems(data, editGoogleBusinessAccount)} />
        )}
      </Content>
    </div>
  );
}

function listItems(
  data: GoogleProfileSubscription | undefined,
  editGoogleBusinessAccount: (location: string, businessName: string) => void,
): ListItem[] {
  const items: ListItem[] =
    data?.googleProfiles.googleProfiles.nodes.map((profile, index) => ({
      id: index,
      content: `**${profile.businessName}**`,
      onClick: () => {
        editGoogleBusinessAccount(profile.locationName, profile.businessName);
      },
    })) || [];

  return items;
}

function ErrorState(): JSX.Element {
  const { formatMessage } = useIntl();
  const [loading, setButtonLoading] = useState<boolean>(false);

  return (
    <div className={styles.errorContainer}>
      <Text size="large">{formatMessage(messages.loadingProfileError)}</Text>
      <div className={styles.button}>
        <Button
          fullWidth={false}
          type="primary"
          onClick={() => {
            setButtonLoading(true);
            window.location.href =
              "/automated_reviews/google_business/connect?post_oauth_redirect=%2Freviews%2Fprofile";
          }}
          loading={loading}
          label={formatMessage(messages.chooseAnotherAccount)}
          ariaLabel={formatMessage(messages.chooseAnotherAccount)}
        />
      </div>
      <Markdown
        externalLink={true}
        content={formatMessage(messages.createProfileLink)}
      />
    </div>
  );
}

function LoadingState(): JSX.Element {
  return (
    <>
      <Glimmer shape="rectangle" size="larger" timing="base" />
      <Glimmer shape="rectangle" size="larger" timing="base" />
      <Glimmer shape="rectangle" size="larger" timing="base" />
    </>
  );
}
