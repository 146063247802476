import React, { createContext, useContext, useEffect } from "react";
import { Text } from "@jobber/components/Text";
import { Heading } from "@jobber/components/Heading";
import { Content } from "@jobber/components/Content";
import { Flex } from "@jobber/components/Flex";
import { Tooltip } from "@jobber/components/Tooltip";
import type { IconNames } from "@jobber/components/Icon";
import { Icon } from "@jobber/components/Icon";
import { Glimmer } from "@jobber/components/Glimmer";
import { Popover } from "@jobber/components/Popover";
import { Emphasis } from "@jobber/components/Emphasis";
import { useBool } from "@jobber/hooks/dist";
import type { ApolloError } from "@apollo/client";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { Card } from "@jobber/components/Card";
import { Grid } from "@jobber/components/Grid";
import styles from "./KPISection.module.css";
import { messages } from "./messages";

interface LoadingContext {
  loading?: boolean;
  error?: ApolloError | boolean;
}
interface LoadingProviderProps
  extends LoadingContext,
    React.PropsWithChildren {}

interface ItemGroupProps extends React.PropsWithChildren {
  isStaticallySized?: boolean;
  variant?: "featured" | "kpi";
}

interface KPIBaseCardBaseProps {
  heading: string | React.ReactNode;
  subheading?: string;
  tooltipText?: string;
  iconName?: IconNames;
  onClick?: () => void;
  tooltipEventCallback?: () => void;
}

interface KPICardProps extends KPIBaseCardBaseProps {
  metrics?: undefined;
  value: string;
  valueDescription?: string;
  trend?: React.ReactNode;
}

interface KPISplitCardProps extends KPIBaseCardBaseProps {
  value?: undefined;
  valueDescription?: undefined;
  trend?: undefined;
  metrics: {
    value: string;
    valueDescription?: string;
    trend?: React.ReactNode;
  }[];
}

type KPIBaseCardProps = KPICardProps | KPISplitCardProps;

interface KPIEventTrackerProps extends React.PropsWithChildren {
  eventCallback?: () => void;
}

interface KPITrendTooltipProps extends React.PropsWithChildren {
  title: string;
  data: { label: string; value: string }[];
  eventCallback?: () => void;
}

const LoadingContext = createContext<LoadingContext>({
  loading: false,
});

const useKPISectionLoading = () => useContext(LoadingContext);

const LoadingProvider = ({
  children,
  loading,
  error,
}: LoadingProviderProps) => {
  return (
    <LoadingContext.Provider value={{ loading, error }}>
      {children}
    </LoadingContext.Provider>
  );
};

const KPISection = ({ children }: React.PropsWithChildren) => {
  return (
    <div className={classNames([styles.kpiSection, styles.row])}>
      {children}
    </div>
  );
};

const Group = ({
  children,
  isStaticallySized,
  variant = "kpi",
}: ItemGroupProps) => {
  return (
    <div
      className={classNames([
        variant === "kpi" ? styles.kpiContainer : styles.featuredWrapper,
        isStaticallySized ? styles.staticFeature : "",
        styles.baseFlexbox,
      ])}
    >
      {children}
    </div>
  );
};

const CardLoadingState = () => {
  return (
    <div className={styles.loadingState}>
      <Glimmer size="auto" />
    </div>
  );
};

const CardErrorState = () => {
  const { formatMessage: t } = useIntl();

  return (
    <div className={styles.errorState}>
      <Flex template={["shrink", "shrink"]} align="center" gap="smaller">
        <Icon size="small" name="alert" />
        <Text variation="subdued" size="small">
          {t(messages.error)}
        </Text>
      </Flex>
    </div>
  );
};

const CardBottomContent = ({
  value,
  valueDescription,
  trend,
}: Pick<KPIBaseCardProps, "value" | "valueDescription" | "trend">) => {
  const { loading, error } = useKPISectionLoading();

  if (loading && !error) return <CardLoadingState />;
  if (error) return <CardErrorState />;

  return (
    <Content spacing="smallest">
      <div className={styles.trendWrapper}>
        <Heading level={1} element="span">
          {value}
        </Heading>
        {trend && trend}
      </div>
      {valueDescription && (
        <Text variation="subdued" size="small">
          {valueDescription}
        </Text>
      )}
    </Content>
  );
};

const KPIEventTracker = ({ children, eventCallback }: KPIEventTrackerProps) => {
  useEffect(() => {
    const timer = eventCallback ? setTimeout(eventCallback, 1000) : undefined;

    return () => {
      timer && clearTimeout(timer);
    };
  }, [eventCallback]);

  return <>{children}</>;
};

const Trend = ({
  children,
  title,
  data,
  eventCallback,
}: KPITrendTooltipProps) => {
  const wrapperRef = React.useRef<HTMLSpanElement>(null);
  const [popoverOpen, setPopoverOpen, setPopoverClose] = useBool(false);

  return (
    <span
      onMouseOverCapture={setPopoverOpen}
      onMouseOutCapture={setPopoverClose}
      ref={wrapperRef}
    >
      {children}
      <Popover
        UNSAFE_style={{ dismissButtonContainer: { display: "none" } }}
        attachTo={wrapperRef}
        open={popoverOpen}
        preferredPlacement="top"
      >
        <KPIEventTracker eventCallback={eventCallback}>
          <Content spacing="small">
            <Text variation="subdued" size="small">
              {title}
            </Text>
            <Flex
              template={data.map(() => "shrink")}
              gap="smaller"
              direction="column"
            >
              {data.map(item => (
                <Flex
                  key={item.label}
                  gap="small"
                  direction="row"
                  template={["grow", "shrink"]}
                >
                  <Text size="small">
                    <Emphasis variation="bold">{item.label}</Emphasis>
                  </Text>
                  <Text size="small">
                    <Emphasis variation="bold">{item.value}</Emphasis>
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Content>
        </KPIEventTracker>
      </Popover>
    </span>
  );
};

const KPIBaseCard = ({
  heading,
  subheading,
  tooltipText,
  iconName,
  onClick,
  metrics,
  value,
  valueDescription,
  trend,
  tooltipEventCallback,
}: KPIBaseCardProps) => {
  const { error, loading } = useKPISectionLoading();

  return (
    <Card onClick={onClick as () => void}>
      <div className={styles.kpiCardBody}>
        <span className={styles.kpiCardIcon}>
          {iconName && <Icon size="small" name={iconName} />}
        </span>
        <Content spacing="smallest">
          <Flex template={["shrink", "shrink"]} gap="smaller">
            <Heading level={4} element="span">
              {heading}
            </Heading>
            {tooltipText && (
              <>
                <span className="u-showForSROnly">{tooltipText}</span>
                <Tooltip message={tooltipText}>
                  <span onMouseEnter={tooltipEventCallback}>
                    <Icon size="small" name="help" />
                  </span>
                </Tooltip>
              </>
            )}
          </Flex>
          <Text variation="subdued" size="small">
            {subheading}
          </Text>
        </Content>
        {error && !loading ? (
          <CardErrorState />
        ) : metrics ? (
          <Grid alignItems="row">
            {metrics.map(metric => (
              <Grid.Cell size={{ xs: 6 }} key={metric.value}>
                <CardBottomContent {...metric} />
              </Grid.Cell>
            ))}
          </Grid>
        ) : (
          <CardBottomContent
            value={value}
            valueDescription={valueDescription}
            trend={trend}
          />
        )}
      </div>
    </Card>
  );
};

const KPICard = ({
  value,
  valueDescription,
  trend,
  ...baseProps
}: KPICardProps) => {
  return (
    <KPIBaseCard
      {...baseProps}
      value={value}
      valueDescription={valueDescription}
      trend={trend}
    />
  );
};

const KPISplitCard = (props: KPISplitCardProps) => {
  return <KPIBaseCard {...props} />;
};

KPISection.Loading = LoadingProvider;
KPISection.Group = Group;
KPISection.Card = KPICard;
KPISection.Trend = Trend;
KPISection.SplitCard = KPISplitCard;

export { KPISection };
