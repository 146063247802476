import { gql } from "@apollo/client";

export const GET_CAMPAIGN_TEMPLATE_STATS = gql`
  query CampaignTemplateStats {
    reengageTemplate: commsCampaignDefaultTemplate(type: AUTOMATED_RE_ENGAGE) {
      activeCampaignsForTemplateTypeCount
    }
    winBackTemplate: commsCampaignDefaultTemplate(
      type: AUTOMATED_WIN_BACK_LOST_LEADS
    ) {
      activeCampaignsForTemplateTypeCount
    }
    closeOnPendingQuoteTemplate: commsCampaignDefaultTemplate(
      type: AUTOMATED_CLOSE_ON_PENDING_QUOTES
    ) {
      activeCampaignsForTemplateTypeCount
    }
  }
`;
