import React from "react";
import { Heading, InlineLabel, Markdown } from "@jobber/components";
import styles from "./FeatureCard.module.css";

export interface FeatureCardProps {
  title: string;
  content: string[];
  image: { src: string; alt: string };
  label?: string;
}

export function FeatureCard({
  title,
  content,
  image,
  label,
}: FeatureCardProps) {
  return (
    <div className={styles.container}>
      <div className={styles.contentSection}>
        {label ? (
          <div className={styles.labelContainer}>
            <InlineLabel color="yellowGreen">{label}</InlineLabel>
          </div>
        ) : (
          <div className={styles.placeholderLabel}></div>
        )}
        <Heading level={2}>{title}</Heading>
        <div className={styles.content}>
          {content.map((text, index) => (
            <Markdown content={text} key={index} externalLink />
          ))}
        </div>
      </div>
      <div className={styles.imageSection}>
        <img src={image.src} alt={image.alt} />
      </div>
    </div>
  );
}
