import { defineMessages } from "react-intl";

export const messages = defineMessages({
  immediateNonDiscountedChargeMessage: {
    id: "billing.purchaseDisclaimer.immediateNonDiscountedChargeMessage",
    defaultMessage:
      "Your <b>{subscriptionDisplayName}</b> will renew at <b>{nextPaymentAmount}</b> on {nextBillingDate}.",
    description: "Immediate prorated charge message",
  },
  immediateDiscountedChargeMessage: {
    id: "billing.purchaseDisclaimer.immediateDiscountedChargeMessage",
    defaultMessage:
      "Your <b>{subscriptionDisplayName}</b> will renew at <b>{nextPaymentAmount}</b> on {nextBillingDate}. After the discount period ends on {discountEndDate}, your subscription will renew at <b>{baseUnitPrice}</b>.",
    description: "Immediate prorated charge message",
  },
  futureBilledDiscountedChargeMessage: {
    id: "billing.purchaseDisclaimer.futureBilledDiscountedChargeMessage",
    defaultMessage:
      "After the discount period ends on {discountEndDate}, your <b>{subscriptionDisplayName}</b> will renew at <b>{baseUnitPrice}</b>.",
    description: "Immediate prorated charge message",
  },
  betaActivationDisclaimer: {
    id: "billing.purchaseDisclaimer.activationDisclaimer",
    defaultMessage:
      "By activating the {fullAddonName} Beta, you confirm that you have read and agree to our {termsOfServiceLink}. You understand that we may limit, suspend, or end your access to these beta services at any time, at our discretion.",
    description: "Activation disclaimer",
  },
  termsOfService: {
    id: "billing.purchaseDisclaimer.termsOfService",
    defaultMessage: "Terms of Service",
    description: "Terms of Service",
  },
  aiReceptionistFullName: {
    id: "billing.purchaseDisclaimer.aiReceptionistFullName",
    defaultMessage: "Artificial Intelligence Receptionist",
    description: "AI Receptionist full name",
  },
});
