import { gql } from "@apollo/client";

export const JOB_FOLLOW_UPS = gql`
  mutation updateJobFollowUp(
    $id: ClientNotificationId!
    $attributes: NotificationUpdateAttributes!
  ) {
    updateNotification(id: $id, attributes: $attributes) {
      clientNotification {
        id
        enabled
      }
      errors
    }
  }
`;
