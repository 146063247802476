import { defineMessages } from "react-intl";

export const messages = defineMessages({
  automatedCampaignsPromptCardTitle: {
    id: "campaigns.landingPage.automatedCampaignsPromptCardTitle",
    defaultMessage: "Set up automated Campaigns",
    description: "Title for the automated campaigns prompt card",
  },
  automatedCampaignsPromptCardDescription: {
    id: "campaigns.landingPage.automatedCampaignsPromptCardDescription",
    defaultMessage:
      "Set email campaigns up once and let them run in the background. Watch your emails bring in new business while you focus on what matters",
    description: "Description for the automated campaigns prompt card",
  },
  automatedCampaignsCompletedCount: {
    id: "campaigns.landingPage.automatedCampaignsCompletedCount",
    defaultMessage: "{count}/3 completed",
    description:
      "Text showing how many automated campaigns are completed out of 3",
  },
  automatedCampaignsDisclosureButton: {
    id: "campaigns.landingPage.automatedCampaignsDisclosureButton",
    defaultMessage: "Get Started",
    description: "Button for the automated campaigns disclosures",
  },
  automatedCampaignsReengageDisclosureTitle: {
    id: "campaigns.landingPage.automatedCampaignsReengageDisclosureTitle",
    defaultMessage: "Re-engage past clients",
    description: "Title for the automated campaigns re-engage disclosure",
  },
  automatedCampaignsReengageDisclosureDescription: {
    id: "campaigns.landingPage.automatedCampaignsReengageDisclosureDescription",
    defaultMessage:
      "Automatically send an email to clients who haven't booked a job with you in a while",
    description: "Description for the automated campaigns re-engage disclosure",
  },
  automatedCampaignsWinBackDisclosureTitle: {
    id: "campaigns.landingPage.automatedCampaignsWinBackDisclosureTitle",
    defaultMessage: "Win back lost leads",
    description: "Title for the automated campaigns win back disclosure",
  },
  automatedCampaignsWinBackDisclosureDescription: {
    id: "campaigns.landingPage.automatedCampaignsWinBackDisclosureDescription",
    defaultMessage:
      "Automatically recapture leads with archived quotes that were previously considered lost",
    description: "Description for the automated campaigns win back disclosure",
  },
  automatedCampaignsCloseOnPendingQuotesDisclosureTitle: {
    id: "campaigns.landingPage.automatedCampaignsCloseOnPendingQuotesDisclosureTitle",
    defaultMessage: "Close on pending quotes",
    description:
      "Title for the automated campaigns close on pending quotes disclosure",
  },
  automatedCampaignsCloseOnPendingQuotesDisclosureDescription: {
    id: "campaigns.landingPage.automatedCampaignsCloseOnPendingQuotesDisclosureDescription",
    defaultMessage:
      "Automatically send a final follow-up to clients with pending quotes to move forward and close the deal",
    description:
      "Description for the automated campaigns close on pending quotes disclosure",
  },
});
