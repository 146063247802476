import { defineMessages } from "react-intl";

export const messages = defineMessages({
  connectImage: {
    id: "automed_reviews.images.connect",
    defaultMessage:
      "A dashed line connecting three elements; Jobber, Google and a satisfied client leaving a five star Google review",
    description: "Alt text for image on connect step",
  },
  messagingImage: {
    id: "automated_reviews.images.messaging",
    defaultMessage:
      "A client leaving a five star Google review that states: 'Their people are always there when we need them and they do an amazing job with anything we throw at them!'",
    description: "Alt text for image on the personalize step",
  },
  requestsImage: {
    id: "automated_reviews.images.requestsImage",
    defaultMessage:
      "A client with 'Ask for review text displayed underneath their profile with a decision between answering yes or no. Yes is selected.",
    description: "Alt text for image on the complete setup step",
  },
  connectedImage: {
    id: "automated_reviews.images.connectedImage",
    defaultMessage:
      "A dashed line connecting three elements; Jobber, Google and a satisfied client leaving a five star Google review",
    description: "Alt text for image on the complete setup step",
  },
  jobTriggeredReviewImage: {
    id: "automated_reviews.images.jobTriggeredReviewImage",
    defaultMessage:
      'A SMS saying "Hi Natasha, we finished up your job and hope you\'re happy with it. Thanks for choosing ABC Landscaping!" followed by another SMS saying "Client reviews help us succeed in our community! We\'d love if you could leave us one: with this link"',
    description: "Alt text for image on the Trigger setup step",
  },
  invoiceTriggeredReviewImage: {
    id: "automated_reviews.images.invoiceTriggeredReviewImage",
    defaultMessage:
      'A SMS saying "Hi Natasha, we received $123.45 for our recent work. Thanks for choosing us!" followed by another SMS saying "Client reviews help us succeed in our community! We\'d love if you could leave us one: with this link"',
    description: "Alt text for image on the Trigger setup step",
  },
  previewTemplateInitialEmail: {
    id: "automated_reviews.images.previewTemplateInitialEmail",
    defaultMessage:
      "An example of the initial email template for the preview template step",
    description: "Alt text for image on the preview template step",
  },
  previewTemplateInitialSms: {
    id: "automated_reviews.images.previewTemplateInitialSms",
    defaultMessage:
      "An example of the initial SMS template for the preview template step",
    description: "Alt text for image on the preview template step",
  },
  previewTemplateFirstFollowUpEmail: {
    id: "automated_reviews.images.previewTemplateFollowUpEmail",
    defaultMessage:
      "An example of the follow up email template for the preview template step",
    description: "Alt text for image on the preview template step",
  },
  previewTemplateFirstFollowUpSms: {
    id: "automated_reviews.images.previewTemplateFirstFollowUpSms",
    defaultMessage:
      "An example of the first follow up SMS template for the preview template step",
    description: "Alt text for image on the preview template step",
  },
  previewTemplateSecondFollowUpEmail: {
    id: "automated_reviews.images.previewTemplateSecondFollowUpEmail",
    defaultMessage:
      "An example of the second follow up email template for the preview template step",
    description: "Alt text for image on the preview template step",
  },
  previewTemplateSecondFollowUpSms: {
    id: "automated_reviews.images.previewTemplateSecondFollowUpSms",
    defaultMessage:
      "An example of the second follow up SMS template for the preview template step",
    description: "Alt text for image on the preview template step",
  },
});
