import React from "react";
import {
  Card,
  Content,
  Glimmer,
  Heading,
  Text,
  Tooltip,
} from "@jobber/components";
import { useIntl } from "react-intl";
import styles from "./AverageCompetitorCard.module.css";
import { messages } from "../../messages";

export interface AverageCompetitorCardProps {
  loading: boolean;
  avgCompetitorRating: string;
  avgCompetitorReviews: string;
  aiInsights?: string;
}

export function AverageCompetitorCard({
  loading,
  avgCompetitorRating,
  avgCompetitorReviews,
  aiInsights,
}: AverageCompetitorCardProps) {
  const { formatMessage } = useIntl();

  return (
    <Card>
      <Content spacing="base">
        <Tooltip message={formatMessage(messages.cardHeaderTooltipMessage)}>
          <div className={styles.cardHeader}>
            <Heading level={4}>
              {formatMessage(messages.avgCompetitorRatingHeading)}
            </Heading>
          </div>
        </Tooltip>
        {loading ? (
          <>
            <Glimmer />
            <Glimmer />
          </>
        ) : (
          <>
            <div className={styles.flexRowContainer}>
              <Text>
                <Heading level={4} element="span">
                  {formatMessage(messages.avgCompetitorRatingNumber, {
                    avgCompetitorRating,
                  })}
                </Heading>
                &nbsp;{formatMessage(messages.avgCompetitorRating)}
              </Text>
              <Text>
                <Heading level={4} element="span">
                  {avgCompetitorReviews}
                </Heading>
                &nbsp;{formatMessage(messages.avgCompetitorReviews)}
              </Text>
            </div>
            {aiInsights && <Text>{aiInsights}</Text>}
          </>
        )}
      </Content>
    </Card>
  );
}
