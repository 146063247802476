/* eslint-disable max-statements */
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import React, { useState } from "react";
import { Divider } from "@jobber/components/Divider";
import { Icon } from "@jobber/components/Icon";
import { Text } from "@jobber/components/Text";
import { Emphasis } from "@jobber/components/Emphasis";
import { Banner } from "@jobber/components/Banner";
import { Disclosure } from "@jobber/components";
import { FormattedMessage, useIntl } from "react-intl";
import { showToast } from "@jobber/components/Toast";
import { LimitsAndUsage } from "jobber/managed_accounts/Settings/LimitsAndUsage/LimitsAndUsage";
import { useTranslation } from "~/utilities/contexts/internal/useTranslations";
import type {
  MutationErrors,
  PaymentOptionsCardQuery,
} from "~/utilities/API/graphql";
import {
  InvoiceOrQuote,
  JobberPaymentsDefaultPaymentPreference,
  JobberPaymentsLimitsChangeRequestLimits,
  JobberPaymentsSettingsName,
} from "~/utilities/API/graphql";
import { CardBrand } from "components/CardBrand";
import { useJobberPayments } from "~/utilities/contexts/internal/useJobberPayments";
import { SplitNames, useSplit, withSplitClient } from "utilities/split";
import styles from "./styles.module.css";
import { messages } from "./messages";
import { SettingsSwitch } from "../SettingsSwitch";
// eslint-disable-next-line import/no-internal-modules
import { DefaultACHModal } from "../modals/DefaultACHModal";
import { useUpdateDefaultPaymentPreference } from "../useUpdateDefaultPaymentPreference";
import { messages as ToastMessage } from "../messages";
import { useOnlinePaymentsDefaultUpdateSplit } from "../useOnlinePaymentsDefaultUpdateSplit";
import { ManageTransactionLimits } from "../ManageTransactionLimits";

declare const window: Window & { dataLayer: Record<string, unknown>[] };

interface PaymentOptionsCardProps {
  data: PaymentOptionsCardQuery;
  toggleSwitch: boolean;
  setUpdateDefaultPreference: React.Dispatch<
    React.SetStateAction<{
      preference: string;
      achEnabled: boolean;
    }>
  >;
}

function PaymentOptionsCard({
  data,
  toggleSwitch,
  setUpdateDefaultPreference,
}: PaymentOptionsCardProps) {
  const creditCardRates = data.jobberPaymentsFees.creditCard;
  const achFees = data.jobberPaymentsFees.ach;
  const achLimits = data.jobberPaymentsSettings.achLimits;
  const achUsage = data.jobberPaymentsSettings.achUsage || {};
  const creditCardLimits = data.jobberPaymentsSettings.creditCardLimits || {};
  const creditCardUsage = data.jobberPaymentsSettings.creditCardUsage || {};
  const achToggleEnabled = data.achEnabledSetting.enabled || false;
  const [mutationErrorMessage, setMutationErrorMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { permissions } = useJobberPayments();
  const { formatMessage } = useIntl();
  const genericError = new Error("Could not update settings");
  const { handleUpdateSettings } = useUpdateDefaultPaymentPreference();
  const [shouldShowManageACHLimits, setShouldShowManageACHLimits] =
    useState(achToggleEnabled);
  const { inOnlinePaymentsDefaultUpdateSplit } =
    useOnlinePaymentsDefaultUpdateSplit();

  const queryParameters = new URLSearchParams(window.location.search);
  const expandAchSectionOnOpen =
    queryParameters.get("ach_usage_expanded") === "true";
  const expandCreditCardSectionOnOpen =
    queryParameters.get("credit_card_usage_expanded") === "true";

  const { getTreatmentValue } = useSplit({
    names: [SplitNames.CreditCardLimitsAndUsageEnabled],
  });
  const creditCardLimitsAndUsageEnabled = getTreatmentValue(
    SplitNames.CreditCardLimitsAndUsageEnabled,
  );

  return (
    <>
      {mutationErrorMessage && (
        <Banner type="error" onDismiss={onErrorBannerDismiss}>
          {mutationErrorMessage}
        </Banner>
      )}
      <Card header={messages.paymentOptionsCardTitle.defaultMessage}>
        <Content spacing="large">
          <Content spacing="small">
            <Heading level={4}>
              {messages.cardPaymentTitle.defaultMessage}
            </Heading>
            <div className={styles.cardPaymentsTitleRow}>
              <CardBrandRow />
              {!inOnlinePaymentsDefaultUpdateSplit && <Icon name="checkmark" />}
            </div>
            <CreditCardRateContent />
          </Content>
          {permissions.canToggleAmexPayments && (
            <SettingsSwitch
              settingsName={
                JobberPaymentsSettingsName.AMERICAN_EXPRESS_PAYMENTS
              }
              title={""}
              description={messages.amexEnabledToggleTitle.defaultMessage}
              defaultEnabled={data.amexEnabledSetting.enabled || false}
              onError={handleMutationError}
            />
          )}
          {creditCardLimitsAndUsageEnabled && (
            <>
              <Divider />
              <Disclosure
                defaultOpen={expandCreditCardSectionOnOpen}
                title={
                  <Heading level={4}>
                    {formatMessage(
                      messages.creditCardUsageAndLimitsSectionTitle,
                    )}
                  </Heading>
                }
              >
                <LimitsAndUsage
                  usage={creditCardUsage}
                  limits={creditCardLimits}
                  testId="credit-card-usage-and-limits"
                />
                {permissions.canRequestCreditCardLimitIncrease && (
                  <ManageTransactionLimits
                    limitsType={
                      JobberPaymentsLimitsChangeRequestLimits.CREDIT_CARD
                    }
                    tier={creditCardLimits?.tier}
                    canRequestLimitIncrease={true}
                  />
                )}
              </Disclosure>
            </>
          )}
        </Content>
        {permissions.canToggleAchPayments && (
          <>
            <Divider />
            <Content>
              <Content spacing="small">
                {inOnlinePaymentsDefaultUpdateSplit ? (
                  <Heading level={4}>
                    {messages.achPaymentsSectionTitle.defaultMessage}
                  </Heading>
                ) : (
                  <SettingsSwitch
                    settingsName={JobberPaymentsSettingsName.ALLOW_ACH_PAYMENTS}
                    title={messages.achPaymentsSectionTitle.defaultMessage}
                    defaultEnabled={achToggleEnabled}
                    onError={handleMutationError}
                    onSuccessCallbackIsEnabled={onEnableAch}
                    toggleSwitch={toggleSwitch}
                  />
                )}
                <AchRateContent />
              </Content>
              <Divider />
              <Disclosure
                defaultOpen={expandAchSectionOnOpen}
                title={
                  <Heading level={4}>
                    {formatMessage(messages.achUsageAndLimitsSectionTitle)}
                  </Heading>
                }
              >
                <LimitsAndUsage
                  usage={achUsage}
                  limits={achLimits}
                  testId="ach-usage-and-limits"
                />
                <Divider />
                {(shouldShowManageACHLimits ||
                  inOnlinePaymentsDefaultUpdateSplit) && (
                  <ManageTransactionLimits
                    limitsType={JobberPaymentsLimitsChangeRequestLimits.ACH}
                    tier={achLimits?.tier}
                    canRequestLimitIncrease={
                      permissions.canRequestAchLimitIncrease
                    }
                  />
                )}
              </Disclosure>
            </Content>
          </>
        )}
      </Card>
      <DefaultACHModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(!isModalOpen)}
        onError={handleMutationError}
        setUpdateDefaultPreference={setUpdateDefaultPreference}
      />
    </>
  );

  function CardBrandRow() {
    const cardBrands = ["visa", "mastercard", "amex", "applePay", "googlePay"];
    return (
      <div className={styles.cardBrandRow}>
        {cardBrands.map(brand => (
          <CardBrand key={brand} brand={brand} size="small" />
        ))}
      </div>
    );
  }

  function CreditCardRateContent() {
    const [t] = useTranslation();
    const centCurrency = t("centCurrency");
    return (
      <div className={styles.infoRow}>
        <Text>
          <Emphasis variation="bold">
            {messages.feeRateTag.defaultMessage}
          </Emphasis>
          <FormattedMessage
            {...messages.creditCardFeeRateDetail}
            values={{
              percent: creditCardRates?.percent,
              centsPerCharge: creditCardRates?.centsPerCharge,
              centCurrency,
            }}
          />
        </Text>
      </div>
    );
  }

  function AchRateContent() {
    return (
      <Content spacing="large">
        <Content spacing="small">
          <div className={styles.infoRow}>
            <Text>
              <Emphasis variation="bold">
                {messages.feeRateTag.defaultMessage}
              </Emphasis>
              <FormattedMessage
                {...messages.achFeeRateDetail}
                values={{
                  percent: achFees?.percent,
                }}
              />
            </Text>
          </div>
        </Content>
      </Content>
    );
  }

  function handleMutationError(mutationError: Error) {
    setMutationErrorMessage((mutationError || genericError).message);
  }

  function onErrorBannerDismiss() {
    setMutationErrorMessage("");
  }

  async function onEnableAch(enabled: boolean) {
    if (enabled) {
      setShouldShowManageACHLimits(true);
      sendGoogleTagManagerEvent(true);
      setIsModalOpen(true);
    } else {
      sendGoogleTagManagerEvent(false);
      setShouldShowManageACHLimits(false);
      try {
        const { data: preferenceData } = await handleUpdateSettings({
          objectType: InvoiceOrQuote.INVOICE_AND_QUOTE,
          preference: JobberPaymentsDefaultPaymentPreference.CREDIT_CARD,
        });
        const errors = preferenceData
          ?.jobberPaymentsUpdateDefaultPaymentPreference
          ?.userErrors as MutationErrors[];

        if (!preferenceData) {
          handleMutationError(genericError);
        }
        if (
          errors &&
          errors.length > 0 &&
          preferenceData?.jobberPaymentsUpdateDefaultPaymentPreference
            .success === false
        ) {
          handleMutationError(new Error(errors[0].message) || genericError);
        } else {
          setUpdateDefaultPreference({
            preference: JobberPaymentsDefaultPaymentPreference.CREDIT_CARD,
            achEnabled: false,
          });
          showToast({
            message: formatMessage(
              ToastMessage.paymentOptionChangeToastMessage,
            ),
          });
        }
      } catch (error) {
        handleMutationError(error as Error);
      }
    }
  }

  function sendGoogleTagManagerEvent(enabled: boolean) {
    const eventName = enabled ? "enable_ach_payment" : "disable_ach_payment";
    window.dataLayer?.push({
      event: eventName,
    });
  }
}

const SplitWrappedComponent = withSplitClient(PaymentOptionsCard);
export { SplitWrappedComponent as PaymentOptionsCard };
