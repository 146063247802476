import React from "react";

export interface JobberPaymentsContextInitType {
  enabled: boolean;
  stripeKey: string;
  permissions: {
    canSetup: boolean;
    canChangeBankAccount: boolean;
    canWriteSettings: boolean;
    canEnablePayments: boolean;
    canDisablePayments: boolean;
    canToggleAmexPayments: boolean;
    canViewInstantPayouts: boolean;
    canToggleAchPayments: boolean;
    canToggleTips: boolean;
    canRequestAchLimitIncrease: boolean;
    canRequestCreditCardLimitIncrease: boolean;
  };
  tipsEnabled: boolean;
  payoutScheduleInDays: number;
  instantPayoutOverrideEnabled: boolean;
}
export type JobberPaymentsContextType = JobberPaymentsContextInitType & {
  setEnabled(newEnabled: boolean): void;
};

export const JobberPaymentsContext =
  React.createContext<JobberPaymentsContextType>({
    instantPayoutOverrideEnabled: false,
    payoutScheduleInDays: 0,
    enabled: false,
    stripeKey: "",
    permissions: {
      canSetup: false,
      canChangeBankAccount: false,
      canWriteSettings: false,
      canEnablePayments: false,
      canDisablePayments: false,
      canToggleAmexPayments: false,
      canViewInstantPayouts: false,
      canToggleAchPayments: false,
      canToggleTips: false,
      canRequestAchLimitIncrease: false,
      canRequestCreditCardLimitIncrease: false,
    },
    tipsEnabled: false,
    setEnabled: () => undefined,
  });
JobberPaymentsContext.displayName = "JobberPaymentsContext";
