import { Tooltip } from "@jobber/components";
import styles from "./UnderlinedText.module.css";

interface UnderlinedTextProps {
  text: string;
  tooltipText: string;
}

export const UnderlinedText = ({ text, tooltipText }: UnderlinedTextProps) => {
  return (
    <Tooltip message={tooltipText}>
      <span className={styles.underlinedText}>{text}</span>
    </Tooltip>
  );
};
