import { BuildingDashboard } from "./components/BuildingDashboard";
import { Routes, type SetupGuideStep } from "./types";
import { ConnectStep } from "./components/ConnectStep/ConnectStep";
import { PersonalizeStep } from "./components/PersonalizeStep/PersonalizeStep";
import { CompleteSetup } from "./components/CompleteSetup";
import { ConnectedStep } from "./components/ConnectedStep/ConnectedStep";
import { ProfileStep } from "./components/ProfileStep";
import { PreviewTemplateStep } from "./components/PreviewTemplateStep/PreviewTemplateStep";
import { TriggerStep } from "./components/TriggerStep/TriggerStep";

export const defaultSteps = (hasMultiTrigger?: boolean): SetupGuideStep[] => [
  {
    route: Routes.connect,
    showBackButton: false,
    includeInProgress: false,
    component: ConnectStep,
  },
  {
    route: Routes.connected,
    showBackButton: false,
    includeInProgress: false,
    component: ConnectedStep,
  },
  {
    route: Routes.profile,
    showBackButton: true,
    includeInProgress: true,
    component: ProfileStep,
  },
  {
    route: hasMultiTrigger ? Routes.trigger : Routes.personalize,
    showBackButton: true,
    includeInProgress: true,
    component: hasMultiTrigger ? TriggerStep : PersonalizeStep,
  },
  {
    route: Routes.previewTemplates,
    showBackButton: true,
    includeInProgress: true,
    component: PreviewTemplateStep,
  },
  {
    route: Routes.completeSetup,
    showBackButton: true,
    includeInProgress: true,
    component: CompleteSetup,
  },
  {
    route: Routes.buildingDashboard,
    showBackButton: true,
    includeInProgress: true,
    component: BuildingDashboard,
  },
];
