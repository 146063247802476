import { gql } from "@apollo/client";
import { ctaComponentData } from "~/jobber/settings/users/components/CallToAction/CallToAction.graphql";

export const EDUCATION_CARDS_QUERY = gql`
  query EducationCardsLookup {
    automatedCampaignsCta: callToAction(
      id: "campaigns_education_card_automated_campaigns"
    ) {
      ...ctaComponentData
    }
    educationVideoCta: callToAction(
      id: "campaigns_education_card_how_campaigns_work"
    ) {
      ...ctaComponentData
    }
    exploreTemplatesCta: callToAction(
      id: "campaigns_education_card_explore_templates"
    ) {
      ...ctaComponentData
    }
  }

  ${ctaComponentData}
`;
