import { defineMessages } from "react-intl";

export const messages = defineMessages({
  newFeatureLabel: {
    id: "ConnectToGrowTrial.UpgradeWaveFeatureCarousel.label",
    defaultMessage: "New Feature",
  },
  tryGrowNow: {
    id: "ConnectToGrowTrial.UpgradeWaveFeatureCarousel.tryGrowNow",
    defaultMessage:
      "Try the Grow plan for 14-days, risk-free. You won't be charged when the trial ends.",
  },
  advancedQuotingTitle: {
    id: "ConnectToGrowTrial.UpgradeWaveFeatureCarousel.advancedQuotingTitle",
    defaultMessage: "Impress clients and win more work",
  },
  advancedQuotingDescription: {
    id: "ConnectToGrowTrial.UpgradeWaveFeatureCarousel.advancedQuotingDescription",
    defaultMessage:
      "Create standout quotes with images, client reviews, and personalized details to leave a lasting impression on your clients.",
  },
  customAutomationsBuilderTitle: {
    id: "ConnectToGrowTrial.UpgradeWaveFeatureCarousel.customAutomationsBuilderTitle",
    defaultMessage: "Automate your tasks and save more time",
  },
  customAutomationsBuilderDescription: {
    id: "ConnectToGrowTrial.UpgradeWaveFeatureCarousel.customAutomationsBuilderDescription",
    defaultMessage:
      "Eliminate repetitive work with custom automations by setting up conditions to keep your business running smoothly.",
  },
  jobCostingTitle: {
    id: "ConnectToGrowTrial.UpgradeWaveFeatureCarousel.jobCostingTitle",
    defaultMessage: "Be confident that every job is profitable",
  },
  jobCostingDescription: {
    id: "ConnectToGrowTrial.UpgradeWaveFeatureCarousel.jobCostingDescription1",
    defaultMessage:
      "With job costing, you can see profitability for every job based on the line items, labor, and expenses you've tracked.",
  },
  locationTimersTitle: {
    id: "ConnectToGrowTrial.UpgradeWaveFeatureCarousel.locationTimersTitle",
    defaultMessage: "Keep track of visits with location timers",
  },
  locationTimersDescription: {
    id: "ConnectToGrowTrial.UpgradeWaveFeatureCarousel.locationTimersDescription",
    defaultMessage:
      "Maintain timesheets by automatically tracking your team's visit time when they're near a client's property.",
  },
  oliTitle: {
    id: "ConnectToGrowTrial.UpgradeWaveFeatureCarousel.oliTitle",
    defaultMessage: "Sell more with optional line items",
  },
  oliDescription: {
    id: "ConnectToGrowTrial.UpgradeWaveFeatureCarousel.oliDescription",
    defaultMessage:
      "Suggest premium packages or add-ons right in the quote. Customers can choose the services they want and see the update instantly without back-and-forth changes.",
  },
  automatedQuoteFollowUpsTitle: {
    id: "ConnectToGrowTrial.UpgradeWaveFeatureCarousel.automatedQuoteFollowUpsTitle",
    defaultMessage: "Automate your quote follow-ups",
  },
  automatedQuoteFollowUpsDescription: {
    id: "ConnectToGrowTrial.UpgradeWaveFeatureCarousel.automatedQuoteFollowUpsDescription",
    defaultMessage:
      "Let automated follow-ups do the work for you. Jobber sends a reminder to customers to make sure you don't miss out on potential jobs.",
  },
  connectLabel: {
    id: "CoreToConnect.UpgradeWaveFeatureCarousel.label",
    defaultMessage: "Connect Plan Trial",
  },
  tryConnectNow: {
    id: "CoreToConnect.UpgradeWaveFeatureCarousel.tryConnectNow",
    defaultMessage:
      "Try it out for free with a 14-day Connect plan trial. Don't worry, you won't be charged when the trial ends.",
  },
  automatedMessagingTitle: {
    id: "CoreToConnect.UpgradeWaveFeatureCarousel.automatedMessagingTitle",
    defaultMessage: "Automated client notifications",
  },
  automatedMessagingDescription: {
    id: "CoreToConnect.UpgradeWaveFeatureCarousel.automatedMessagingDescription",
    defaultMessage:
      "Keep clients in the loop—before, during, and after each job. Easily customize appointment reminders or on-my-way text messages all on the Connect plan.",
  },
  autopayTitle: {
    id: "CoreToConnect.UpgradeWaveFeatureCarousel.autopayTitle",
    defaultMessage: "Automatic payments",
  },
  autopayDescription: {
    id: "CoreToConnect.UpgradeWaveFeatureCarousel.autopayDescription",
    defaultMessage:
      "Get paid effortlessly for recurring work by charging your client's saved card.",
  },
  timeTrackingTitle: {
    id: "CoreToConnect.UpgradeWaveFeatureCarousel.timeTrackingTitle",
    defaultMessage: "Time and expense tracking",
  },
  timeTrackingDescription: {
    id: "CoreToConnect.UpgradeWaveFeatureCarousel.timeTrackingDescription",
    defaultMessage:
      "Your team can clock in and out of jobs and track expenses from anywhere, so you can prep payroll faster.",
  },
  customFieldTitle: {
    id: "CoreToConnect.UpgradeWaveFeatureCarousel.customFieldTitle",
    defaultMessage: "Custom fields",
  },
  customFieldDescription: {
    id: "CoreToConnect.UpgradeWaveFeatureCarousel.customFieldDescription",
    defaultMessage:
      "Track the details that matter most and easily filter or sort reports using your custom fields.",
  },
});
