import React from "react";
import { Content } from "@jobber/components/Content";
import { useIntl } from "react-intl";
import { Tab, Tabs } from "@jobber/components";
import { messages } from "./messages";
import styles from "./FollowUpTabs.module.css";
import {
  PreviewTemplateFirstFollowUpEmail,
  PreviewTemplateFirstFollowUpSms,
  PreviewTemplateInitialEmail,
  PreviewTemplateInitialSms,
  PreviewTemplateSecondFollowUpEmail,
  PreviewTemplateSecondFollowUpSms,
} from "../../../../images";

export interface FollowUpTabsProps {
  selected: string;
}

const emailImages = [
  {
    src: PreviewTemplateInitialEmail.src,
    alt: PreviewTemplateInitialEmail.alt,
  },
  {
    src: PreviewTemplateFirstFollowUpEmail.src,
    alt: PreviewTemplateFirstFollowUpEmail.alt,
  },
  {
    src: PreviewTemplateSecondFollowUpEmail.src,
    alt: PreviewTemplateSecondFollowUpEmail.alt,
  },
];

const smsImages = [
  {
    src: PreviewTemplateInitialSms.src,
    alt: PreviewTemplateInitialSms.alt,
  },
  {
    src: PreviewTemplateFirstFollowUpSms.src,
    alt: PreviewTemplateFirstFollowUpSms.alt,
  },
  {
    src: PreviewTemplateSecondFollowUpSms.src,
    alt: PreviewTemplateSecondFollowUpSms.alt,
  },
];

export function FollowUpTabs({ selected }: FollowUpTabsProps): JSX.Element {
  const { formatMessage } = useIntl();

  const selectedIndex = parseInt(selected, 10);

  const emailImage = emailImages[selectedIndex];
  const smsImage = smsImages[selectedIndex];

  return (
    <Content>
      <Tabs>
        <Tab label={formatMessage(messages.emailTab)}>
          <div className={styles.imageContainer}>
            <img src={emailImage.src} alt={formatMessage(emailImage.alt)} />
          </div>
        </Tab>
        <Tab label={formatMessage(messages.smsTab)}>
          <div className={styles.imageContainer}>
            <img src={smsImage.src} alt={formatMessage(smsImage.alt)} />
          </div>
        </Tab>
      </Tabs>
    </Content>
  );
}
