import React, { useRef, useState } from "react";
import type { IntlFormatters } from "react-intl";
import {
  Content,
  Heading,
  Icon,
  InlineLabel,
  Popover,
  Text,
} from "@jobber/components";
import { ReviewKPICard } from "jobber/reviews/views/ReviewsPage/components/ReviewKPICard/ReviewKPICard";
import { StarColour } from "jobber/reviews/views/ReviewsPage/components/StarGroup/constants";
import { messages } from "../messages";
import styles from "../ReviewsPage.module.css";

interface ReviewMetricsCardProps {
  averageRating?: number;
  formatMessage: IntlFormatters["formatMessage"];
  isLoading: boolean;
  previousAverageRating: number;
  previousTotalReviewCount: number;
  totalReviewCount?: number;
}

export function ReviewMetricsCard({
  averageRating,
  formatMessage,
  isLoading,
  previousAverageRating,
  previousTotalReviewCount,
  totalReviewCount,
}: ReviewMetricsCardProps): JSX.Element {
  return (
    <ReviewKPICard
      header={formatMessage(messages.reviewsHeader)}
      isLoading={isLoading}
      KPIValues={[
        {
          amount: (
            <div className={styles.KPICardRow}>
              <Heading level={2}>{(averageRating ?? 0).toFixed(1)}</Heading>
              <Icon
                name="starFill"
                customColor={StarColour.Orange}
                size="small"
              />
              {averageRating && (
                <TrendChip
                  previousAmount={previousAverageRating}
                  currentAmount={averageRating}
                  tooltipText={formatMessage(messages.reviewsAverageTooltip)}
                  fixedDecimalFormat={true}
                />
              )}
            </div>
          ),
          description: formatMessage(messages.previousDescription, {
            amount: previousAverageRating,
          }),
        },
        {
          amount: (
            <div className={styles.KPICardRow}>
              <Heading level={2}>{totalReviewCount || 0}</Heading>
              {totalReviewCount && (
                <TrendChip
                  previousAmount={previousTotalReviewCount}
                  currentAmount={totalReviewCount}
                  tooltipText={formatMessage(messages.reviewsTotalTooltip)}
                />
              )}
            </div>
          ),
          description: formatMessage(messages.previousDescription, {
            amount: previousTotalReviewCount,
          }),
        },
      ]}
      subheader={formatMessage(messages.reviewsTotalSubheader)}
    />
  );
}

interface TrendChipProps {
  currentAmount: number;
  fixedDecimalFormat?: boolean;
  previousAmount: number;
  tooltipText: string;
}

function TrendChip({
  currentAmount,
  fixedDecimalFormat = false,
  previousAmount,
  tooltipText,
}: TrendChipProps): JSX.Element {
  const [showPopover, setShowPopover] = useState(false);
  const labelRef = useRef<HTMLSpanElement>(null);

  if (previousAmount === currentAmount || currentAmount < previousAmount) {
    return <></>;
  }

  const value = currentAmount - previousAmount;
  const trendText = `↑  ${fixedDecimalFormat ? value.toFixed(1) : value}`;

  return (
    <>
      <span
        ref={labelRef}
        onMouseEnter={() => setShowPopover(true)}
        onMouseLeave={() => setShowPopover(false)}
      >
        <InlineLabel color="green">{trendText}</InlineLabel>
      </span>
      <Popover
        attachTo={labelRef}
        open={showPopover}
        preferredPlacement="top"
        UNSAFE_style={{ dismissButtonContainer: { display: "none" } }}
      >
        <Content spacing="smallest">
          <Text size="small">{tooltipText}</Text>
        </Content>
      </Popover>
    </>
  );
}
