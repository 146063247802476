import { defineMessages } from "react-intl";

export const messages = defineMessages({
  achOfflinePLEBenefitsPaymentTitle: {
    id: "achOfflinePLE.benefits.payment.title",
    defaultMessage: "Quick online payments",
    description: "Title for the ACH Offline Payment Example Modal",
  },
  achOfflinePLEBenefitsPaymentDescription: {
    id: "achOfflinePLE.benefits.payment.description",
    defaultMessage:
      "Clients can pay online with ease by connecting their bank or manually entering their details for a hassle-free experience.",
    description: "Description for the ACH Offline Payment Example Modal",
  },
  achOfflinePLEBenefitsAllInOneTitle: {
    id: "achOfflinePLE.benefits.allInOne.title",
    defaultMessage: "All-in-one management",
    description: "Title for the ACH Offline Payment Example Modal",
  },
  achOfflinePLEBenefitsAllInOneDescription: {
    id: "achOfflinePLE.benefits.allInOne.description",
    defaultMessage:
      "Reduce admin time and errors by managing payments and invoicing together in one centralized platform.",
    description: "Description for the ACH Offline Payment Example Modal",
  },
  achOfflinePLEBenefitsEffortlessAccountingTitle: {
    id: "achOfflinePLE.benefits.effortlessAccounting.title",
    defaultMessage: "Effortless accounting",
    description: "Title for the ACH Offline Payment Example Modal",
  },
  achOfflinePLEBenefitsEffortlessAccountingDescription: {
    id: "achOfflinePLE.benefits.effortlessAccounting.description",
    defaultMessage:
      "Automatically sync payments with QuickBooks Online or Xero to keep your records up-to-date and reduce manual work.",
    description: "Description for the ACH Offline Payment Example Modal",
  },
  achOfflinePLEModalTitle: {
    id: "achOfflinePLE.modal.title",
    defaultMessage: "Simplify ACH bank payments",
    description: "Title for the ACH Offline Payment Example Modal",
  },
  achOfflinePLEModalPrimaryButtonLabel: {
    id: "achOfflinePLE.modal.primaryButton.label",
    defaultMessage: "Enable Jobber Payments",
    description:
      "Label for the primary button in the ACH Offline Payment Example Modal",
  },
  achOfflinePLEModalSecondaryButtonLabel: {
    id: "achOfflinePLE.modal.secondaryButton.label",
    defaultMessage: "I'm not interested",
    description:
      "Label for the secondary button in the ACH Offline Payment Example Modal",
  },
  achOfflinePLEModalCreditCardOnlyPrimaryButtonLabel: {
    id: "achOfflinePLE.modal.creditCardOnly.primaryButton.label",
    defaultMessage: "Enable ACH bank payments",
    description:
      "Label for the primary button in the credit card only view in the ACH Offline Payment Example Modal",
  },
  achOfflinePLEModalNotInterestedTitle: {
    id: "achOfflinePLE.modal.notInterested.title",
    defaultMessage: "Not interested in ACH bank payments with Jobber?",
    description:
      "Title for the not interested view in the ACH Offline Payment Example Modal",
  },
  achOfflinePLEModalNotInterestedPrimaryButtonLabel: {
    id: "achOfflinePLE.modal.notInterested.primaryButton.label",
    defaultMessage: "Submit",
    description:
      "Label for the primary button in the not interested view in the ACH Offline Payment Example Modal",
  },
  achOfflinePLEModalJPayDisabledPrimaryButtonLabel: {
    id: "achOfflinePLE.modal.jPayDisabled.primaryButton.label",
    defaultMessage: "Re-enable Jobber Payments",
    description:
      "Label for the primary button in the JPay disabled view in the ACH Offline Payment Example Modal",
  },
  achOfflinePLEModalSetPreferencesPrimaryButtonLabel: {
    id: "achOfflinePLE.modal.setPreferences.primaryButton.label",
    defaultMessage: "Set preferences",
    description:
      "Label for the primary button in the set preferences view in the ACH Offline Payment Example Modal",
  },
  achOfflinePLEModalGotItPrimaryButtonLabel: {
    id: "achOfflinePLE.modal.gotIt.primaryButton.label",
    defaultMessage: "Got it",
    description:
      "Label for the primary button in the got it view in the ACH Offline Payment Example Modal",
  },
  failedToSavePreferencesError: {
    id: "jobber.invoices.components.AchOfflinePLEModal.failedToSavePreferencesError",
    defaultMessage: "Failed to save preferences. Please try again.",
    description: "Error message shown when saving payment preferences fails",
  },
  failedToEnableJobberPaymentsError: {
    id: "achOfflinePLEModal.error.failedToEnableJobberPaymentsError",
    defaultMessage: "Failed to enable Jobber Payments. Please try again.",
    description: "Error message shown when enabling Jobber Payments fails",
  },
  defaultMutationErrorMessage: {
    id: "achOfflinePLEModal.paymentPreferencesSettings.mutationErrorMessage",
    defaultMessage: "Cannot retrieve Jobber Payments settings",
    description: "error message for generic mutation error",
  },
  paymentPreferencesUpdated: {
    id: "achOfflinePLE.success.paymentPreferencesUpdated",
    defaultMessage: "Your default payment options have been updated",
    description: "Success message shown when payment preferences are updated",
  },
  surveySubmitted: {
    id: "achOfflinePLE.success.surveySubmitted",
    defaultMessage: "Thank you for your feedback",
    description: "Success message shown when survey is submitted",
  },
  jPayEnabledAndPreferencesUpdated: {
    id: "achOfflinePLE.success.jPayEnabledAndPreferencesUpdated",
    defaultMessage:
      "Jobber Payments has been turned ON and your default payment options have been updated",
    description:
      "Success message shown when Jobber Payments is enabled and preferences are updated",
  },
});
