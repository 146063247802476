import GrowTrialLocationTimers from "@images/GrowTrialLocationTimers.png";
import GrowTrialJobCosting from "@images/GrowTrialJobCosting.png";
import GrowTrialAdvancedQuoting from "@images/GrowTrialAdvancedQuoting.png";
import GrowTrialCustomAutomationsBuilder from "@images/GrowTrialCustomAutomationsBuilder.png";
import ConnectAutomatedMessaging from "@images/selfServePerUserPricingExpansion/ConnectAutomatedMessaging.png";
import ConnectAutopay from "@images/selfServePerUserPricingExpansion/ConnectAutopay.png";
import ConnectCustomField from "@images/selfServePerUserPricingExpansion/ConnectCustomField.png";
import ConnectTimeTracking from "@images/selfServePerUserPricingExpansion/ConnectTimeTracking.png";
import type { messages } from "./messages";
import { FeatureCarouselPlanEnum } from "../FeatureCarousel/featureCarouselPlans";

interface FeatureCarouselType {
  title: keyof typeof messages;
  description: (keyof typeof messages)[];
  imgSrc: string;
  showLabel?: boolean;
}

const growFeatures: FeatureCarouselType[] = [
  {
    title: "advancedQuotingTitle",
    description: ["advancedQuotingDescription", "tryGrowNow"],
    imgSrc: GrowTrialAdvancedQuoting as string,
    showLabel: true,
  },
  {
    title: "customAutomationsBuilderTitle",
    description: ["customAutomationsBuilderDescription", "tryGrowNow"],
    imgSrc: GrowTrialCustomAutomationsBuilder as string,
    showLabel: true,
  },
  {
    title: "jobCostingTitle",
    description: ["jobCostingDescription", "tryGrowNow"],
    imgSrc: GrowTrialJobCosting as string,
    showLabel: false,
  },
  {
    title: "locationTimersTitle",
    description: ["locationTimersDescription", "tryGrowNow"],
    imgSrc: GrowTrialLocationTimers as string,
    showLabel: false,
  },
];

const connectFeatures: FeatureCarouselType[] = [
  {
    title: "automatedMessagingTitle",
    description: ["automatedMessagingDescription", "tryConnectNow"],
    imgSrc: ConnectAutomatedMessaging as string,
    showLabel: true,
  },
  {
    title: "autopayTitle",
    description: ["autopayDescription", "tryConnectNow"],
    imgSrc: ConnectAutopay as string,
    showLabel: true,
  },
  {
    title: "timeTrackingTitle",
    description: ["timeTrackingDescription", "tryConnectNow"],
    imgSrc: ConnectTimeTracking as string,
    showLabel: true,
  },
  {
    title: "customFieldTitle",
    description: ["customFieldDescription", "tryConnectNow"],
    imgSrc: ConnectCustomField as string,
    showLabel: true,
  },
];

export const UpgradeWaveCarouselPlans: {
  [key in FeatureCarouselPlanEnum]: {
    features: FeatureCarouselType[];
    label: keyof typeof messages;
  };
} = {
  [FeatureCarouselPlanEnum.Connect]: {
    features: connectFeatures,
    label: "connectLabel",
  },
  [FeatureCarouselPlanEnum.Grow]: {
    features: growFeatures,
    label: "newFeatureLabel",
  },
};
