import { useQuery } from "@apollo/client";
import type { GetCurrentUserQuery } from "~/utilities/API/graphql";
import { GET_CURRENT_USER_QUERY } from "./OnlinePaymentsToggle.graphql";

export function useOnlinePaymentsQueries() {
  const { data: userData, loading: userQueryLoading } =
    useQuery<GetCurrentUserQuery>(GET_CURRENT_USER_QUERY);

  return {
    userData,
    loading: userQueryLoading,
  };
}
