import React, { useEffect } from "react";
import { IntlProvider } from "@translations/IntlProvider";
import { APIProvider } from "~/utilities/API/APIProvider";
import { useStatusOptions } from "jobber/reviews/views/ReviewsPage/hooks/useStatusOptions";
import { useFilterState } from "jobber/reviews/views/ReviewsPage/hooks/useFilterState";
import { useReviewsQuery } from "jobber/reviews/views/ReviewsPage/hooks/useReviewsQuery";
import { ReviewsSettingsDrawerContextProvider } from "jobber/reviews/views/ReviewsPage/context/ReviewsSettingsDrawerContext";
import { viewedPageAmplitudeEvent } from "jobber/reviews/amplitude/events";
import { SplitNames, useFeatureFlag, withSplitClient } from "utilities/split";
import { ReviewQueryParams } from "./types";
import { ReviewsPage } from "./ReviewsPage";

export function ReviewsPageLoader() {
  return (
    <IntlProvider>
      <APIProvider>
        <ReviewsSettingsDrawerContextProvider>
          <ReviewsPageLoaderInternal />
        </ReviewsSettingsDrawerContextProvider>
      </APIProvider>
    </IntlProvider>
  );
}

const ReviewsPageLoaderInternal = withSplitClient(() => {
  const filterQueryParam = new URLSearchParams(location.search).get(
    ReviewQueryParams.filter,
  );
  const { defaultStatus } = useStatusOptions(filterQueryParam);
  const {
    filterState: { status },
    setStatus,
    filtered,
  } = useFilterState({ defaultStatus });
  const { data, loading, error } = useReviewsQuery({
    statusFilter: status[0].id,
  });

  useEffect(() => {
    viewedPageAmplitudeEvent({
      name: "reviews_dashboard",
    });
  }, []);

  const isFeatureFlagEnabled = useFeatureFlag(
    SplitNames.ReviewsBenchmarkFeature,
  );

  return (
    <ReviewsPage
      data={data}
      loading={loading}
      error={error}
      setStatus={setStatus}
      filtered={filtered}
      status={status}
      defaultStatus={defaultStatus}
      isFeatureFlagEnabled={isFeatureFlagEnabled}
    />
  );
});
