import React from "react";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { Box } from "@jobber/components/Box";
import { Button } from "@jobber/components/Button";
import { messages } from "./messages";
import image from "./Visual.png";
import styles from "./GpsTrackingSignupOrConnectModal.module.css";

interface NewScheduleInfoContentProps {
  onContinue: () => void;
  onCancel: () => void;
  app: { id: string; name: string };
}

export function NewScheduleInfoContent({
  onContinue,
  onCancel,
  app,
}: NewScheduleInfoContentProps) {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.gpsTrackingModalLayout}>
      <div className={styles.gpsTrackingModalContentContainer}>
        <Box gap="large">
          <Box gap="base">
            <Heading level={2} element="span">
              {formatMessage(messages.newScheduleInfoHeading)}
            </Heading>
            <Text>
              {formatMessage(messages.newScheduleInfoText1, {
                appName: app.name,
              })}
            </Text>
            <Text>{formatMessage(messages.newScheduleInfoText2)}</Text>
          </Box>
          <Box alignItems="flex-start" gap="small" direction="row">
            <Button label="Got it, Continue" onClick={onContinue} />
            <Button label="Cancel" variation="subtle" onClick={onCancel} />
          </Box>
        </Box>
      </div>
      <div className={styles.imageContainer}>
        <img
          src={image}
          alt={formatMessage(messages.newScheduleInfoImageAlt)}
        />
      </div>
    </div>
  );
}
