import { defineMessages } from "react-intl";

export const messages = defineMessages({
  campaignImageAlt: {
    id: "campaigns.landingPage.campaignImageAlt",
    defaultMessage: "An assortment of campaign views",
    description: "Alt text for the campaign image",
  },
  dismiss: {
    id: "campaigns.landingPage.dismiss",
    defaultMessage: "Dismiss",
    description: "Aria label for the dismiss button",
  },
  educationVideoCardHeading: {
    id: "campaigns.landingPage.educationVideoCardHeading",
    defaultMessage: "See how Campaigns work",
    description: "Heading for the education video card",
  },
  educationVideoCardText: {
    id: "campaigns.landingPage.educationVideoCardText",
    defaultMessage:
      "Watch a tutorial on everything Campaigns can do for your business",
    description: "Text for the education video card",
  },
  educationVideoCardiFrameTitle: {
    id: "campaigns.landingPage.educationVideoCardiFrameTitle",
    defaultMessage: "How Campaigns Work Video",
    description: "Title for the education video card iframe",
  },
  educationVideoCardError: {
    id: "campaigns.landingPage.educationVideoCardError",
    defaultMessage: "Failed to load video. Please try again later.",
    description: "Error message shown when video fails to load",
  },
  educationVideoCardWatchDemoButton: {
    id: "campaigns.landingPage.educationVideoCardWatchDemoButton",
    defaultMessage: "Watch Demo",
    description: "Label for the education video card watch demo button",
  },
  educationVideoCardLearnMoreButton: {
    id: "campaigns.landingPage.educationVideoCardLearnMoreButton",
    defaultMessage: "Learn More",
    description: "Label for the education video card learn more button",
  },
  exploreTemplatesHeading: {
    id: "campaigns.landingPage.exploreTemplatesHeading",
    defaultMessage: "Create Campaigns in no time",
    description: "Heading for the explore templates card",
  },
  exploreTemplatesCardText: {
    id: "campaigns.landingPage.exploreTemplatesCardText",
    defaultMessage:
      "Start with prebuilt templates that include your own branding",
    description: "Text description for the campaigns card",
  },
  exploreTemplatesButton: {
    id: "campaigns.landingPage.exploreTemplatesButton",
    defaultMessage: "Explore Templates",
    description: "Label for the explore templates button",
  },
});
