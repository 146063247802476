import { defineMessages } from "react-intl";

export const messages = defineMessages({
  add: {
    id: "billing.subscriptionAddonCard.add",
    defaultMessage: "Add",
    description: "Add to plan button text",
  },
  addAriaLabel: {
    id: "billing.subscriptionAddonCard.addAriaLabel",
    defaultMessage: "Add {addonName}",
    description: "Aria label for add to plan button",
  },
  betaPillText: {
    id: "billing.subscriptionAddonCard.betaPillText",
    defaultMessage: "Beta",
    description: "Beta text for pill",
  },
  aiReceptionistTooltip: {
    id: "billing.subscriptionAddonCard.aiReceptionistTooltip",
    defaultMessage:
      "AI Receptionist is rapidly improving with increased call volume and user feedback. Monitor call recordings in Jobber, and follow-up with customers in the case of occasional glitches.",
    description: "AI receptionist addon tooltip",
  },
});
