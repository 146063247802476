import { defineMessages } from "react-intl";

export const messages = defineMessages({
  modalTitle: {
    id: "downgradePage.downgradeContactModal.modalTitle",
    defaultMessage: "Connect with us to downgrade",
    description: "Modal Title",
  },
  modalContent: {
    id: "downgradePage.downgradeContactModal.modalContent",
    defaultMessage: `{isPlusAccount, select,
      true {Our support team is here to help you switch to the right plan. Give us a call at {phoneNumber} or start a chat with us now.}
      other {Connect to a member on our support team to help process your downgrade. Call us at {phoneNumber} or chat with us.}
    }`,
    description: "Modal Content",
  },
  ctaLabel: {
    id: "downgradePage.downgradeContactModal.ctaLabel",
    defaultMessage: "Chat with us",
    description: "CTA Label",
  },
  cancelLabel: {
    id: "downgradePage.downgradeContactModal.cancelLabel",
    defaultMessage: "Cancel",
    description: "Cancel Label",
  },
  downgradePhoneNumber: {
    id: "downgradePage.downgradeContactModal.downgradePhoneNumber",
    defaultMessage: "1-888-424-5301",
    description: "Default downgrade phone number",
  },
  priorityPhoneNumber: {
    id: "downgradePage.downgradeContactModal.priorityPhoneNumber",
    defaultMessage: "1-888-511-9193",
    description: "Priority phone number for Plus plan accounts",
  },
});
