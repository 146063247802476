import React from "react";
import { Text } from "@jobber/components/Text";
import { Heading } from "@jobber/components/Heading";
import { Link } from "@jobber/components";
import { strFormatDate } from "@jobber/components/FormatDate";
import { Content } from "@jobber/components/Content";
import { useIntl } from "react-intl";
import {
  type AddonDiscount,
  type AddonPreview,
  CouponDiscountType,
} from "~/utilities/API/graphql";
import { PurchaseDisclaimer } from "~/shared/billing/pricePreview/components/PurchaseDisclaimer/PurchaseDisclaimer";
import { formatCurrency } from "utilities/formatCurrency";
import styles from "./AddonPurchasePreview.module.css";
import { messages } from "./messages";

interface AddonPurchasePreviewProps {
  selectedPurchasePreview:
    | Omit<AddonPreview, "taxRate" | "proratedSubtotal">
    | undefined;
  selectedDiscount: AddonDiscount | undefined;
  monthlyCost: number;
  startBillingOnDate?: string;
  addonName?: string;
  addonIdentifier?: string;
  isBetaAddon?: boolean;
}

// eslint-disable-next-line max-statements
export function AddonPurchasePreview(props: AddonPurchasePreviewProps) {
  const {
    selectedPurchasePreview,
    selectedDiscount,
    monthlyCost,
    startBillingOnDate,
    addonName,
    addonIdentifier,
    isBetaAddon,
  } = props;

  const { formatMessage } = useIntl();

  const accountsBillingInfoPath = "/accounts/billing_info";

  return (
    <Content spacing="small">
      {selectedPurchasePreview &&
        (() => {
          const {
            nextBillingDate,
            proratedDiscount,
            proratedTax,
            proratedTotal,
            totalCredit,
          } = selectedPurchasePreview;

          const {
            discountEndDate,
            addonCostMonthlyDiscounted,
            discountAmount,
            discountType,
            hasMultipleActiveDiscounts,
          } = selectedDiscount || {};

          const billedImmediately =
            startBillingOnDate &&
            new Date(startBillingOnDate).toISOString() <=
              new Date().toISOString();

          const discountLabel =
            discountType === CouponDiscountType.PERCENT &&
            discountAmount &&
            !hasMultipleActiveDiscounts
              ? formatMessage(messages.discountLabelWithPercent, {
                  discountAmount,
                })
              : formatMessage(messages.discountLabel);

          const formattedTotalWithCredit = getFormattedAmountWithCredit(
            proratedTotal,
            totalCredit,
            proratedTax,
          );
          const formattedMonthlyCost = getFormattedAmountWithCredit(
            monthlyCost,
            totalCredit,
          );
          const formattedMonthlyDiscountedCost = getFormattedAmountWithCredit(
            addonCostMonthlyDiscounted,
            totalCredit,
          );

          function getPreviewDetails() {
            if (hasMultipleActiveDiscounts) {
              return formatMessage(messages.multipleDiscountedPreviewDetails, {
                proratedTotal: formattedTotalWithCredit,
                monthlyCost: getFormattedAmount(monthlyCost),
              });
            }

            return (
              <PurchaseDisclaimer
                startBillingOnDate={new Date(startBillingOnDate || "")}
                baseUnitPrice={monthlyCost}
                nextBillingDate={new Date(nextBillingDate || "")}
                subscriptionDisplayName={formatMessage(
                  messages.addonSubscription,
                  {
                    addonName: addonName,
                  },
                )}
                discountedUnitPrice={addonCostMonthlyDiscounted}
                discountEndDate={
                  discountEndDate ? new Date(discountEndDate) : undefined
                }
                showActivationDisclaimer={isBetaAddon}
                addonIdentifier={addonIdentifier}
              />
            );
          }

          function getBilledTodayAmount() {
            return billedImmediately
              ? formattedTotalWithCredit
              : addonCostMonthlyDiscounted
                ? `${formattedMonthlyDiscountedCost}`
                : `${formattedMonthlyCost}`;
          }

          function getBilledTodayLabel() {
            return billedImmediately
              ? formatMessage(messages.billedToday)
              : formatMessage(messages.futureBilledHeader, {
                  startBillingOnDate: getFormattedDate(startBillingOnDate),
                });
          }

          return (
            <>
              {!!proratedDiscount && (
                <div className={styles.previewFlex}>
                  <Text>{discountLabel}</Text>
                  <Text>{getFormattedAmount(-proratedDiscount)}</Text>
                </div>
              )}
              {!!totalCredit && (
                <div className={styles.previewFlex}>
                  <Text>{formatMessage(messages.creditLabel)}</Text>
                  <Text>{getFormattedAmount(totalCredit)}</Text>
                </div>
              )}
              <div className={styles.previewFlex}>
                <Heading level={3}>{getBilledTodayLabel()}</Heading>
                <Heading level={3}>{getBilledTodayAmount()}</Heading>
              </div>
              <Text size="small" variation="subdued" align="end">
                {formatMessage(messages.allPricesUSD)}
              </Text>
              <Text size="small" variation="subdued">
                {getPreviewDetails()}
              </Text>
            </>
          );
        })()}
      <Text size="small" variation="subdued">
        {formatMessage(messages.renewalDisclaimer, {
          cancelAnytimeLink: (
            <Link url={accountsBillingInfoPath}>
              {formatMessage(messages.cancelAnytime)}
            </Link>
          ),
        })}
      </Text>
      <Text size="small" variation="subdued">
        {formatMessage(messages.taxDisclaimer)}
      </Text>
    </Content>
  );
}

function getFormattedAmount(amount?: number) {
  if (amount !== undefined) {
    return `${formatCurrency(amount, "$", 2)}`;
  }
  return "";
}

function getFormattedDate(renewalDate: string | undefined) {
  if (renewalDate) {
    return strFormatDate(new Date(renewalDate));
  }
  return "";
}

function getFormattedAmountWithCredit(
  amount?: number,
  totalCredit?: number,
  tax?: number,
) {
  if (amount !== undefined) {
    const adjustedTotal = amount - (tax || 0) + (totalCredit || 0); // totalCredit is a negative value, if defined

    // Ensure the displayed total is not negative
    return getFormattedAmount(Math.max(adjustedTotal, 0));
  }
  return "";
}
