import React, { useContext, useState } from "react";
import { parse } from "date-fns";
import { useIntl } from "react-intl";
import type { FormatDateOptions } from "react-intl";
import {
  Banner,
  Button,
  ConfirmationModal,
  Content,
  Heading,
  SideDrawer,
  Text,
} from "@jobber/components";
import type { ApolloError } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/client";
import type {
  GoogleBusinessAccountDisconnectMutation,
  NotificationSchedule,
  RequestTrigger,
} from "~/utilities/API/graphql";
import {
  DrawerView,
  ReviewsSettingsDrawerContext,
} from "jobber/reviews/views/ReviewsPage/context/ReviewsSettingsDrawerContext";
import { SideDrawerMenuOption } from "~/jobber/SideDrawerMenuOption/SideDrawerMenuOption";
import { PrivacyMask } from "components/Observability/PrivacyMask";
import { messages as reviewsPageMessages } from "jobber/reviews/views/ReviewsPage/messages";
import { messages } from "./messages";
import styles from "./ManageSettings.module.css";
import { messages as sharedMessages } from "../messages";
import {
  FIRST_MESSAGE_SETTINGS_QUERY,
  GOOGLE_BUSINESS_ACCOUNT_DISCONNECT_MUTATION,
} from "../ReviewsSettings.graphql";

export function ManageSettings({
  error,
  loading,
  schedules,
}: {
  error?: ApolloError;
  loading: boolean;
  schedules: NotificationSchedule[];
}): JSX.Element {
  const { formatMessage, formatTime } = useIntl();
  const { drawerActions, currentView } = useContext(
    ReviewsSettingsDrawerContext,
  );
  const [disconnectAccount, { loading: disconnectLoading }] =
    useMutation<GoogleBusinessAccountDisconnectMutation>(
      GOOGLE_BUSINESS_ACCOUNT_DISCONNECT_MUTATION,
    );
  const {
    loading: initialSettingsLoading,
    error: initialSettingsError,
    data: initialSettingsData,
  } = useQuery(FIRST_MESSAGE_SETTINGS_QUERY);
  const [displayBanner, setDisplayBanner] = useState<boolean>(false);
  const [confirmationModalOpen, setConfirmationModalOpen] =
    useState<boolean>(false);

  const { initialSchedule, secondarySchedule } = {
    initialSchedule: schedules[0],
    secondarySchedule: schedules[1],
  };

  const renderFollowUpLayout = () => {
    const failedToGetSchedules =
      error ||
      (!loading && initialSchedule === undefined) ||
      (!loading && secondarySchedule === undefined);

    if (failedToGetSchedules) {
      return (
        <>
          <Heading level={4}>
            {formatMessage(sharedMessages.followUpsTitle)}
          </Heading>
          <Text variation="error">
            {formatMessage(sharedMessages.followUpError)}
          </Text>
        </>
      );
    }

    const firstFollowUpLabel = initialSchedule?.enabled
      ? formatMessage(
          sharedMessages.initialFollowUp,
          buildMessageParams(initialSchedule, formatTime),
        )
      : formatMessage(sharedMessages.followUpScheduleDisabled);
    const secondFollowUpLabel = secondarySchedule?.enabled
      ? formatMessage(
          sharedMessages.secondaryFollowUp,
          buildMessageParams(secondarySchedule, formatTime),
        )
      : formatMessage(sharedMessages.followUpScheduleDisabled);

    return (
      <>
        <SideDrawerMenuOption
          ariaLabel={formatMessage(
            sharedMessages.firstFollowUpMessageSettingsAriaLabel,
          )}
          heading={formatMessage(sharedMessages.followUpsTitle)}
          label={firstFollowUpLabel}
          loading={loading}
          onClick={() =>
            drawerActions?.goTo(DrawerView.FollowUpMessageSettings1)
          }
        />
        <SideDrawerMenuOption
          ariaLabel={formatMessage(
            sharedMessages.secondFollowUpMessageSettingsAriaLabel,
          )}
          label={secondFollowUpLabel}
          loading={loading}
          onClick={() => {
            drawerActions?.goTo(DrawerView.FollowUpMessageSettings2);
          }}
        />
      </>
    );
  };

  return (
    <PrivacyMask disabled>
      <SideDrawer
        open={currentView === DrawerView.ManageSettings}
        onRequestClose={() => drawerActions?.closeSideDrawer()}
      >
        {displayBanner && (
          <Banner type="error" dismissible={false}>
            {formatMessage(sharedMessages.generalError)}
          </Banner>
        )}
        {error && (
          <Banner type="error" dismissible={true}>
            {formatMessage(sharedMessages.scheduledTimeOffsetError)}
          </Banner>
        )}
        <SideDrawer.Title>
          {formatMessage(reviewsPageMessages.settingsLabel)}
        </SideDrawer.Title>

        <Content>
          <div className={styles.titleContainer}>
            <Heading level={3}>{formatMessage(messages.title)}</Heading>
            <Text variation="subdued" size="small">
              {formatMessage(messages.titleHelper)}
            </Text>
          </div>

          <SideDrawerMenuOption
            ariaLabel={formatMessage(sharedMessages.messageSettingsAriaLabel)}
            heading={formatMessage(sharedMessages.messageTitle)}
            label={formatMessage(messages.firstMessageDescription, {
              triggerSuffix: getFirstMessageSuffix(
                initialSettingsData?.reviewsInitialMessageSettings?.triggerType,
                initialSettingsError,
              ).defaultMessage,
            })}
            loading={loading || initialSettingsLoading}
            onClick={() => drawerActions?.goTo(DrawerView.MessageSettings)}
          />
          {renderFollowUpLayout()}

          <Heading level={3}>{formatMessage(sharedMessages.accounts)}</Heading>
          <div className={styles.inlineSection}>
            <Text>{formatMessage(sharedMessages.accountsDescription)}</Text>
            <Button
              label={formatMessage(sharedMessages.disconnect)}
              type="secondary"
              variation="destructive"
              size="small"
              loading={disconnectLoading}
              onClick={() => setConfirmationModalOpen(true)}
            />
            <ConfirmationModal
              title={formatMessage(sharedMessages.confirmDisconnectTitle)}
              message={formatMessage(
                sharedMessages.confirmDisconnectDescription,
              )}
              variation="destructive"
              size="small"
              open={confirmationModalOpen}
              confirmLabel={formatMessage(sharedMessages.disconnect)}
              onConfirm={() =>
                disconnectAccount()
                  .then(() => {
                    window.location.href = "/reviews/";
                  })
                  .catch(() => {
                    setDisplayBanner(true);
                  })
              }
              onRequestClose={() => setConfirmationModalOpen(false)}
            />
          </div>
        </Content>
      </SideDrawer>
    </PrivacyMask>
  );
}

function getFirstMessageSuffix(
  triggerType?: RequestTrigger,
  error?: ApolloError,
) {
  if (error) {
    return messages.defaultSuffix;
  }

  switch (triggerType) {
    case "INVOICE":
      return messages.invoiceSuffix;
    case "JOB":
      return messages.jobSuffix;
    default:
      return messages.invoiceSuffix;
  }
}

function buildMessageParams(
  node: Partial<NotificationSchedule>,
  formatTime: (
    value: number | Date | string,
    opts?: FormatDateOptions,
  ) => string,
) {
  let nodeAt = "";

  if (node.at) {
    const parsedTime = parse(node.at, "HH:mm:ss.SSSSSSSSS", new Date());
    nodeAt = formatTime(parsedTime, {
      hourCycle: window.is_24_hour_format ? "h23" : "h12",
    });
  }

  return {
    offsetValue: node.offset?.value || 0,
    offsetUnit:
      node.offset?.value === 1
        ? node.offset?.unit.toLowerCase()
        : `${node.offset?.unit.toLowerCase()}s`,
    at: nodeAt,
  };
}
