import React from "react";
import { Button, Card, Glimmer, Heading } from "@jobber/components";
import { Content } from "@jobber/components/Content";
import { useIntl } from "react-intl";
import { IntlProvider } from "@translations/IntlProvider";
import { useReviewBenchmarksQuery } from "jobber/reviews/views/ReviewsPage/hooks/useReviewBenchmarksQuery";
import { useReviewBenchmarkInsightsQuery } from "jobber/reviews/views/ReviewsPage/hooks/useReviewBenchmarkInsightsQuery";
import { CompetitorList } from "./components/CompetitorList";
import { AverageCompetitorCard } from "./components/AverageCompetitorCard/AverageCompetitorCard";
import { EmptyCompetitorCard } from "./components/EmptyCompetitorCard";
import { messages } from "./messages";
import styles from "./styles.module.css";

const goToBenchmark = () => {
  window.location.assign("/reviews/competitors");
};

interface Competitor {
  averageRating: number;
  totalReviews: number;
}

const calculateAverage = (
  data: Competitor[] | null | undefined,
  key: keyof Pick<Competitor, "averageRating" | "totalReviews">,
): number => {
  if (!data?.length) return 0;
  return data.reduce((acc, curr) => acc + curr[key], 0) / data.length;
};

export function InternalReviewsBenchmark() {
  const {
    benchmarkData,
    loading: benchmarksLoading,
    error: benchmarksError,
  } = useReviewBenchmarksQuery();
  const {
    insights,
    loading: insightsLoading,
    error: insightsError,
  } = useReviewBenchmarkInsightsQuery();
  const { formatMessage } = useIntl();
  const competitors = benchmarkData ?? [];
  const emptyList = benchmarkData?.length === 0;
  const loading = benchmarksLoading || insightsLoading;
  const error = benchmarksError || insightsError;

  if (error) {
    return (
      <div>{formatMessage(messages.error, { message: error.message })}</div>
    );
  }

  if (loading) {
    return <LoadingGlimmer />;
  }

  return (
    <div className={styles.benchmarkContainer}>
      <div className={styles.benchmarkHeader}>
        <Heading level={3}>{formatMessage(messages.benchmarkTitle)}</Heading>
        {!emptyList && (
          <Button
            icon="plus2"
            type="secondary"
            onClick={goToBenchmark}
            ariaLabel={formatMessage(messages.addCompetitorsButton)}
          />
        )}
      </div>
      {!loading && emptyList ? (
        <EmptyCompetitorCard cta={goToBenchmark} />
      ) : (
        <>
          <AverageCompetitorCard
            loading={loading}
            avgCompetitorRating={calculateAverage(
              benchmarkData,
              "averageRating",
            ).toFixed(2)}
            avgCompetitorReviews={calculateAverage(
              benchmarkData,
              "totalReviews",
            ).toFixed(0)}
            aiInsights={insights?.aiInsights}
          />
          <Card>
            {/*@ts-ignore*/}
            <CompetitorList competitors={competitors} />
          </Card>
        </>
      )}
    </div>
  );
}

export const ReviewsBenchmark = () => {
  return (
    <IntlProvider>
      <InternalReviewsBenchmark />
    </IntlProvider>
  );
};

function LoadingGlimmer() {
  return (
    <div className={styles.loading}>
      <Card>
        <Content>
          <Glimmer size={"largest"} />
          <Glimmer size={"largest"} />
          <Glimmer size={"largest"} />
          <Glimmer size={"largest"} />
          <Glimmer size={"largest"} />
          <Glimmer size={"largest"} />
          <Glimmer size={"largest"} />
          <Glimmer size={"largest"} />
          <Glimmer size={"largest"} />
          <Glimmer size={"largest"} />
        </Content>
      </Card>
    </div>
  );
}
