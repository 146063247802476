import React from "react";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { Link } from "@jobber/components";
import type {
  PlanInfo,
  SubscriptionAddonPreview,
  SubscriptionDiscountGroup,
  SubscriptionPreview,
} from "~/shared/billing/pricePreview/types";
import {
  getAddonPurchaseDiscountInformation,
  getPurchaseDisclaimerInformation,
} from "~/shared/billing/pricePreview/utils";
import type { BillingCycleName } from "~/utilities/API/graphql";
import { PurchaseDisclaimer } from "~/shared/billing/pricePreview/components/PurchaseDisclaimer/PurchaseDisclaimer";
import { tiersWithActivationDisclaimers } from "./config";
import { messages } from "./messages";

interface CheckoutPurchaseDisclaimerProps {
  viewingCurrentPlan: boolean;
  subscriptionPreview: SubscriptionPreview;
  selectedBillingCycle: BillingCycleName;
  planInfo: PlanInfo;
  discountInformation: SubscriptionDiscountGroup;
  selectedAddonCodes: string[];
  subscriptionAddons: SubscriptionAddonPreview[];
}

export function CheckoutPurchaseDisclaimer({
  viewingCurrentPlan,
  subscriptionPreview,
  selectedBillingCycle,
  planInfo,
  discountInformation,
  selectedAddonCodes,
  subscriptionAddons,
}: CheckoutPurchaseDisclaimerProps) {
  const { formatMessage } = useIntl();
  const { baseUnitPrice, discountUnitPrice, discountEndDate } =
    getPurchaseDisclaimerInformation(
      selectedBillingCycle,
      planInfo,
      discountInformation,
    );

  const {
    selectedAddonsBaseUnitPrice,
    addonsNextBillingDate,
    selectedAddonsDiscountedUnitPrice,
    addonsDiscountEndDate,
  } = getAddonPurchaseDiscountInformation(
    selectedAddonCodes,
    subscriptionAddons,
  );
  const showActivationDisclaimer = tiersWithActivationDisclaimers.includes(
    planInfo.planTier.toLowerCase(),
  );
  const accountsBillingInfoPath = "/accounts/billing_info";

  return (
    <>
      {!viewingCurrentPlan &&
        subscriptionPreview.renewalDate &&
        baseUnitPrice && (
          <PurchaseDisclaimer
            startBillingOnDate={new Date(subscriptionPreview.startBillingDate)}
            baseUnitPrice={baseUnitPrice}
            nextBillingDate={new Date(subscriptionPreview.renewalDate)}
            subscriptionDisplayName={formatMessage(
              messages.mainSubscriptionDisplayName,
              {
                subscriptionDisplayName: planInfo.planTier.toLowerCase(),
              },
            )}
            discountedUnitPrice={discountUnitPrice}
            discountEndDate={
              discountEndDate ? new Date(discountEndDate) : undefined
            }
            showActivationDisclaimer={showActivationDisclaimer}
          />
        )}
      {selectedAddonCodes.length > 0 && addonsNextBillingDate !== undefined && (
        <PurchaseDisclaimer
          startBillingOnDate={new Date(subscriptionPreview.startBillingDate)}
          baseUnitPrice={selectedAddonsBaseUnitPrice}
          nextBillingDate={new Date(addonsNextBillingDate)}
          subscriptionDisplayName={formatMessage(
            messages.addonSubscriptionDisplayName,
          )}
          discountedUnitPrice={selectedAddonsDiscountedUnitPrice}
          discountEndDate={
            addonsDiscountEndDate ? new Date(addonsDiscountEndDate) : undefined
          }
          showActivationDisclaimer={showActivationDisclaimer}
        />
      )}
      <Text variation="subdued" size="small">
        {formatMessage(messages.renewalDisclaimer, {
          cancelAnytimeLink: (
            <Link url={accountsBillingInfoPath}>
              {formatMessage(messages.cancelAnytime)}
            </Link>
          ),
        })}
      </Text>
      <Text variation="subdued" size="small">
        {formatMessage(messages.taxDisclaimer)}
      </Text>
    </>
  );
}
