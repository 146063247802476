import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Box, Button, Flex, Spinner, Text } from "@jobber/components";
import {
  CAMPAIGNS_HELP_DOCS_PATH,
  CAMPAIGNS_HOW_CAMPAIGNS_WORK_VIDEO_ID,
} from "jobber/campaigns/constants";
import { messages } from "./messages";
import { EducationCard } from "./EducationCard";
import styles from "./EducationCards.module.css";

interface EducationVideoCardProps {
  onDismiss?: () => void;
  useExpandedLayout?: boolean;
}

function VideoContent({
  isLoading,
  hasError,
  onLoad,
  onError,
  useExpandedLayout,
}: {
  isLoading: boolean;
  hasError: boolean;
  onLoad: () => void;
  onError: () => void;
  useExpandedLayout: boolean;
}) {
  const { formatMessage } = useIntl();

  return (
    <Box height="100%" alignItems="center" justifyContent="center">
      {isLoading && <Spinner size="base" />}
      {hasError ? (
        <Text variation="error">
          {formatMessage(messages.educationVideoCardError)}
        </Text>
      ) : (
        <div
          className={
            useExpandedLayout
              ? styles.mediaContainerExpanded
              : styles.mediaContainerCompact
          }
          data-testid="media-container"
        >
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${CAMPAIGNS_HOW_CAMPAIGNS_WORK_VIDEO_ID}`}
            title={formatMessage(messages.educationVideoCardiFrameTitle)}
            allow="encrypted-media; picture-in-picture; fullscreen"
            referrerPolicy="strict-origin-when-cross-origin"
            loading="lazy"
            onLoad={onLoad}
            onError={onError}
            frameBorder="0"
            allowFullScreen
            sandbox="allow-scripts allow-same-origin allow-presentation"
            style={{ border: 0 }}
          />
        </div>
      )}
    </Box>
  );
}

function TextContent() {
  const { formatMessage } = useIntl();
  return (
    <>
      <Text>{formatMessage(messages.educationVideoCardText)}</Text>
      <Box gap="base" direction="row">
        <Button
          external={true}
          fullWidth={false}
          size="small"
          type="secondary"
          url={`https://www.youtube.com/watch?v=${CAMPAIGNS_HOW_CAMPAIGNS_WORK_VIDEO_ID}`}
          label={formatMessage(messages.educationVideoCardWatchDemoButton)}
        />
        <Button
          external={true}
          fullWidth={false}
          size="small"
          type="secondary"
          url={CAMPAIGNS_HELP_DOCS_PATH}
          label={formatMessage(messages.educationVideoCardLearnMoreButton)}
        />
      </Box>
    </>
  );
}

export function EducationVideoCard({
  onDismiss,
  useExpandedLayout = false,
}: EducationVideoCardProps) {
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  const handleIframeError = () => {
    setIsLoading(false);
    setHasError(true);
  };

  return (
    <EducationCard
      ctaName="campaigns_education_card_how_campaigns_work"
      title={formatMessage(messages.educationVideoCardHeading)}
      onDismiss={onDismiss}
    >
      {useExpandedLayout ? (
        <Flex direction="row" template={["shrink", "shrink"]}>
          <VideoContent
            isLoading={isLoading}
            hasError={hasError}
            onLoad={handleIframeLoad}
            onError={handleIframeError}
            useExpandedLayout={useExpandedLayout}
          />
          <Box gap="base" direction="column">
            <TextContent />
          </Box>
        </Flex>
      ) : (
        <>
          <VideoContent
            isLoading={isLoading}
            hasError={hasError}
            onLoad={handleIframeLoad}
            onError={handleIframeError}
            useExpandedLayout={useExpandedLayout}
          />
          <TextContent />
        </>
      )}
    </EducationCard>
  );
}
