import type { Datum, DatumValue, Serie } from "@nivo/line";
import { isAfter, isBefore, subDays } from "date-fns";

export function roundActivationDate(
  activationDate: Date,
  seriesData?: Datum[],
): Date | null {
  if (seriesData == undefined) {
    const defaultDateSegments = generateDateSegments();
    seriesData = defaultDateSegments.map(date => ({
      x: date,
      y: 0,
    }));
  }

  const extendedData = [
    {
      x: subDays(seriesData[0].x as Date, 30),
      y: 0,
    },
    ...seriesData.map(point => ({
      x: point.x as Date,
      y: point.y as number,
    })),
  ];

  return extendedData.reduce<Date | null>((closest, currentPoint, index) => {
    const currentDate = currentPoint.x;
    const nextDate = extendedData[index + 1]?.x;

    if (currentDate.getTime() === activationDate.getTime()) {
      return currentPoint.x;
    }

    if (
      nextDate &&
      isAfter(activationDate, currentDate) &&
      isBefore(activationDate, nextDate)
    ) {
      return nextDate;
    }

    return closest;
  }, null);
}

export function getYAxisBounds({
  series,
  defaultMin = 0,
  defaultMax = 5,
}: {
  series?: Serie[];
  defaultMin?: number;
  defaultMax?: number;
}) {
  if (series == undefined) {
    return { minY: defaultMin, maxY: defaultMax };
  }

  const allSeriesValues = series.flatMap(
    seriesData => seriesData?.data?.map(point => Number(point.y)) ?? [],
  );
  const minY = Math.min(...allSeriesValues, defaultMin);
  const maxY = Math.max(...allSeriesValues, defaultMax);

  return { minY, maxY };
}

export function generateDateSegments(): DatumValue[] {
  const dates = [
    subDays(new Date(), 90),
    subDays(new Date(), 60),
    subDays(new Date(), 30),
    new Date(),
  ];

  return dates;
}

export function useTimeScaleTickValues(
  seriesData?: readonly Datum[],
): DatumValue[] {
  if (seriesData == undefined) {
    return generateDateSegments();
  }
  return seriesData.map(value => value.x as DatumValue);
}

export function isSeriesEmpty(data?: Serie[]): boolean {
  if (data == undefined) {
    return true;
  }
  return (
    data.every(serie => serie.data.every(point => Number(point.y) === 0)) ??
    false
  );
}
