import React, { useState } from "react";
import { useIntl } from "react-intl";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Content,
  Glimmer,
  InputValidation,
  Text,
} from "@jobber/components";
import { useQuery } from "@apollo/client";
import { TextActionButton } from "~/components/TextActionButton/TextActionButton";
import type {
  AutomationRule,
  ProductsQuery,
  Segment,
} from "~/utilities/API/graphql";
import { useTranslateAutomationRulesConditionToText } from "jobber/campaigns/hooks/useTranslateAutomationRulesConditionToText/useTranslateAutomationRulesConditionToText";
import { PRODUCT_OR_SERVICES_QUERY } from "~/jobber/lineItems/components/LineItemsBulkEdit/components/graphql";
import { ClientSegmentDrawer } from "jobber/campaigns/views/CampaignRecipientsPage/components/ClientSegmentInternals/components/ClientSegmentDrawer/ClientSegmentDrawer";
import { messages } from "./messages";
import styles from "./RecipientsSection.module.css";

export interface RecipientsSectionProps {
  loading: boolean;
  segmentName: string;
  consentingClientsCount: number;
  totalCount: number;
  timeoutError: boolean;
  redirectToEdit: () => void;
  confirmImpliedConsentPermission: boolean;
  setConfirmImpliedConsentPermission: (value: boolean) => void;
  validationErrors:
    | Record<"confirmImpliedConsentPermission", string | undefined>
    | undefined;
  enableAutomation: boolean;
  automationRule?: AutomationRule;
  selectedSegment: Segment;
}

export function RecipientsSection({
  loading,
  segmentName,
  consentingClientsCount,
  totalCount,
  timeoutError,
  redirectToEdit,
  setConfirmImpliedConsentPermission,
  validationErrors,
  enableAutomation,
  automationRule,
  selectedSegment,
}: RecipientsSectionProps): JSX.Element {
  const { formatMessage } = useIntl();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { data: lineItemData, loading: productsLoading } =
    useQuery<ProductsQuery>(PRODUCT_OR_SERVICES_QUERY);

  const { trigger, timeFrame, conditionText } =
    useTranslateAutomationRulesConditionToText({
      allLoading: loading || productsLoading,
      automationRule,
      lineItemData,
    });

  const summaryMessage = enableAutomation
    ? formatMessage(messages.automatedCampaignRecipientSummary, {
        timeFrame: timeFrame,
        trigger: trigger,
        condition: conditionText,
      })
    : formatMessage(messages.recipientSummary, {
        segmentName,
        consentingClientsCount,
        totalCount,
      });

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);

  return (
    <Card
      header={{
        title: formatMessage(messages.campaignRecipientsTitle),
        action: (
          <Button
            label={formatMessage(messages.campaignRecipientEditButton)}
            onClick={redirectToEdit}
            type="tertiary"
            size="base"
          />
        ),
      }}
    >
      <Content>
        {loading && <Glimmer width={500} />}
        {!loading && (
          <div
            className={styles.recipientContainer}
            data-testid="recipientSummary"
          >
            <Box direction="column" gap="base">
              <div className={styles.summaryMessage}>{summaryMessage}</div>
              {totalCount > 0 && (
                <TextActionButton
                  label={formatMessage(messages.clients, {
                    totalCount: totalCount,
                  })}
                  onClick={openDrawer}
                />
              )}
            </Box>
          </div>
        )}
        {timeoutError && (
          <Text size="small" variation="warn">
            {formatMessage(messages.campaignRecipientTimeoutError)}
          </Text>
        )}

        <div className={styles.confirmImpliedConsentPermissionContainer}>
          <Checkbox
            label={formatMessage(
              messages.campaignsConfirmImpliedConsentPermission,
            )}
            onChange={setConfirmImpliedConsentPermission}
          />
          {validationErrors?.confirmImpliedConsentPermission && (
            <InputValidation
              message={validationErrors?.confirmImpliedConsentPermission}
            />
          )}
        </div>

        <ClientSegmentDrawer
          isOpen={isDrawerOpen}
          closeSideDrawer={closeDrawer}
          segmentName={segmentName}
          selectedSegment={selectedSegment}
        />
      </Content>
    </Card>
  );
}
