import React from "react";
import { Button, Heading, Text } from "@jobber/components";
import { useIntl } from "react-intl";
import emptyCompetitorCardImage from "@images/ReviewsBenchmarkEmpty.png";
import styles from "./EmptyCompetitorCard.module.css";
import { messages } from "../../messages";

export function EmptyCompetitorCard({ cta }: { cta: () => void }) {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.emptyCompetitorCard}>
      <img
        width="90%"
        alt={formatMessage(messages.emptyCompetitorAltText)}
        src={emptyCompetitorCardImage}
      />
      <Heading level={4}>
        {formatMessage(messages.emptyCompetitorHeading)}
      </Heading>
      <Text variation="subdued">
        {formatMessage(messages.emptyCompetitorDescription)}
      </Text>
      <Button
        label={formatMessage(messages.addCompetitorsButton)}
        icon="plus2"
        onClick={cta}
      />
    </div>
  );
}
