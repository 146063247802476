/* eslint-disable max-statements */
import { generatePath, useHistory } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import { useHandleUpdateCampaign } from "jobber/campaigns/views/CampaignsContentPage/hooks/utils/useHandleUpdateCampaign";
import { updatedCampaignAmplitudeEvent } from "jobber/campaigns/amplitude/events";
import {
  CAMPAIGNS_CONTENT_EDIT_PATH,
  CAMPAIGNS_REVIEW_PATH,
  CAMPAIGNS_SEGMENT_EDIT_PATH,
  CAMPAIGNS_SEGMENT_PATH,
} from "jobber/campaigns/constants";
import {
  CampaignTemplateCtaLinkTo,
  type Campaigns,
  type CommsCampaignQuery,
} from "~/utilities/API/graphql";
import { useCampaignWizardContext } from "jobber/campaigns/contexts";
import { useSendTestCampaignActions } from "jobber/campaigns/hooks/useSendTestCampaignActions/useSendTestCampaignActions";
import { SplitNames, useFeatureFlag } from "utilities/split";

export interface UseCampaignContentActionsProps {
  campaignId?: string;
  campaign: CommsCampaignQuery["commsCampaign"];
  setRenderExitConfirmationModal: (newValue: boolean) => void;
  setIsErrorState: (error: boolean) => void;
  setIsSendATestModalOpen: (newValue: boolean) => void;
  setIsSendATestModalInErrorState: (newValue: boolean) => void;
  userEmail: string;
  removeBeforeUnloadListener: () => void;
  loading: boolean;
  formValidationErrors: boolean;
}
export function useCampaignContentActions({
  campaignId,
  campaign,
  formValidationErrors,
  setRenderExitConfirmationModal,
  setIsErrorState,
  setIsSendATestModalOpen,
  setIsSendATestModalInErrorState,
  userEmail,
  removeBeforeUnloadListener,
  loading,
}: UseCampaignContentActionsProps) {
  const history = useHistory();
  const [actionLoading, setActionLoading] = useState(false);
  const isCampaignsStreamlined = useFeatureFlag(
    SplitNames.CampaignsStreamlined,
  );

  const { updateCampaignTemplates } = useHandleUpdateCampaign({
    commsCampaign: campaign as Campaigns,
  });

  const {
    campaignContent: {
      isDirty,
      setIsDirty,
      body,
      header,
      subject,
      showLogo,
      ctaButtonEnabled,
      linkTo,
      linkValue,
      buttonText,
      buttonColor,
      setCampaignId,
      templateType,
      headerImage,
      imageIds,
    },
  } = useCampaignWizardContext();

  const invalidCTA = useMemo(
    () =>
      ctaButtonEnabled &&
      linkTo === CampaignTemplateCtaLinkTo.EXTERNAL_LINK &&
      !linkValue,
    [linkTo, linkValue, ctaButtonEnabled],
  );

  const handleTestEmailError = useCallback(() => {
    setIsSendATestModalOpen(true);
    setIsSendATestModalInErrorState(true);
  }, [setIsSendATestModalOpen, setIsSendATestModalInErrorState]);

  async function onNext() {
    if (loading || !campaignId) {
      setIsErrorState(true);
      return;
    }
    if ((formValidationErrors && isDirty) || invalidCTA) {
      return;
    }

    setActionLoading(true);

    await updateCampaignTemplates({
      subject: subject,
      body: body,
      header: header,
      showLogo: showLogo,
      ctaButtonEnabled: ctaButtonEnabled,
      linkTo: linkTo,
      linkValue: linkValue,
      buttonText: buttonText,
      buttonColor: buttonColor,
      headerImage: headerImage,
      imageIds: imageIds,
      templateType,
      onError: () => {
        setIsErrorState(true);
        setActionLoading(false);
      },
      onSuccess: ({ campaignId: upsertedCampaignId }) => {
        setIsErrorState(false);
        setIsDirty(false);
        removeBeforeUnloadListener();
        setActionLoading(false);
        const reviewPath = generatePath(CAMPAIGNS_REVIEW_PATH, {
          campaignId: campaignId || upsertedCampaignId || "",
        });
        history.push(reviewPath);
        updatedCampaignAmplitudeEvent({
          interaction: "Reviewed",
          /* eslint-disable @typescript-eslint/naming-convention */
          segment_type: campaign?.clientSegment?.type,
          is_automated: campaign?.isAutomated,
          template_type: templateType,
          /* eslint-enable @typescript-eslint/naming-convention */
        });
      },
    });
  }

  function onBack() {
    if (!isDirty) {
      onConfirmExitModal();
    } else {
      setRenderExitConfirmationModal(true);
    }
  }

  function saveCampaign(skipRedirect = false) {
    return new Promise<string>((resolve, reject) => {
      if ((formValidationErrors && isDirty) || invalidCTA) {
        reject();
        return;
      }
      if (!campaignId) {
        setIsErrorState(true);
        reject();
        return;
      }

      setActionLoading(true);
      const ctaData = ctaButtonEnabled
        ? {
            linkTo: linkTo,
            linkValue: linkValue,
            buttonText: buttonText,
            buttonColor: buttonColor,
          }
        : {
            linkTo: undefined,
            linkValue: undefined,
            buttonText: undefined,
            buttonColor: undefined,
          };

      return updateCampaignTemplates({
        subject: subject,
        body: body,
        header: header,
        showLogo: showLogo,
        ctaButtonEnabled: ctaButtonEnabled,
        ...ctaData,
        templateType,
        headerImage,
        imageIds,
        onError: e => {
          setIsErrorState(true);
          setActionLoading(false);
          reject(e);
        },
        onSuccess: ({ campaignId: upsertedCampaignId }) => {
          setIsDirty(false);
          setIsErrorState(false);
          setActionLoading(false);
          if (upsertedCampaignId) {
            setCampaignId(upsertedCampaignId);
            if (!skipRedirect) {
              const contentPath = generatePath(CAMPAIGNS_CONTENT_EDIT_PATH, {
                campaignId: upsertedCampaignId,
              });
              removeBeforeUnloadListener();

              history.replace(contentPath);
            }
            resolve(upsertedCampaignId);
          }
        },
      }).catch(e => {
        setIsErrorState(true);
        setActionLoading(false);
        reject(e);
      });
    });
  }

  const onConfirmExitModal = useCallback(() => {
    let segmentPath;
    if (campaignId) {
      if (isCampaignsStreamlined) {
        segmentPath = generatePath(CAMPAIGNS_REVIEW_PATH, {
          campaignId: campaignId,
        });
      } else {
        segmentPath = generatePath(CAMPAIGNS_SEGMENT_EDIT_PATH, {
          campaignId: campaignId,
        });
      }
    } else {
      // Note: this path will never _not_ have a campaignId when isCampaignsStreamlined is true
      segmentPath = generatePath(CAMPAIGNS_SEGMENT_PATH, {
        templateType: templateType,
      });
    }
    setIsDirty(false);
    removeBeforeUnloadListener();
    history.push({
      pathname: segmentPath,
      search: location.search,
    });
  }, [
    isCampaignsStreamlined,
    campaignId,
    history,
    removeBeforeUnloadListener,
    setIsDirty,
    templateType,
  ]);

  const { onSendTestEmail, onSendTestEmailForDemo } =
    useSendTestCampaignActions({
      userEmail,
      saveCampaign,
      loading,
      setIsErrorState,
      setActionLoading,
      handleError: handleTestEmailError,
      navigationPath: CAMPAIGNS_CONTENT_EDIT_PATH,
    });

  return {
    onNext,
    onBack,
    saveCampaign,
    onSendTestEmail,
    onSendTestEmailForDemo,
    onConfirmExitModal,
    actionLoading,
  };
}
