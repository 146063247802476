import React, { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { Content, Glimmer, ProgressBar, Text } from "@jobber/components";
import { Template } from "~/utilities/API/graphql";
import { useTemplatePageNavigation } from "jobber/campaigns/views/CampaignsTemplatePage/hooks";
import { AutomatedCampaignDisclosure } from "./AutomatedCampaignDisclosure";
import { useCampaignTemplateStatsQuery } from "./useCampaignTemplateStatsQuery";
import { messages } from "./messages";
import { getDefaultOpenDisclosure } from "./utils";
import styles from "./styles.module.css";
import { EducationCard } from "../EducationCard";

interface AutomatedCampaignsPromptCardProps {
  onDismiss?: () => void;
  activeDisclosure: Template | null;
  onToggleDisclosure: (template: Template | null) => void;
}

export function AutomatedCampaignsPromptCard({
  onDismiss,
  activeDisclosure,
  onToggleDisclosure,
}: AutomatedCampaignsPromptCardProps) {
  const { formatMessage } = useIntl();
  const {
    automatedTemplateTypesEnabledCount,
    reengageEnabled,
    winBackEnabled,
    closeOnPendingQuoteEnabled,
    loading,
  } = useCampaignTemplateStatsQuery();

  const { onNext } = useTemplatePageNavigation({});
  const hasSetInitialDisclosure = useRef(false);

  // Update the active disclosure only on initial load
  useEffect(() => {
    if (!loading && !hasSetInitialDisclosure.current) {
      const defaultDisclosure = getDefaultOpenDisclosure({
        reengageEnabled,
        winBackEnabled,
        closeOnPendingQuoteEnabled,
      });
      if (defaultDisclosure) {
        onToggleDisclosure(defaultDisclosure);
      }
      hasSetInitialDisclosure.current = true;
    }
  }, [
    loading,
    reengageEnabled,
    winBackEnabled,
    closeOnPendingQuoteEnabled,
    onToggleDisclosure,
  ]);

  const toggleDisclosure = (template: Template) => {
    onToggleDisclosure(activeDisclosure === template ? null : template);
  };

  return (
    <EducationCard
      ctaName="campaigns_education_card_automated_campaigns"
      title={formatMessage(messages.automatedCampaignsPromptCardTitle)}
      flexTemplate={["shrink", "shrink", "shrink"]}
      onDismiss={onDismiss}
    >
      {loading ? (
        <Glimmer />
      ) : (
        <Content>
          <div className={styles.progressBar}>
            <ProgressBar
              currentStep={automatedTemplateTypesEnabledCount}
              totalSteps={3}
              size="smaller"
              variation="progress"
            />
            <Text>
              {formatMessage(messages.automatedCampaignsCompletedCount, {
                count: automatedTemplateTypesEnabledCount,
              })}
            </Text>
          </div>
          <Text>
            {formatMessage(messages.automatedCampaignsPromptCardDescription)}
          </Text>
          <div className={styles.disclosureContainer}>
            <Content>
              <AutomatedCampaignDisclosure
                template={Template.AUTOMATED_RE_ENGAGE}
                isEnabled={reengageEnabled}
                activeDisclosure={activeDisclosure}
                onToggle={toggleDisclosure}
                onNext={onNext}
                title={formatMessage(
                  messages.automatedCampaignsReengageDisclosureTitle,
                )}
                description={formatMessage(
                  messages.automatedCampaignsReengageDisclosureDescription,
                )}
              />
              <AutomatedCampaignDisclosure
                template={Template.AUTOMATED_WIN_BACK_LOST_LEADS}
                isEnabled={winBackEnabled}
                activeDisclosure={activeDisclosure}
                onToggle={toggleDisclosure}
                onNext={onNext}
                title={formatMessage(
                  messages.automatedCampaignsWinBackDisclosureTitle,
                )}
                description={formatMessage(
                  messages.automatedCampaignsWinBackDisclosureDescription,
                )}
              />
              <AutomatedCampaignDisclosure
                template={Template.AUTOMATED_CLOSE_ON_PENDING_QUOTES}
                isEnabled={closeOnPendingQuoteEnabled}
                activeDisclosure={activeDisclosure}
                onToggle={toggleDisclosure}
                onNext={onNext}
                title={formatMessage(
                  messages.automatedCampaignsCloseOnPendingQuotesDisclosureTitle,
                )}
                description={formatMessage(
                  messages.automatedCampaignsCloseOnPendingQuotesDisclosureDescription,
                )}
              />
            </Content>
          </div>
        </Content>
      )}
    </EducationCard>
  );
}
