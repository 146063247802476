import ExperimentalFeature1Src from "@images/experimental_review_feature_1.png";
import ExperimentalFeature2Src from "@images/experimental_review_feature_2.png";
import ExperimentalFeature3Src from "@images/experimental_review_feature_3.png";
import PlanTapeSrc from "@images/Marketing/marketingSuiteTape.png";
import MoreClientsSrc from "@images/more_clients.png";
import ProtectReputationSrc from "@images/protect_reputation.png";
import ReviewsHeroSrc from "@images/reviews_hero.png";
import ExperimentalReviewsHeroSrc from "@images/experimental_reviews_hero.png";
import TrustedBrandSrc from "@images/trusted_brand.png";
import { messages } from "./messages";
import type { ImageType } from "../../types";

const PlanTapeImg: ImageType = {
  src: PlanTapeSrc as string,
  alt: messages.planTapeAltText,
};

const MoreClientsImg: ImageType = {
  src: MoreClientsSrc as string,
  alt: messages.moreClientsAltText,
};

const ProtectReputationImg: ImageType = {
  src: ProtectReputationSrc as string,
  alt: messages.protectReputationAltText,
};

const ReviewsHeroImg: ImageType = {
  src: ReviewsHeroSrc as string,
  alt: messages.heroAltText,
};

const TrustedBrandImg: ImageType = {
  src: TrustedBrandSrc as string,
  alt: messages.trustedBrandAltText,
};

// Experiment - COBRA KAI
const ExperimentalReviewsHeroImg: ImageType = {
  src: ExperimentalReviewsHeroSrc as string,
  alt: messages.heroAltText,
};
const ExperimentalFeature1Img: ImageType = {
  src: ExperimentalFeature1Src as string,
  alt: messages.heroAltText,
};
const ExperimentalFeature2Img: ImageType = {
  src: ExperimentalFeature2Src as string,
  alt: messages.heroAltText,
};
const ExperimentalFeature3Img: ImageType = {
  src: ExperimentalFeature3Src as string,
  alt: messages.heroAltText,
};

export {
  ReviewsHeroImg,
  TrustedBrandImg,
  ProtectReputationImg,
  MoreClientsImg,
  PlanTapeImg,
  ExperimentalReviewsHeroImg,
  ExperimentalFeature1Img,
  ExperimentalFeature2Img,
  ExperimentalFeature3Img,
};
