import React from "react";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Markdown } from "@jobber/components/Markdown";
import { useIntl } from "react-intl";
import { IntlProvider } from "@translations/IntlProvider";
import { Intercom } from "utilities/chat";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { useRequestPlusPlanPrioritySupport } from "jobber/downgradePage/hooks/useCreatePrioritySupportIntercomConversation";
import { messages } from "./messages";

export interface DowngradeContactModalProps {
  showModal: boolean;
  hideModal: () => void;
  currentPlanTier?: string;
}

function DowngradeContactModal({
  showModal,
  hideModal,
  currentPlanTier,
}: DowngradeContactModalProps) {
  const { formatMessage } = useIntl();
  const onPlusTier = currentPlanTier === "PLUS";

  const phoneNumber = onPlusTier
    ? formatMessage(messages.priorityPhoneNumber)
    : formatMessage(messages.downgradePhoneNumber);

  const content = formatMessage(messages.modalContent, {
    isPlusAccount: onPlusTier,
    phoneNumber: `[${phoneNumber}](tel:${phoneNumber.replace(/-/g, "")})`,
  });

  const { handlePrioritySupportCall, loading } =
    useRequestPlusPlanPrioritySupport();

  return (
    <Modal
      title={formatMessage(messages.modalTitle)}
      open={showModal}
      onRequestClose={handleModalDismissal}
      dismissible
      primaryAction={{
        label: formatMessage(messages.ctaLabel),
        onClick: handleModalPrimaryCTA,
      }}
      secondaryAction={{
        label: formatMessage(messages.cancelLabel),
        onClick: handleModalDismissal,
        type: "tertiary",
      }}
      size={"small"}
    >
      <Content>
        <Markdown content={content} />
      </Content>
    </Modal>
  );

  function handleModalDismissal() {
    hideModal();
    Amplitude.TRACK_EVENT("CTA Dismissed", {
      source: "downgrade_contact_modal",
      name: "cancel_button",
    });
  }

  async function handleModalPrimaryCTA() {
    if (onPlusTier) {
      await handlePrioritySupportCall();
      !loading && hideModal();
    } else {
      hideModal();
      Intercom.EXECUTE("showNewMessage");
    }
    Amplitude.TRACK_EVENT("CTA Clicked", {
      source: "downgrade_contact_modal",
      name: "chat_with_us_button",
      type: onPlusTier ? "premium_support" : undefined,
    });
  }
}

function WrappedDowngradeContactModal({
  showModal,
  hideModal,
  currentPlanTier,
}: DowngradeContactModalProps) {
  return (
    <IntlProvider>
      <DowngradeContactModal
        showModal={showModal}
        hideModal={hideModal}
        currentPlanTier={currentPlanTier}
      />
    </IntlProvider>
  );
}

export { WrappedDowngradeContactModal as DowngradeContactModal };
