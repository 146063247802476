import type { Dispatch, SetStateAction } from "react";
import React, { useState } from "react";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { useIntl } from "react-intl";
// Need to use typography to achieve bold green text
// eslint-disable-next-line no-restricted-imports
import { Typography } from "@jobber/components/Typography";
import { Emphasis } from "@jobber/components/Emphasis";
import { PurchaseButton } from "jobber/billing/features/Checkout/components/CheckoutSummary/components/PurchaseButton/PurchaseButton";
import { BillingDetails } from "~/shared/billing/pricePreview/components/BillingDetails/BillingDetails";
import { CheckoutPurchaseDisclaimer } from "~/shared/billing/pricePreview/components/CheckoutPurchaseDisclaimer/CheckoutPurchaseDisclaimer";
import type {
  PlanInfo,
  SubscriptionAddonPreview,
  SubscriptionDiscountGroup,
  SubscriptionPreview,
} from "~/shared/billing/pricePreview/types";
import { TermsOfServiceAndPrivacyPolicyLinks } from "jobber/billing/features/Checkout/components/CheckoutSummary/components/TermsOfServiceAndPrivacyPolicyLinks";
import type { BillingCycleName } from "~/utilities/API/graphql";
import {
  getDiscountInformationByBillingCycle,
  savingsMessage,
} from "~/shared/billing/pricePreview/utils";
import { getDiscountHeader } from "jobber/billing/features/Checkout/utils";
import { PurchaseTotal } from "~/jobber/billing/components/PurchaseTotal/PurchaseTotal";
import { messages } from "./messages";
import { Disclosure } from "./components/Disclosure";
import styles from "./MobileSummary.module.css";

interface MobileSummaryProps {
  planInfo: PlanInfo;
  discountInformation: SubscriptionDiscountGroup;
  subscriptionPreview: SubscriptionPreview;
  selectedBillingCycle: BillingCycleName;
  viewingCurrentPlan: boolean;
  subscribeDisabled: boolean;
  enablePurchaseButton: boolean;
  subscriptionAddons: SubscriptionAddonPreview[];
  selectedAddonCodes: string[];
  shouldShowTax: boolean;
  purchaseTotal: number;
  onSelectBillingCycle: (cycle: BillingCycleName) => void;
  onConfirmSubscription?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  setSelectedAddons: Dispatch<SetStateAction<string[]>>;
}

interface HeaderProps {
  planTier: string;
  selectedBillingCycle: BillingCycleName;
  discountInformation: SubscriptionDiscountGroup;
}

interface FooterProps {
  viewingCurrentPlan: boolean;
  enablePurchaseButton: boolean;
  subscribeDisabled: boolean;
  onConfirmSubscription?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
}

export function MobileSummary({
  planInfo,
  discountInformation,
  subscriptionPreview,
  selectedBillingCycle,
  viewingCurrentPlan,
  subscribeDisabled,
  enablePurchaseButton,
  subscriptionAddons,
  selectedAddonCodes,
  shouldShowTax,
  purchaseTotal,
  onSelectBillingCycle,
  onConfirmSubscription,
  setSelectedAddons,
}: MobileSummaryProps) {
  const { formatMessage } = useIntl();
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <div className={styles.spacer} />
      <div className={styles.container}>
        <div className={styles.stickyContainer}>
          <Content>
            <div className={styles.summaryContainer}>
              <div className={styles.disclosure}>
                <Disclosure
                  summary={
                    <Header
                      planTier={planInfo.planTier}
                      selectedBillingCycle={selectedBillingCycle}
                      discountInformation={discountInformation}
                    />
                  }
                  isOpen={isOpen}
                  onToggleOpen={setOpen}
                >
                  <Content>
                    <BillingDetails
                      monthlyPrice={planInfo?.monthlyBillingCycle?.monthlyPrice}
                      annualPrice={planInfo?.annualBillingCycle?.monthlyPrice}
                      savingsMessage={savingsMessage(
                        formatMessage,
                        planInfo?.monthlyBillingCycle?.annualPrice,
                        planInfo?.annualBillingCycle?.annualPrice,
                      )}
                      subscriptionPreview={subscriptionPreview}
                      discountInformation={discountInformation}
                      selectedBillingCycle={selectedBillingCycle}
                      viewingCurrentPlan={viewingCurrentPlan}
                      subscriptionAddons={subscriptionAddons}
                      selectedAddonCodes={selectedAddonCodes}
                      shouldShowTax={shouldShowTax}
                      purchaseTotal={purchaseTotal}
                      onSetSelectedCycle={onSelectBillingCycle}
                      setSelectedAddons={setSelectedAddons}
                    />
                    <CheckoutPurchaseDisclaimer
                      viewingCurrentPlan={viewingCurrentPlan}
                      subscriptionPreview={subscriptionPreview}
                      selectedBillingCycle={selectedBillingCycle}
                      planInfo={planInfo}
                      discountInformation={discountInformation}
                      selectedAddonCodes={selectedAddonCodes}
                      subscriptionAddons={subscriptionAddons}
                    />
                  </Content>
                  {!viewingCurrentPlan && (
                    <div className={styles.termsOfService}>
                      <TermsOfServiceAndPrivacyPolicyLinks />
                    </div>
                  )}
                </Disclosure>
                {shouldShowPurchaseTotal() && subscriptionPreview && (
                  <div
                    className={styles.collapsedBody}
                    data-testid="collapsed-mobile-purchase-total"
                  >
                    <PurchaseTotal
                      startBillingDate={
                        new Date(subscriptionPreview.startBillingDate)
                      }
                      purchaseTotal={purchaseTotal}
                    />
                  </div>
                )}
                <Footer
                  enablePurchaseButton={enablePurchaseButton}
                  subscribeDisabled={subscribeDisabled}
                  onConfirmSubscription={onConfirmSubscription}
                  viewingCurrentPlan={viewingCurrentPlan}
                />
              </div>
            </div>
          </Content>
        </div>
      </div>
    </>
  );

  function shouldShowPurchaseTotal() {
    return !viewingCurrentPlan && !isOpen;
  }
}

function Header({
  planTier,
  selectedBillingCycle,
  discountInformation,
}: HeaderProps) {
  const { formatMessage } = useIntl();

  const discountInformationByBillingCycle =
    getDiscountInformationByBillingCycle(
      selectedBillingCycle,
      discountInformation,
    );

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerTop}>
        <Heading level={2}>{planTier}</Heading>
        <Typography textColor={"green"} fontWeight={"bold"}>
          {formatMessage(messages.viewDetails)}
        </Typography>
      </div>
      <Emphasis variation={"highlight"}>
        {getDiscountHeader(
          formatMessage,
          selectedBillingCycle,
          discountInformationByBillingCycle?.discountType,
          discountInformationByBillingCycle?.discountAmount,
          discountInformationByBillingCycle?.remainingDiscountedFullMonths,
        )}
      </Emphasis>
    </div>
  );
}

function Footer({
  viewingCurrentPlan,
  subscribeDisabled,
  enablePurchaseButton,
  onConfirmSubscription,
}: FooterProps) {
  return (
    <div className={styles.footer}>
      <Content>
        <PurchaseButton
          enablePurchaseButton={enablePurchaseButton}
          viewingCurrentPlan={viewingCurrentPlan}
          subscribeDisabled={subscribeDisabled}
          onConfirmSubscription={onConfirmSubscription}
        />
      </Content>
    </div>
  );
}
