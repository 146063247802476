import { gql } from "@apollo/client";

const CLIENT_ATTRIBUTION_FRAGMENT = gql`
  fragment clientAttributionFragment on ReviewAttribution {
    id
    status
    client {
      id
      name
      jobberWebUri
      clientProperties {
        nodes {
          address {
            street
          }
        }
      }
    }
  }
`;

const REVIEW_FRAGMENT = gql`
  fragment reviewFragment on Review {
    clientAttributions {
      confirmedReviewAttributions {
        nodes {
          ...clientAttributionFragment
        }
      }
      pendingReviewAttributions {
        nodes {
          ...clientAttributionFragment
        }
      }
    }
    reviewId
    id
    rating
    comment
    reviewer {
      displayName
      isAnonymous
    }
    updatedAt
  }
  ${CLIENT_ATTRIBUTION_FRAGMENT}
`;

export const REVIEW_ATTRIBUTION_FRAGMENT = gql`
  fragment reviewAttributionFragment on ReviewAttribution {
    id
    status
    client {
      id
      name
    }
    review {
      ...reviewFragment
    }
  }
  ${REVIEW_FRAGMENT}
`;

export const REVIEW_ATTRIBUTION_BULK_EDIT = gql`
  mutation ReviewAttributionsBulkEdit($input: [ReviewAttributionEditInput!]!) {
    reviewAttributionsEdit(input: $input) {
      editedReviewAttributions {
        nodes {
          ...reviewAttributionFragment
        }
      }
      userErrors {
        path
        message
      }
    }
  }
  ${REVIEW_ATTRIBUTION_FRAGMENT}
`;

export const CLIENT_AUTOMATED_REVIEW_DETAILS_QUERY = gql`
  query ClientAutomatedReviewDetails($clientId: EncodedId!) {
    clientAutomatedReviewDetails(clientId: $clientId) {
      allowReviewRequests
      nextReadyToSendAt
    }
  }
`;

export const FIRST_MESSAGE_SETTINGS_QUERY = gql`
  query FirstMessageSettings {
    reviewsInitialMessageSettings {
      triggerType
    }
  }
`;
