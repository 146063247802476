import React, { useMemo } from "react";
import { Icon } from "@jobber/components/Icon";
import { Heading } from "@jobber/components/Heading";
import { Button } from "@jobber/components/Button";
import { Emphasis } from "@jobber/components/Emphasis";
import { ProgressBar } from "@jobber/components/ProgressBar";
import { useBreakpoints } from "@jobber/hooks/useBreakpoints";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { IntlProvider } from "@translations/IntlProvider";
import { SplitNames, useFeatureFlag } from "utilities/split";
import styles from "./CampaignsBreadCrumb.module.css";
import { messages } from "./messages";

interface CampaignsBreadCrumbProps {
  /**
   * Step currently being viewed. Must be in steps array.
   */
  currentStep: string;
  /**
   * @default ["Template", "Recipients", "Content", "Review"]`
   */
  onBack: () => void;
}

interface InternalCampaignsBreadCrumbProps extends CampaignsBreadCrumbProps {
  showBreadCrumb: boolean;
  steps: string[];
}

export function CampaignsBreadCrumbWrapper(props: CampaignsBreadCrumbProps) {
  return (
    <IntlProvider>
      <CampaignsBreadCrumbInternal {...props} />
    </IntlProvider>
  );
}

function CampaignsBreadCrumbInternal({
  currentStep,
  onBack,
}: CampaignsBreadCrumbProps): JSX.Element {
  const isCampaignsStreamlined = useFeatureFlag(
    SplitNames.CampaignsStreamlined,
  );
  const showBreadCrumb = !isCampaignsStreamlined;
  const { formatMessage } = useIntl();
  const { mediumAndUp } = useBreakpoints();
  const steps = useMemo(() => {
    return [
      formatMessage(messages.templateLabel),
      formatMessage(messages.recipientsLabel),
      formatMessage(messages.contentLabel),
      formatMessage(messages.reviewLabel),
    ];
  }, [formatMessage]);

  return (
    <>
      {!mediumAndUp ? (
        <CampaignsMobileBreadCrumbWrapper
          {...{
            showBreadCrumb,
            steps,
            currentStep,
            onBack,
          }}
        />
      ) : (
        <DesktopCampaignsBreadCrumbWrapper
          {...{
            showBreadCrumb,
            steps,
            currentStep,
            onBack,
          }}
        />
      )}
    </>
  );
}

function CampaignsMobileBreadCrumbWrapper({
  showBreadCrumb,
  currentStep,
  steps,
  onBack,
}: InternalCampaignsBreadCrumbProps): React.ReactNode {
  const numberOfSteps = steps.length;
  const stepIndex = steps.findIndex(step => step === currentStep) + 1;

  return (
    <div className={styles.breadcrumbMobileContainer}>
      <div className={styles.headingBox}>
        <div className={styles.mobileContent}>
          <div className={styles.backButton}>
            <Button
              variation="subtle"
              type="tertiary"
              icon="arrowLeft"
              ariaLabel="arrowLeft"
              onClick={onBack}
            />
          </div>
          {showBreadCrumb && (
            <div className={styles.mobileContentText}>
              <Text size={"large"}>
                Step {stepIndex} of {numberOfSteps}
              </Text>
              <Emphasis variation={"bold"}>
                <Heading level={3}>{currentStep}</Heading>
              </Emphasis>
            </div>
          )}
        </div>
        {showBreadCrumb && (
          <div className={styles.mobileProgressBar}>
            <ProgressBar
              currentStep={stepIndex}
              totalSteps={numberOfSteps}
              size={"smaller"}
            />
          </div>
        )}
      </div>
    </div>
  );
}

function DesktopCampaignsBreadCrumbWrapper({
  showBreadCrumb,

  currentStep,
  steps,
}: InternalCampaignsBreadCrumbProps): React.ReactNode {
  if (!showBreadCrumb) {
    return null;
  }
  const renderedSteps = steps.map((step, index) => {
    const isCurrentStep = step === currentStep;
    const addIcon = index !== steps.length - 1;
    return (
      <div key={index} className={styles.parentItem}>
        <div className={styles.breadCrumb}>
          <Heading level={4}>
            {isCurrentStep ? (
              step
            ) : (
              <span className={styles.inactiveBreadCrumb}>{step}</span>
            )}
          </Heading>
        </div>
        {addIcon && <Icon name={"arrowRight"} />}
      </div>
    );
  });

  return (
    <div className={styles.breadcrumbContainer}>
      <div className={styles.navigationContainer}>{renderedSteps}</div>
    </div>
  );
}
