import React, { useState } from "react";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import { useBreakpoints } from "@jobber/hooks/useBreakpoints";
import { Heading } from "@jobber/components/Heading";
import { Icon } from "@jobber/components/Icon";
import { type MessageDescriptor, useIntl } from "react-intl";
import type { StepComponentProps } from "./types";
import styles from "./styles.module.css";
import { messages } from "./messages";

export function StepComponent({
  navigation,
  stepDetails,
}: StepComponentProps): JSX.Element {
  const { formatMessage: fm } = useIntl();
  const { largeAndUp } = useBreakpoints();
  const fullWidthButton = largeAndUp ? false : true;

  const [buttonLoading, setButtonLoading] = useState(false);

  const getDescriptionSubText = (item: MessageDescriptor) => {
    return (
      <div className={styles.descriptionSubText}>
        <Icon name="checkmark" />
        <Text>{fm(item)} </Text>
      </div>
    );
  };

  return (
    <div className={styles.stepContainer}>
      <div className={styles.column}>
        <Heading level={1}>{fm(messages.title)}</Heading>

        {messages.subtitle && (
          <div className={styles.subtitle}>
            <Text variation="subdued" size="large">
              {fm(messages.subtitle)}
            </Text>
          </div>
        )}

        <div className={styles.descriptionTitleContainer}>
          <Text>{fm(messages.description)} </Text>
        </div>

        <div className={styles.descriptionSubTextContainer}>
          {getDescriptionSubText(messages.descriptionSubText1)}
          {getDescriptionSubText(messages.descriptionSubText2)}
          {getDescriptionSubText(messages.descriptionSubText3)}
        </div>

        <div className={styles.buttonRow}>
          <Button
            fullWidth={fullWidthButton}
            type={"primary"}
            onClick={() => {
              setButtonLoading(true);
              if (stepDetails.primaryButton.action !== undefined) {
                stepDetails.primaryButton.action();
              } else {
                navigation.onNext();
              }
            }}
            loading={buttonLoading}
            label={fm(stepDetails.primaryButton.label)}
            ariaLabel={fm(stepDetails.primaryButton.label)}
          />

          {stepDetails.secondaryButton && (
            <Button
              fullWidth={fullWidthButton}
              variation={"work"}
              type={"tertiary"}
              onClick={() => {
                if (stepDetails.secondaryButton.action !== undefined) {
                  stepDetails.secondaryButton.action();
                }
              }}
              label={fm(stepDetails.secondaryButton.label)}
              ariaLabel={fm(stepDetails.secondaryButton.label)}
            />
          )}
        </div>
      </div>

      <div className={styles.imgContainer}>
        <img
          className={styles.img}
          src={stepDetails.image.src}
          alt={fm(stepDetails.image.alt)}
        />
      </div>
    </div>
  );
}
