import { gql } from "@apollo/client";

export const GET_LIMITS_CHANGE_REQUEST = gql`
  query GetLimitsChangeRequests {
    jobberPaymentsSettings {
      achLimitsChangeRequest {
        id
        limitsType
        status
        requestedAt
      }
      ccLimitsChangeRequest {
        id
        limitsType
        status
        requestedAt
      }
    }
  }
`;
