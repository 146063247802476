import { defineMessages } from "react-intl";

export const messages = defineMessages({
  dpnMissingDescription: {
    id: "billing.subscriptionAddonCard.dpnMissingDescription",
    defaultMessage: "Dedicated phone number is required to use AI Receptionist",
    description: "dpn missing message text",
  },
  dpnMissingBeforeSubscriptionDescription: {
    id: "billing.subscriptionAddonCard.dpnMissingBeforeSubscriptionDescription",
    defaultMessage:
      "Dedicated phone number is required to use AI Receptionist, after you subscribe you can set one up for free",
    description:
      "dpn missing message text that appears before the sp subscribes",
  },
  setUp: {
    id: "billing.subscriptionAddonCard.setUp",
    defaultMessage: "Set one up",
    description: "dpn missing set up button text",
  },
  setUpAriaLabel: {
    id: "billing.subscriptionAddonCard.setUpAriaLabel",
    defaultMessage: "Set a dedicated phone number up",
    description: "dpn missing set up aria label",
  },
});
