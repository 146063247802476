import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Button } from "@jobber/components/Button";
import { Glimmer } from "@jobber/components";
import { messages } from "jobber/campaigns/views/CampaignsReviewPage/messages";

export interface CompanyAddressInfo {
  street1?: string;
  street2?: string;
  city?: string;
  province?: string;
  postalCode?: string;
  country?: string;
}

export interface BottomBarDefaultFlowProps {
  enableAutomation: boolean | undefined;
  onNext: () => void;
  setShowScheduleSendModal: (value: boolean) => void;
  validateCampaignForSending: () => boolean;
  loadingAll: boolean;
  submitButtonLabel: string;
  existingAddress: CompanyAddressInfo;
  checkCompanyAddress: (address: CompanyAddressInfo) => boolean;
  setIsCompanyAddressModalOpen: (value: boolean) => void;
}

export function BottomBarDefaultFlow({
  enableAutomation,
  onNext,
  setShowScheduleSendModal,
  validateCampaignForSending,
  loadingAll,
  submitButtonLabel,
  existingAddress,
  checkCompanyAddress,
  setIsCompanyAddressModalOpen,
}: BottomBarDefaultFlowProps): JSX.Element {
  const { formatMessage } = useIntl();
  const [initialPageLoading, setInitialPageLoading] = useState(true);

  useEffect(() => {
    if (!loadingAll && initialPageLoading) {
      setInitialPageLoading(false);
    }
  }, [initialPageLoading, loadingAll]);

  const handleScheduleSendClick = () => {
    if (validateCampaignForSending()) {
      const isFullCompanyAddressPresent = checkCompanyAddress(existingAddress);

      if (isFullCompanyAddressPresent) {
        setShowScheduleSendModal(true);
      } else {
        setIsCompanyAddressModalOpen(true);
      }
    }
  };

  return (
    <>
      {!enableAutomation && !initialPageLoading && (
        <Button
          label={formatMessage(messages.sendCampaignLaterButton)}
          onClick={handleScheduleSendClick}
          variation={"work"}
          type={"secondary"}
          fullWidth={true}
          loading={loadingAll}
        />
      )}
      {initialPageLoading ? (
        <Glimmer.Button />
      ) : (
        <Button
          label={submitButtonLabel}
          onClick={() => onNext()}
          variation={"work"}
          fullWidth={true}
          loading={loadingAll}
        />
      )}
    </>
  );
}
