import { defineMessages } from "react-intl";

export const complianceStartMessages = defineMessages({
  gettingStartedHeader: {
    id: "ukKycRegistration.gettingStarted.gettingStartedHeader",
    defaultMessage: "Register your business",
    description: "Header for UK KYC registration",
  },
  business: {
    id: "ukKycRegistration.gettingStarted.business",
    defaultMessage: "Business",
    description: "Option type for UK KYC registration - business",
  },
  individual: {
    id: "ukKycRegistration.gettingStarted.individual",
    defaultMessage: "Individual",
    description: "Option type for UK KYC registration - individual",
  },
  continueButton: {
    id: "ukKycRegistration.gettingStarted.continueButton",
    defaultMessage: "Continue",
    description: "CTA text for continue button",
  },
  businessRegistrationNotice: {
    id: "ukKycComplianceRegistration.UkKycComplianceRegistrationMain.businsessRegistrationNotice",
    defaultMessage:
      "Business registration requires the upload of incorporation documents",
    description: "Notice for business registration",
  },
});
