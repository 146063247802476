import { defineMessages } from "react-intl";

export const messages = defineMessages({
  reviews: {
    id: "reviews.benchmark.competitor_list.reviews",
    description: "reviews",
    defaultMessage: "reviews",
  },
  sp: {
    id: "reviews.benchmark.competitor_list.sp",
    description: "SP indicatior that this is their business",
    defaultMessage: "Your business",
  },
});
