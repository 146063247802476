import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "setupGuide.previewTemplate.title",
    defaultMessage: "Preview templates",
    description: "Title for the preview template step",
  },
  description: {
    id: "setupGuide.previewTemplate.description",
    defaultMessage:
      "Clients are sent one initial message and two follow-ups if they do not use your review link",
    description: "Description for the preview template step",
  },
  primaryButton: {
    id: "setupGuide.previewTemplate.primaryButton",
    defaultMessage: "Continue",
    description: "Button that navigates to the reviews page",
  },
});
