import React from "react";
import type { DatumValue, Point, Serie } from "@nivo/line";
import { ResponsiveLine } from "@nivo/line";
import {
  Content,
  Divider,
  Flex,
  Text,
  useAtlantisTheme,
} from "@jobber/components";
import { format, subDays } from "date-fns";
import { useIntl } from "react-intl";
import type { BarCustomLayerProps, BarDatum } from "@nivo/bar";
import { MarketingTick } from "~/jobber/features/Marketing/views/Dashboard/charts/MarketingTick";
import {
  generateDateSegments,
  getYAxisBounds,
  isSeriesEmpty,
  roundActivationDate,
  useTimeScaleTickValues,
} from "jobber/reviews/views/ReviewsPage/utilities";
import { ChartTooltip } from "jobber/dataVisualizations/ChartTooltip";
import { HoverPoint } from "jobber/dataVisualizations/LineChart/HoverPoint";
import { messages } from "jobber/reviews/views/ReviewsPage/messages";

export interface ReviewTrendLineChartProps {
  activationDate?: Date;
  requestsData?: Array<Serie>;
  reviewsTotalData?: Array<Serie>;
}

export function ReviewTrendLineChart({
  activationDate,
  requestsData,
  reviewsTotalData,
}: ReviewTrendLineChartProps) {
  const { theme, tokens } = useAtlantisTheme();
  const defaultChartColor =
    theme === "dark"
      ? tokens["color-base-purple--500"]
      : tokens["color-base-purple--700"];

  const { firstSeries, minY, maxY } = parseSeries(
    reviewsTotalData,
    requestsData,
  );
  const timeScaleTickValues = useTimeScaleTickValues(firstSeries);
  const activationDatePoint =
    activationDate && roundActivationDate(activationDate, firstSeries);

  const xAxisLayer = (layerProps: BarCustomLayerProps<BarDatum>) => {
    return (
      <line
        x1={firstSeries ? 0 : 1}
        y1={layerProps.innerHeight}
        x2={firstSeries ? layerProps.innerWidth : layerProps.innerWidth + 2}
        y2={layerProps.innerHeight}
        stroke="var(--color-base-grey--300)"
      />
    );
  };

  return (
    <ResponsiveLine
      animate={true}
      axisBottom={{
        renderTick: tick => (
          <MarketingTick
            tick={tick}
            y={20}
            x={0}
            anchor="middle"
            // eslint-disable-next-line no-date-parsing/no-date-parsing
            formatValue={val => format(new Date(val), "MMM d")}
          />
        ),
        tickValues: timeScaleTickValues,
      }}
      axisLeft={
        firstSeries && {
          renderTick: tick => (
            <MarketingTick
              tick={tick}
              x={-5}
              y={0}
              formatValue={val => Number(val).toString()}
            />
          ),
          tickValues: [minY, maxY],
        }
      }
      colors={serie => {
        return serie.id === "requests"
          ? tokens["color-base-grey--400"]
          : defaultChartColor;
      }}
      data={
        firstSeries == undefined
          ? [
              {
                id: "empty",
                data: generateDateSegments().map(date => ({
                  x: date,
                  y: undefined,
                })),
              },
            ]
          : [...(reviewsTotalData ?? []), ...(requestsData ?? [])]
      }
      defs={[
        {
          id: "gradientReviews",
          type: "linearGradient",
          colors: [
            { offset: 0, color: defaultChartColor },
            { offset: 100, color: tokens["color-surface"] },
          ],
        },
        {
          id: "gradientRequests",
          type: "linearGradient",
          colors: [
            { offset: 0, color: tokens["color-base-grey--400"] },
            { offset: 100, color: tokens["color-surface"] },
          ],
        },
      ]}
      areaBaselineValue={minY}
      enableArea={true}
      enableCrosshair={firstSeries != undefined}
      enableGridY={false}
      enablePoints={false}
      enableSlices="x"
      fill={[
        { match: ({ id }) => id === "reviews", id: "gradientReviews" },
        { match: ({ id }) => id === "requests", id: "gradientRequests" },
      ]}
      gridXValues={timeScaleTickValues}
      layers={[
        "grid",
        "markers",
        "axes",
        xAxisLayer,
        "areas",
        "crosshair",
        "lines",
        "slices",
        "points",
        HoverPoint,
        "mesh",
      ]}
      markers={
        activationDatePoint
          ? [
              {
                axis: "x",
                lineStyle: {
                  stroke: tokens["color-base-teal--500"],
                  strokeWidth: 1,
                },
                value: activationDatePoint,
              },
            ]
          : []
      }
      margin={{ top: 15, right: 25, bottom: 25, left: 25 }}
      pointSize={1}
      sliceTooltip={({ slice }) => {
        return (
          <ReviewTrendTooltip
            slice={slice}
            activationInfo={
              activationDate
                ? {
                    date: activationDate,
                    point: activationDatePoint ?? undefined,
                  }
                : undefined
            }
          />
        );
      }}
      xFormat="time:%b %d"
      xScale={{
        type: "time",
        format: "native",
        useUTC: false,
        precision: "day",
      }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
      }}
    />
  );
}

function parseSeries(
  reviewsTotalData?: Serie[],
  requestsData?: Serie[],
): { firstSeries: Serie[] | undefined; minY: number; maxY: number } {
  const dataEmpty =
    isSeriesEmpty(reviewsTotalData) && isSeriesEmpty(requestsData);
  if (dataEmpty) {
    return { firstSeries: undefined, minY: 0, maxY: 5 };
  }

  const { minY, maxY } = dataEmpty
    ? { minY: 0, maxY: 5 }
    : getYAxisBounds({
        series: [...(reviewsTotalData ?? []), ...(requestsData ?? [])],
      });
  const firstSeries = (reviewsTotalData?.[0]?.data ??
    requestsData?.[0]?.data) as Serie[];
  return {
    firstSeries,
    minY,
    maxY,
  };
}

interface Slice {
  id: DatumValue;
  height: number;
  width: number;
  x0: number;
  x: number;
  y0: number;
  y: number;
  points: readonly Point[];
}

interface Slice {
  id: DatumValue;
  height: number;
  width: number;
  x0: number;
  x: number;
  y0: number;
  y: number;
  points: readonly Point[];
}

interface ReviewTrendTooltipProps {
  slice: Slice;
  activationInfo?: {
    date: Date;
    point: Date | undefined;
  };
}

function ReviewTrendTooltip({
  slice,
  activationInfo,
}: ReviewTrendTooltipProps): JSX.Element {
  const { formatMessage } = useIntl();
  const { tokens } = useAtlantisTheme();
  const reviewsPoint = slice.points.find(point => point.serieId === "reviews");
  const requestsPoint = slice.points.find(
    point => point.serieId === "requests",
  );
  if (
    slice.id === "empty" ||
    (reviewsPoint == undefined && requestsPoint == undefined)
  ) {
    return <></>;
  }

  const endDate = (reviewsPoint?.data.x ?? requestsPoint?.data.x) as Date;
  const startDate = subDays(endDate, 30);
  const showActivationDate = activationInfo && activationInfo.point == endDate;
  const showRequested = requestsPoint && (requestsPoint.data.y as number) > 0;
  const conversionRate =
    showRequested && reviewsPoint
      ? `${Math.round(((reviewsPoint.data.y as number) / (requestsPoint.data.y as number)) * 100)}%`
      : "-";

  return (
    <ChartTooltip
      label={`${format(startDate, "MMM d")} - ${format(endDate, "MMM d")}`}
      content={
        <div style={{ minWidth: "180px" }}>
          <Content spacing="smaller">
            {showActivationDate && (
              <Text size="small">
                <div style={{ color: tokens["color-base-teal--500"] }}>
                  {formatMessage(messages.activationDate, {
                    date: format(activationInfo.date, "MMM d"),
                  })}
                </div>
              </Text>
            )}
            {showRequested && (
              <Flex template={["grow", "shrink"]} direction="row">
                <DataIndicator
                  label={formatMessage(messages.requested)}
                  color={tokens["color-border"]}
                />
                <Text size="small">{requestsPoint.data.y as number}</Text>
              </Flex>
            )}
            {reviewsPoint && (
              <Flex template={["grow", "shrink"]} direction="row">
                <DataIndicator
                  label={formatMessage(messages.received)}
                  color={tokens["color-base-purple--700"]}
                />
                <Text size="small">{reviewsPoint.data.y as number}</Text>
              </Flex>
            )}
            <Divider direction="horizontal" />
            <Flex template={["grow", "shrink"]} direction="row">
              <Text size="small">{formatMessage(messages.conversionRate)}</Text>
              <Text size="small">{conversionRate}</Text>
            </Flex>
          </Content>
        </div>
      }
    />
  );
}

function DataIndicator({
  label,
  color,
}: {
  label: string;
  color: string;
}): JSX.Element {
  return (
    <Flex template={["shrink", "shrink"]} direction="row" gap="smaller">
      <div
        style={{
          backgroundColor: color,
          width: "8px",
          height: "8px",
          borderRadius: "100%",
        }}
      />
      <Text size="small">{label}</Text>
    </Flex>
  );
}
