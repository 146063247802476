import { gql } from "@apollo/client";

export const ctaComponentData = gql`
  fragment ctaComponentData on CallToAction {
    id
    timesShown
    shouldShow
  }
`;

export const CTA_QUERY = gql`
  query CtaLookup($id: String!) {
    callToAction(id: $id) {
      ...ctaComponentData
    }
  }

  ${ctaComponentData}
`;

export const CTAS_QUERY = gql`
  query CtasLookup($ids: [String!]!) {
    callToActions(ids: $ids) {
      nodes {
        ...ctaComponentData
      }
    }
  }

  ${ctaComponentData}
`;

export const CTA_SHOWN = gql`
  mutation CtaShown($id: String!) {
    ctaShown(id: $id) {
      callToAction {
        id
      }
    }
  }
`;

export const CTA_CONVERT = gql`
  mutation CtaConvert($id: String!) {
    ctaConvert(id: $id) {
      callToAction {
        ...ctaComponentData
      }
    }
  }

  ${ctaComponentData}
`;

export const CTA_DISMISS = gql`
  mutation CtaDismiss($id: String!) {
    ctaDismiss(id: $id) {
      callToAction {
        ...ctaComponentData
      }
    }
  }

  ${ctaComponentData}
`;
