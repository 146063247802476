import { defineMessages } from "react-intl";

export const messages = defineMessages({
  generalError: {
    id: "reviews.reviewsPage.generalErrorBanner",
    defaultMessage: "Something went wrong. Please try again later.",
    description:
      "A general warning that something has gone wrong while loading Google reviews",
  },
  credentialError: {
    id: "reviews.reviewsPage.credentialErrorBanner",
    defaultMessage:
      "Something went wrong. Please login to your Google Business Account and try again.",
    description: "An error when the user's google creds are missing",
  },
  reviewsHeader: {
    id: "reviews.reviewsPage.reviewsHeader",
    defaultMessage: "Reviews",
    description: "Large title that is displayed at the top of the page",
  },
  overviewHeader: {
    id: "reviews.reviewsPage.overviewHeader",
    defaultMessage: "Overview",
    description: "Title that is displayed at the top of the summary section",
  },
  ratingsHeader: {
    id: "reviews.reviewsPage.ratingsHeader",
    defaultMessage: "Average rating",
    description: "Title that is displayed at the top of rating summary",
  },
  smsHeader: {
    id: "reviews.reviewsPage.smsHeader",
    defaultMessage: "SMS sent",
    description: "Title that is displayed at the top of sms summary",
  },
  reviewsTotalHeader: {
    id: "reviews.reviewsPage.reviewsTotalHeader",
    defaultMessage: "Total reviews",
    description: "Title that is displayed at the top of reviews summary",
  },
  reviewsTotalSubheader: {
    id: "reviews.reviewsPage.reviewsTotalSubheader",
    defaultMessage: "Since using Jobber Reviews",
    description: "Subheader that is displayed at the top of reviews kpi card",
  },
  reviewsTotalTooltip: {
    id: "reviews.reviewsPage.reviewsTotalTooltip",
    defaultMessage: "New review increase",
    description: "Tooltip that is displayed at the top of reviews kpi card",
  },
  reviewsAverageTooltip: {
    id: "reviews.reviewsPage.reviewsAverageTooltip",
    defaultMessage: "Your avg rating increase",
    description: "Tooltip that is displayed at the top of reviews kpi card",
  },
  previousDescription: {
    id: "reviews.reviewsPage.previousDescription",
    defaultMessage: "Previously {amount}",
    description: "Description that is displayed at the top of reviews kpi card",
  },
  reviewTrendsHeader: {
    id: "reviews.reviewsPage.reviewTrendsHeader",
    defaultMessage: "Review growth",
    description: "Title that is displayed at the top of review trends kpi card",
  },
  reviewTrendsSubheader: {
    id: "reviews.reviewsPage.reviewTrendsSubheader",
    defaultMessage: "Past 90 days",
    description:
      "Subheader that is displayed at the top of review trends kpi card",
  },
  reviewTrendsTooltip: {
    id: "reviews.reviewsPage.reviewTrendsTooltip",
    defaultMessage: "Cumulative review growth over the past 90 days",
    description:
      "Tooltip that is displayed at the top of review trends kpi card",
  },
  responseTimeHeader: {
    id: "reviews.reviewsPage.responseTimeHeader",
    defaultMessage: "Avg response time",
    description: "Title that is displayed at the top of response time kpi card",
  },
  responseTimeSubheader: {
    id: "reviews.reviewsPage.responseTimeSubheader",
    defaultMessage: "Past 30 days",
    description:
      "Subheader that is displayed at the top of response time kpi card",
  },
  responseTimeFooterOffTrack: {
    id: "reviews.reviewsPage.responseTimeFooterOffTrack",
    defaultMessage: "Recommended Avg: {amount}hrs",
    description:
      "Footer that is displayed at the bottom of response time kpi card",
  },
  responseTimeFooterOnTrack: {
    id: "reviews.reviewsPage.responseTimeFooterOnTrack",
    defaultMessage: "Recommended Avg: {amount}hrs – You're on track!",
    description:
      "Footer that is displayed at the bottom of response time kpi card",
  },
  hours: {
    id: "reviews.reviewsPage.hours",
    defaultMessage: "{amount}hrs",
    description: "Label for hours",
  },
  requested: {
    id: "reviews.reviewsPage.requested",
    defaultMessage: "Requested",
    description: "Label for trends tooltiprequested",
  },
  received: {
    id: "reviews.reviewsPage.received",
    defaultMessage: "Received",
    description: "Label for trends tooltip received",
  },
  activationDate: {
    id: "reviews.reviewsPage.activationDate",
    defaultMessage: "Activation date: {date}",
    description: "Label for trends tooltip activation date",
  },
  conversionRate: {
    id: "reviews.reviewsPage.conversionRate",
    defaultMessage: "Conversion rate",
    description: "Label for trends tooltip conversion rate",
  },
  latestReviewsHeader: {
    id: "reviews.reviewsPage.latestReviewsHeader",
    defaultMessage: "Latest reviews",
    description: "Title that is displayed at the top of latest reviews",
  },
  noReviews: {
    id: "reviews.reviewsPage.noReviews",
    defaultMessage: "No reviews have been left yet",
    description: "Message that is displayed when there are no reviews",
  },
  noUnansweredReviews: {
    id: "reviews.reviewsPage.noUnansweredReviews",
    defaultMessage: "No unanswered reviews",
    description:
      "Message that is displayed when there are no unanswered reviews",
  },
  showAll: {
    id: "reviews.reviewsPage.showAll",
    defaultMessage: "Show all",
    description: "Label for the show all button",
  },
  settingsLabel: {
    id: "reviews.reviewsPage.settingsLabel",
    defaultMessage: "Settings",
    description: "Title for the button that opens the settings drawer",
  },
  CTAModalHeader: {
    id: "reviews.reviewsPage.CTAModalHeader",
    defaultMessage: "Customize your message",
    description: "Header for the customization CTA modal",
  },
  CTAModalButton: {
    id: "reviews.reviewsPage.CTAModalButton",
    defaultMessage: "Customize",
    description: "Button for the customization CTA modal",
  },
  CTAModalBody: {
    id: "reviews.reviewsPage.CTAModalBody",
    defaultMessage:
      "Adjust your review message template to match the tone of your business and get more reviews!",
    description: "Body text for the customization CTA modal",
  },
  clientMatchesButtonLabel: {
    id: "reviews.reviewsPage.clientMatchesButtonLabel",
    defaultMessage: "Client Matches",
    description: "Label for the client matches button",
  },
});
