import type { ApolloError } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { INITIAL_MESSAGE_SETTINGS_QUERY } from "jobber/reviews/views/ReviewsPage/components/ReviewsSettings/ReviewsSettings.graphql";
import { messages as messageCTA } from "jobber/reviews/views/ReviewsPage/components/Resources/messages";
import type {
  InitialMessageSettingsQuery,
  MessageTemplateEmail,
  MessageTemplateSms,
} from "~/utilities/API/graphql";
import { DeliveryMethod, RequestTrigger } from "~/utilities/API/graphql";

interface useInitialMessageQueryInsightsData {
  deliveryMethod: DeliveryMethod;
  triggerType: RequestTrigger;
  email: MessageTemplateEmail;
  sms: MessageTemplateSms;
}

export interface useInitialMessageQueryData {
  dynamicTemplates: useInitialMessageQueryInsightsData;
  staticTemplates: Array<MessageTemplateSms>;
  loading: boolean;
  fetchMethodError: ApolloError | undefined;
}

export function useInitialMessageQuery(
  showInsights: boolean,
): useInitialMessageQueryData {
  const {
    data,
    loading,
    error: fetchMethodError,
  } = useQuery<InitialMessageSettingsQuery>(INITIAL_MESSAGE_SETTINGS_QUERY);

  const deliveryMethod: DeliveryMethod =
    data?.reviewsInitialMessageSettings?.deliveryMethod ||
    DeliveryMethod.EMAIL_AND_SMS;

  const triggerType: RequestTrigger =
    data?.reviewsInitialMessageSettings?.triggerType || RequestTrigger.INVOICE;

  const queryValues = {
    deliveryMethod: deliveryMethod,
    triggerType: triggerType,
    email: data?.reviewRequest?.templates?.nodes[0] as MessageTemplateEmail,
    sms: data?.reviewV2Request?.templates?.nodes[0] as MessageTemplateSms,
  };

  const insightValues = {
    deliveryMethod: deliveryMethod,
    triggerType: triggerType,
    email: {
      ...(data?.reviewRequest?.templates?.nodes[0] as MessageTemplateEmail),
      message: {
        current: messageCTA.templateText.defaultMessage,
        default: queryValues?.email?.message.default,
      },
    } as MessageTemplateEmail,
    sms: {
      ...(data?.reviewV2Request?.templates?.nodes[0] as MessageTemplateSms),
      message: {
        current: messageCTA.templateText.defaultMessage,
        default: queryValues?.sms?.message.default,
      },
    } as MessageTemplateSms,
  };

  const staticTemplates = [
    data?.workOrderReviewTransactional?.templates
      ?.nodes[0] as MessageTemplateSms,
    data?.reviewV2Transactional?.templates?.nodes[0] as MessageTemplateSms,
  ];

  return {
    dynamicTemplates: showInsights ? insightValues : queryValues,
    staticTemplates,
    loading,
    fetchMethodError,
  };
}
