import type { Dispatch, SetStateAction } from "react";
import React from "react";
import { useIntl } from "react-intl";
import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Emphasis } from "@jobber/components/Emphasis";
import { PurchaseButton } from "jobber/billing/features/Checkout/components/CheckoutSummary/components/PurchaseButton/PurchaseButton";
import { BillingDetails } from "~/shared/billing/pricePreview/components/BillingDetails";
import { CheckoutPurchaseDisclaimer } from "~/shared/billing/pricePreview/components/CheckoutPurchaseDisclaimer/CheckoutPurchaseDisclaimer";
import { TermsOfServiceAndPrivacyPolicyLinks } from "jobber/billing/features/Checkout/components/CheckoutSummary/components/TermsOfServiceAndPrivacyPolicyLinks";
import type { BillingCycleName } from "~/utilities/API/graphql";
import { messages } from "jobber/billing/features/Checkout/components/CheckoutSummary/messages";
import {
  getDiscountInformationByBillingCycle,
  savingsMessage,
} from "~/shared/billing/pricePreview/utils";
import { getDiscountHeader } from "jobber/billing/features/Checkout/utils";
import type {
  PlanInfo,
  SubscriptionAddonPreview,
  SubscriptionDiscountGroup,
  SubscriptionPreview,
} from "~/shared/billing/pricePreview/types";
import styles from "./DesktopSummary.module.css";

export interface DesktopSummaryProps {
  planInfo: PlanInfo;
  discountInformation: SubscriptionDiscountGroup;
  subscriptionPreview: SubscriptionPreview;
  selectedBillingCycle: BillingCycleName;
  viewingCurrentPlan: boolean;
  subscribeDisabled: boolean;
  enablePurchaseButton: boolean;
  subscriptionAddons: SubscriptionAddonPreview[];
  selectedAddonCodes: string[];
  shouldShowTax: boolean;
  purchaseTotal: number;
  onSelectBillingCycle: (cycle: BillingCycleName) => void;
  onConfirmSubscription?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  setSelectedAddons: Dispatch<SetStateAction<string[]>>;
}

export function DesktopSummary({
  planInfo,
  discountInformation,
  subscriptionPreview,
  selectedBillingCycle,
  viewingCurrentPlan,
  subscribeDisabled,
  enablePurchaseButton,
  subscriptionAddons,
  selectedAddonCodes,
  shouldShowTax,
  purchaseTotal,
  onSelectBillingCycle,
  onConfirmSubscription,
  setSelectedAddons,
}: DesktopSummaryProps) {
  const { formatMessage } = useIntl();

  const discountInformationByBillingCycle =
    getDiscountInformationByBillingCycle(
      selectedBillingCycle,
      discountInformation,
    );

  return (
    <>
      <div className={styles.container}>
        <Content>
          <Heading level={4}>{formatMessage(messages.summary)}</Heading>
          <div className={styles.tierNameContainer}>
            <Heading level={2}>{planInfo.planTier}</Heading>
            <div className={styles.savingsMessage}>
              <Emphasis variation={"highlight"}>
                {getDiscountHeader(
                  formatMessage,
                  selectedBillingCycle,
                  discountInformationByBillingCycle?.discountType,
                  discountInformationByBillingCycle?.discountAmount,
                  discountInformationByBillingCycle?.remainingDiscountedFullMonths,
                )}
              </Emphasis>
            </div>
          </div>
          <BillingDetails
            monthlyPrice={planInfo?.monthlyBillingCycle?.monthlyPrice}
            annualPrice={planInfo?.annualBillingCycle?.monthlyPrice}
            savingsMessage={savingsMessage(
              formatMessage,
              planInfo?.monthlyBillingCycle?.annualPrice,
              planInfo?.annualBillingCycle?.annualPrice,
            )}
            subscriptionPreview={subscriptionPreview}
            discountInformation={discountInformation}
            selectedBillingCycle={selectedBillingCycle}
            viewingCurrentPlan={viewingCurrentPlan}
            subscriptionAddons={subscriptionAddons}
            selectedAddonCodes={selectedAddonCodes}
            shouldShowTax={shouldShowTax}
            purchaseTotal={purchaseTotal}
            onSetSelectedCycle={onSelectBillingCycle}
            setSelectedAddons={setSelectedAddons}
          />
          <CheckoutPurchaseDisclaimer
            viewingCurrentPlan={viewingCurrentPlan}
            subscriptionPreview={subscriptionPreview}
            selectedBillingCycle={selectedBillingCycle}
            planInfo={planInfo}
            discountInformation={discountInformation}
            selectedAddonCodes={selectedAddonCodes}
            subscriptionAddons={subscriptionAddons}
          />
          <PurchaseButton
            viewingCurrentPlan={viewingCurrentPlan}
            enablePurchaseButton={enablePurchaseButton}
            subscribeDisabled={subscribeDisabled}
            onConfirmSubscription={onConfirmSubscription}
          />
        </Content>
      </div>
      {!viewingCurrentPlan && (
        <div className={styles.termsOfService}>
          <TermsOfServiceAndPrivacyPolicyLinks />
        </div>
      )}
    </>
  );
}
