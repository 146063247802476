import { gql } from "@apollo/client";

export const PRIORITY_SUPPORT_INTERCOM_CONVERSATION = gql`
  mutation prioritySupportIntercomConversationCreate(
    $input: IntercomConversationCreateInput!
  ) {
    intercomConversationCreate(input: $input) {
      conversationId
      userErrors {
        message
      }
    }
  }
`;
