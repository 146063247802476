import { useQuery } from "@apollo/client";
import { JobberPaymentsLimitsChangeRequestLimits } from "~/utilities/API/graphql";
import { GET_LIMITS_CHANGE_REQUEST } from "./getLimitsChangeRequest.graphql";

export const useLimitsChangeRequest = (
  limitsType: JobberPaymentsLimitsChangeRequestLimits,
) => {
  const { data, loading, error } = useQuery(GET_LIMITS_CHANGE_REQUEST);

  const limitsChangeRequest =
    limitsType === JobberPaymentsLimitsChangeRequestLimits.ACH
      ? data?.jobberPaymentsSettings?.achLimitsChangeRequest
      : data?.jobberPaymentsSettings?.ccLimitsChangeRequest;

  return {
    data: limitsChangeRequest,
    loading,
    error,
  };
};
