import React from "react";
import { Content } from "@jobber/components/Content";
import { Spinner } from "@jobber/components/Spinner";
import { APIProvider } from "~/utilities/API/APIProvider";
import { IntlProvider } from "@translations/IntlProvider";
import { withSplitClient } from "utilities/split";
import { OnlinePaymentsSettings } from "./OnlinePaymentsSettings";
import { useOnlinePaymentsQueries } from "./useOnlinePaymentsQueries";

export interface OnlinePaymentsToggleProps {
  disableCreditCardPayments: boolean;
  disableAchPayments: boolean;
  showAchPayments: boolean;
  allowOnlinePayment: boolean;
  objectName: "quote" | "invoice";
  jobberPaymentsEnabled?: boolean;
  partialPaymentsDisabled?: boolean;
  isProgressInvoicing?: boolean;
  showPaymentsToggle?: boolean;
  mandatoryPaymentMethodOnFile?: boolean;
}

export function OnlinePaymentsToggle(props: OnlinePaymentsToggleProps) {
  return (
    <APIProvider>
      <IntlProvider>
        <InternalOnlinePaymentsToggleSplitWrapped
          {...props}
          data-testid="internal-online-payments-test-id"
        />
      </IntlProvider>
    </APIProvider>
  );
}

function InternalOnlinePaymentsToggle({
  objectName,
  showAchPayments,
  allowOnlinePayment,
  disableAchPayments,
  disableCreditCardPayments,
  partialPaymentsDisabled,
  isProgressInvoicing,
  showPaymentsToggle,
  jobberPaymentsEnabled,
  mandatoryPaymentMethodOnFile,
}: OnlinePaymentsToggleProps) {
  const { userData, loading } = useOnlinePaymentsQueries();

  if (loading) {
    return (
      <div data-testid="online-payments-toggle-spinner">
        <Spinner size={"base"} />
      </div>
    );
  }

  return (
    <div data-testid="OnlinePaymentsToggle-test-id">
      <Content>
        <OnlinePaymentsSettings
          showAchPayments={showAchPayments}
          disableCreditCardPayments={disableCreditCardPayments}
          disableAchPayments={disableAchPayments}
          currentUser={userData}
          objectName={objectName}
          allowOnlinePayment={allowOnlinePayment}
          partialPaymentsDisabled={partialPaymentsDisabled}
          isProgressInvoicing={isProgressInvoicing}
          showPaymentsToggle={showPaymentsToggle}
          jobberPaymentsEnabled={jobberPaymentsEnabled}
          initalMandatoryPaymentMethodOnFile={mandatoryPaymentMethodOnFile}
          data-testid="onlinePaymentsSettings-test-id"
        />
      </Content>
    </div>
  );
}

const InternalOnlinePaymentsToggleSplitWrapped = withSplitClient(
  InternalOnlinePaymentsToggle,
);
