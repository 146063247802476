import type { Dispatch, SetStateAction } from "react";
import React, { useState } from "react";
import { Page } from "@jobber/components/Page";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Glimmer } from "@jobber/components/Glimmer";
import { Text } from "@jobber/components/Text";
import { Heading } from "@jobber/components/Heading";
import { Banner } from "@jobber/components/Banner";
import { Checkbox } from "@jobber/components/Checkbox";
import { InputValidation } from "@jobber/components/InputValidation";
import { showToast } from "@jobber/components/Toast";
import { StatusLabel } from "@jobber/components/StatusLabel";
import { CampaignRecipientsSection } from "jobber/campaigns/views/CampaignsReviewPage/components/OldCampaignRecipientsSection/CampaignsRecipientSection";
import { SplitNames, useFeatureFlag } from "utilities/split";
import { useCampaignCheckFromEmail } from "jobber/campaigns/hooks/useCampaignCheckFromEmail";
import { ValidateEmailModal } from "jobber/campaigns/components/ValidateEmailModal/ValidateEmailModal";
import { transformCommsCampaign } from "jobber/campaigns/views/CampaignsReviewPage/hooks/utils";
import {
  CampaignsBreadCrumb,
  breadCrumbMessages,
} from "jobber/campaigns/components/Breadcrumbs";
import { BottomBar } from "jobber/campaigns/components/Bottombar/BottomBar";
import { IntlProvider } from "@translations/IntlProvider";
import { APIProvider } from "~/utilities/API/APIProvider";
import {
  CAMPAIGNS_LANDING_PAGE_PATH,
  CAMPAIGNS_PAGE_TITLE_STEPS,
} from "jobber/campaigns/constants";
import { useCommsCampaignTemplateQuery } from "jobber/campaigns/hooks/useCommsCampaignTemplateQuery";
import {
  CampaignReviewErrorState,
  useCampaignsReviewActions,
} from "jobber/campaigns/views/CampaignsReviewPage/hooks/useCampaignsReviewActions/useCampaignsReviewActions";
import { usePreventEditCampaign } from "jobber/campaigns/hooks/usePreventEditCampaign/usePreventEditCampaign";
import { useCustomClientSideValidation } from "jobber/campaigns/views/CampaignsReviewPage/hooks/useCustomClientSideValidation";
import { EmailPreview } from "jobber/campaigns/components/EmailPreview";
import { messages as bottomBarMessages } from "jobber/campaigns/components/Bottombar/messages";
import { useCommsCampaignReviewQuery } from "jobber/campaigns/views/CampaignsReviewPage/hooks/useCommsCampaignReviewQuery/useCommsCampaignReviewQuery";
import { AutomatedCampaignRecipientsSection } from "jobber/campaigns/views/CampaignsReviewPage/components/AutomatedCampaignRecipientsSection/AutomatedCampaignRecipientsSection";
import { BottomBarPurchaseFlow } from "jobber/campaigns/views/CampaignsReviewPage/components/BottomBarPurchaseFlow/BottomBarPurchaseFlow";
import { BottomBarDefaultFlow } from "jobber/campaigns/views/CampaignsReviewPage/components/BottomBarDefaultFlow/BottomBarDefaultFlow";
import { useEmailCampaignsPurchaseFlow } from "jobber/campaigns/hooks/useEmailCampaignsPurchaseFlow/useEmailCampaignsPurchaseFlow";
import { SendTestEmailForDemoModal } from "jobber/campaigns/components/SendTestEmailForDemoModal/SendTestEmailForDemoModal";
import { SendTestEmailModal } from "jobber/campaigns/components/SendTestEmailModal/SendTestEmailModal";
import { useCampaignCompanyInformation } from "jobber/campaigns/views/CampaignsContentPage/hooks/useCampaignCompanyInformation";
import { checkCompanyAddress } from "jobber/campaigns/views/CampaignsContentPage/utils/checkCompanyAddress";
import { CompanyAddressModal } from "jobber/campaigns/components/CompanyAddressModal/CompanyAddressModal";
import { messages } from "./messages";
import styles from "./CampaignsReviewPage.module.css";
import { StreamlinedCampaignsReviewPage } from "./StreamlinedCampaignsReviewPage";
import { ScheduleSendModal } from "../../components/ScheduleSendModal/ScheduleSendModal";

export interface EmptyClientSegmentErrorBannerProps {
  redirectToEdit: () => void;
}

function EmptyClientSegmentErrorBanner({
  redirectToEdit,
}: EmptyClientSegmentErrorBannerProps): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <Banner
      type={"error"}
      primaryAction={{
        label: formatMessage(messages.emptyClientSegmentErrorBannerCtaText),
        onClick: redirectToEdit,
      }}
    >
      {formatMessage(messages.emptyClientSegmentErrorBannerText)}
    </Banner>
  );
}

function CampaignSendErrorBanner({
  message,
  setErrorState,
}: {
  message: string;
  setErrorState: Dispatch<SetStateAction<CampaignReviewErrorState | null>>;
}): JSX.Element {
  return (
    <Banner type={"error"} onDismiss={() => setErrorState(null)}>
      {message}
    </Banner>
  );
}
export interface CampaignsReviewPageProps {
  campaignId: string;
  recurlyPublicKey: string;
  salesforceTrackingId: string | null;
}

export function CampaignsReviewPage(props: CampaignsReviewPageProps) {
  const isCampaignsStreamlinedEnabled = useFeatureFlag(
    SplitNames.CampaignsStreamlined,
  );

  return (
    <APIProvider>
      <IntlProvider>
        {isCampaignsStreamlinedEnabled ? (
          <StreamlinedCampaignsReviewPage {...props} />
        ) : (
          <CampaignsReviewPageInternal {...props} />
        )}
      </IntlProvider>
    </APIProvider>
  );
}

// eslint-disable-next-line max-statements
function CampaignsReviewPageInternal({
  campaignId,
  recurlyPublicKey,
  salesforceTrackingId,
}: CampaignsReviewPageProps): JSX.Element {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const [confirmImpliedConsentPermission, setConfirmImpliedConsentPermission] =
    useState(false);

  const [isSendATestModalOpen, setIsSendATestModalOpen] = useState(false);
  const [isSendATestModalInErrorState, setIsSendATestModalInErrorState] =
    useState<boolean>(false);
  const sharedSendTestEmailModalProps = {
    isOpen: isSendATestModalOpen,
    closeModal: () => {
      setIsSendATestModalInErrorState(false);
      setIsSendATestModalOpen(false);
    },
  };

  const [showScheduleSendModal, setShowScheduleSendModal] = useState(false);
  const [scheduleSendError, setScheduleSendError] = useState<
    string | undefined
  >(undefined);
  const [isCompanyAddressModalOpen, setIsCompanyAddressModalOpen] =
    useState(false);

  const {
    loadingCampaign,
    campaignData,
    loadingClientSegment,
    clientSegmentData,
    refetchCampaign,
    timeoutError,
  } = useCommsCampaignReviewQuery({
    campaignId: campaignId,
    onCampaignNotFound: () => {
      history.replace(CAMPAIGNS_LANDING_PAGE_PATH);
    },
  });

  const { template, loading: loadingTemplate } = useCommsCampaignTemplateQuery({
    campaignId,
  });

  const {
    loadingCompanyInformation,
    street1,
    street2,
    city,
    province,
    postalCode,
    country,
  } = useCampaignCompanyInformation();

  const existingAddress = {
    street1,
    street2,
    city,
    province,
    postalCode,
    country,
  };

  const enableAutomation = campaignData?.commsCampaign?.isAutomated;

  const {
    toggleModal,
    isFromEmailValidated,
    showValidateEmailModal,
    sendValidationCode,
    checkValidationCode,
    isCheckValidationCodeDataLoading,
    error,
  } = useCampaignCheckFromEmail({ campaignData, refetchCampaign });

  const {
    totalCount,
    segmentName,
    replyToEmail,
    fromEmail,
    consentingClientsCount,
  } = transformCommsCampaign({ campaignData, clientSegmentData });

  usePreventEditCampaign({
    campaignStatus: campaignData?.commsCampaign?.status,
  });

  const { validationErrors, checkIsFormValid } = useCustomClientSideValidation({
    validationRules: {
      confirmImpliedConsentPermission: () => {
        if (!confirmImpliedConsentPermission) {
          return formatMessage(messages.shouldShowConsentEntriesErrorMessage);
        }
      },
    },
  });

  const {
    previewEnabled,
    isMobileBilled,
    loading: previewLoading,
  } = useEmailCampaignsPurchaseFlow(campaignId, isFromEmailValidated);

  const loadingCampaignInformation = loadingCampaign || loadingTemplate;
  const loadingAll = loadingCampaignInformation || loadingClientSegment;

  const submitButtonLabel = () => {
    if (!isFromEmailValidated && !loadingCampaignInformation) {
      return formatMessage(bottomBarMessages.verifyFromEmail);
    }

    return enableAutomation
      ? formatMessage(messages.automatedCampaignActivateButton)
      : formatMessage(bottomBarMessages.sendCampaign);
  };

  const {
    errorState,
    setErrorState,
    redirectToRecipientsEdit,
    onNext,
    validateCampaignForSending,
    onCancel,
    actionLoading,
    onSendTestEmail,
    onSendTestEmailForDemo,
  } = useCampaignsReviewActions({
    campaignId,
    campaign: campaignData?.commsCampaign,
    totalCount,
    toggleEmailVerificationModal: toggleModal,
    loading: loadingAll,
    checkIsFormValid,
    timeoutError,
    hasDemoExperience:
      campaignData?.commsCampaignsExperience?.hasDemoExperience,
    userEmail: campaignData?.user?.email.raw || "",
    setIsSendATestModalOpen,
    setIsSendATestModalInErrorState,
    setIsCompanyAddressModalOpen,
  });

  const scheduleSend = async (date: Date) => {
    try {
      const isFullCompanyAddressPresent = checkCompanyAddress(existingAddress);
      await onNext(date, isFullCompanyAddressPresent);
      showToast({
        message: formatMessage(messages.scheduledCampaignSuccessToast),
        variation: "success",
      });
    } catch (err) {
      switch (err.message) {
        case CampaignReviewErrorState.SCHEDULE_INVALID:
          setScheduleSendError(
            formatMessage(messages.scheduledCampaignDateTimeErrorToast),
          );
          return;
        default:
          setScheduleSendError(
            formatMessage(messages.scheduledCampaignErrorToast),
          );
          return;
      }
    }
  };

  const onNextCallback = () => {
    const isFullCompanyAddressPresent = checkCompanyAddress(existingAddress);
    return onNext(undefined, isFullCompanyAddressPresent);
  };

  return (
    <div className={styles.container}>
      <CampaignsBreadCrumb
        currentStep={formatMessage(breadCrumbMessages.reviewLabel)}
        onBack={onCancel}
      />
      <ScheduleSendModal
        showModal={showScheduleSendModal}
        toggleModal={() => setShowScheduleSendModal(!showScheduleSendModal)}
        onSubmit={scheduleSend}
        loading={loadingCampaignInformation || actionLoading}
        error={scheduleSendError}
        onErrorDismiss={() => setScheduleSendError(undefined)}
      />
      {!loadingCompanyInformation && (
        <CompanyAddressModal
          showModal={isCompanyAddressModalOpen}
          existingAddress={existingAddress}
          closeModal={() => setIsCompanyAddressModalOpen(false)}
        />
      )}
      {campaignData?.commsCampaignsExperience?.hasDemoExperience ? (
        <SendTestEmailForDemoModal
          {...sharedSendTestEmailModalProps}
          isInErrorState={isSendATestModalInErrorState}
          onSendTestEmail={onSendTestEmailForDemo}
        />
      ) : (
        <SendTestEmailModal
          {...sharedSendTestEmailModalProps}
          isInErrorState={isSendATestModalInErrorState}
          onSendTestEmail={onSendTestEmail}
          userEmail={campaignData?.user?.email.raw || ""}
          formValidationErrors={false}
        />
      )}
      <div className={styles.reviewPageContainer}>
        <div className={styles.editContainer}>
          <Page title={formatMessage(messages.campaignReviewPageTitle)}>
            {showValidateEmailModal && campaignId && (
              <ValidateEmailModal
                showModal={showValidateEmailModal}
                toggleModal={toggleModal}
                email={campaignData?.commsCampaign?.fromEmailAddress?.rawEmail}
                error={error}
                sendValidationCode={sendValidationCode}
                checkValidationCode={checkValidationCode}
                isCheckValidationCodeDataLoading={
                  isCheckValidationCodeDataLoading
                }
              />
            )}
            {errorState === CampaignReviewErrorState.EMPTY_CLIENT_SEGMENT &&
              !enableAutomation && (
                <EmptyClientSegmentErrorBanner
                  redirectToEdit={redirectToRecipientsEdit}
                />
              )}
            {errorState === CampaignReviewErrorState.AUTHORIZATION_ERROR && (
              <CampaignSendErrorBanner
                message={formatMessage(
                  messages.campaignSendAuthorizationErrorBannerText,
                )}
                setErrorState={setErrorState}
              />
            )}
            {errorState === CampaignReviewErrorState.GENERIC_ERROR && (
              <CampaignSendErrorBanner
                message={formatMessage(messages.campaignSendErrorBannerText)}
                setErrorState={setErrorState}
              />
            )}
            {!enableAutomation && (
              // This is the old recipients section component
              <CampaignRecipientsSection
                loading={loadingAll}
                summaryMessage={formatMessage(messages.recipientSummary, {
                  segmentName,
                  consentingClientsCount,
                  totalCount,
                })}
                timeoutError={timeoutError}
                redirectToEdit={redirectToRecipientsEdit}
              />
            )}
            {enableAutomation && (
              <AutomatedCampaignRecipientsSection
                loading={loadingCampaignInformation}
                timeoutError={timeoutError}
                redirectToEdit={redirectToRecipientsEdit}
                automationRule={campaignData?.commsCampaign?.automationRule}
              />
            )}

            <div className={styles.confirmImpliedConsentPermissionContainer}>
              <Checkbox
                label={formatMessage(
                  messages.campaignsConfirmImpliedConsentPermission,
                )}
                onChange={setConfirmImpliedConsentPermission}
              />
              {validationErrors?.confirmImpliedConsentPermission && (
                <InputValidation
                  message={validationErrors?.confirmImpliedConsentPermission}
                />
              )}
            </div>

            <Heading level={4}>
              {formatMessage(messages.campaignsFromEmail)}
            </Heading>
            {loadingCampaignInformation && <Glimmer width={500} />}
            {fromEmail && !loadingCampaignInformation && (
              <div className={styles.fromEmailContainer}>
                <Text> {fromEmail}</Text>
                <StatusLabel
                  status={isFromEmailValidated ? "success" : "warning"}
                  label={
                    isFromEmailValidated
                      ? formatMessage(messages.fromEmailVerifiedLabel)
                      : formatMessage(messages.fromEmailNotVerifiedLabel)
                  }
                />
              </div>
            )}
            {fromEmail &&
              !loadingCampaignInformation &&
              !isFromEmailValidated && (
                <Text variation="warn">
                  {formatMessage(messages.fromEmailNotVerifiedText)}
                </Text>
              )}
            <Heading level={4}>
              {formatMessage(messages.campaignsReplyToEmailTitle)}
            </Heading>
            {loadingCampaignInformation && <Glimmer width={500} />}
            {replyToEmail && !loadingCampaignInformation && (
              <Text>{replyToEmail}</Text>
            )}
            <Heading level={4}>
              {formatMessage(messages.campaignsSubjectTitle)}
            </Heading>
            {loadingCampaignInformation && <Glimmer width={500} />}
            {template.subject && !loadingCampaignInformation && (
              <Text>{template.subject}</Text>
            )}
          </Page>
        </div>
        <div
          className={styles.previewContainer}
          data-testid={"CampaignEmailPreview"}
        >
          <EmailPreview
            header={template.header}
            subject={template.subject}
            fromEmail={fromEmail}
            body={template.body}
            buttonColor={template.buttonColor}
            buttonText={template.buttonText}
            ctaButtonEnabled={template.ctaButtonEnabled}
            showLogo={template.showLogo}
            loading={loadingTemplate}
            headerImageUrl={template.headerImage?.url}
            variables={template.variables}
            referralLink={template.referralLink}
          />
        </div>
      </div>

      <BottomBar
        onNext={onNextCallback}
        onCancel={onCancel}
        sendTestButton={{
          buttonType: "tertiary",
          onSendTest: () => {
            setIsSendATestModalOpen(true);
          },
        }}
        step={CAMPAIGNS_PAGE_TITLE_STEPS.review}
        isFromEmailValidated={isFromEmailValidated}
        loading={loadingAll || actionLoading || previewLoading}
      >
        {!previewEnabled && (
          <BottomBarDefaultFlow
            enableAutomation={enableAutomation}
            onNext={onNextCallback}
            setShowScheduleSendModal={setShowScheduleSendModal}
            validateCampaignForSending={validateCampaignForSending}
            loadingAll={loadingAll}
            submitButtonLabel={submitButtonLabel()}
            existingAddress={existingAddress}
            checkCompanyAddress={checkCompanyAddress}
            setIsCompanyAddressModalOpen={setIsCompanyAddressModalOpen}
          />
        )}
        {previewEnabled && (
          <BottomBarPurchaseFlow
            enableAutomation={enableAutomation}
            isMobileBilled={isMobileBilled}
            recurlyPublicKey={recurlyPublicKey}
            salesforceTrackingId={salesforceTrackingId}
            onNext={onNextCallback}
            checkIsFormValid={checkIsFormValid}
            setShowScheduleSendModal={setShowScheduleSendModal}
            loadingAll={loadingAll}
            existingAddress={existingAddress}
            checkCompanyAddress={checkCompanyAddress}
            setIsCompanyAddressModalOpen={setIsCompanyAddressModalOpen}
          />
        )}
      </BottomBar>
    </div>
  );
}
