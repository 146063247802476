import { defineMessages } from "react-intl";

export const messages = defineMessages({
  planTapeAltText: {
    id: "reviews.purchase.planTapeAltText",
    defaultMessage:
      "A black tape strip with the words 'MARKETING TOOLS' at the top of the page",
    description:
      "Alt text for the plan tape image in the marketing tools section",
  },
  heroAltText: {
    id: "reviews.purchase.heroAltText",
    defaultMessage:
      "A dashboard showing all reviews on the left, with additional imagery showing a text being sent to a client, and a sample review.",
    description:
      "Alt text for the main hero image on the reviews purchase page",
  },
  experimentalHeroAltText: {
    id: "reviews.purchase.experimentalHeroAltText",
    defaultMessage: "A screenshot of the experimental reviews landing page.",
    description: "Alt text for the experimental version of the hero section",
  },
  moreClientsAltText: {
    id: "reviews.purchase.moreClientsAltText",
    defaultMessage:
      "Google search results displayed in the background with an arrow demonstrating that your business is ranking higher in the search.",
    description:
      "Alt text for the image showing improved search ranking benefits",
  },
  protectReputationAltText: {
    id: "reviews.purchase.protectReputationAltText",
    defaultMessage:
      "A man (left) shows a woman (right) an iPad, and the woman appears satisfied with the outcome.",
    description: "Alt text for the image illustrating reputation management",
  },
  trustedBrandAltText: {
    id: "reviews.purchase.trustedBrandAltText",
    defaultMessage:
      "Demonstration of sending a Google review request through an invoice on the left with a five star review on the right.",
    description: "Alt text for the image showing the review request workflow",
  },
});
