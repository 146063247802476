import { defineMessages } from "react-intl";

export const messages = defineMessages({
  campaignReviewPageTitle: {
    id: "campaigns.campaignReviewPage.campaignReviewPageTitle",
    defaultMessage: "Review campaign",
    description: "Title of campaign review page",
  },
  campaignsFromEmail: {
    id: "campaigns.campaignReviewPage.campaignsFromEmail",
    defaultMessage: "From",
    description: "Title of campaign from email section",
  },
  campaignsReplyToEmailTitle: {
    id: "campaigns.campaignReviewPage.campaignsReplyToEmailTitle",
    defaultMessage: "Reply-to email address",
    description: "Title of campaign reply-to email section",
  },
  campaignsConfirmImpliedConsentPermission: {
    id: "campaigns.campaignReviewPage.campaignsConfirmImpliedConsentPermission",
    defaultMessage: "I confirm that I have permission to email these clients",
    description:
      "Description to confirm that I have permission to email these clients",
  },
  shouldShowConsentEntriesErrorMessage: {
    id: "campaigns.campaignReviewPage.shouldShowConsentEntriesErrorMessage",
    defaultMessage: "Confirm you have consent from clients to send campaign",
    description: "Error message for consent entries checkbox",
  },
  campaignsSubjectTitle: {
    id: "campaigns.campaignReviewPage.campaignsSubjectTitle",
    defaultMessage: "Subject line",
    description: "Title of campaign subject section",
  },
  emptyClientSegmentErrorBannerText: {
    id: "campaigns.campaignReviewPage.emptyClientSegmentErrorBanner.text",
    defaultMessage:
      "In order to send a campaign, there must be at least one recipient",
    description: "Error banner text",
  },
  emptyClientSegmentErrorBannerCtaText: {
    id: "campaigns.campaignReviewPage.emptyClientSegmentErrorBanner.ctaText",
    defaultMessage: "Edit Recipients",
    description: "Error recipients text",
  },
  campaignSendAuthorizationErrorBannerText: {
    id: "campaigns.campaignReviewPage.campaignSendAuthorizationErrorBannerText",
    defaultMessage: "Sending campaigns is not available on demo accounts",
    description: "When the mutation returns an error related to authorization",
  },
  campaignSendErrorBannerText: {
    id: "campaigns.campaignReviewPage.campaignSendErrorBannerText",
    defaultMessage: "Something went wrong. Please try again later.",
    description: "When the mutation returns a generic error",
  },
  fromEmailVerifiedLabel: {
    id: "campaigns.campaignReviewPage.fromEmailVerifiedLabel",
    defaultMessage: "Verified",
    description: "From email verified label text",
  },
  fromEmailNotVerifiedLabel: {
    id: "campaigns.campaignReviewPage.fromEmailNotVerifiedLabel",
    defaultMessage: "Not verified",
    description: "From email not verified label text",
  },
  fromEmailNotVerifiedText: {
    id: "campaigns.campaignReviewPage.fromEmailNotVerifiedText",
    defaultMessage:
      "In order to send this campaign, we need to make sure this email address belongs to you.",
    description: "From email not verified text",
  },
  payAndSendButton: {
    id: "campaigns.addToPlanButton.payAndSendButton",
    defaultMessage: "Pay and Send",
    description: "Pay and Send Button",
  },
  payAndScheduleButton: {
    id: "campaigns.addToPlanButton.payAndScheduleButton",
    defaultMessage: "Pay and Schedule",
    description: "Pay and Schedule Button",
  },
  payAndActivateButton: {
    id: "campaigns.addToPlanButton.payAndActivateButton",
    defaultMessage: "Pay and Activate",
    description: "Pay and Activate Button",
  },
  sendCampaignButton: {
    id: "campaigns.addToPlanButton.sendCampaignButton",
    defaultMessage: "Send",
    description: "Send Campaign Button",
  },
  sendCampaignLaterButton: {
    id: "campaigns.addToPlanButton.sendCampaignLaterButton",
    defaultMessage: "Schedule",
    description: "Send Campaign Later Button",
  },
  scheduledCampaignSuccessToast: {
    id: "campaigns.campaignReviewPage.scheduledCampaignSuccessToast",
    defaultMessage: "Scheduled campaign",
    description: "Campaign scheduled success toast",
  },
  scheduledCampaignErrorToast: {
    id: "campaigns.campaignReviewPage.scheduledCampaignErrorToast",
    defaultMessage: "Something went wrong. Please try again later.",
    description: "Campaign scheduled error toast",
  },
  scheduledCampaignDateTimeErrorToast: {
    id: "campaigns.campaignReviewPage.scheduledCampaignDateTimeErrorToast",
    defaultMessage:
      "The future is now the past. Please select a future date and time.",
    description: "Campaign scheduled datetime error toast",
  },
  automatedCampaignActivateButton: {
    id: "campaigns.campaignReviewPage.automatedCampaignActivateButton",
    defaultMessage: "Activate Campaign",
    description: "Activate campaign button",
  },
  activatedCampaignToast: {
    id: "campaigns.campaignReviewPage.activatedCampaignToast",
    defaultMessage: "Activated campaign",
    description: "Campaign activated toast",
  },
  activatedDemoAutomatedCampaignToast: {
    id: "campaigns.campaignReviewPage.activatedDemoAutomatedCampaignToast",
    defaultMessage: "Activated campaign",
    description: "Demo Automated Campaign activated toast",
  },
  sentDemoCampaignToast: {
    id: "campaigns.campaignReviewPage.sentDemoCampaignToast",
    defaultMessage: "Sent campaign",
    description: "Demo Campaign sent toast",
  },
  editSubjectButton: {
    id: "campaigns.campaignReviewPage.editSubjectButton",
    defaultMessage: "Edit",
    description: "Edit Subject Button",
  },
  contentCardTitle: {
    id: "campaigns.campaignReviewPage.contentCardTitle",
    defaultMessage: "Content",
    description: "Content Card Title",
  },
  recipientSummary: {
    id: "campaigns.campaignReviewPage.recipientSummary",
    defaultMessage:
      "This email will send to the <b>{segmentName}</b> segment, with <b>{consentingClientsCount}</b> of {totalCount} subscribed to email marketing.",
    description: "Recipient summary",
  },
});
