import React from "react";
import { Box, Content, Divider, Text } from "@jobber/components";
import { useIntl } from "react-intl";
import { formatCurrency } from "utilities/formatCurrency";
import { useAccount } from "~/utilities/contexts/internal/useAccount";
import type {
  JobberPaymentsTransactionLimits,
  TransactionLimitsUsage,
} from "~/utilities/API/graphql";
import { LimitsUsageProgressBar } from "./LimitsUsageProgressBar";
import { messages } from "./messages";
import styles from "./styles.module.css";

export function LimitsAndUsage({
  usage,
  limits,
  testId = "limits-and-usage",
}: {
  usage: TransactionLimitsUsage;
  limits: JobberPaymentsTransactionLimits;
  testId?: string;
}) {
  const { currencySymbol } = useAccount();

  const singleTransactionLimit = (limits.singleTransactionLimit || 0) / 100;
  const twentyFourHourTransactionLimit =
    (limits.twentyFourHourTransactionLimit || 0) / 100;
  const weekTransactionLimit = (limits.weekTransactionLimit || 0) / 100;
  const monthTransactionLimit = (limits.monthTransactionLimit || 0) / 100;

  return (
    <Content>
      <div className={styles.transactionsUsageSection} data-testid={testId}>
        <TransactionLimitUsageRow
          limit={singleTransactionLimit}
          messageKey="singleTransactionLimit"
          testId="singleTransactionSection"
          currencySymbol={currencySymbol}
        />
        <TransactionLimitUsageRow
          limit={twentyFourHourTransactionLimit}
          currentUsage={(usage.last24Hours ?? 0) / 100}
          messageKey="twentyFourHourLimit"
          testId="twentyFourHourSection"
          currencySymbol={currencySymbol}
        />
        <TransactionLimitUsageRow
          limit={weekTransactionLimit}
          currentUsage={(usage.last7Days ?? 0) / 100}
          messageKey="weeklyLimit"
          testId="weekSection"
          currencySymbol={currencySymbol}
          skipDivider={!monthTransactionLimit}
        />
        <TransactionLimitUsageRow
          limit={monthTransactionLimit}
          currentUsage={(usage.last30Days ?? 0) / 100}
          messageKey="thirtyDayLimit"
          testId="thirtyDaySection"
          currencySymbol={currencySymbol}
          skipDivider={true}
        />
      </div>
    </Content>
  );
}

interface TransactionLimitUsageRowProps {
  limit: number;
  currentUsage?: number;
  messageKey: keyof typeof messages;
  testId: string;
  currencySymbol: string;
  skipDivider?: boolean;
}

const TransactionLimitUsageRow = ({
  limit,
  currentUsage,
  messageKey,
  testId,
  currencySymbol,
  skipDivider = false,
}: TransactionLimitUsageRowProps) => {
  const { formatMessage } = useIntl();

  if (!limit) return null;

  return (
    <>
      <div className={styles.progressBarRow}>
        <Box width={"100%"} height={"grow"} data-testid={testId}>
          <Text>
            {formatMessage(messages[messageKey], {
              amount: formatCurrency(limit, currencySymbol, 0),
            })}
          </Text>
        </Box>
        {currentUsage !== undefined && (
          <LimitsUsageProgressBar
            currentUsage={currentUsage}
            totalLimit={limit}
          />
        )}
      </div>
      {!skipDivider && <Divider />}
    </>
  );
};
