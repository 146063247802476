import React from "react";
import {
  type IntlFormatters,
  type MessageDescriptor,
  useIntl,
} from "react-intl";
import { Text } from "@jobber/components/Text";
import { strFormatDate } from "@jobber/components/FormatDate";
import { Link } from "@jobber/components";
import { isAfter } from "date-fns";
import { formatCurrency } from "utilities/formatCurrency";
import { ADDON_IDENTIFIER } from "legacy/jobber/billing/types";
import styles from "./PurchaseDisclaimer.module.css";
import { messages } from "./messages";

interface PurchaseDisclaimerProps {
  startBillingOnDate: Date;
  baseUnitPrice: number;
  nextBillingDate: Date;
  subscriptionDisplayName: string;
  discountedUnitPrice?: number;
  discountEndDate?: Date;
  showActivationDisclaimer?: boolean;
  addonIdentifier?: string;
}

const betaAddonNameMap: { [key: string]: MessageDescriptor } = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ai_receptionist: messages.aiReceptionistFullName,
};

export function PurchaseDisclaimer({
  startBillingOnDate,
  baseUnitPrice,
  nextBillingDate,
  subscriptionDisplayName,
  discountedUnitPrice,
  discountEndDate,
  addonIdentifier,
  showActivationDisclaimer,
}: PurchaseDisclaimerProps) {
  const { formatMessage } = useIntl();

  const isDiscounted =
    discountedUnitPrice !== undefined && discountEndDate !== undefined;

  const message = getMessage();

  return (
    <>
      <div data-testid="purchase-disclaimer">
        <Text variation="subdued" size="small">
          {message}
        </Text>
      </div>
      <div data-testid="activation-disclaimer">
        <Text variation="subdued" size="small">
          {showActivationDisclaimer && (
            <div className={styles.disclaimerActivation}>
              {getActivationDisclaimer()}
            </div>
          )}
        </Text>
      </div>
    </>
  );

  function getActivationDisclaimer() {
    if (!addonIdentifier && !showActivationDisclaimer) {
      return null;
    }
    const termsOfServiceLink = "https://getjobber.com/terms-of-service/";
    return formatMessage(messages.betaActivationDisclaimer, {
      fullAddonName: formatMessage(
        betaAddonNameMap[addonIdentifier ?? ADDON_IDENTIFIER.AI_RECEPTIONIST],
      ),
      termsOfServiceLink: (
        <Link external url={termsOfServiceLink}>
          {formatMessage(messages.termsOfService)}
        </Link>
      ),
    });
  }

  function getMessage() {
    if (isBilledImmediately(startBillingOnDate)) {
      return getImmediateBilledMessage(
        formatMessage,
        isDiscounted,
        baseUnitPrice,
        nextBillingDate,
        subscriptionDisplayName,
        discountEndDate,
        discountedUnitPrice,
      );
    }
    return getFutureBilledMessage(
      formatMessage,
      isDiscounted,
      baseUnitPrice,
      subscriptionDisplayName,
      discountEndDate,
      nextBillingDate,
    );
  }
}

function getImmediateBilledMessage(
  formatMessage: IntlFormatters["formatMessage"],
  isDiscounted: boolean,
  baseUnitPrice: number,
  nextBillingDate: Date,
  subscriptionDisplayName: string,
  discountEndDate?: Date,
  discountedUnitPrice?: number,
) {
  if (
    isDiscounted &&
    discountEndDate &&
    isAfter(discountEndDate, nextBillingDate)
  ) {
    return formatMessage(messages.immediateDiscountedChargeMessage, {
      subscriptionDisplayName: subscriptionDisplayName,
      nextPaymentAmount: getFormattedPrice(discountedUnitPrice),
      nextBillingDate: getFormattedDate(nextBillingDate),
      discountEndDate: getFormattedDate(discountEndDate),
      baseUnitPrice: getFormattedPrice(baseUnitPrice),
    });
  }

  return formatMessage(messages.immediateNonDiscountedChargeMessage, {
    subscriptionDisplayName: subscriptionDisplayName,
    nextPaymentAmount: getFormattedPrice(baseUnitPrice),
    nextBillingDate: getFormattedDate(nextBillingDate),
  });
}

function getFutureBilledMessage(
  formatMessage: IntlFormatters["formatMessage"],
  isDiscounted: boolean,
  baseUnitPrice: number,
  subscriptionDisplayName: string,
  discountEndDate?: Date,
  nextBillingDate?: Date,
) {
  if (
    isDiscounted &&
    discountEndDate &&
    nextBillingDate &&
    isAfter(discountEndDate, nextBillingDate)
  ) {
    return formatMessage(messages.futureBilledDiscountedChargeMessage, {
      subscriptionDisplayName: subscriptionDisplayName,
      discountEndDate: getFormattedDate(discountEndDate),
      baseUnitPrice: getFormattedPrice(baseUnitPrice),
    });
  }

  return "";
}

export function getFormattedPrice(total?: number) {
  if (total) {
    return formatCurrency(total, "$", 2);
  }
  return "";
}

export function getFormattedDate(date?: Date) {
  if (date) {
    return strFormatDate(date);
  }
  return "";
}

function isBilledImmediately(startBillingDate: Date) {
  const today = new Date();
  return !isAfter(startBillingDate, today);
}
