import { defineMessages } from "react-intl";

export const messages = defineMessages({
  emailTab: {
    id: "setupGuide.previewTemplate.FollowUpTabs.emailTab",
    defaultMessage: "Email",
    description: "Email tab for the preview template step",
  },
  smsTab: {
    id: "setupGuide.previewTemplate.FollowUpTabs.smsTab",
    defaultMessage: "Text",
    description: "Text tab for the preview template step",
  },
});
