import { defineMessages } from "react-intl";

export const messages = defineMessages({
  detailsSectionTitle: {
    id: "campaigns.review.details.title",
    defaultMessage: "Details",
    description: "Title for the details section of campaign review",
  },
  campaignsFromEmail: {
    id: "campaigns.campaignReviewPage.campaignsFromEmail",
    defaultMessage: "From",
    description: "Title of campaign from email section",
  },
  campaignsReplyToEmailTitle: {
    id: "campaigns.campaignReviewPage.campaignsReplyToEmailTitle",
    defaultMessage: "Reply-to email address",
    description: "Title of campaign reply-to email section",
  },
  fromEmailVerifiedLabel: {
    id: "campaigns.campaignReviewPage.fromEmailVerifiedLabel",
    defaultMessage: "Verified",
    description: "From email verified label text",
  },
  fromEmailNotVerifiedLabel: {
    id: "campaigns.campaignReviewPage.fromEmailNotVerifiedLabel",
    defaultMessage: "Not verified",
    description: "From email not verified label text",
  },
  fromEmailNotVerifiedText: {
    id: "campaigns.campaignReviewPage.fromEmailNotVerifiedText",
    defaultMessage:
      "In order to send this campaign, we need to make sure this email address belongs to you.",
    description: "From email not verified text",
  },
});
