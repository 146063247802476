import React from "react";
import { Tooltip } from "@jobber/components/Tooltip";
import classNames from "classnames";
import {
  AtlantisThemeContextProvider,
  useAtlantisTheme,
} from "@jobber/components";
import styles from "./ItemDonut.module.css";
import type { ItemType } from "../types";

export interface ItemDonutProps {
  itemType?: ItemType;
  currentAmount: number;
  shouldShowTooltip: boolean;
}

const MAX_ITEMS = 5;
const MIN_ITEMS = 0;

export function ItemDonut(props: ItemDonutProps) {
  return (
    <AtlantisThemeContextProvider>
      <InternalItemDonut {...props} />
    </AtlantisThemeContextProvider>
  );
}

function InternalItemDonut({
  itemType,
  currentAmount,
  shouldShowTooltip,
}: ItemDonutProps) {
  const { tokens } = useAtlantisTheme();
  const itemsUsed = Math.min(currentAmount, MAX_ITEMS);
  const tooltipMessage = `${itemType} ${itemsUsed}/${MAX_ITEMS}`;
  const donut = (
    <svg
      role="progressbar"
      aria-valuenow={itemsUsed}
      aria-valuemin={MIN_ITEMS}
      aria-valuemax={MAX_ITEMS}
      className={classNames(
        styles.donut,
        itemType ? styles[itemType.toLowerCase()] : "",
      )}
      viewBox={`0 0 ${tokens["space-large"]} ${tokens["space-large"]}`}
      style={{ ["--progress"]: itemsUsed / MAX_ITEMS } as React.CSSProperties}
    >
      <circle className={styles.track} />
      {itemsUsed > 0 && <circle className={styles.progress} />}
    </svg>
  );

  if (shouldShowTooltip) {
    return (
      <Tooltip preferredPlacement="right" message={tooltipMessage}>
        {donut}
      </Tooltip>
    );
  }

  return donut;
}
