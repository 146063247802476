import React, { useState } from "react";
import { useIntl } from "react-intl";
import { messages } from "./messages";
import { FollowUpChips } from "./components/FollowUpChips/FollowUpChips";
import { FollowUpTabs } from "./components/FollowUpTabs/FollowUpTabs";
import type { SetupGuideStepProps } from "../../types";
import { OnboardingStep } from "../OnboardingStep";

export function PreviewTemplateStep(
  stepProps: SetupGuideStepProps,
): JSX.Element {
  const [followUpChipSelected, setFollowUpChipSelected] = useState<string>("0");

  const { formatMessage } = useIntl();

  const emailOrSmsTabs = <FollowUpTabs selected={followUpChipSelected} />;
  const followUpChips = (
    <FollowUpChips
      selected={followUpChipSelected}
      setSelected={setFollowUpChipSelected}
    />
  );

  return (
    <OnboardingStep
      title={formatMessage(messages.title)}
      subtitle={formatMessage(messages.description)}
      primaryButton={{
        label: formatMessage(messages.primaryButton),
        action: () => {
          stepProps.navigation.onNext();
        },
      }}
      leftColumnChild={followUpChips}
      rightColumnChild={emailOrSmsTabs}
    />
  );
}
