import { Heading } from "@jobber/components";
import React from "react";
import type { IntlFormatters } from "react-intl";
import { ReviewKPICard } from "jobber/reviews/views/ReviewsPage/components/ReviewKPICard/ReviewKPICard";
import { messages } from "../messages";

interface ResponseTimeCardProps {
  formatMessage: IntlFormatters["formatMessage"];
  isLoading: boolean;
  recommendedResponseTime: number;
  responseTime: number;
}

export function ResponseTimeCard({
  formatMessage,
  isLoading,
  recommendedResponseTime,
  responseTime,
}: ResponseTimeCardProps): JSX.Element {
  return (
    <ReviewKPICard
      header={formatMessage(messages.responseTimeHeader)}
      subheader={formatMessage(messages.responseTimeSubheader)}
      isLoading={isLoading}
      KPIValues={[
        {
          amount: (
            <Heading level={2}>
              {formatMessage(messages.hours, {
                amount: responseTime,
              })}
            </Heading>
          ),
          description: formatMessage(
            responseTime && responseTime <= recommendedResponseTime
              ? messages.responseTimeFooterOnTrack
              : messages.responseTimeFooterOffTrack,
            {
              amount: recommendedResponseTime,
            },
          ),
        },
      ]}
    />
  );
}
