import React from "react";
import { useIntl } from "react-intl";
import { useBreakpoints } from "@jobber/hooks/useBreakpoints";
import { Carousel } from "components/Carousel";
import { FeatureCard } from "~/components/Expansion/FeatureCard/FeatureCard";
import { UpgradeWaveCarouselPlans } from "./upgradeWaveFeatures";
import { messages } from "./messages";
import styles from "./UpgradeWaveFeatureCarousel.module.css";
import type { FeatureCarouselPlanEnum } from "../FeatureCarousel/featureCarouselPlans";

export function UpgradeWaveFeatureCarousel({
  plan,
}: {
  plan: FeatureCarouselPlanEnum;
}) {
  const { formatMessage: t } = useIntl();

  const features = UpgradeWaveCarouselPlans[plan].features;
  const { largeAndUp } = useBreakpoints();

  return (
    <div className={styles.carouselContainer}>
      <Carousel showArrowIndicators={largeAndUp} autoplaySeconds={6}>
        {features.map((feature, index) => (
          <FeatureCard
            key={index}
            label={
              feature.showLabel
                ? t(messages[UpgradeWaveCarouselPlans[plan].label])
                : undefined
            }
            image={{
              src: feature.imgSrc,
              alt: t(messages[feature.title]),
            }}
            title={t(messages[feature.title])}
            content={feature.description.map(description =>
              t(messages[description]),
            )}
          />
        ))}
      </Carousel>
    </div>
  );
}
