import React from "react";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import { useBreakpoints } from "@jobber/hooks/useBreakpoints";
import { Heading } from "@jobber/components/Heading";
import styles from "./styles.module.css";

export interface OnboardingStepButtonProps {
  label: string;
  action: () => void;
}

export interface OnboardingStepProps {
  title: string;

  primaryButton: OnboardingStepButtonProps;

  subtitle?: string;

  leftColumnChild?: React.ReactNode;

  rightColumnChild?: React.ReactNode;
}

export function OnboardingStep({
  title,
  primaryButton,
  subtitle,
  leftColumnChild,
  rightColumnChild,
}: OnboardingStepProps): JSX.Element {
  const { largeAndUp } = useBreakpoints();

  const getTitle = () => {
    return <Heading level={1}>{title}</Heading>;
  };

  const getSubtitle = () => {
    return subtitle ? (
      <div className={styles.subtitle}>
        <Text variation="subdued" size="large">
          {subtitle}
        </Text>
      </div>
    ) : null;
  };

  const getPrimaryButton = () => {
    return (
      <div className={styles.buttonRow}>
        <Button
          fullWidth={!largeAndUp}
          type="primary"
          onClick={primaryButton.action}
          label={primaryButton.label}
          ariaLabel={primaryButton.label}
        />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.column1}>
        {getTitle()}

        {getSubtitle()}

        <div className={styles.child1}>{leftColumnChild}</div>

        {getPrimaryButton()}
      </div>

      <div className={styles.column2}>{rightColumnChild}</div>
    </div>
  );
}
