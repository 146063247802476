/* eslint-disable max-statements */
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import React, { useEffect, useRef, useState } from "react";
import { Divider } from "@jobber/components/Divider";
import { showToast } from "@jobber/components/Toast";
import { useIntl } from "react-intl";
import {
  InvoiceOrQuote,
  JobberPaymentsDefaultPaymentPreference,
  JobberPaymentsSettingsName,
} from "~/utilities/API/graphql";
import type { ClientHubSettingsQuery } from "~/utilities/API/graphql";
import { useJobberPayments } from "~/utilities/contexts/internal/useJobberPayments";
import { withSplitClient } from "utilities/split";
import { useOnlinePaymentsDefaultUpdateSplit } from "jobber/managed_accounts/Settings/useOnlinePaymentsDefaultUpdateSplit";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { SettingsSwitch } from "./SettingsSwitch";
import { DefaultPaymentRadioGroup } from "./DefaultPaymentRadioGroup";
import { messages } from "./messages";
import styles from "./QuotePreferenceSettings.module.css";
import { useScrollToComponentAnchor } from "./useScrollToComponentAnchor";

const genericError = new Error("Could not update settings");

interface QuotePreferencesSettingsProps {
  quoteData?: ClientHubSettingsQuery;
  setMutationErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  payQuoteDeposits?: boolean;
  setPayQuoteDeposits: (_: boolean) => void;
  setToggleAchFlag?: React.Dispatch<React.SetStateAction<boolean>>;
  updateDefaultPreference?: {
    preference: string;
    achEnabled: boolean;
  };
  setIsAchEnabled?: React.Dispatch<React.SetStateAction<boolean>>;
  isAchEnabled?: boolean;
}

declare const window: Window & { dataLayer: Record<string, unknown>[] };

function QuotePreferencesSettings({
  quoteData,
  setMutationErrorMessage,
  payQuoteDeposits,
  setPayQuoteDeposits,
  setToggleAchFlag,
  updateDefaultPreference,
  setIsAchEnabled,
  isAchEnabled,
}: QuotePreferencesSettingsProps) {
  const { formatMessage } = useIntl();
  const anchorRef = useScrollToComponentAnchor("#quote-preferences");

  const isFirstRender = useRef(true);
  const { inOnlinePaymentsDefaultUpdateSplit } =
    useOnlinePaymentsDefaultUpdateSplit();

  const [preference, setDefaultPreference] = useState<
    JobberPaymentsDefaultPaymentPreference | string
  >(
    !quoteData?.achPayments.enabled && !inOnlinePaymentsDefaultUpdateSplit
      ? JobberPaymentsDefaultPaymentPreference.CREDIT_CARD
      : quoteData?.jobberPaymentsSettings.quotePaymentOptionPreference ??
          JobberPaymentsDefaultPaymentPreference.ACH_AND_CREDIT_CARD,
  );

  const [
    isMandatoryPaymentMethodOnFileSettingEnabled,
    setIsMandatoryPaymentMethodOnFileSettingEnabled,
  ] = useState<boolean>(
    quoteData?.mandatoryPaymentMethodOnFile.enabled ?? false,
  );

  useEffect(() => {
    // Should be removed with OnlinePaymentDefault clean up - JOB-110205
    setDefaultPreference(
      !quoteData?.achPayments.enabled && !inOnlinePaymentsDefaultUpdateSplit
        ? JobberPaymentsDefaultPaymentPreference.CREDIT_CARD
        : quoteData?.jobberPaymentsSettings.quotePaymentOptionPreference ??
            JobberPaymentsDefaultPaymentPreference.ACH_AND_CREDIT_CARD,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inOnlinePaymentsDefaultUpdateSplit]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (updateDefaultPreference) {
      if (!isMandatoryPaymentMethodOnFileSettingEnabled) {
        setDefaultPreference(updateDefaultPreference.preference);
      }
      setIsAchEnabled?.(updateDefaultPreference.achEnabled);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDefaultPreference]);
  const { permissions } = useJobberPayments();

  return (
    <div ref={anchorRef}>
      <Card title={formatMessage(messages.quotePreferenceSettingsCardTitle)}>
        <Content spacing="large">
          <SettingsSwitch
            settingsName={JobberPaymentsSettingsName.PAY_QUOTE_DEPOSITS}
            title={formatMessage(messages.quotePreferenceSettingsTitle)}
            defaultEnabled={quoteData?.payQuoteDeposits.enabled ?? false}
            onError={handleMutationError}
            onSuccessCallback={updatePayQuoteDeposits}
          />
          <Divider />
          <div className={styles.lastSwitch}>
            <SettingsSwitch
              settingsName={
                JobberPaymentsSettingsName.MANDATORY_PAYMENT_METHOD_ON_FILE
              }
              title={formatMessage(
                messages.quoteMandatoryPaymentMethodOnFileTitle,
              )}
              defaultEnabled={
                quoteData?.mandatoryPaymentMethodOnFile.enabled ?? false
              }
              onError={handleMutationError}
              onSuccessCallbackIsEnabled={updateRadioOptions}
            />
          </div>
          {permissions.canToggleAchPayments && (
            <>
              <Divider />
              <DefaultPaymentRadioGroup
                handleOnChange={handleRadioOnChange}
                selectedOption={preference}
                objectType={InvoiceOrQuote.QUOTE}
                onError={handleMutationError}
                isAchEnabled={isAchEnabled}
                setIsAchEnabled={setIsAchEnabled}
                setToggleAchFlag={setToggleAchFlag}
              />
            </>
          )}
        </Content>
      </Card>
    </div>
  );

  function handleMutationError(mutationError: Error) {
    setMutationErrorMessage((mutationError || genericError).message);
  }

  function updatePayQuoteDeposits() {
    setPayQuoteDeposits(!payQuoteDeposits);
  }

  function handleRadioOnChange(value: JobberPaymentsDefaultPaymentPreference) {
    setDefaultPreference(value);
    sendGoogleTagManagerEvent(value);
    showToast({
      message: formatMessage(messages.paymentOptionChangeToastMessage),
    });
  }

  async function updateRadioOptions(enabled: boolean) {
    setIsMandatoryPaymentMethodOnFileSettingEnabled(enabled);

    Amplitude.TRACK_EVENT("CTA Clicked", {
      name: "settings mandatory payment for quote deposit toggle",
      action: "Toggled state",
      enabled: enabled,
    });
  }

  function sendGoogleTagManagerEvent(
    value: JobberPaymentsDefaultPaymentPreference,
  ) {
    window.dataLayer?.push({
      event: "select_quote_payment_option",
      option: value, //both card and ach bank payments, card payments only or ach bank payments only
    });
  }
}

const SplitWrappedComponent = withSplitClient(QuotePreferencesSettings);
export { SplitWrappedComponent as QuotePreferencesSettings };
