import React from "react";
import { Grid } from "@jobber/components/Grid";
import { Button } from "@jobber/components/Button";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { useIntl } from "react-intl";
import { Card } from "@jobber/components/Card";
import { Content } from "@jobber/components/Content";
import { SubscriptionAddonCard } from "~/jobber/billing/components/SubscriptionAddonCard/SubscriptionAddonCard";
import type { SubscriptionAddonPreview } from "~/shared/billing/pricePreview/types";
import { isAddonSelected } from "~/shared/billing/pricePreview/utils";
import { UpsellAddon } from "~/jobber/billing/components/UpsellAddon/UpsellAddon";
import styles from "./SubscriptionAddonCards.module.css";
import { messages } from "./messages";

interface SubscriptionAddonCardsProps {
  subscriptionAddons: SubscriptionAddonPreview[];
  selectedAddonCodes: string[];
  onChangeSelectedAddons: (added: string[], removed: string[]) => void;
  shouldShowDisclaimerCTA?: boolean;
}

// eslint-disable-next-line max-statements
export function SubscriptionAddonCards(props: SubscriptionAddonCardsProps) {
  const {
    subscriptionAddons,
    selectedAddonCodes,
    onChangeSelectedAddons,
    shouldShowDisclaimerCTA,
  } = props;

  const { formatMessage } = useIntl();

  if (!subscriptionAddons.length) {
    return null;
  }

  let recommendedAddons = subscriptionAddons.filter(addon => addon.recommended);
  let moreAddons = subscriptionAddons.filter(addon => !addon.recommended);

  // TODO: Should fix this when we can get the bundle addon from the server.
  // As of now there is only one bundle and the other add-ons belong to this bundle.
  // Should fix this when different combinations of add-ons can belong to different bundles.
  const marketingSuiteAddon = recommendedAddons[0];

  // If nothing is a recommended addon then everything is a recommended addon
  if (recommendedAddons.length === 0) {
    recommendedAddons = subscriptionAddons;
    moreAddons = [];
  }

  return (
    <div className={styles.container} data-testid="addon-cards">
      <Card>
        <Content>
          <Heading level={4}>{formatMessage(messages.addons)}</Heading>
          <Text variation="subdued">{formatMessage(messages.recommended)}</Text>
          <Grid>
            {recommendedAddons.map((subscriptionAddon, index) => (
              <Grid.Cell
                key={`addon-card-${index}`}
                size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
              >
                {renderAddonCard(subscriptionAddon)}
              </Grid.Cell>
            ))}
          </Grid>

          {moreAddons.length > 0 && (
            <>
              <Text variation="subdued">
                {formatMessage(messages.moreAddOns)}
              </Text>
              {selectedAddonCodes.length > 0 &&
                marketingSuiteAddon &&
                !isAddonSelected(selectedAddonCodes, marketingSuiteAddon) && (
                  <UpsellAddon
                    onAddonSelect={() => {
                      handleAddonSelection(marketingSuiteAddon);
                    }}
                  />
                )}
              <Grid>
                {moreAddons.map((subscriptionAddon, index) => (
                  <Grid.Cell
                    key={`addon-card-${index}`}
                    size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
                  >
                    {renderAddonCard(subscriptionAddon)}
                  </Grid.Cell>
                ))}
              </Grid>
            </>
          )}
        </Content>
      </Card>
    </div>
  );

  function renderAddonCard(subscriptionAddon: SubscriptionAddonPreview) {
    return (
      <SubscriptionAddonCard
        shouldShowDisclaimerCTA={shouldShowDisclaimerCTA}
        subscriptionAddon={subscriptionAddon}
        inactiveCardAction={
          isAddonSelected(selectedAddonCodes, subscriptionAddon) ? (
            <Button
              size={"small"}
              type={"tertiary"}
              variation={"destructive"}
              label={formatMessage(messages.remove)}
              ariaLabel={formatMessage(messages.removeAriaLabel, {
                addonName: subscriptionAddon.name,
              })}
              onClick={() =>
                onChangeSelectedAddons(
                  [],
                  [subscriptionAddon.monthlyBillingCycle.addonCode],
                )
              }
            />
          ) : (
            <Button
              size={"small"}
              type={"tertiary"}
              label={formatMessage(messages.addToPlan)}
              ariaLabel={formatMessage(messages.addToPlanAriaLabel, {
                addonName: subscriptionAddon.name,
              })}
              onClick={() => handleAddonSelection(subscriptionAddon)}
            />
          )
        }
      />
    );
  }

  function handleAddonSelection(subscriptionAddon: SubscriptionAddonPreview) {
    const includedAndIncludedWith = (
      subscriptionAddon.includedAddonSetIdentifiers || []
    ).concat(subscriptionAddon.includedWithAddonSetIdentifiers || []);

    const addonCodesToDeselect: string[] = [];

    includedAndIncludedWith.forEach(addonIdentifier => {
      const addonSet = subscriptionAddons.find(
        addon => addon.identifier === addonIdentifier,
      );

      if (addonSet && isAddonSelected(selectedAddonCodes, addonSet)) {
        addonCodesToDeselect.push(addonSet.monthlyBillingCycle.addonCode);
      }
    });

    onChangeSelectedAddons(
      [subscriptionAddon.monthlyBillingCycle.addonCode],
      addonCodesToDeselect,
    );
  }
}
