import React, { useEffect, useState } from "react";
import {
  Button,
  Content,
  Flex,
  Heading,
  Link,
  StatusLabel,
  Text,
  Typography,
} from "@jobber/components";
import { showToast } from "@jobber/components/Toast";
import { type IntlFormatters, useIntl } from "react-intl";
import { useBreakpoints } from "@jobber/hooks/useBreakpoints";
import type {
  JobberPaymentsLimitsChangeRequest,
  JobberPaymentsLimitsChangeRequestLimits,
  JobberPaymentsLimitsChangeRequestStatus,
} from "~/utilities/API/graphql";
import { JobberPaymentsLimitsChangeRequestStatus as JobberPaymentsLimitsChangeRequestEnum } from "~/utilities/API/graphql";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { getMessages } from "./messages";
import {
  useJobberPaymentsLimitsChangeRequestCancel,
  useJobberPaymentsLimitsChangeRequestCreate,
  useLimitsChangeRequest,
} from "./hooks";
import styles from "./styles.module.css";

interface ManageTransactionLimitsProps {
  limitsType: JobberPaymentsLimitsChangeRequestLimits;
  tier?: string;
  canRequestLimitIncrease: boolean;
}

const getStatusDetails = (
  status: JobberPaymentsLimitsChangeRequestStatus | undefined,
  formatMessage: IntlFormatters["formatMessage"],
  messages: ReturnType<typeof getMessages>,
) => {
  const statusDetailsMap: Record<
    JobberPaymentsLimitsChangeRequestStatus,
    { statusLabel: JSX.Element | null; message: string | null }
  > = {
    [JobberPaymentsLimitsChangeRequestEnum.APPROVED]: {
      statusLabel: (
        <StatusLabel
          status="success"
          label={formatMessage(messages.requestApproved)}
        />
      ),
      message: formatMessage(messages.requestedLimitApproved),
    },
    [JobberPaymentsLimitsChangeRequestEnum.PENDING]: {
      statusLabel: (
        <StatusLabel
          status="warning"
          label={formatMessage(messages.requestPending)}
        />
      ),
      message: formatMessage(messages.requestedLimitProcessing),
    },
    [JobberPaymentsLimitsChangeRequestEnum.DECLINED]: {
      statusLabel: (
        <StatusLabel
          status="critical"
          label={formatMessage(messages.requestDeclined)}
        />
      ),
      message: formatMessage(messages.requestedLimitDeclined),
    },
    [JobberPaymentsLimitsChangeRequestEnum.CANCELLED]: {
      statusLabel: null,
      message: null,
    },
  };

  return status
    ? statusDetailsMap[status]
    : { statusLabel: null, message: null };
};

// eslint-disable-next-line max-statements
export function ManageTransactionLimits({
  limitsType,
  canRequestLimitIncrease,
  tier,
}: ManageTransactionLimitsProps) {
  const { formatMessage, formatDate } = useIntl();
  const { data: limitsChangeRequestResponse } =
    useLimitsChangeRequest(limitsType);
  const { extraSmallOnly } = useBreakpoints();
  const [limitsChangeRequest, setLimitsChangeRequest] = useState<
    JobberPaymentsLimitsChangeRequest | undefined
  >(undefined);

  useEffect(() => {
    /* eslint-disable @typescript-eslint/naming-convention */
    Amplitude.TRACK_EVENT("CTA Viewed", {
      name: "Request Limit Increase",
      ach_tier: tier,
      disabled: !canRequestLimitIncrease,
    });
  }, [tier, canRequestLimitIncrease]);

  useEffect(() => {
    if (limitsChangeRequestResponse) {
      setLimitsChangeRequest(limitsChangeRequestResponse);
    }
  }, [limitsChangeRequestResponse]);

  const { createPaymentsLimitsRequest } =
    useJobberPaymentsLimitsChangeRequestCreate(limitsType);

  const { cancelPaymentsLimitsRequest } =
    useJobberPaymentsLimitsChangeRequestCancel(limitsChangeRequest?.id);

  const headingAndStatusLabel = extraSmallOnly ? "column" : "row";
  const messages = getMessages(limitsType);

  const statusDetails = getStatusDetails(
    limitsChangeRequest?.status,
    formatMessage,
    messages,
  );

  return (
    <Content>
      <div className={styles.achLimitIncreaseContent}>
        <Content>
          {limitsChangeRequest?.status ? (
            <Content>
              <Flex
                template={["grow", "shrink"]}
                direction={headingAndStatusLabel}
              >
                <Heading level={4}>
                  {formatMessage(messages.limitIncrease)}
                </Heading>
                {statusDetails.statusLabel}
              </Flex>
              {limitsChangeRequest?.requestedAt && (
                <Text variation="subdued">
                  {formatMessage(messages.requestedOn, {
                    date: formatDate(limitsChangeRequest.requestedAt, {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }),
                  })}
                </Text>
              )}
              <Text>{statusDetails.message}</Text>
              {limitsChangeRequest?.status ===
                JobberPaymentsLimitsChangeRequestEnum.PENDING && (
                <Button
                  label={formatMessage(messages.cancelLimitIncreaseButton)}
                  variation="destructive"
                  size="small"
                  onClick={cancelLimitIncrease}
                />
              )}
            </Content>
          ) : (
            <>
              <Heading level={4}>
                {formatMessage(messages.manageLimits)}
              </Heading>
              <Text>
                {canRequestLimitIncrease && !limitsChangeRequest?.status ? (
                  <>
                    {formatMessage(messages.manageLimitsDescriptionAllowed)}
                    &nbsp;
                    <Typography element={"span"} fontWeight="semiBold">
                      <Link
                        url={
                          "https://help.getjobber.com/hc/en-us/articles/1500004781762-Bank-Payments-ACH"
                        }
                        external={true}
                        ariaLabel={formatMessage(
                          messages.learnMoreAboutLimitsButton,
                        )}
                      >
                        {formatMessage(messages.learnMoreAboutLimitsButton)}
                      </Link>
                    </Typography>
                  </>
                ) : !canRequestLimitIncrease ? (
                  <>
                    {formatMessage(messages.manageLimitsDescriptionNotAllowed)}
                    &nbsp;
                    <Typography element={"span"} fontWeight="semiBold">
                      <Link
                        url={
                          // TODO: Add the correct URL for the limits
                          "https://help.getjobber.com/hc/en-us/articles/1500004781762-Bank-Payments-ACH"
                        }
                        external={true}
                        ariaLabel={formatMessage(
                          messages.learnMoreAboutLimitsButton,
                        )}
                      >
                        {formatMessage(messages.learnMoreAboutLimitsButton)}
                      </Link>
                    </Typography>
                  </>
                ) : null}
              </Text>
              {canRequestLimitIncrease && !limitsChangeRequest?.status ? (
                <Button
                  label={formatMessage(messages.requestLimitIncreaseButton)}
                  variation="work"
                  onClick={requestLimitIncrease}
                  type="primary"
                  size="small"
                />
              ) : null}
            </>
          )}
        </Content>
      </div>
    </Content>
  );

  async function requestLimitIncrease() {
    const { jobberPaymentsLimitsChangeRequest, errorMessage } =
      await createPaymentsLimitsRequest();

    if (jobberPaymentsLimitsChangeRequest) {
      setLimitsChangeRequest(jobberPaymentsLimitsChangeRequest);
      showToast({
        message: formatMessage(messages.requestLimitIncreaseSubmitted),
        variation: "success",
      });
    } else {
      showToast({
        message:
          errorMessage ||
          formatMessage(messages.requestLimitIncreaseSubmittedError),
        variation: "error",
      });
    }
  }

  async function cancelLimitIncrease() {
    const { jobberPaymentsLimitsChangeRequest, errorMessage } =
      await cancelPaymentsLimitsRequest();

    if (
      jobberPaymentsLimitsChangeRequest?.status ===
      JobberPaymentsLimitsChangeRequestEnum.CANCELLED
    ) {
      setLimitsChangeRequest(undefined);
      showToast({
        message: formatMessage(messages.requestLimitIncreaseCancelled),
        variation: "success",
      });
    } else {
      showToast({
        message:
          errorMessage ||
          formatMessage(messages.requestLimitIncreaseCancelledError),
        variation: "error",
      });
    }
  }
}
