import { defineMessages } from "react-intl";

export const messages = defineMessages({
  defaultSuffix: {
    id: "jobber.manageSettings.defaultSuffix",
    defaultMessage: "a specific action.",
    description: "The default suffix for the first message label",
  },
  jobSuffix: {
    id: "jobber.manageSettings.jobSuffix",
    defaultMessage: "their job is closed",
    description: "The suffix for the job trigger on the first message label",
  },
  invoiceSuffix: {
    id: "jobber.manageSettings.invoiceSuffix",
    defaultMessage: "they’ve paid their invoice",
    description:
      "The suffix for the invoice trigger on the first message label",
  },
  firstMessageDescription: {
    id: "reviews.manageSettings.firstMessageDescription",
    defaultMessage: "Automatically ask for a review after {triggerSuffix}",
    description: "Description for the message section",
  },
  title: {
    id: "reviews.manageSettings.title",
    defaultMessage: "Client messages",
    description: "Header for the side drawer",
  },
  titleHelper: {
    id: "reviews.manageSettings.titleHelper",
    defaultMessage:
      "To avoid overwhelming valued clients, messages and follow-ups are not sent more than once every six months",
    description: "Helper text for the side drawer title",
  },
});
